import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import {
  DRIVERS_API,
  VEHICLE_API,
  driver_vehicle_up_api,
  driver_update_api,
  driver_bank_up_api,
  Bank_API_2,
  vehicle_API_2,

} from "../../../utils/apiUrls";
import {
  getApiWithAuth,
  patchApiWithAuth,
  deleteApiWithAuth,
  postApiWithAuth
} from "../../../utils/api";
import { useNavigate, useParams } from "react-router";

import "./EditDriver.css";

function EditDriver() {
  const [backendimg, setbackendimg] = useState(
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
  );
  const [idImage, setIdImage] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [idImage3, setIdImage3] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [isChecked, setIsChecked] = useState(true);
  const [isChecked2, setIsChecked2] = useState(false);
  const [Front, setFront] = useState("");
  const [Fronts, setFronts] = useState("");
  const [Back, setBack] = useState("");
  const [loading, setLoading] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [driverId, setDriverId] = useState(null);
  const navigate = useNavigate();
  const [personalDetail, setPersonalDetail] = useState({
    firstName: "",
    lastName: "",
    address: "",
    // "createdAt": "2023-11-08",
    date: "",
    phoneNumber: "",
    cellNumber: "",
    email: "",
    socialSecurityNo: "",
    decument: "",
    additionInfo: "",
    licenseId: "",
    licenseIssued: "",
    licenseExpired: "",
    isactive: true,
    shift1Price: 0,
    shift2Price: 0,
    shift3Price: 0,
    failedDeliveryPrice: 0,
    urgentDeliveryPrice: 0,
    addtionalNote: "",
    name: "",
    profilefile: null,
    driver: null,
    password: "",
    confirmPassword: "",
    salaryType: null,
    id1card: "",
    id2card: "",
  });

  const [bankDetails, setBankDetails] = useState({
    id: 0,
    bankname: "",
    accountholdername: "",
    accountno: "",
    achRouting: "",
  });

  const [vehicleDetail, setVehicleDetail] = useState({
    id: 0,
    make: "",
    model: "",
    color: "",
    modelYear: "",
    plateNo: "",
    price: 53454.0,
    documentfile: null,
    vehicleType: null,
    // createdby: 3
  });

  const [fieldErrors, setFieldErrors] = useState({
    address: '',
    phoneNumber: '',
    cellNumber: '',
    email: '',
    firstName: '',
    lastName: '',
  });


  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { did } = useParams();

  const getDriver = async () => {
    const response = await getApiWithAuth(`${DRIVERS_API}${did}/`);
    if (response.data.status === 200) {
      setbackendimg(response.data.data?.profilefile);
      if (response.data.data?.isactive === true) {
        setIsChecked(true);
        setIsChecked2(false);
      } else if (response.data.data?.isactive === false) {
        setIsChecked2(true);
        setIsChecked(false);
      }
      setDriverId(response.data.data.id);
      setIdImage(response.data.data?.id1card);
      setIdImage3(response.data.data?.id2card);
      setPersonalDetail((prevState) => ({
        ...prevState,

        location: response.data.data.location,
        phoneNumber: response.data.data.phoneNumber,
        cellNumber: response.data.data.cellNumber,
        socialSecurityNo: response.data.data.socialSecurityNo,
        decument: response.data.data.decument,
        additionInfo: response.data.data.additionInfo,
        licenseId: response.data.data.licenseId,
        licenseIssued: response.data.data.licenseIssued,
        licenseExpired: response.data.data.licenseExpired,
        shift1Price: parseFloat(response.data.data.shift1Price).toFixed(2),
        shift2Price: parseFloat(response.data.data.shift2Price).toFixed(2),
        shift3Price: parseFloat(response.data.data.shift3Price).toFixed(2),
        email: response.data.data.email,
        failedDeliveryPrice: parseFloat(response.data.data.failedDeliveryPrice).toFixed(2),
        urgentDeliveryPrice: parseFloat(response.data.data.urgentDeliveryPrice).toFixed(2),
        firstName: response.data.data.firstName,
        lastName: response.data.data.lastName,
        profilefile: response.data.data?.profilefile,
        address: response.data.data.address,
        driver: response.data.data.driver,
        salaryType: response.data.data.salaryType,
        date: response.data.data.date,
        addtionalNote: response.data.data.addtionalNote,
        id1card: response.data.data?.id1card,
        id2card: response.data.data?.id2card,
        isactive: response.data.data?.isactive,
      }));
      setVehicleDetail((prevState) => ({
        ...prevState,
        id: response.data.data?.vehicle_driver[0]?.id,
        make: response.data.data?.vehicle_driver[0]?.make,
        model: response.data.data?.vehicle_driver[0]?.model,
        color: response.data.data?.vehicle_driver[0]?.color,
        modelYear: response.data.data?.vehicle_driver[0]?.modelYear,
        plateNo: response.data.data?.vehicle_driver[0]?.plateNo,
        price: response.data.data?.vehicle_driver[0]?.price,
        documentfile: response.data.data?.vehicle_driver[0]?.documentfile,
        vehicleType: response.data.data?.vehicle_driver[0]?.vehicleType,
        // createdby: response.data.data?.vehicle_driver[0]?.createdby,
      }));

      setBankDetails((prevState) => ({
        ...prevState,
        id: response.data.data?.bank_detail_driver[0]?.id,
        bankname: response.data.data?.bank_detail_driver[0]?.bankname,
        accountholdername:
          response.data.data?.bank_detail_driver[0]?.accountholdername,
        accountno: response.data.data?.bank_detail_driver[0]?.accountno,
        achRouting: response.data.data?.bank_detail_driver[0]?.achRouting,
      }));
    } else {
      console.log("backend error");
    }
  };

  const getVehicle = async () => {
    setLoading(true);
    console.log("work");
    const response = await getApiWithAuth(VEHICLE_API);

    if (response.data !== undefined) {
      setVehicleTypes(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handlePersonalInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalDetail({
      ...personalDetail,
      [name]: value,
    });

    setFieldErrors({ ...fieldErrors, [name]: '' });
  };

  const handleBankInputChange = (event) => {
    const { name, value } = event.target;
    setBankDetails({
      ...bankDetails,
      [name]: value,
    });
  };

  const handleVehicleInputChange = (event) => {
    const { name, value } = event.target;
    setVehicleDetail({
      ...vehicleDetail,
      [name]: value,
    });
  };

  useEffect(() => {
    getVehicle();
    getDriver();
  }, []);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Check if fields are empty
    if (!personalDetail.address) {
      errors.address = 'Address is required';
      isValid = false;
    }
    if (!personalDetail.phoneNumber) {
      errors.phoneNumber = 'Phone Number is required';
      isValid = false;
    }
    if (!personalDetail.cellNumber) {
      errors.cellNumber = 'Cell Number is required';
      isValid = false;
    }
    if (!personalDetail.email) {
      errors.email = 'Email is required';
      isValid = false;
    }
    if (!personalDetail.firstName) {
      errors.firstName = 'First name is required';
      isValid = false;
    }
    if (!personalDetail.lastName) {
      errors.lastName = 'Last name is required';
      isValid = false;
    }
    if (!personalDetail.shift1Price) {
      errors.shift1Price = '9:00 AM To 3:00 PM Price is required';
      isValid = false;
    }
    if (!personalDetail.shift2Price) {
      errors.shift2Price = '2:00 PM To 6:00 PM Price is required';
      isValid = false;
    }
    if (!personalDetail.shift3Price) {
      errors.shift3Price = '5:00 PM To 9:00 PM Price is required';
      isValid = false;
    }
    if (!personalDetail.failedDeliveryPrice) {
      errors.failedDeliveryPrice = 'Failed Delivery Price is required';
      isValid = false;
    }
    if (!personalDetail.urgentDeliveryPrice) {
      errors.urgentDeliveryPrice = 'Urgent Delivery Price is required';
      isValid = false;
    }
    setFieldErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    console.log("Button clicked");
    event.preventDefault();
    if (validateForm()) {
      console.log("Button clicked 292");
      const updatedPersonalDetail = {
        ...personalDetail,
        name: personalDetail.firstName + " " + personalDetail.lastName,
      };

      let payload = new FormData();
      payload.append("isactive", updatedPersonalDetail.isactive);
      payload.append("name", updatedPersonalDetail.name);
      payload.append("address", updatedPersonalDetail.address);
      payload.append("location", updatedPersonalDetail.location);
      payload.append("phoneNumber", updatedPersonalDetail.phoneNumber);
      payload.append("cellNumber", updatedPersonalDetail.cellNumber);
      payload.append("socialSecurityNo", updatedPersonalDetail.socialSecurityNo);
      payload.append("decument", updatedPersonalDetail.decument);
      payload.append("additionInfo", updatedPersonalDetail.additionInfo);
      payload.append("licenseId", updatedPersonalDetail.licenseId);
      payload.append("licenseIssued", updatedPersonalDetail.licenseIssued);
      payload.append("licenseExpired", updatedPersonalDetail.licenseExpired);
      payload.append("shift1Price", updatedPersonalDetail.shift1Price);
      payload.append("shift2Price", updatedPersonalDetail.shift2Price);
      payload.append("shift3Price", updatedPersonalDetail.shift3Price);
      payload.append("email", updatedPersonalDetail.email);
      if (updatedPersonalDetail.password !== "") {
        payload.append("password", updatedPersonalDetail.password);
      }
      payload.append(
        "failedDeliveryPrice",
        updatedPersonalDetail.failedDeliveryPrice
      );
      payload.append(
        "urgentDeliveryPrice",
        updatedPersonalDetail.urgentDeliveryPrice
      );
      payload.append("firstName", updatedPersonalDetail.firstName);
      payload.append("lastName", updatedPersonalDetail.lastName);
      if (Fronts.length > 1) {
        payload.append("profilefile", updatedPersonalDetail.profilefile);
      }
      if (Back.length > 1) {
        payload.append("id2card", updatedPersonalDetail.id2card);
      }
      if (Front.length > 1) {
        payload.append("id1card", updatedPersonalDetail.id1card);
      }

      // payload.append("driver", personalDetail.driver);
      // payload.append("salaryType", personalDetail.salaryType);
      payload.append("date", personalDetail.date);
      payload.append("addtionalNote", personalDetail.addtionalNote);
      const response1 = await patchApiWithAuth(
        `${driver_update_api}/${driverId}`,
        payload
      );
      
      if (bankDetails.bankname !== "") {
        let payloadBank = new FormData();
        payloadBank.append("bankname", bankDetails.bankname);
        payloadBank.append("accountholdername", bankDetails.accountholdername);
        payloadBank.append("accountno", bankDetails.accountno);
        payloadBank.append("achRouting", bankDetails.achRouting);
        console.log(bankDetails.id)
        if (bankDetails.id && bankDetails.id !== 0) {
          const responseBank = await patchApiWithAuth(
            `${driver_bank_up_api}/${bankDetails.id}`,
            payloadBank
          );
        } else {
          payloadBank.append("driver", driverId);
          const responseBank = await postApiWithAuth(Bank_API_2, payloadBank);
        }
      }
      
      if (vehicleDetail.make !== "") {
        let vehicle_payload = new FormData();
        vehicle_payload.append("make", vehicleDetail.make);
        vehicle_payload.append("model", vehicleDetail.model);
        vehicle_payload.append("color", vehicleDetail.color);
        vehicle_payload.append("modelYear", vehicleDetail.modelYear);
        vehicle_payload.append("plateNo", vehicleDetail.plateNo);
        if (vehicleDetail.id && vehicleDetail.id !== 0) {
          vehicle_payload.append("price", vehicleDetail.price);
          const vresp = await patchApiWithAuth(
            `${driver_vehicle_up_api}/${vehicleDetail.id}`,
            vehicle_payload
          );
        } else {
          vehicle_payload.append("driver", driverId);
          const vehiclePersonal = await postApiWithAuth(
            vehicle_API_2,
            vehicle_payload
          );
        }
      }
      
      navigate("/list/driver");
    }
  };

  const handleEditClickkk = () => {
    // Trigger the hidden file input when EditIcon is clicked
    document.getElementById("imageInput").click();
  };

  const setFrontImage = (imageData) => {
    setPersonalDetail({
      ...personalDetail,
      id1card: imageData,
    });
  };

  const setBackImage = (imageData) => {
    setPersonalDetail({
      ...personalDetail,
      id2card: imageData,
    });
  };

  const handleImageUploaddd = (event) => {
    const fileObject = event.target.files[0];
    setFrontImage(fileObject);

    const { name, size, type, lastModified } = fileObject;

    const fileBlob = new Blob([fileObject], { type });
    const file = new File([fileBlob], name, { lastModified });
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the image URL in the state
        setFront(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClickkk2 = () => {
    // Trigger the hidden file input when EditIcon is clicked
    document.getElementById("imageInput2").click();
  };

  const handleImageUploaddd2 = (event) => {
    // const file = event.target.files[0];
    // setBackImage(file);
    const fileObject = event.target.files[0];
    setBackImage(fileObject);
    // Extract the file data from the object
    const { name, size, type, lastModified } = fileObject;

    // Create a Blob from the file data
    const fileBlob = new Blob([fileObject], { type });

    // Create a File instance
    const file = new File([fileBlob], name, { lastModified });
    // setBackImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the image URL in the state
        setBack(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledelete = async () => {
    const id = driverId;
    if (id !== null) {
      const response = await deleteApiWithAuth(`${DRIVERS_API}${id}/`);

      handleClose();
      navigate("/list/driver");
    } else {
      alert("Please Select valid Driver");
      handleClose();
    }
  };

  const handleEditClickk = () => {
    document.getElementById("imageInputt").click();
  };

  const updateIsActive = () => {
    setIsChecked2(false);
    setIsChecked(true);
    setPersonalDetail({
      ...personalDetail,
      isactive: true,
    });
  };

  const updateIsActive2 = () => {
    setIsChecked(false);
    setIsChecked2(true);
    setPersonalDetail({
      ...personalDetail,
      isactive: false,
    });
  };

  const handleImageUploadds = (event) => {
    const fileObject = event.target.files[0];

    setPersonalDetail({
      ...personalDetail,
      profilefile: fileObject,
    });
    const { name, size, type, lastModified } = fileObject;

    const fileBlob = new Blob([fileObject], { type });

    const file = new File([fileBlob], name, { lastModified });

    if (file) {
      const reader = new FileReader();
      // Read the contents of the image file as a data URL
      reader.onloadend = () => {
        // Set the image URL in the state
        setFronts(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container className="form-grid">
          <Grid item className="drive-grid" xs={3}>
            <div className="form-sec-head-driver margin-top-heading">
              Drive Information
            </div>
          </Grid>{" "}
          <Grid item className="drive-grid ml-5" xs={3}></Grid>
          <Grid item className="drive-grid " xs={3}>
            <button className="activate" color="apple" variant="contained">
              Activate
            </button>{" "}
            <Checkbox
              name="bussAddress"
              checked={isChecked}
              onClick={() => updateIsActive()}
            />
            {/* </Grid>
          <Grid item className="drive-grid " > */}
            <button className="deactivate">Deactivate</button>{" "}
            <Checkbox
              name="bussAddress2"
              checked={isChecked2}
              onChange={() => updateIsActive2()}
            />
          </Grid>
          <Grid item className="drive-grid " xs={1.5}>
            {/* Hidden file input triggered by the EditIcon click */}
            <input
              autoComplete="off"
              type="file"
              id="imageInputt"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageUploadds}
            />

            {Fronts ? (
              <img
                src={Fronts}
                style={{ borderRadius: "100%", height: "50px", width: "50px" }}
                className="id-image"
                alt="Image 1"
              />
            ) : (
              <img
                src={backendimg}
                style={{ borderRadius: "100%", height: "50px", width: "50px" }}
                className="id-image"
                alt="Image 1"
              />
            )}
            <div className="img-icon" onClick={handleEditClickk}>
              {/* Render your EditIcon component here */}
              <EditIcon />
            </div>
          </Grid>
          <Grid item className="drive-grid " style={{ marginLeft: "-30px" }}>
            <div className="img-icon" onClick={handleEditClickk}>
              {/* Render your EditIcon component here */}
            </div>
          </Grid>
        </Grid>
        <Grid container className="form-grid" spacing={2}>
          {/* First Row */}
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">First Name</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="firstName"
              placeholder="First Name"
              value={personalDetail.firstName}
              onChange={handlePersonalInputChange}
            />
            {fieldErrors.firstName && <span className="error-message">{fieldErrors.firstName}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Last Name</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="lastName"
              placeholder="Last Name"
              value={personalDetail.lastName}
              onChange={handlePersonalInputChange}
            />
            {fieldErrors.lastName && <span className="error-message">{fieldErrors.lastName}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={6}>
            <label className="driver-field-label">Address</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="address"
              placeholder="Address"
              value={personalDetail.address}
              onChange={handlePersonalInputChange}
            />
            {fieldErrors.address && <span className="error-message">{fieldErrors.address}</span>}
          </Grid>

          {/* Second Row */}
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Date of Birth</label>
            <input
              autoComplete="off"
              type="date"
              className="driver-field"
              name="date"
              placeholder="YYYY/MM/DD"
              value={personalDetail.date}
              onChange={handlePersonalInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Phone Number</label>
            <input
              autoComplete="off"
              type="number"
              className="driver-field"
              name="phoneNumber"
              placeholder="Phone Number"
              value={personalDetail.phoneNumber}
              onChange={handlePersonalInputChange}
            />
            {fieldErrors.phoneNumber && <span className="error-message">{fieldErrors.phoneNumber}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Cell Number</label>
            <input
              autoComplete="off"
              type="number"
              className="driver-field"
              name="cellNumber"
              placeholder="Cell Number"
              value={personalDetail.cellNumber}
              onChange={handlePersonalInputChange}
            />
            {fieldErrors.cellNumber && <span className="error-message">{fieldErrors.cellNumber}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Email</label>
            <input
              autoComplete="off"
              type="email"
              className="driver-field"
              name="email"
              placeholder="Email"
              value={personalDetail.email}
              onChange={handlePersonalInputChange}
              disabled
            />
            {fieldErrors.email && <span className="error-message">{fieldErrors.email}</span>}
          </Grid>

          {/* Third& Forth Row */}
          <Grid item className="drive-grid" xs={6} md={6}>
            <Grid container spacing={1}>
              {/* Four Fields in a 3-3-3-3 Configuration */}
              <Grid item className="drive-in-grid drive-sec-grid" xs={6} md={6}>
                <label className="driver-field-label">
                  Social Security Number
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className="driver-field"
                  name="socialSecurityNo"
                  placeholder="Number"
                  value={personalDetail.socialSecurityNo}
                  onChange={handlePersonalInputChange}
                />
              </Grid>
              <Grid item className="drive-grid-2" xs={6} md={6}>
                <label className="driver-field-label">Driver License ID</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="driver-field"
                  name="licenseId"
                  placeholder="Driver License ID"
                  value={personalDetail.licenseId}
                  onChange={handlePersonalInputChange}
                />
              </Grid>
              <Grid item className="drive-in-grid drive-sec-grid" xs={6} md={6}>
                <label className="driver-field-label">License Issued</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="driver-field"
                  name="licenseIssued"
                  placeholder="License Issued"
                  value={personalDetail.licenseIssued}
                  onChange={handlePersonalInputChange}
                />
              </Grid>
              <Grid item className="drive-grid-2" xs={6} md={6}>
                <label className="driver-field-label">License Expires</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="driver-field"
                  name="licenseExpired"
                  placeholder="License Expired"
                  value={personalDetail.licenseExpired}
                  onChange={handlePersonalInputChange}
                />
              </Grid>
              <Grid item className="drive-in-grid drive-sec-grid" xs={6} md={6}>
                <label className="driver-field-label">Change Password</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="driver-field"
                  name="password"
                  // placeholder="Password"
                  value={personalDetail.password}
                  onChange={handlePersonalInputChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right Grid */}
          <Grid item className="drive-grid" xs={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="img-sec-driver">
                  <p>License Front</p>
                  <div className="img-icon" onClick={handleEditClickkk}>
                    {/* Render your EditIcon component here */}
                    <EditIcon />
                  </div>
                  {/* Hidden file input triggered by the EditIcon click */}
                  <input
                    autoComplete="off"
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleImageUploaddd}
                  />
                </div>
                {Front ? (
                  <img src={Front} className="id-image" alt="Image 1" />
                ) : (
                  <img src={idImage} className="id-image" alt="Image 1" />
                )}
              </Grid>
              <Grid item xs={6}>
                <div className="img-sec-driver">
                  <p>License Back</p>
                  <div className="img-icon" onClick={handleEditClickkk2}>
                    {/* Render your EditIcon component here */}
                    <EditIcon />
                  </div>
                  {/* Hidden file input triggered by the EditIcon click */}
                  <input
                    autoComplete="off"
                    type="file"
                    id="imageInput2"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleImageUploaddd2}
                  />
                </div>
                {Back ? (
                  <img src={Back} className="id-image" alt="Image 1" />
                ) : (
                  <img src={idImage3} className="id-image" alt="Image 1" />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="form-sec-head-driver">Banking Details</div>
        <Grid container className="form-grid" spacing={2}>
          {/* First Row */}
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Bank Name</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="bankname"
              placeholder="Bank Name"
              value={bankDetails.bankname}
              onChange={handleBankInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Title on Account</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="accountholdername"
              placeholder="Title on Account"
              value={bankDetails.accountholdername}
              onChange={handleBankInputChange}
            />
          </Grid>

          {/* Second Row */}
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Account Number</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="accountno"
              placeholder="Account Number"
              value={bankDetails.accountno}
              onChange={handleBankInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">ACH Routing</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="achRouting"
              placeholder="ACH Routing"
              value={bankDetails.achRouting}
              onChange={handleBankInputChange}
            />
          </Grid>
        </Grid>

        <div className="form-sec-head-driver">Vehicle Information</div>
        <Grid container className="form-grid" spacing={2}>
          {/* First Row */}
          {/* <Grid item className="drive-grid" xs={2}>
            <label className="driver-field-label">Type</label>
            <input
                  autoComplete="off"
              type="text"
              className="driver-field"
              name="vehicleType"
              placeholder="Type"
              // value={1}
              value={vehicleDetail.vehicleType}
              onChange={handleVehicleInputChange}
            />
          </Grid> */}
          <Grid item className="drive-grid" xs={2}>
            <label className="driver-field-label">Type</label>
            <select
              className="driver-field"
              name="vehicleType"
              value={vehicleDetail.vehicleType || ""} // Added a fallback to empty string to handle null or undefined
              onChange={handleVehicleInputChange}
            >
              {vehicleTypes.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.type}
                </option>
              ))}
            </select>
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="driver-field-label">Make</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="make"
              placeholder="Make"
              value={vehicleDetail.make}
              onChange={handleVehicleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="driver-field-label">Model</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="model"
              placeholder="Model"
              value={vehicleDetail.model}
              onChange={handleVehicleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="driver-field-label">Color</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="color"
              placeholder="Color"
              value={vehicleDetail.color}
              onChange={handleVehicleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="driver-field-label">Model Year</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="modelYear"
              placeholder="Model Year"
              value={vehicleDetail.modelYear}
              onChange={handleVehicleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="driver-field-label">Plate Number</label>
            <input
              autoComplete="off"
              type="text"
              className="driver-field"
              name="plateNo"
              placeholder="Plate Number"
              value={vehicleDetail.plateNo}
              onChange={handleVehicleInputChange}
            />
          </Grid>
        </Grid>

        <div className="form-sec-head-driver">Pay Rates</div>
        <Grid container className="form-grid" spacing={2}>
          {/* First Row */}
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">9:00 AM To 3:00 PM</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="driver-field"
              name="shift1Price"
              placeholder="price"
              value={personalDetail.shift1Price}
              onChange={handlePersonalInputChange}
            />
             {fieldErrors.shift1Price && <span className="error-message">{fieldErrors.shift1Price}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">2:00 PM To 6:00 PM</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="driver-field"
              name="shift2Price"
              placeholder="price"
              value={personalDetail.shift2Price}
              onChange={handlePersonalInputChange}
            />
           {fieldErrors.shift2Price && <span className="error-message">{fieldErrors.shift2Price}</span>}
          </Grid>

          {/* Second Row */}
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">5:00 PM To 9:00 PM</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="driver-field"
              name="shift3Price"
              placeholder="price"
              value={personalDetail.shift3Price}
              onChange={handlePersonalInputChange}
            />
             {fieldErrors.shift3Price && <span className="error-message">{fieldErrors.shift3Price}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="driver-field-label">Failed Delivery</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="driver-field"
              name="failedDeliveryPrice"
              placeholder="Failed Delivery"
              value={personalDetail.failedDeliveryPrice}
              onChange={handlePersonalInputChange}
            />
                         {fieldErrors.failedDeliveryPrice && <span className="error-message">{fieldErrors.failedDeliveryPrice}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Urgent Delivery</label>
          <input
            autoComplete="off"
            type="number"
            step="0.01"
            className="driver-field"
            name="urgentDeliveryPrice"
            placeholder="Urgent Delivery"
            value={personalDetail.urgentDeliveryPrice}
            onChange={handlePersonalInputChange}
            required
          />
             {fieldErrors.urgentDeliveryPrice && <span className="error-message">{fieldErrors.urgentDeliveryPrice}</span>}
        </Grid>
        </Grid>

        <Grid container className="form-grid" spacing={2}>
          {/* Four Fields in a 3-3-3-3 Configuration */}
          {/* <Grid item className="drive-in-grid" xs={6}>
          <div className="form-sec-head-driver">Addtional Document</div>
            <textarea
              rows="8" 
              className="driver-textarea-field"
              name="socialSecurityNumber"
              value={personalDetail.socialSecurityNumber}
              onChange={handlePersonalInputChange}
            />
          </Grid> */}
          <Grid item className="drive-in-grid" xs={6}>
            <div className="form-sec-head-driver ">Addtional Information</div>
            <textarea
              rows="8"
              className="driver-textarea-field"
              name="addtionalNote"
              value={personalDetail.addtionalNote}
              onChange={handlePersonalInputChange}
            />
          </Grid>
        </Grid>

        <div className="driver-btn">
          <button
            type="button"
            className="del-driver"
            onClick={handleClickOpen}
          >
            Delete Account
          </button>
          <button type="submit" className="save-driver">
            Update Details
          </button>
        </div>
      </form>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        style={{ color: "white" }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are You Sure to delete this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{
              color: "red",
              border: "1px solid red",
              borderRadius: "20px",
            }}
            onClick={handledelete}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditDriver;

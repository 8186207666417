import React from "react";
import WestIcon from "@mui/icons-material/West";
import OrderCard from "./OrderCard/OrderCard";

export default function OrdersListing() {
  return (
    <div className="OrdersListing flex gap-sm w-full">
      <div className="basis-[1%]">
        <WestIcon />
      </div>

      <div className="basis-[99%]">
        <div className="OrdersListing flex flex-col gap-sm w-full">
          <div className="flex items-center gap-sm">
            <p className="text-[20px] font-[700]">DAILY ORDERS BY PHARMACIES</p>
          </div>

          <div className="grid grid-cols-7 rounded p-sm border border-[#D9D9D9]">
            <div>
              <p className="text-[18px] font-[700]">Today Orders (400)</p>
            </div>
            <div>
              <p className="text-[18px] font-[700]">Today 2pm To 6pm (97)</p>
            </div>
            <div>
              <p className="text-[18px] font-[700]">Today 5pm TO 9pm (88)</p>
            </div>
            <div>
              <p className="text-[18px] font-[700]">Next day Anytime (250)</p>
            </div>
            <div>
              <p className="text-[18px] font-[700]">Next day 9am To 2pm (88)</p>
            </div>
            <div>
              <p className="text-[18px] font-[700]">Next day 2pm TO 6pm (88)</p>
            </div>
            <div>
              <p className="text-[18px] font-[700]">Next day 5pm TO 9pm (88)</p>
            </div>
          </div>

          <div>
            <OrderCard />
            <OrderCard />
            <OrderCard />
            <OrderCard />
            <OrderCard />
            <OrderCard />
            <OrderCard />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ForwardIcon from "@mui/icons-material/Forward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RouteSlipRoute from "../../CommonComponents/PrintDoc/RouteSlipRoute";
import { useReactToPrint } from 'react-to-print';

import { DateRange } from "react-date-range";
import {
  landline,
  iphone,
  route_report,
  pharmacyCallBlack,
  pdfLogo,
  registered,
  photoId,
  buildings
} from '../../../assests';
import { Autocomplete, TextField, Button } from "@mui/material";

import { toast } from "react-toastify";
import { ArrowDropDownCircle } from "@mui/icons-material";
import {
  ALL_ROUTES_WEB,
  MERGE_ROUTE,
  ALL_ZONES_LIST,
  DRIVERS_ROUTE
} from "../../../utils/apiUrls";
import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";

import RouteObject from "../../MiniComponents/RouteObject";
import "./routeList.css";

function RouteList() {
  const [loading, setLoading] = useState(false);
  const [mergeRoute, setMergerRoute] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRouteIds, setSelectedRouteIds] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [zip, setZip] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [activeDriverList, setActiveDriverList] = useState([]);
  const [borough, setBorough] = useState([]);
  const [driver, setDriver] = useState(null);
  const [modalCreateRouteOpen, setModalCreateRouteOpen] = useState(false);
  const [zone, setZone] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [slug, setSlug] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000),
      key: "selection",
    },
  ]);
  const [selectedRoutes, setSelectedRoutes] = useState(null);

  const handleIconClick = (routes) => {
    console.log("selectedroutes66",routes)
    setSelectedRoutes(routes);
  };
  useEffect(() => {
    handlePrintSlip();
  }, [selectedRoutes]);
  
  const getRoutes = async () => {
    setLoading(true);
    console.log(search !== "", zip.length > 0, driver);
    // const response = await getApiWithAuth(
    //   ALL_ROUTES_WEB + `?page=${pageIndex + 1}&pageSize=${pageSize}&dated_response=true&search=${search}&slug=${slug}`,
    //   search === "" && zip.length === 0 && !driver ? {
    //     zip: zip,
    //     driver: driver,
    //     from: moment(dateRange[0].startDate).format("MM-DD-YYYY"),
    //     to: search === "" && moment(dateRange[0].endDate).format("MM-DD-YYYY"),
    //   } : {
    //     zip: zip,
    //     driver: driver
    //   }
    // );
    const queryParams = {
      zip: zip,
      driver: driver,
    };
    
    if (search === "" && slug === "") {
      queryParams.from = moment(dateRange[0].startDate).format("MM-DD-YYYY");
      queryParams.to = moment(dateRange[0].endDate).format("MM-DD-YYYY");
    }
    
    const response = await getApiWithAuth(
      ALL_ROUTES_WEB + `?page=${pageIndex + 1}&pageSize=${pageSize}&dated_response=true&search=${search}&slug=${slug}`,
      queryParams
    );
    console.log("WW", response)
    if (response.data !== undefined) {
      // setRoutes(response.data.data.data);
      console.log("response.data.data.data",response.data.data.data)
      let groupedRoutes = {};
        response.data.data.data.forEach(route => {
            // Extract date from createdAt field
            const createdAtDate = new Date(route.createdAt).toLocaleDateString();
            // Group routes by createdAt date
            if (!groupedRoutes[createdAtDate]) {
                groupedRoutes[createdAtDate] = [route];
            } else {
                groupedRoutes[createdAtDate].push(route);
            }
        });
        // Convert object to array of grouped data
        const groupedRoutesArray = Object.keys(groupedRoutes).map(date => ({ date, data: groupedRoutes[date] }));

        // If you need to filter based on selectedDate
        let filteredRoutes = groupedRoutesArray;
        if (selectedDate) {
            const selectedDateString = selectedDate.toLocaleDateString();
            filteredRoutes = groupedRoutesArray.filter(group => group.date === selectedDateString);
        }
      console.log("filteredRoutes",filteredRoutes)
      setRoutes(filteredRoutes);
      setPageIndex(response.data.data.pagination.pageIndex - 1);
      setPageSize(response.data.data.pagination.pageSize);
      setTotalPages(response.data.data.pagination.totalPages);
      setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      if (inputValue.includes("-")) {
        setSlug(inputValue);
      } else {
        setSearch(inputValue);
      }
    }
  };

  const getDrivers = async () => {
    try {
      const Response = await getApiWithAuth(DRIVERS_ROUTE, {"justList": true});;
      setDriverList(Response.data.data);
      const filteredDrivers = Response.data.data.filter(item => item.isactive);
      setActiveDriverList(filteredDrivers);
    } catch {
      toast.error(`Backend Error for get driver list!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setMergerRoute(false);
    }
  };

  useEffect(() => {
    getRoutes();
  }, [search,slug, zip, driver, dateRange, pageIndex, pageSize]);

  useEffect(() => {
    fetchBorough();
    getDrivers();
  }, []);

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  const handleSelect = (ranges) => {
    if (ranges.selection.endDate) {
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const startItem = pageIndex * pageSize + 1;
  const endItem = Math.min(startItem + pageSize - 1, totalItems);
  const componentRef2 = useRef();

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });
  const handleRouteSelection = (routeId) => {
    if (selectedRouteIds.includes(routeId)) {
      setSelectedRouteIds(selectedRouteIds.filter((id) => id !== routeId));
    } else {
      setSelectedRouteIds([...selectedRouteIds, routeId]);
    }
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  useEffect(() => {
    const inputElement = document.getElementById("customDatePicker");

    if (inputElement) {
      inputElement.addEventListener("click", () => {
        inputElement.click();
      });
    }
  }, []);

  const handleMergeRoute = async () => {
    setLoading(true);
    const routeOrderResponse = await postApiWithAuth(MERGE_ROUTE, {
      routeIds: selectedRouteIds,
    });

    if (routeOrderResponse.data.status === 201) {
      toast.success(`Route merge successfully!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setModalCreateRouteOpen(false);
      getRoutes();
      setMergerRoute(false);
    } else {
      toast.error(`Backend Error!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      setMergerRoute(false);
    }
    setLoading(false);
    setSelectedRouteIds([])
  };

  const fetchBorough = async () => {
    try {
      const response = await getApiWithAuth(ALL_ZONES_LIST + "borros/", {"justList": true});
      console.log(response);
      if (response.data !== undefined) {
        setBorough(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleZipChange = (e, value) => {
    console.log(value);
    if (value.length > 0) {
      const selectedZones = value?.map((selectedOption) =>
        zone.find((zone) => zone.id === selectedOption.id)
      );
      const zipcodes = selectedZones.flatMap((selectedZone) =>
        selectedZone ? selectedZone.zipcode.map((item) => item.zipcode) : []
      );
      setZip(zipcodes);
      console.log(e);
    } else {
      setZip("");
    }
  };

  const handleBoroughChange = async (e, value) => {
    console.log(value);
    if (value !== null) {
      const selectedBoroughs = value;
      const response = await getApiWithAuth(ALL_ZONES_LIST + `borros/${selectedBoroughs.id}/`);
      if (response.data !== undefined) {
        setZone(response.data.data.zones);
      }

      const zones = response.data.data.zones

      // Use flatMap to extract zipcodes from selected zones within selected boroughs
      const zipcodes = zones
        .filter((zone) => zone.zipcode.length > 0)
        .flatMap((zone) => zone.zipcode.map((zipcode) => zipcode.zipcode));
      console.log(zipcodes);
      setZip(zipcodes);
    } else {
      setZip([]);
      setZone([]);
    }
  };

  const handleDriverChange = (e, value) => {
    if (value !== null) {
      setDriver(value.id);
    } else {
      setDriver(null);
    }
  };

  // const sortedDates = Object.keys(groupedRoutes).sort((a, b) => {
  //   const dateA = new Date(a).toLocaleDateString('en-US');
  //   const dateB = new Date(b).toLocaleDateString('en-US');
  //   return new Date(dateB) - new Date(dateA);
  // });

  return (
    <div>
      <div className="second-row">
        <div className="filter-container">
          <div className="route-search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="slug"
              autoComplete="off"
              onKeyDown={handleSearchKeyDown}
              placeholder="Route Name, Order Name"
              className="search-input"
            />
          </div>
          <div className="search-filter-container">
            <Autocomplete
              style={{ padding: "0" }}
              disablePortal
              id="combo-box-demo"
              options={borough}
              className="filter-input"
              sx={{
                width: 300,
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px', fontFamily: "Saira Extra Condensed" }}>
                  {option.name}
                </p>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="Select Borough" sx={{
                  "& input::placeholder": {
                    fontSize: "14px",
                    opacity: 1,
                    fontFamily: "Saira Extra Condensed !important"
                  },
                  "& input": {
                    fontSize: '14px !important',
                    fontFamily: "Saira Extra Condensed !important"
                  }
                }}/>
              )}
              getOptionLabel={(option) => option.name }
              onChange={handleBoroughChange}
              // IconComponent={ExpandCircleDownIcon}
              popupIcon={<ArrowDropDownCircle sx={{color: 'black', width: 20}}/>}
            />

            {/* <ExpandCircleDownIcon /> */}
          </div>
          <div className="search-filter-container">
            <Autocomplete
              style={{ padding: "0" }}
              disablePortal
              multiple
              id="combo-box-demo"
              options={zone}
              className="filter-input"
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px',fontFamily: "Saira Extra Condensed" }}>
                  {option.name}
                </p>
              )}
              sx={{
                width: 300,
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="Select Zone" sx={{
                  "& input::placeholder": {
                    fontSize: "14px",
                    opacity: 1,
                    fontFamily: "Saira Extra Condensed !important"
                  },
                  "& input": {
                    fontSize: '14px !important',
                    fontFamily: "Saira Extra Condensed !important"
                  }
                }}/>
              )}
              getOptionLabel={(option) => option.name}
              onChange={handleZipChange}
              popupIcon={<ArrowDropDownCircle sx={{color: 'black', width: 20}}/>}
            />
          </div>
          <div className="search-filter-container">
            <Autocomplete
              style={{ padding: "0" ,fontFamily: "Saira Extra Condensed !important"}}
              disablePortal
              id="combo-box-demo"
              options={driverList}
              className="filter-input"
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px' ,fontFamily: "Saira Extra Condensed" }}>
                  {option.name}
                </p>
              )}
              sx={{
                // width: 300,
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="Select Driver" sx={{
                  "& input::placeholder": {
                    fontSize: "14px",
                    opacity: 1,
                    fontFamily: "Saira Extra Condensed !important"
                  },
                  "& input": {
                    fontSize: '14px !important',
                    fontFamily: "Saira Extra Condensed !important"
                  }
                }}/>
              )}
              getOptionLabel={(option) => option.name}
              onChange={handleDriverChange}
              popupIcon={<ArrowDropDownCircle sx={{color: 'black', width: 20}}/>}
            />
          </div>
        </div>
        {mergeRoute && (
          <>
            <Button
              variant="contained"
              color="success"
              disabled={loading}
              onClick={handleMergeRoute}
            >
              Merge Routes
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setMergerRoute(false)}
            >
              Cancel
            </Button>
          </>
        )}
        <div className="route-date-input-container">
          <div type="text" className="date-input">
            <CalendarMonthIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={toggleCalendar}
            />
            {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
            <ForwardIcon style={{ fontSize: "32px" }} />
            {dateRange[0].endDate
              ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
              : ""}
          </div>
          {isCalendarVisible && (
            <div className="calendar-container">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )}
        </div>
      </div>
      <div>
      <div className="route-obj">
        {routes.map((date, i) => (
          <div key={i} className="daily-route-list">
            <div className="route-obj-top">
              <h3 className="route-top-day">{moment(date.date).format("MM-DD-YYYY")}</h3>
              <img 
                src={route_report} 
                onClick={() => handleIconClick(date.data)}
                style={{ marginRight: '30px', marginBottom: '3px' }}
                alt="print" 
              />
            </div>
            {date.data.map((route) => (
              <div key={route.id} style={{ display: "flex" }}>
                {mergeRoute && (
                  <input
                    autoComplete="off"
                    type="checkbox"
                    checked={selectedRouteIds.includes(route.id)}
                    onChange={() => handleRouteSelection(route.id)}
                  />
                )}
                <RouteObject
                  route={route}
                  setMergerRoute={setMergerRoute}
                  getRoutes={getRoutes}
                  driverList={activeDriverList}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div style={{display: 'none'}}>
      {selectedRoutes && (
        <div ref={componentRef2}>
        <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px', pageBreakBefore: 'always', pageBreakAfter: 'always', pageBreakInside: 'avoid'}} >
          <RouteSlipRoute routes={selectedRoutes} />
        </div>
    </div>
      )}
      </div>
      </div>
      <div className="footer-container">
        <div />
        <div className="pagination">
          <button className="page-row">Row per page - {pageSize}</button>
          {/* <button className="pag-row" style={{backgroundColor: '#e2e2e2'}}>Row per page - {pageSize}</button> */}
          <div className="page-row">
            {pageIndex > 0 && (
              <ExpandCircleDownIcon
                className="pag-icon-prev"
                onClick={() => setPageIndex(pageIndex - 1)}
              />
            )}
            
            <p>
              Page {startItem} - {endItem}
            </p>
            {pageIndex < totalPages - 1 && (
              <ExpandCircleDownIcon
                className="pag-icon"
                onClick={() => setPageIndex(pageIndex + 1)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RouteList;
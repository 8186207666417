import { Avatar, Button } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import {
  phone,
  userBlack,
  landline,
  mobile,
  closeIconRed,
  history,
  view,
  notes,
  images,
  resecdule,
} from "../../../../assests";
import Checkbox from "@mui/material/Checkbox";
import { BiCheckDouble } from "react-icons/bi";

export default function TicketCard({ticket}) {
  return (
    <div className="TicketCard rounded-sm border border-[#D9D9D9] p-md">
      <div className="grid grid-cols-12 gap-4">
        <div className="flex flex-col justify-around gap-md md:col-span-3 lg:col-span-8">
          <div className="flex justify-between">
            <div className="flex flex-col gap-[2px]">
              <div className="flex gap-sm items-center">
                <Avatar>U</Avatar>
                <p className="text-[20px] font-[700]">{ticket.created_by.first_name} {ticket.created_by.last_name}</p>
              </div>
              <p className="text-[20px] font-[700] underline ">
                Order ID {ticket.order.slug}
              </p>
              <div className="flex gap-sm items-center">
                <img width="20px" height="auto" src={userBlack} alt="user" />
                <p className="text-[15px] font-[700]">{ticket.order.name}</p>
              </div>
              <div className="flex justify-between">
                <p className="text-sm">
                  {ticket.order.address}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[2px] items-end justify-center md:col-span-2 lg:col-span-4">
          <div className="text-red font-[700] rounded-xs py-xs px-md">
            <div className="flex items-center gap-sm">
            <p className="text-sm font-[700]" style={{ color: ticket.ticket_status === 'completed' || ticket.ticket_status === 'Completed' ? 'green' : 'red' }}>
                      {ticket.ticket_status}
                    </p>
            </div>
          </div>
          <div className="text-[14px] font-[400]">
  <strong style={{ marginRight: '0.5rem' }}>Created At :</strong>
  {ticket.created_at.split("T")[0]} {ticket.created_at.split("T")[1].split(".")[0]}
</div>
          <p className="text-[14px] font-[400]">
            By : {ticket.created_by.first_name} {ticket.created_by.last_name}
          </p>
          <p className="text-[14px] font-[400] flex gap-sm">
            {/* <BiCheckDouble color="#2757FF" /> Recieved */}
          </p>
          <p className="text-[16px] font-[700]">Apt #: {ticket.order.apart}</p>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Page, View, Text, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { barCode } from '../../../assests';

import SairaExtraCondensed_reg from './SairaExtraCondensed-Regular.ttf';
import SairaExtraCondensed_bold from './SairaExtraCondensed-Bold.ttf';
import Wingdings_Regular from './Wingdings-Regular-Font.ttf';

Font.register({ family: 'Saira Extra Condensed Reg', src: SairaExtraCondensed_reg });
Font.register({ family: 'Saira Extra Condensed Bold', src: SairaExtraCondensed_bold });
Font.register({ family: 'Wingdings Regular', src: Wingdings_Regular });

const PrintDocDownList = ({ orderDetail, pharmacy }) => {
  const styles = StyleSheet.create({
    page: {
      width: '7in',
      height: '9.25in',
      margin: 0,
      flexDirection: 'column',
      padding: '20pt',
    },
    section: {
      marginBottom: '10pt',
      fontSize: '12pt',
      fontFamily: 'Saira Extra Condensed Reg',
    },
    sectionTable: {
      marginBottom: '10pt',
      fontSize: '12pt',
      fontFamily: 'Saira Extra Condensed Reg',
      width: '100%'
    },
    sectionMain: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '10pt',
      fontFamily: 'Saira Extra Condensed Reg',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '10pt',
      fontSize: '12pt',
    },
    title: {
      fontWeight: '800',
      fontSize: '14pt',
      textAlign: 'center',
      fontFamily: 'Saira Extra Condensed Bold',
    },
    subtitle: {
      fontWeight: '500',
      textAlign: 'center',
      fontSize: '12pt',
    },
    delivery:{
      fontWeight: '800',
      textAlign: 'center',
      fontSize: '14pt',
      border: '2px solid black',
      width: '120px',
      fontFamily: 'Saira Extra Condensed Bold',
    },
    bar: {
      height: '3px',
      widht: '100%',
      backgroundColor: 'black',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
      marginBottom: '10px'
    },
    bar2: {
      height: '1px',
      widht: '100%',
      backgroundColor: 'black',
      marginTop: '15px',
      marginBottom: '10px'
    },
    detail: {
      fontWeight: '800',
      fontSize: '12pt',
      textDecoration: 'underline',
      fontFamily: 'Saira Extra Condensed Bold',
    },
    content1: {
      fontWeight: '800',
      fontSize: '12pt',
      marginBottom: '8pt',
      fontFamily: 'Saira Extra Condensed Bold',
    },
    content: {
      fontSize: '12pt',
      marginBottom: '5pt',
    },
    contentTick: {
      fontSize: '12pt',
      marginBottom: '5pt',
      fontFamily: 'Wingdings Regular'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '10px'
    },
    th: {
      textAlign: 'center',
      padding: '5pt',
      fontFamily: 'Saira Extra Condensed Bold',
      borderBottom: '1px solid black',
      width: '80px'
    },
    td: {
      border: 'none',
      textAlign: 'center',
      padding: '5pt',
      width: '80px'
    },
  });

  return (
    <Document>
      <Page size="letter" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <Text>Date: {moment(orderDetail?.createdAt).format('MM/DD/YYYY')}</Text>
            <Text>Page 1</Text>
          </View>
          <View style={styles.sectionMain}>
            <Text style={styles.title}>{pharmacy?.name}</Text>
            <Text style={styles.subtitle}>{pharmacy?.location}</Text>
            <Text style={styles.subtitle}>Phone: {pharmacy?.contact_no} Fax: {pharmacy?.fax_number}</Text>
            <Text style={styles.delivery}>Delivery Slip</Text>
          </View>
          <View style={styles.bar} />
          <View style={styles.section}>
            <Text style={styles.detail}>Client:</Text>
            <Text style={styles.content1}>{orderDetail?.name}</Text>
            <Text style={styles.content}>{orderDetail?.address}</Text>
            <Text style={styles.content}>Ph#: {orderDetail?.contact}</Text>
            <Text style={styles.content}>Cell#: {orderDetail?.cell}</Text>
            <Text style={styles.content}>Work#: {orderDetail?.workNumber}</Text>
          </View>
          <View style={styles.sectionTable}>
            <View style={styles.table}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.th}>Date Filled</Text>
                <Text style={styles.th}>Rx. #</Text>
                <Text style={styles.th}>Rf. #</Text>
                <Text style={styles.th}>Rx. Barcode</Text>
                <Text />
                <Text />
                <Text />
                <Text style={styles.th}>Qty</Text>
                <Text style={styles.th}>Pat Pay Amt</Text>
                {/* Add more table headers */}
              </View>
              {orderDetail?.orderdetail.map((rxOrder, i) => (    
                <View style={{ flexDirection: 'row' }}>
                  <Text style={styles.td}>{rxOrder.date}</Text>
                  <Text style={styles.td}>{rxOrder.rxnumber}</Text>
                  <Text style={styles.td}>{rxOrder.rfnumber}</Text>
                  <Text style={styles.td}>
  {barCode && (
    <Image style={{ height: "20px", width: '80px' }} src={barCode} />
  )}
</Text>
                  <Text />
                  <Text />
                  <Text />
                  <Text style={styles.td}>{rxOrder.quantity}</Text>
                  <Text style={styles.td}>{rxOrder.copay}</Text>
                  {/* Add more table data */}
                </View>
              ))}
            </View>
          </View>
          <View style={styles.bar2} />
          <View style={styles.section}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', fontFamily: 'Saira Extra Condensed Bold' }}>
              <Text>Total Rx Count: {orderDetail?.orderdetail.length}</Text>
              <Text>Total Pat. Amt: ${orderDetail?.orderdetail.reduce((total, rx) => total + parseFloat(rx.copay), 0)}</Text>
            </View>
          </View>
            <>
              <Text style={styles.content}>Patient is requesting Counseling: Yes: _{orderDetail.requestCouncelling ? <Text style={styles.contentTick}>&#254;</Text> : ""}_ No: _{orderDetail.requestCouncelling ? "" : <Text style={styles.contentTick}>&#254;</Text>}_</Text>
              <Text style={styles.content}>
                {pharmacy?.hippa_notes === null ?
                  `I certify that I requested and received my medication listed above from ${pharmacy.name} Pharmacy (${pharmacy.location} - the "Pharmacy"). I further certify that I have a patient relationship with the ordering medical provider indicated on the prescription label and that I requested that the prescriber send this prescription to the Pharmacy. The foregoing is certified as true and accurate under the penalty of perjury.`
                  : pharmacy?.hippa_notes
                }
              </Text>
              <View style={styles.section}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text>Receiver Name: <Text style={{paddingLeft: '10px', fontFamily: 'Saira Extra Condensed Bold', paddingRight: '10px', borderBottom: '1px solid black'}}>{orderDetail.name}</Text></Text>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ marginRight: '10pt' }}>Date: <Text style={{paddingLeft: '10px', fontFamily: 'Saira Extra Condensed Bold', paddingRight: '10px', borderBottom: '1px solid black'}}>{moment(orderDetail.deliverAt).format('MM/DD/YYYY')}</Text></Text>
                    <Text>Time: <Text style={{paddingLeft: '10px', fontFamily: 'Saira Extra Condensed Bold', paddingRight: '10px', borderBottom: '1px solid black'}}>{moment(orderDetail.deliverAt).format('hh:mm:ss a')}</Text></Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text>
                    Receiver Signature: 
                    {orderDetail.signature_img !== undefined && typeof orderDetail.signature_img === 'string' ? (
                      <Text style={{
                        paddingLeft: '10px',
                        fontFamily: 'Saira Extra Condensed Bold',
                        paddingRight: '10px',
                        borderBottom: '1px solid black'
                      }}>
                        image goes here...
                        {/* <Image style={{ height: "20px", width: '80px' }} src={`${orderDetail.signature_img}`} alt="signature_img" /> */}
                      </Text>
                    ) : <Text style={{
                      paddingLeft: '10px',
                      fontFamily: 'Saira Extra Condensed Bold',
                      paddingRight: '10px',
                      borderBottom: '1px solid black'
                    }}>
                      ____________________
                      {/* <Image style={{ height: "20px", width: '80px' }} src={`${orderDetail.signature_img}`} alt="signature_img" /> */}
                    </Text>}
                  </Text>
                  <Text>Delivered by: <Text style={{paddingLeft: '10px', fontFamily: 'Saira Extra Condensed Bold', paddingRight: '10px', borderBottom: '1px solid black'}}>{pharmacy?.delivered_by === "PD" ? "PD" : pharmacy?.delivered_by === "Driver" ? orderDetail.driver : '_______________' }</Text></Text>
                </View>
              </View>
            </>
        </View>
      </Page>
    </Document>
  );
};

export default PrintDocDownList;

import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router';

import { 
  ALL_USERS
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth,
} from "../../../utils/api";

import  PharmacyEmployeeObject from '../../MiniComponents/PharmacyEmployeeObject';
import './PharmacyEmployeeList.css'

function PharmacyEmployeeList() {
  const [loading, setLoading] = useState(false);
  const [pharmacyEmployees, setPharmacyEmployees] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const {pid} = useParams();
  const navigate = useNavigate();
  
  const getPharmacyEmployees = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_USERS+`all?pharmacy_id=${pid}&pharmacy=true`);
      setPharmacyEmployees(response.data.data);
  };

  useEffect(() => {
    getPharmacyEmployees();
  }, []);

  useEffect(() => {
    const pharmacyId = localStorage.getItem('pharmacyId')
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigate('/404')
      }
    }
  }, [pid])

  return (
    <div >
      <div className='list-head'>
        <h2 className='listHeader'>Pharmacy Team</h2>
        <button className='driver-add-btn' onClick={() =>{
           navigate(`/pharmacy/${pid}/add/employee`)
           localStorage.setItem('driverData', JSON.stringify({}));
           localStorage.setItem('userIdData', JSON.stringify({}));
           }}>Add Employee</button>
      </div>
      <div className='driver-obj'>
        {pharmacyEmployees?.length > 0 &&
          pharmacyEmployees.map((pharmacyEmployee) => (
            <PharmacyEmployeeObject pharmacyEmployee={pharmacyEmployee} />
          ))
        }
      </div>
    </div>
  )
}

export default PharmacyEmployeeList
import React, {useEffect, useState} from "react";
import moment from "moment";
import { useNavigate } from 'react-router';
import {
  getApiWithAuth,
  deleteApiWithAuth
} from "../../../utils/api";

import "./orderObject.css";

import { 
  DRIVERS_API,
  USER_URL,
  ALL_USERS,
  FACILITY_TEAM_URL
  
}from "../../../utils/apiUrls";

function FacilityObject({ driver, onDelete }) {
  const [doneCount, setDoneCount] = useState(13);
  const [failCount, setFailCount] = useState(3);
  const [pendingCount, setPendingCount] = useState(4);
  

  const navigate = useNavigate();

  const totalOrders = doneCount + failCount + pendingCount;

  const donePercentage = (doneCount / totalOrders) * 100;
  const failPercentage = (failCount / totalOrders) * 100;
  const pendingPercentage = (pendingCount / totalOrders) * 100;

  return (
    <div className="driver-div">
      <div className="driver-first">
        <h3 className="driver-date">Name</h3>
        <h2 className="cust-name-driver">{driver.firstName} { driver.lastName}</h2>
      </div>
      <div className="driver-second">
        <h3 className="driver-detail">Contact: {driver.phoneNumber}</h3>
        <h2 className="driver-detail">Email: {driver.email}</h2>
      </div>
    
      <div className="driver-forth">
        <p className="driver-detail">Address:{driver.address}</p>
        <p className="driver-detail">phone:{driver.phoneNumber}</p>
      </div>
      <div className="driver-fifth">
        <h3 className="driver-detail">Status </h3>
        <p className={`driver-status ${driver.isActive ? "actived" : "deactivated"}`}>{driver.isActive ? "Active" : "Deactivated"}</p>
      </div>
      <div>
        {/* <button className="pharmacy-action-delete" onClick={() => deleteDriver(driver.id)}>
          delete
        </button> */}
      </div>
      <div className="driver-six">
        <button className="driver-action"  onClick={() => navigate(`/edit/facility-team/${driver.id}`)}>
          Account Setting
        </button>
        {/* <h3 className="driver-detail-date">From {moment(driver.createdAt).format('MM-DD-YYYY')}</h3> */}
      </div>
    </div>
  );
}

export default FacilityObject;

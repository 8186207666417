import React from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import DateIcon from "../../../assests/003date.png";
import SearchIcon from "../../../assests/032-search.png";
import MenuIcon from "../../../assests/049-menu-1.png";

const DashboardBottom = () => {
  const cardContent = [
    {
      arrowBG: "#FF5B5B26",
      arrowDirection: "up",
      arrowColor: "#FF3EA5",
      title: "AWS Cost",
      date: "November 24th, 2020 at 2:45 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:'up',
      // arrowColor:'#FF3EA5',
      title: "AWS",
      lastDate: "+42.54 From last month",
      price: "- $198.21",
      priceColor: "#FF5B5B",
    },
    {
      arrowBG: "#FF5B5B26",
      arrowDirection: "up",
      arrowColor: "#FF3EA5",
      title: "Pay Check",
      date: "November 24th, 2020 at 2:45 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:'up',
      // arrowColor:'#FF3EA5',
      title: "Monica",
      // date:'November 24th, 2020 at 2:45 AM'
      // lastDate:'+42.54 From last month',
      price: "- $198.21",
      priceColor: "#FF5B5B",
    },
    {
      arrowBG: "#FF5B5B26",
      arrowDirection: "up",
      arrowColor: "#FF3EA5",
      title: "Google API Services",
      date: "September 5th, 2020 at 11:56 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:'up',
      // arrowColor:'#FF3EA5',
      title: "Google",
      // date:'November 24th, 2020 at 2:45 AM'
      lastDate: "+10.54 From last month",
      price: "- $85.45",
      priceColor: "#FF5B5B",
    },
    {
      arrowBG: "#FF5B5B26",
      arrowDirection: "up",
      arrowColor: "#FF3EA5",
      title: "Pay Check",
      date: "September 12th, 2020 at 11:56 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:'up',
      // arrowColor:'#FF3EA5',
      title: "Laticia Hernandez",
      // date:'November 24th, 2020 at 2:45 AM'
      // lastDate:'+42.54 From last month',
      price: "- $1200.00",
      priceColor: "#FF5B5B",
    },
    {
      arrowBG: "#FF5B5B26",
      arrowDirection: "up",
      arrowColor: "#FF3EA5",
      title: "IOS Developer Account",
      date: "September 5th, 2020 at 11:56 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:'up',
      // arrowColor:'#FF3EA5',
      title: "Apple Developer Program",
      // date:'November 24th, 2020 at 2:45 AM'
      lastDate: "+42.54 From last month",
      price: "- $100.00",
      priceColor: "#FF5B5B",
    },
    {
      arrowBG: "#FF5B5B26",
      arrowDirection: "up",
      arrowColor: "#FF3EA5",
      title: "Pay Check",
      date: "September 12th, 2020 at 11:56 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:'up',
      // arrowColor:'#FF3EA5',
      title: "Jose Arturo Rodriguez",
      // date:'November 24th, 2020 at 2:45 AM'
      // lastDate:'+42.54 From last month',
      price: "- $2500.00",
      priceColor: "#FF5B5B",
    },
    {
      arrowBG: "#FF5B5B26",
      arrowDirection: "up",
      arrowColor: "#FF3EA5",
      title: "Microsoft Monthly Subscription",
      date: "September 5th, 2020 at 11:56 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:'up',
      // arrowColor:'#FF3EA5',
      title: "Microsoft",
      // date:'November 24th, 2020 at 2:45 AM'
      lastDate: "-05.42 From last month",
      price: "- $49.99",
      priceColor: "#FF5B5B",
    },
    {
      arrowBG: "#FF5B5B26",
      arrowDirection: "up",
      arrowColor: "#FF3EA5",
      title: "Pay Check",
      date: "September 5th, 2020 at 11:56 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:'up',
      // arrowColor:'#FF3EA5',
      title: "Edison",
      // date:'November 24th, 2020 at 2:45 AM'
      // lastDate:'+42.54 From last month',
      price: "- $1800.50",
      priceColor: "#FF5B5B",
    },
    {
      arrowBG: "#2ED6A326",
      arrowDirection: ArrowDownwardIcon,
      arrowColor: "#2C7865",
      title: "Receiving Check",
      date: "September 5th, 2020 at 11:56 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:ArrowUpwardIcon,
      // arrowColor:'#FF3EA5',
      title: "MediServ Pharmacy",
      titleDownContent: "@thomasedis",
      // date:'November 24th, 2020 at 2:45 AM'
      // lastDate:'-05.42 From last month',
      price: "+ $20,000.00",
      priceColor: "#00A389",
    },
    {
      arrowBG: "#2ED6A326",
      arrowDirection: ArrowDownwardIcon,
      arrowColor: "#2C7865",
      title: "Receiving Bank Transfer",
      date: "September 5th, 2020 at 11:56 AM",
    },
    {
      arrowBG: "#C4C4C4",
      // arrowDirection:ArrowUpwardIcon,
      // arrowColor:'#FF3EA5',
      title: "SJS Pharmacy",
      titleDownContent: "@thomasedis",

      // date:'November 24th, 2020 at 2:45 AM'
      // lastDate:'+42.54 From last month',
      price: "+ $1050.87",
      priceColor: "#00A389",
    },
  ];
  return (
    <div className="flex flex-col w-full gap-4 mb-[100px]">
      <div className="flex justify-between items-center px-4">
        <div>
          <p className="text-[#464255] font-bold text-xl tracking-wide">
            Finance History
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <p className="py-2 px-4 bg-[#AB54DB26] border border-solid tracking-wide border-[#AB54DB] rounded-lg">
            History
          </p>
          <p className="py-2 px-4 bg-gray tracking-wide  rounded-lg">
            Upcoming
          </p>
          <p className="py-2 px-4 bg-gray tracking-wide  rounded-lg">
            Requests
          </p>
        </div>
        <div className="flex gap-1">
          <img src={DateIcon} className="w-[30px] h-[30px]" />

          <div>
            <img src={SearchIcon} className="w-[30px] h-[30px]" />
          </div>
          <div>
            <img src={MenuIcon} className="w-[30px] h-[30px]" />
          </div>
        </div>
      </div>
      <div className="flex-1">
        <p className="bg-gray p-[1px]"></p>
      </div>
      <div className="flex flex-col gap-2">
        {[...Array(Math.ceil(cardContent.length / 4))].map((_, rowIndex) => (
          <div key={rowIndex} className="flex gap-2">
            {cardContent
              .slice(rowIndex * 4, (rowIndex + 1) * 4)
              .map((item, index) => (
                <div key={index} className="flex p-1 w-1/4">
                  <div className="flex justify-between w-full items-center">
                    <div className="flex gap-2 items-center">
                      <div
                        style={{ backgroundColor: `${item.arrowBG}` }}
                        className={` ${
                          item.arrowDirection ? "p-2 py-3" : "px-5 py-6"
                        }  rounded-lg`}
                      >
                        {item.arrowDirection ? (
                          item.arrowDirection === "up" ? (
                            <ArrowUpwardIcon sx={{ color: item.arrowColor }} />
                          ) : (
                            <ArrowDownwardIcon
                              sx={{ color: item.arrowColor }}
                            />
                          )
                        ) : null}
                      </div>
                      <div className="flex flex-col justify-between">
                        <div>
                          <p className="text-lg font-semibold tracking-wide">
                            {item.title}
                          </p>
                          <p style={{ color: "#A3A3A3" }}>
                            {item.date && item.date}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-between">
                      <div>
                        <p style={{ color: "#A3A3A3" }}>
                          {item.lastDate && item.lastDate}
                        </p>
                      </div>
                      <div className="flex w-full justify-end">
                        <p
                          style={{ color: `${item.priceColor}` }}
                          className="text-xl font-semibold tracking-wide"
                        >
                          {item.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardBottom;

{
  /* <div className="flex flex-wrap gap-2">
{cardContent.map((items, index) => (
  <div key={index} className="flex p-1 w-1/4">
    <div className="flex justify-between w-full">
      <div className="flex gap-2">
        <div className="bg-gray p-2 py-3 rounded-lg">
          <ArrowDownwardIcon sx={{ color: "red" }} />
        </div>
        <div className="flex flex-col justify-between">
          <div>
            <p>title</p>
            <p>date</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <p>last date</p>
        <p>price</p>
      </div>
    </div>
  </div>
))}
</div> */
}

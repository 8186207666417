import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import moment from "moment";
import { TextareaAutosize } from "@mui/base";

import {
  landline,
  mobile,
  phone,
  action2,
  qrImage,
  action3,
  action4,
  action5,
  action6,
  action7,
  action8,
  userBlack,
  pdfLogo,
  photoId,
  registered,
  buildings,
  cellphone,
  iphone,
  delivery,
  route_report,
  readyPickOrder,
  pickedOrder,
  recievedFacilityOrder,
  transitOrder,
  rescheduleOrder,
  cancelOrder,
  deliverOrder
} from "../../../assests";
function getStatusMessage(statusCode) {
    switch (statusCode) {
      case "1":
        return "Ready to Pick Up";
      case "2":
        return "Picked Up";
      case "3":
        return "Received At Facility";
      case "4":
        return "In Transit";
      case "5":
        return "Delivered";
      case "6":
        return "Cancelled";
      default:
        return "Unknown Status";
    }
  }
const OrderDetailsModal = ({ open, onClose, orderDetails}) => {
    console.log("orderDetails",orderDetails, typeof(orderDetails))
    const statusMessage = orderDetails?.[0]?.status ? getStatusMessage(orderDetails?.[0].status) : "";
    return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div  className="modal-content rounded-lg p-6" style={{ margin: '0 auto', maxWidth: '80%', background: 'white', marginTop: '50px'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <h2 id="modal-modal-title" style={{ margin: 0 }}>Order Details</h2>
    <button onClick={onClose} className="close-button"><i className="fa-solid fa-x"></i></button>
  </div>
  <div style={{ maxHeight: '800px', overflowY: 'auto' }}>
      {orderDetails && Object.keys(orderDetails).length > 0 && (
        Object.values(orderDetails).map((orderDetail, index) => (
    console.log("fetched data",orderDetails),
<div key={index} className="overflow-x-auto grid-flow-col grid grid-cols-4 gap-4 border border-[#D9D9D9] rounded-lg px-md py-sm mb-xs hover:bg-slate-300">
        {orderDetail.last_orderticket !== null ? (
          <div className="flex flex-col justify-start gap-[2px] col-span-2">
            <div className="flex items-center justify-between">
              <p className="text-[16px] font-[700] underline">
                Order ID - {orderDetail.slug}
              </p>

              <div
                className={
                  orderDetail.refrigrated && orderDetail.photoid
                    ? "urgent-tag refriPhoto"
                    : orderDetail.photoid
                    ? "urgent-tag photoId"
                    : orderDetail.refrigrated && " urgent-tag refri"
                }
              >
                {orderDetail.refrigrated && orderDetail.photoid
                  ? "Refrigerated / Photo ID"
                  : orderDetail.photoid
                  ? "Photo ID"
                  : orderDetail.refrigrated && "Refrigerated"}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex items-center gap-sm">
                <img width="16px" height="auto" src={userBlack} alt="user" />
                <p className="text-[16px] font-[700]">{orderDetail.name}</p>
              </div>
            </div>

            <div className="flex justify-between gap-[2px] xl:flex-row">
              <div className="flex items-center min-w-[100px]">
                <img width="16px" height="auto" src={cellphone} alt="phone" />
                <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">{orderDetail.contact}</p>
              </div>
              <div className="flex items-center min-w-[100px]">
                <img width="16px" height="auto" src={iphone} alt="mobile" />
                <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">{orderDetail.cell}</p>
              </div>
              <div className="flex items-center min-w-[100px]">
                <img width="16px" height="auto" src={landline} alt="landline" />
                <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">{orderDetail.workNumber}</p>
              </div>
            </div>

            <div className="flex items-center gap-sm">
              <img width="16px" height="auto" src={buildings} alt="work" />
              <p className="pfontupdate">{orderDetail.address}</p>
            </div>

            <div className="flex justify-between">
              <p className="font-[700]">
                Apt #: <span className="apt-in">{orderDetail.apart}</span>
              </p>
              <div className="flex justify-end gap-sm">
              {orderDetail.totalcopay !== 0 && <p className="font-[700]">
                Copay:
                <span className="apt-in text-green">${orderDetail.totalcopay.toFixed(2)}</span>
                {orderDetail.status === "5" && orderDetail.iscollected && <p className="collect-text">Collected</p>}
                {orderDetail.status === "5" && orderDetail.ispaidpharmacy && <p className="collect-text">Paid To Pharmacy</p>}
                {orderDetail.status === "5" && orderDetail.iswaivedpharmacy && <p className="collect-text">Waived By Pharmacy</p>}
              </p>}
            </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-start gap-[2px] col-span-2">
            <div className="flex items-center justify-between">
              <p className="text-[16px] font-[700] underline">
                Order ID - {orderDetail.slug}
              </p>

              <div
                className={
                  orderDetail.refrigrated && orderDetail.photoid
                    ? "urgent-tag refriPhoto"
                    : orderDetail.photoid
                    ? "urgent-tag photoId"
                    : orderDetail.refrigrated && " urgent-tag refri"
                }
              >
                {orderDetail.refrigrated && orderDetail.photoid
                  ? "Refrigerated / Photo ID"
                  : orderDetail.photoid
                  ? "Photo ID"
                  : orderDetail.refrigrated && "Refrigerated"}
              </div>
              {/* {orderDetail.deliverydate === "Urgent" && (
                <div className="urgent-tag urgent">Urgent</div>
              )} */}
            </div>

            <div className="flex justify-between">
              <div className="flex items-center gap-sm">
                <img width="16px" height="auto" src={userBlack} alt="user" />
                <p className="text-[14px] font-[700] pfontupdate">{orderDetail.name}</p>
              </div>
            </div>

            <div className="flex justify-between gap-[2px] xl:flex-row">
              <div className="flex items-center min-w-[100px] gap-sm">
                <img width="16px" height="auto" src={cellphone} alt="phone" />
                <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">{orderDetail.contact}</p>
              </div>
              <div className="flex items-center min-w-[100px] gap-sm">
                <img width="12px" height="auto" src={iphone} alt="mobile" />
                <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">{orderDetail.cell}</p>
              </div>
              <div className="flex items-center min-w-[100px] gap-sm">
                <img width="16px" height="auto" src={landline} alt="landline" />
                <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">{orderDetail.workNumber}</p>
              </div>
              {/* {orderDetail.deliverydate === "Urgent" && (
                <div className="urgent-tag urgent">Urgent</div>
              )} */}
            </div>

            <div className="flex items-center gap-sm">
              <img width="16px" height="auto" src={buildings} alt="work" />
              <p className="pfontupdate">  {orderDetail.address}</p>
            </div>

            <div className="flex justify-between">
              <p className="font-[700] pfontupdate">
                Apt #: <span className="apt-in">{orderDetail.apart}</span>
              </p>
              
              <div className="flex justify-end gap-sm pfontupdate">
              {orderDetail.totalcopay !== 0 && <p className="font-[700]">
                Copay:
                <span className="apt-in text-green">${orderDetail.totalcopay.toFixed(2)}</span>
                {orderDetail.status === "5" && orderDetail.iscollected && <p className="collect-text">Collected</p>}
                {orderDetail.status === "5" && orderDetail.ispaidpharmacy && <p className="collect-text">Paid To Pharmacy</p>}
                {orderDetail.status === "5" && orderDetail.iswaivedpharmacy && <p className="collect-text">Waived By Pharmacy</p>}
              </p>}
            </div>
            </div>
            
          </div>
        )}

{orderDetail && orderDetail.deliverydate === "Urgent" && (
  <div className="rounded-md p-sm flex flex-col gap-[2px] items-center justify-center md:col-span-1 lg:col-span-1">
    <div className="urgent-tag urgent">Urgent</div>
  </div>
)}

        <div className="flex flex-col gap-[2px] items-start justify-start mx-auto col-span-2">
          {/* <div className="flex flex-col items-start"></div> */}
          <h2 className="status text-center">
            Status:
            <span
              className={
                statusMessage === "Ready to Pick Up"
                  ? "readyToPick"
                  : statusMessage === "Received At Facility"
                  ? "receivedAtFacility"
                  : statusMessage === "In transit"
                  ? "inTransit"
                  : statusMessage === "In transit"
                  ? "dispatch"
                  : statusMessage === "Delivered"
                  ? "delivered"
                  : "cancel"
              }
            >
              {statusMessage}
            </span>
          </h2>

          <h2 className="date  text-start">
            Created Date:
            <span className="date-in">
              {moment(orderDetail.createdAt).format("MM-DD-YYYY")}
            </span>
          </h2>

          <h2 className="date  text-start">
            Created Time:
            <span className="time-in">
              {moment(orderDetail.createdAt).format("hh:mm:ss A")}
            </span>
          </h2>

          <h2 className="date  text-start">
            Delivery Date:
            <span className="date-in">
              {moment(orderDetail.deliverAtSelected).format("MM-DD-YYYY")}
            </span>
          </h2>

          <h2 className="date text-start">
            Delivery Time:
            <span className="time-in">{orderDetail.deliverydate}</span>
          </h2>

          {/* <div className="orderTicket">
            <h2 className="attempt text-start">
              Attempts: <span className="attempt-in">{orderDetail.attempt}</span>
            </h2>
            
            {type === "pharmacy" && (orderDetail.last_orderticket === null ? (
              
                <button
                  className="ticketbutton"
                >
                  Create Ticket
                </button>
            ) : (
              <button className="ticketbutton text-start">
                Ticket Created
              </button>
            ))}
          </div> */}
        </div>
        <div className="deliveryBox rounded-md bg-#30abf8 p-sm flex flex-col gap-[2px] items-center justify-start md:col-span-2 lg:col-span-1" style={{width: '230px', height: '130px' }}>
          <p className="text-[20px] font-[700] text-white underline">
            Delivery Note
          </p>
          <TextareaAutosize
            maxRows={4}
            style={{background: 'transparent', textAlign: 'center'}}
            className="bg-#30abf8 text-white w-full text-[13px] mt-sm"
          >
            {orderDetail.notes}
          </TextareaAutosize>
        </div>
  </div>
)))}
</div>
</div>
    </Modal>
  );
};

export default OrderDetailsModal;

import React, { useEffect, useState, useRef } from "react";
import WestIcon from "@mui/icons-material/West";
import OrderUpdateCard from "./OrderUpdateCard/OrderUpdateCard";
import { ORDER_Filter } from "../../../utils/apiUrls";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForwardIcon from "@mui/icons-material/Forward";
import {
  Dialog,
  DialogActions,
  Autocomplete as MUIAutocomplete,
  TextField,
  Select,
  MenuItem,
  Button,
  Menu,
  Paper,
  Grid,
  DialogContent,
  IconButton,
  FormControl,
  Checkbox
} from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ArrowDropDownCircle, CheckBox } from "@mui/icons-material";
import {
  getApiWithAuth, patchApiWithAuth
} from "../../../utils/api";
import {
  ALL_ORDERS,
  ALL_PHARMACY_LIST,
  ALL_USERS,
  ALL_ZONES_LIST,
  MARK_READ,
} from "../../../utils/apiUrls";
function OrdersUpdate() {
  const userType = localStorage.getItem("userType");
  const [orders1, setOrders1] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [pharmacy, setPharmacy] = useState("");
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [searchHit, setSearchHit] = useState(false);
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5000);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      key: "selection",
    },
  ]);


  const navigate = useNavigate();
  const { pid } = useParams();

  const calendarRef = useRef(null);
  const [filterName, setFilterName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterName(
      typeof value === 'string' ? value.split(',') : value,
    );
    console.log("i am handle change")
    const pharmacyIds = uniquePharmacy
      .filter(pharmacy => value.includes(pharmacy.name))
      .map(pharmacy => pharmacy.id);
    setPharmacy(pharmacyIds);
  };
  const handleSelect = (ranges) => {
    console.log("ranges inside the handleselect", ranges)
    if (ranges.selection.endDate) {
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  const getOrders = async () => {
    setLoading(true);
    const response1 = await getApiWithAuth(ORDER_Filter +
      `?pageIndex=${pageIndex}&pageSize=${pageSize}&pharmacy=${pharmacy}&search=${search}&from=${moment(
        dateRange[0].startDate
      ).format("MM-DD-YYYY")}&to=${moment(dateRange[0].endDate).format(
        "MM-DD-YYYY"
      )}`);
    console.log("response1", response1.data.data.data)
    setOrders1(response1.data.data.data);
    setLoading(false);
  };
  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST);
      if (response.data !== undefined) {
        setUniquePharmacy(response.data.data.map((item) => ({ id: item.id, name: item.name })));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const startItem = pageIndex * pageSize + 1;
  const endItem = Math.min(startItem + pageSize - 1, totalItems);

  useEffect(() => {
    getOrders();
  }, [search, pharmacy, pageIndex, pageSize, dateRange]);

  useEffect(() => {
    fetchPharmacies();
  }, []);
  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        setCalendarVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearch(e.target.value);
      setSearchHit(!searchHit);
    }
  };
  useEffect(() => {
    const pharmacyId = localStorage.getItem('pharmacyId')
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigate('/404')
      }
    }
  }, [pid])
  const handleMarkAllAsRead = async () => {
    setLoading(true);
    let response;
    if (userType === "PharmacyEmployee" || userType === "PharmacyOwner") {
      response = await patchApiWithAuth(MARK_READ, { isPharmacyRead: true });
    } else {
      response = await patchApiWithAuth(MARK_READ, { isAdminRead: true });
    }

    if (response.data !== undefined) {
      console.log(response);
      getOrders();

    } else {
      console.log("error");
    }
  }

  return (
    <div className="OrderList">
      {loading && <CustomLoader />}
      <div className="second-row">
        <div className="filter-container">
          <div className="search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              placeholder="Order ID, Name, Address, Phone, Rx"
              className="search-input"
              autoComplete="off"
              onKeyDown={handleKeyPress}

            />
          </div>
          {userType === "Admin" && (
            <div className="search-filter-container">
              <FormControl className="filter-input">
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  IconComponent={(props) => <ArrowDropDownCircle {...props} sx={{ color: 'black', width: 20 }} />}
                  multiple
                  displayEmpty
                  sx={{
                    fontSize: '11px',
                    "& .MuiSvgIcon-root": {
                      color: 'black'
                    }
                  }}
                  MenuProps={{
                    sx: {
                      mt: '8px'
                    }
                  }}
                  value={filterName}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <p style={{ fontSize: '14px', opacity: 1, fontFamily: "Saira Extra Condensed" }}>Filter Pharmacy</p>;
                    }
                    return <p style={{ fontSize: '14px', opacity: 1, fontFamily: "Saira Extra Condensed" }}>{selected.length > 1 ? `${selected.slice(0, 1)}  ...` : selected}</p>
                  }}
                >
                  {uniquePharmacy?.map((pharmacy, index) => {
                    console.log("Pharmacy:", pharmacy);
                    return (
                      <MenuItem
                        key={index}
                        value={pharmacy.name}
                        sx={{
                          fontSize: '13px',
                          fontFamily: "Saira Extra Condensed"
                        }}
                      >
                        <Checkbox size="small" checked={filterName.indexOf(pharmacy.name) > -1} />
                        {pharmacy.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="date-input-container">
            <div type="text" className="date-input">
              <CalendarMonthIcon
                style={{ fontSize: "28px", cursor: "pointer" }}
                onClick={toggleCalendar}
              />
              {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
              <ForwardIcon style={{ fontSize: "32px" }} />
              {dateRange[0].endDate
                ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
                : ""}
            </div>
            {isCalendarVisible && (
              <div className="calendar-container">
                <DateRange
                  ranges={dateRange}
                  onChange={handleSelect}
                  retainEndDateOnFirstSelection={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="order-obj">
        <div className="flex justify-between items-center w-full" style={{ cursor: 'pointer' }}>
          <div className="basis-[1%]" onClick={() => navigate(userType === "PharmacyEmployee" || userType === "PharmacyOwner" ? `/pharmacy/${pid}/list/order` : `/list/order`)}>
            <WestIcon className="mt-sm" />
          </div>
          <div className="flex items-center gap-sm">
            <p className="text-golden text-md font-[400]">Mark All As Read</p>
            <Checkbox onClick={() => handleMarkAllAsRead()} size="small" />
          </div>
        </div>
        <div className="basis-[99%]">
          <div className="OrdersUpdate flex flex-col gap-sm w-full">
            <div className="flex flex-col gap-sm">

              {orders1?.length > 0 &&
                orders1.map((order, index) => (
                  <OrderUpdateCard key={index} order={order} getOrders={getOrders} type="admin" />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrdersUpdate;

import React from "react";
import { ToastContainer } from "react-toastify";
import { StyledEngineProvider } from "@mui/material/styles";
import "react-toastify/ReactToastify.min.css";

import "./FullComponent.css";
import FullNavbar from "../CommonComponents/FullNavbar/FullNavbar";

const FullComponent = () => {
  return (
    <>
      <FullNavbar />
      <StyledEngineProvider injectFirst>
        <ToastContainer position="top-right" newestOnTop />
      </StyledEngineProvider>
    </>
  );
};

export default FullComponent;

import { Avatar, Button } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import {
  phone,
  userBlack,
  landline,
  mobile,
  closeIconRed,
  history,
  view,
  notes,
  images,
  resecdule,
} from "../../../../assests";
import Checkbox from "@mui/material/Checkbox";
import { BiCheckDouble } from "react-icons/bi";

export default function TicketCard() {
  return (
    <div className="TicketCard rounded-sm border border-[#D9D9D9] p-md">
      <div className="grid grid-cols-12 gap-4">
        <div className="flex flex-col justify-around gap-md md:col-span-3 lg:col-span-8">
          <div className="flex justify-between">
            <div className="flex flex-col gap-sm">
              <div className="flex gap-sm items-center">
                <Avatar>U</Avatar>
                <p className="text-[20px] font-[700]">SARA, JOSEPH</p>
              </div>
              <p className="text-[20px] font-[700] underline ">
                Order ID 01-69548
              </p>
              <div className="flex gap-sm items-center">
                <img width="20px" height="auto" src={userBlack} alt="user" />
                <p className="text-[20px] font-[700]">SARA, JOSEPH</p>
              </div>
              <div className="flex justify-between">
                <p>
                  24-07 94th street, first floor east elmhurst, Forest
                  Hills,Queens, NY 11369
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-sm items-end justify-center md:col-span-2 lg:col-span-4">
          <div className="text-red font-[700] rounded-xs py-xs px-md">
            <div className="flex items-center gap-sm">
              <p>TICKET OPEN</p>
            </div>
          </div>
          {/* <p className="text-[16px] font-[400]">
            READ At : 10-12-2023 11:45 pm
          </p> */}
          <p className="text-[16px] font-[400]">
            By : Leticia Hernandez PillDrop
          </p>
          <p className="text-[16px] font-[400] flex gap-sm">
            <BiCheckDouble color="#2757FF" /> Recieved
          </p>
          <p className="text-[20px] font-[700]">Apt #: 4C</p>
        </div>
      </div>
    </div>
  );
}

import React, {useState, useEffect} from 'react'
import { 
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';
import { toast } from 'react-toastify';

import { 
  ALL_ZONES_LIST
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth, postApiWithAuth,
} from "../../../utils/api";

import BoroughObject from '../../MiniComponents/BoroughObject';
import './boroughList.css';

function BoroughList() {
  const [loading, setLoading] = useState(false);
  const [borough, setBorough] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    comment: "Comment will be here!",
  });


  const handleInputChange = (e) => {
    const { name, value , code} = e.target;
    setFormState({
      ...formState,
      [name]: value,
      [code]: value,
    });
  };

  const handleAddBorough = () => {
    setModalOpen(true);
    console.log(borough,'brough')
  };

  const handleAddBoroughClose = () => {
    setModalOpen(false);
  };

  const handleSaveBorough = async () => {
    if (!borough.some(item => item.name === formState.name)) {

    setLoading(true);
    const response = await postApiWithAuth(ALL_ZONES_LIST+ 'borros/', formState);
    console.log(response)
    if (response.status !== 400) {
      toast.success('Borough Created Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setModalOpen(false);
    setLoading(false);
    setFormState({
      name: "",
      comment: "Comment will be here!"
    });
    }
    else {
      toast.error('Name already present!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setModalOpen(false);
      setLoading(false);
      setFormState({
        name: "",
        comment: "Comment will be here!"
      });
    }
    getBorough();
    // if (response.data !== undefined) {
    //   setBorough(response.data.data)
    //   // setPageIndex(response.data.data.pagination.pageIndex - 1);
    //   // setPageSize(response.data.data.pagination.pageSize);
    //   // setTotalPages(response.data.data.pagination.totalPages);
    //   // setTotalItems(response.data.data.pagination.totalItems);
    //   setLoading(false);
    // } else {
    //   setLoading(false);
    // }
  };

  const getBorough = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_ZONES_LIST+ 'borros/');
    console.log(response)
    if (response.data !== undefined) {
      setBorough(response.data.data)
      // setPageIndex(response.data.data.pagination.pageIndex - 1);
      // setPageSize(response.data.data.pagination.pageSize);
      // setTotalPages(response.data.data.pagination.totalPages);
      // setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBorough();
  }, []);

  return (
    <div >
      <div className='list-head'>
        <h2 className='listHeader'>Cities</h2>
        <button className='borough-add-btn' onClick={handleAddBorough}>Add City</button>
      </div>
      <div className='user-obj'>
        {borough?.length > 0 &&
          borough.map((borough, i) => (
            <BoroughObject borough={borough} i={i} getBorough={getBorough} />
          ))
        }
      </div>
      <div className='bottom-tab'>
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div>
      <Dialog open={modalOpen} onClose={handleAddBoroughClose}>
        <DialogTitle>Add City</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Name</label>
          <input
            type="text"
            className="modal-field"
            name="name"
            placeholder="Name"
            style={{marginBottom: '10px'}}
            value={formState.name}
            autoFocus
            onChange={handleInputChange}
            autoComplete="off"
          />
          <label className="pharmacy-field-label">Code</label>
          <input
            type="text"
            className="modal-field"
            name="code"
            placeholder="Code"
            style={{marginBottom: '10px'}}
            value={formState.code}
            autoFocus
            onChange={handleInputChange}
            autoComplete="off"
          />
          <Button onClick={handleSaveBorough} variant="contained" style={{marginRight: '10px'}} color="primary">
            Save
          </Button>
          <Button onClick={handleAddBoroughClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default BoroughList
import React, {useState, useEffect,useMemo } from 'react'
import { useNavigate } from 'react-router';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';

import { 
  ALL_USERS,
  ALL_PHARMACY_LIST,
  ALL_ZONES_LIST
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth,
} from "../../../utils/api";

import UserObject from '../../MiniComponents/UserObject';
import './userList.css'

import { MaterialReactTable } from 'material-react-table';

//nested data is ok, see accessorKeys in ColumnDef below
const data = [
  {
    id:1,
    pharmacyname:"p name",
    accounttype:"ab account",
    email:"abc@gmail.com",
    contect:"9329434",
    name: {
      firstName: 'John',
      lastName: 'Doe',
    },
    address: '261 Erdman Ford',
    city: 'East Daphne',
    state: 'Kentucky',
  },
  {
    id:2,
    pharmacyname:"p name",
    accounttype:"ab account",
    email:"abc@gmail.com",
    contect:"9329434",
    name: {
      firstName: 'Jane',
      lastName: 'Doe',
    },
    address: '769 Dominic Grove',
    city: 'Columbus',
    state: 'Ohio',
  },
  {
    id:3,
    pharmacyname:"p name",
    accounttype:"ab account",
    email:"abc@gmail.com",
    contect:"9329434",
    name: {
      firstName: 'Joe',
      lastName: 'Doe',
    },
    address: '566 Brakus Inlet',
    city: 'South Linda',
    state: 'West Virginia',
  },
  {
    id:4,
    pharmacyname:"p name",
    accounttype:"ab account",
    email:"abc@gmail.com",
    contect:"9329434",
    name: {
      firstName: 'Kevin',
      lastName: 'Vandy',
    },
    address: '722 Emie Stream',
    city: 'Lincoln',
    state: 'Nebraska',
  },
  {
    id:5,
    pharmacyname:"p name",
    accounttype:"ab account",
    email:"abc@gmail.com",
    contect:"9329434",
    name: {
      firstName: 'Joshua',
      lastName: 'Rolluffs',
    },
    address: '32188 Larkin Turnpike',
    city: 'Charleston',
    state: 'South Carolina',
  },
  {
    id:6,
    pharmacyname:"p name",
    accounttype:"ab account",
    email:"abc@gmail.com",
    contect:"9329434",
    name: {
      firstName: 'Joshua',
      lastName: 'Rolluffs',
    },
    address: '32188 Larkin Turnpike',
    city: 'Charleston',
    state: 'South Carolina',
  },
  {
    id:7,
    pharmacyname:"p name",
    accounttype:"abc",
    email:"abc@gmail.com",
    contect:"9329434",
    name: {
      firstName: 'Joshua',
      lastName: 'Rolluffs',
    },
    address: '32188 Larkin Turnpike',
    city: 'Charleston',
    state: 'South Carolina',
    
  },
  {
    id:8,
    pharmacyname:"p name",
    accounttype:"ab account",
    email:"abc@gmail.com",
    contect:"9329434",
    name: {
      firstName: 'Joshua',
      lastName: 'Rolluffs',
    },
    address: '32188 Larkin Turnpike',
    city: 'Charleston',
    state: 'South Carolina',
  },
];


function UserList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Admin");
  const [users, setUsers] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const transformData = (inputArray) => {
    return inputArray?.map((item) => {
      const {
        id,
        email,
        contact,
        first_name,
        last_name,
        address,
      } = item;
      const name = {
        firstName: first_name,
        lastName: last_name,
      };
      return {
        id,
        pharmacyname: `Pharmacy ${item.pharmacy_id}`,
        accounttype: item.type.type,
        email,
        contact,
        name,
        address,
        city: 'Charleston', // Add city, state, and any other desired properties here.
        state: 'South Carolina',
      };
    });
  };
  
  

  const getUsers = async () => {
    setLoading(true);
    // const response = await getApiWithAuth(ALL_USERS);
    const response = await getApiWithAuth(ALL_USERS+`/users/${type}/`);
    console.log(response)
    if (response.data !== undefined) {
      setUsers(response.data.data)
      
      // setPageIndex(response.data.data.pagination.pageIndex - 1);
      // setPageSize(response.data.data.pagination.pageSize);
      // setTotalPages(response.data.data.pagination.totalPages);
      // setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    getUsers();
  }, [type]);
  const transformedData = transformData(users);
  console.log("========================",transformedData);
  // {
  //   id:8,
  //   pharmacyname:"p name",
  //   accounttype:"ab account",
  //   email:"abc@gmail.com",
  //   contect:"9329434",
  //   name: {
  //     firstName: 'Joshua',
  //     lastName: 'Rolluffs',
  //   },
  //   address: '32188 Larkin Turnpike',
  //   city: 'Charleston',
  //   state: 'South Carolina',
  // },
  //should be memoized or stable
  const columns = useMemo(
    () => [

      {
        accessorKey: 'id', //access nested data with dot notation
        header: 'Id',
        size: 10,
      },
      {
        accessorKey: 'pharmacyname', //normal accessorKey
        header: 'Pharmacy Name',
        size: 100,
      },
      {
        accessorKey: 'accounttype', //normal accessorKey
        header: 'Account Type',
        size: 50,
      },
      {
        accessorKey: 'name.firstName', //access nested data with dot notation
        header: 'First Name',
        size: 100,
      },
      {
        accessorKey: 'name.lastName',
        header: 'Last Name',
        size: 100,
      },
      {
        accessorKey: 'contact', //normal accessorKey
        header: 'Contact',
        size: 100,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 150,
      },
      // {
      //   accessorKey: 'actions', // New column for the button
      //   header: 'Actions',
      //   size: 100,
      //   Cell: ({ row }) => (
      //     <button onClick={() => handleButtonClick(row.original)}>Action</button>
      //   ),
      // },
      {
        accessorKey: 'selected',
        header: 'Selected', // Header for the checkbox column
        size: 15,
        Cell: ({ row }) => (
          <input
            type="checkbox"
            autoComplete="off"
            checked={row.original.selected}
            // onChange={() => handleCheckboxChange(row.original)}
          />
        ),
      },
    
    ],
    [],
  );
 
  // const handleCheckboxChange = (row) => {
  //   // Handle button click for the selected row
  //   console.log(`Button clicked for row with ID ${row.original.id}`);
  // };

  return (
    <div >
    {/* css-i4bv87-MuiSvgIcon-root  */}
      <div className='user-list-tab'>
        <button className='tab-toggle-btn-user' onClick={() => setType("Admin")}>PD Facility Admin</button>
        <button className='tab-toggle-btn-user' onClick={() => setType("Staff")}>PD Facility Team</button>
        <button className='tab-toggle-btn-user' onClick={() => setType("Driver")}>PD Delivery Team</button>
        <button className='tab-toggle-btn-user' onClick={() => setType("PharmacyOwner")}>Pharmacy Owners</button>
        <button className='tab-toggle-btn-user' onClick={() => setType("PharmacyEmployee")}>Pharmacy Employees</button>
        {/* <button className='tab-toggle-btn-user' onClick={() =>navigate("/add/facility-team")}>Add Facility Team</button> */}
      </div>
      {/* <div className='user-obj'>
        {users?.length > 0 &&
          users.map((user, i) => (
            <UserObject user={user} i={i} />
          ))
        }
        
      </div> */}
      {/* <div className='bottom-tab'>
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>boroughs
      </div> */}
      <div style={{marginTop:"2%"}}>
      <MaterialReactTable columns={columns} data={transformedData} />
      </div>
     
    </div>
  )
}

export default UserList


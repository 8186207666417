import React, {useEffect} from 'react'
import './routeName.css';
import Eye from '../../../../src/assests/Vector.png';
import PercentImg from '../../../../src/assests/routeStatusPercentage.png';
import moment from "moment";

const RouteName = ({routeStatusData, setShowMap, setRouteColors}) => {


  function getUniqueColor(index) {
    // This checks if the index is within the first 30; if not, it'll just wrap around
    return colors[index % 30];
  }

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const colors = [
    '#FF5733', // Vivid Red
    '#16A085', // Medium Sea Green
    '#3498DB', // Light Blue
    '#F4D03F', // Bright Yellow
    '#8E44AD', // Dark Violet
    '#2ECC71', // Shamrock Green
    '#154360', // Dark Blue
    '#F1948A', // Light Coral
    '#7D3C98', // Medium Orchid
    '#F7DC6F', // Light Goldenrod Yellow
    '#1ABC9C', // Light Sea Green
    '#900C3F', // Dark Magenta
    '#A569BD', // Soft Lavender
    '#2874A6', // Steel Blue
    '#F1C40F', // Canary Yellow
    '#DC7633', // Burnt Orange
    '#5DADE2', // Light Bright Blue
    '#C70039', // Strong Crimson
    '#A3E4D7', // Pale Turquoise
    '#581845', // Dark Purple
    '#E74C3C', // Pale Red
    '#2E4053', // Dark Slate Gray
    '#D35400', // Pumpkin Orange
    '#F8C471', // Sandy Brown
    '#45B39D', // Medium Aquamarine
    '#4A235A', // Plum Purple
    '#58D68D', // Light Green
    '#1B4F72', // Dark Teal Blue
    '#D2B4DE', // Thistle
    '#85C1E9'  // Sky Blue
  ];

  useEffect(() => {
    const routeColorMapping = routeStatusData.reduce((acc, route, index) => {
      acc[route.id] = getUniqueColor(index); // Assign color based on index
      return acc;
    }, {});
    
    setRouteColors(routeColorMapping); // Pass color mapping to parent component
  }, [routeStatusData, setRouteColors]);

  return (
    <div className='route-name-and-progresstime'>
    <div className='route-name-container'>
        <div className='route-name-heading-container'>
          <div style={{display:'flex',gap:110}}>
          <p className='route-name-heading'>Route Name</p>
            <p className='route-name-heading'>Team Member</p>
          </div>
         <div className='route-progress-container'>
         <div className="route-progress-heading-container">
        <p className="route-progress-heading">Route Progress Time</p>
      </div>
      <div className="route-progress-slider-container">
        
      </div>
         </div>
           
        </div>
        <div className='route-name-heading-2-container'>
            <img onClick={()=>setShowMap(0)} src={Eye} style={{width: 20, height: 15}}/>
            <p className='route-name-heading-2'>Tours 1 To 11 / 100</p>
        </div>
      <div className='route-status-object-data'>
        {routeStatusData?.map((data, index) => {
          const backgroundColor = index < 30 ? getUniqueColor(index) : getRandomColor();
          return (
              <div className='route-name-item-container'>
                   <img onClick={()=>setShowMap(data.id)} src={Eye} style={{width: 20, height: 15}} />
                  <div className='route-borough-name-container'  style={{ backgroundColor }}>
                      <p className='route-status-cust-name'>{data.name}</p>
                      <p className='route-status-cust-name'>{data.detail_name}</p>
                  </div>
                  <div>
                      <div>
                          <p className='route-status-team-member'>{data?.driver?.name}</p>
                      </div>
                      <div className='time-and-percent'>
                          <div>
                              <p>{moment(data.createdAt).format('MM-DD-YYYY')}</p>
                          </div>
                          <div className='percent-img-and-percent'>
                              <img src={PercentImg} style={{width: 11, height: 11}}/>
                              <p>{data.percentageOfDone}</p>
                          </div>
                      </div>
                  </div>
                  <div className="route-progress-number-container">
              <div className="route-progress-number-line-initial" style={{ backgroundColor }} />
              <div className="route-progress-numbers">
                {data.routeorder.map((value, i) => (
                  <div className="route-progress-number" style={{backgroundColor: value.status=="delivered" ? "#05B321" : value.status=="failed" ? "#FB1001" : "#FFFFF" }}>
                    <p className="route-progress-number-text">{i+1}</p>
                  </div>
                ))}
              </div>
            </div>
              </div>
          )
        })}
      </div>
    </div>

    {/* <div className="route-progress-container">
      <div className="route-progress-heading-container">
        <p className="route-progress-heading">Route Progress Time</p>
      </div>
      <div className="route-progress-slider-container">
      </div>
      <div className="route-progress-numbers-container">
        {routeStatusData?.map((data, index) => {
          return (
            <div className="route-progress-number-container">
              <div className="route-progress-number-line-initial" style={{backgroundColor: '#0455A6'}} />
              <div className="route-progress-numbers">
                {data.routeorder.map((value, i) => (
                  <div className="route-progress-number" style={{backgroundColor: value.status=="delivered" ? "#05B321" : value.status=="failed" ? "#FB1001" : "#FFFFF" }}>
                    <p className="route-progress-number-text">{i+1}</p>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div> */}
    </div>
  )
}

export default RouteName
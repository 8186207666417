import { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Menu as BaseMenu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem } from "@mui/base/MenuItem";
import { Dropdown } from "@mui/base/Dropdown";
import { notification, text, chatTop } from "../../../../assests";
import { Button, IconButton } from "@mui/material";

import { BiCheckDouble } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function MessagesDropdown({isNavDropdownsOpen, setIsNavDropdownsOpen}) {
  const navigate = useNavigate();

  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  const handleClick = () => {
    setIsNavDropdownsOpen({...isNavDropdownsOpen, messages: false})
    navigate("/customersupport");
  };

  const handleButtonClick = (property) => {
    const updatedDropdowns = {};

    // Set the clicked property to true and others to false
    Object.keys(isNavDropdownsOpen).forEach((key) => {
      updatedDropdowns[key] = key === property;
    });

    setIsNavDropdownsOpen(updatedDropdowns);
  };

  return (
    <div>
      <Dropdown
        open={isNavDropdownsOpen.messages}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "left", // Position the menu to the left
        // }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "left", // Position the menu to the left
        // }}

        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuButton>
          <IconButton color="inherit" onClick={() => handleButtonClick('messages')}>
            <img className="top-icon" src={chatTop} alt="Logo" />
          </IconButton>
        </MenuButton>
        <Menu className="z-[1500] left-[-11%] bg-gray py-sm px-md h-[330px] max-w-[650px] w-[500px] rounded-sm flex items-center justify-center">
          <div className="flex flex-col gap-md">
            <p className="text-blue text-3xl font-[700]">MESSAGES</p>

            <MenuItem
              className="bg-white py-sm px-md rounded-xs"
              onClick={createHandleMenuClick("Profile")}
            >
              <div className="flex flex-col gap-sm">
                <div className="flex items-center justify-between">
                  <p className="text-md font-[700]">
                    message or reply they received..........
                  </p>

                  <p className="text-md font-[700] flex gap-sm items-center">
                    <BiCheckDouble color="#2757FF" /> Read
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-md font-[400]">
                    fROM : pHARMACY & ACC NAME
                  </p>
                  <p className="text-md font-[400]">10-27-2023 08:36:49AM</p>
                </div>
              </div>
            </MenuItem>

            <MenuItem
              className="bg-white py-sm px-md rounded-xs"
              onClick={createHandleMenuClick("Profile")}
            >
              <div className="flex flex-col gap-sm">
                <div className="flex items-center justify-between">
                  <p className="text-md font-[700]">
                    message or reply they received..........
                  </p>

                  <p className="text-md font-[700] flex gap-sm items-center">
                    <BiCheckDouble color="#2757FF" /> Read
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-md font-[400]">
                    fROM : pHARMACY & ACC NAME
                  </p>
                  <p className="text-md font-[400]">10-27-2023 08:36:49AM</p>
                </div>
              </div>
            </MenuItem>

            <MenuItem
              className="bg-white py-sm px-md rounded-xs"
              onClick={createHandleMenuClick("Profile")}
            >
              <div className="flex flex-col gap-sm">
                <div className="flex items-center justify-between">
                  <p className="text-md font-[700]">
                    message or reply they received..........
                  </p>

                  <p className="text-md font-[700] flex gap-sm items-center">
                    <BiCheckDouble color="#2757FF" /> Read
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-md font-[400]">
                    fROM : pHARMACY & ACC NAME
                  </p>
                  <p className="text-md font-[400]">10-27-2023 08:36:49AM</p>
                </div>
              </div>
            </MenuItem>

            <div className="flex justify-end gap-sm">
              <button
                onClick={() => setIsNavDropdownsOpen({...isNavDropdownsOpen, messages: false})}
                className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-red rounded-[2px]"
              >
                Clear
              </button>

              <button
                onClick={handleClick}
                className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-[#0455A6] rounded-[2px]"
              >
                See All
              </button>
            </div>
          </div>
        </Menu>
      </Dropdown>
    </div>
  );
}

MessagesDropdown.propTypes = {
  // You can add propTypes for your props here if needed
};

const Menu = BaseMenu;

Menu.propTypes = {
  open: true,
  defaultOpen: true,
  slotProps: PropTypes.shape({
    listbox: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    root: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
};

const MenuButton = BaseMenuButton;

MenuButton.propTypes = {
  className: PropTypes.string,
};

const MenuItem = BaseMenuItem;

MenuItem.propTypes = {
  className: PropTypes.string,
};

import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useNavigate } from 'react-router';
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { FormControl, InputLabel, MenuItem, Select, Typography, Autocomplete  as MUIAutocomplete, TextField, Checkbox, Grid} from '@mui/material';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Multiselect from 'multiselect-react-dropdown';
import { ArrowDropDownCircle } from "@mui/icons-material";
// import { RMIUploader } from "react-multiple-image-uploader";
import { 
    ADD_DRIVER,
    PHARMACY_detail_URL,
    PHARMACY_URL,
    ADD_BANK,
    ADD_VEHICLE,
    PHARMACY_api_URL,
    PHARMACY_apiupdate_URL,
    PHARMACY_bankupdate_URL,
    PHARMACY_docupdate_URL,
    PHARMACY_bank_URL,
    PHARMACY_doc_URL,
    ALL_DRIVERS
 } from "../../../utils/apiUrls";
import {
  postApiWithAuth,
  patchApiWithAuth,
  putApiWithAuth,
  postFormDataAPI,
  getApiWithAuth
  
} from "../../../utils/api";

import { idImage } from "../../../assests";

import "./addPharmacy.css";
function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

function AddPharmacy() {
  const [editAble, setEditAble]=useState(false)
  const [pharmacyId, setPharmacyId]=useState(null)
  const navigate = useNavigate();
  const [Fronts, setFronts] = useState("");
  const [backendimg, setbackendimg] = useState(
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
  );
  const [isChecked, setIsChecked] = useState(true);
  const [isChecked2, setIsChecked2] = useState(false);
  const [selectedOption, setSelectedOption] = useState('FACE TO FACE');
  const [selectedOptionrx, setSelectedOptionrx] = useState('PrimeRX');
  const [selectedOption2, setSelectedOption2] = useState('Driver');
  const [selectedOptions4, setSelectedOptions4] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [driver, setDriver] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const [pharmacyDetails, setPharmacyDetails] = useState({
    name: '',
    description: '',
    short_description: '', // Using snake_case to match the payload
    logo: '', // This property is commented out in the payload
    location: '',
    contact_no: '', // Using snake_case to match the payload
    urgentPrice: '',
    sameDayPrice: '',
    timeWindowPrice: '',
    f_attemptPrice: '',
    s_attemptPrice: '',
    t_attemptPrice: '',
    nextDayPrice: '',
    cancelPrice: '',
    isActive: true,
    fileRequired: false,
    approved: true,
    fax_number: '', // Using snake_case to match the payload
    emergency_contact: '', // Using snake_case to match the payload
    email: '',
    designation: '',
    first_name: '',
    last_name: '',
    employ_contact: '',
    employ_email: '',
    preferred_way_of_delivery: '1',
    call_to_notify_failed_attempt: '1',
    firstattempt: false,
    secondattempt: false,
    thirdattempt: false,
    preferred_number: '',
    delivered_by: '',
    notes: '',
    pos_types:'',
    hippa_notes: '',
    createdBy: 1,
    PharmacyOwner:"Pharmacy Owner",
    username:"",
    user_email:"",
    password:"",
   
  });

  const [fieldErrors, setFieldErrors] = useState({
    name: '',
    location: '',
    contact_no: '',
    email: '',
    first_name: '',
    last_name: '',
    password:"",
  });


  const options = [
    { id: 1, name: 'First Attempt' },
    { id: 2, name: 'Second Attempt' },
    { id: 3, name: 'Third Attempt' },
  ];

  const onSelect = (selectedList) => {
    
    setSelectedOptions4(selectedList);
    console.log("=====----",selectedList,selectedOptions4)
  };
 
  const findAttempt = (attemptName) => {
    return selectedOptions4.some(option => option.name === attemptName);
  };

  const onRemove = (selectedList) => {
 
    setSelectedOptions4(selectedList);
  };
  const [selectedOptions3, setSelectedOptions3] = useState({
    'PD': false,
    'Driver': false,
    "None": false,
  });


  const fetchDrivers = async () => {
    try {
      const response = await getApiWithAuth(ALL_DRIVERS);
      if (response.data !== undefined) {
        setDriverList(response.data.data.map((item) => ({id: item.id, name: item.name})));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDropDownChange = (event) => {
    setSelectedOption(event.target.value);
    setPharmacyDetails({
      ...pharmacyDetails,
      preferred_way_of_delivery: event.target.value,
    });
  
  };
  const handleDropDownChange3 = (event) => {
    setSelectedOption2(event.target.value);
    setPharmacyDetails({
      ...pharmacyDetails,
      delivered_by: event.target.value,
    });
   
  };
  const handleDropDownChange4 = (event) => {
    console.log("event.target.value", event.target.value);
    const selectedValue = event.target.value;
    const posTypeValue = selectedValue || "PrimeRX";
    setSelectedOptionrx(posTypeValue);
    setPharmacyDetails((prevDetails) => ({
      ...prevDetails,
      pos_types: event.target.value,
    }));
  };
  
  useEffect(() => {
    // Set the initial value of pos_types in pharmacyDetails when component mounts
    setPharmacyDetails((prevDetails) => ({
      ...prevDetails,
      pos_types: selectedOptionrx,
    }));
  }, []);
  
  

  const [bankInfo, setBankInfo] = useState({
    name: '',
    account_title: '',
    account_number: '',
    ach_routing: '',
    credit_crad: ''
  });

  const [documents, setDocuments] = useState(
    {
      doc_name: "abc",
      // document: ""
    }
  );

  const handleInputChange = (event) => {
    console.log("working")
    const { name, value } = event.target;
    setPharmacyDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setFieldErrors({ ...fieldErrors, [name]: '' });
  };

  useEffect(() => {
    fetchDrivers()
  }, []);

  const handleBankInputChange = (event) => {
    const { name, value } = event.target;
    setBankInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Check if fields are empty
    if (!pharmacyDetails.name) {
      errors.name = 'Pharmacy Name is required';
      isValid = false;
    }
    if (!pharmacyDetails.location) {
      errors.location = 'Address is required';
      isValid = false;
    }
    if (!pharmacyDetails.contact_no) {
      errors.contact_no = 'Phone Number is required';
      isValid = false;
    }
    if (!pharmacyDetails.emergency_contact) {
      errors.emergency_contact = 'Contact is required';
      isValid = false;
    }
    if (!pharmacyDetails.email) {
      errors.email = 'Email is required';
      isValid = false;
    }
    if (!pharmacyDetails.user_email) {
      errors.user_email = 'Email is required';
      isValid = false;
    }
    if (!pharmacyDetails.first_name) {
      errors.first_name = 'First name is required';
      isValid = false;
    }
    if (!pharmacyDetails.username) {
      errors.username = 'UserName is required';
      isValid = false;
    }
    if (!pharmacyDetails.last_name) {
      errors.last_name = 'Last name is required';
      isValid = false;
    }
    if (!pharmacyDetails.password) {
      errors.password = 'Password is required';
      isValid = false;
    }
    if (!pharmacyDetails.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
      isValid = false;
    }
    if (!pharmacyDetails.nextDayPrice) {
      errors.nextDayPrice = 'Next Day Price is required';
      isValid = false;
    }
    if (!pharmacyDetails.urgentPrice) {
      errors.urgentPrice = 'Urgent Price is required';
      isValid = false;
    }
    if (!pharmacyDetails.timeWindowPrice) {
      errors.timeWindowPrice = 'Time window Price is required';
      isValid = false;
    }
    if (!pharmacyDetails.sameDayPrice) {
      errors.sameDayPrice = 'Same Day Price is required';
      isValid = false;
    }
    if (!pharmacyDetails.cancelPrice) {
      errors.cancelPrice = 'Cancel Price is required';
      isValid = false;
    }
    if (!pharmacyDetails.f_attemptPrice) {
      errors.f_attemptPrice = 'First Attempt Price is required';
      isValid = false;
    }
    if (!pharmacyDetails.s_attemptPrice) {
      errors.s_attemptPrice = 'Second Attempt Price is required';
      isValid = false;
    }
    if (!pharmacyDetails.t_attemptPrice) {
      errors.t_attemptPrice = 'Third Attempt Price is required';
      isValid = false;
    }
    setFieldErrors(errors);
    return isValid;
  };
  
  const handleSubmit = async (event) => {
    selectedOptions4.filter((option) => {
      
      if (option.name === 'First Attempt') {
        pharmacyDetails.firstattempt = true;
      } else if (option.name === 'Second Attempt') {
        pharmacyDetails.secondattempt = true;
      } else if (option.name === 'Third Attempt') {
        pharmacyDetails.thirdattempt = true;
      }
    });
  
    event.preventDefault();
    if (validateForm()) {

      let payload = new FormData();
      payload.append("name", pharmacyDetails.name);
      payload.append("description", pharmacyDetails.description);
      payload.append('short_description', pharmacyDetails.short_description)
      payload.append("location", pharmacyDetails.location);
      payload.append("contact_no", pharmacyDetails.contact_no);
      payload.append("urgentPrice", pharmacyDetails.urgentPrice);
      payload.append("sameDayPrice", pharmacyDetails.sameDayPrice);
      payload.append("timeWindowPrice", pharmacyDetails.timeWindowPrice);
      payload.append("f_attemptPrice", pharmacyDetails.f_attemptPrice);
      payload.append("s_attemptPrice", pharmacyDetails.s_attemptPrice);
      payload.append("t_attemptPrice", pharmacyDetails.t_attemptPrice);
      payload.append("nextDayPrice", pharmacyDetails.nextDayPrice);
      payload.append("cancelPrice", pharmacyDetails.cancelPrice);
      payload.append("fax_number", pharmacyDetails.fax_number);
      payload.append("emergency_contact", pharmacyDetails.emergency_contact);
      payload.append("email", pharmacyDetails.email);
      payload.append("designation", pharmacyDetails.designation);
      payload.append("first_name", pharmacyDetails.first_name);
      payload.append("last_name", pharmacyDetails.last_name);
      payload.append("employ_contact", pharmacyDetails.employ_contact);
      payload.append("employ_email", pharmacyDetails.employ_email);
      console.log("POS Type before submission2:", pharmacyDetails.pos_types);
      payload.append("preferred_way_of_delivery", pharmacyDetails.preferred_way_of_delivery);
      payload.append("pos_types", pharmacyDetails.pos_types);
      payload.append("firstattempt", pharmacyDetails.firstattempt);
      payload.append("secondattempt", pharmacyDetails.secondattempt);
      payload.append("thirdattempt", pharmacyDetails.thirdattempt);
      payload.append("preferred_number", pharmacyDetails.preferred_number);
      payload.append("delivered_by", pharmacyDetails.delivered_by);
      payload.append("notes", pharmacyDetails.notes);
      payload.append("hippa_notes", pharmacyDetails.hippa_notes);
      payload.append("username", pharmacyDetails.username);
      payload.append("user_email", pharmacyDetails.user_email);
      payload.append("password", pharmacyDetails.password);
      payload.append("createdBy", pharmacyDetails.createdBy);
      payload.append("PharmacyOwner", pharmacyDetails.PharmacyOwner);
      payload.append("approved", pharmacyDetails.approved);
      payload.append("isActive", pharmacyDetails.isActive);
      payload.append("fileRequired", pharmacyDetails.fileRequired);
      payload.append("logo", pharmacyDetails.logo);
      if ( driver.id !== undefined || pharmacyDetails.delivered_by === "Driver") {
        payload.append("cashCounter", driver.id);
      }



      const response_pharmacy = await postApiWithAuth(PHARMACY_detail_URL, payload);
      let bankInfoPayload = new FormData();
      if (bankInfo.name !== "") {
        bankInfoPayload.append("name", bankInfo.name);
        bankInfoPayload.append("account_title", bankInfo.account_title);
        bankInfoPayload.append("account_number", bankInfo.account_number);
        bankInfoPayload.append("ach_routing", bankInfo.ach_routing);
        bankInfoPayload.append("credit_crad", bankInfo.credit_crad);
        bankInfoPayload.append("pharmacy", response_pharmacy.data.data.id);

        const bank_response = await postApiWithAuth(PHARMACY_bank_URL, bankInfoPayload);
        console.log("bank_response", bank_response)
      }
      
      let documentsPayload = new FormData();
      documentsPayload.append("doc_name", documents.doc_name);
      const doc_response = await postApiWithAuth(PHARMACY_doc_URL, documentsPayload);
      console.log("doc_response", doc_response, 'done-----------------')
      navigate("/list/pharmacy")
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const updateIsActive = (newValue) => {
    setIsChecked(true);
    setIsChecked2(false);

    setPharmacyDetails({
      ...pharmacyDetails,
      isActive: true,
    });
  };

  const updateIsActive2 = () => {
    setIsChecked2(true);
    setIsChecked(false);

    setPharmacyDetails({
      ...pharmacyDetails,
      isActive: false,
    });
  };

  const handleEditClick = () => {
    // Trigger the hidden file input when EditIcon is clicked
    document.getElementById("imageInput").click();
  };

  const handleImageUpload3 = (event) => {
    const fileObject = event.target.files[0];
    const { name, size, type, lastModified } = fileObject;
    const fileBlob = new Blob([fileObject], { type });
  
    const file = new File([fileBlob], name, { lastModified });
  
    setPharmacyDetails({
      ...pharmacyDetails,
      logo: file,
    });
  
    if (file) {
      const reader = new FileReader();
  
      // Read the contents of the image file as a data URL
      reader.onloadend = () => {
        // Set the image URL in the state (if needed)
        setFronts(reader.result);
  
        // Convert the image to base64 and include it in your payload
        const base64Image = reader.result;
        const payload = {
          ...pharmacyDetails,
          logo: base64Image,
        };
  
        // Make your API request with the payload
        // Example: const response1 = await patchApiWithAuth(`${PHARMACY_apiupdate_URL}/${pharmacyId}`, payload);
      };
  
      reader.readAsDataURL(file);
    }
  };

  const handleDriverChange = (e, value) => {
    console.log(value);
    if (value !== null) {
      setDriver(value)
    } else {
      setDriver("");
    }
  };
  
  return (
    <div>
        <Grid container className="form-grid" >
        <Grid item className="drive-grid" xs={3}>
          </Grid> <Grid item className="drive-grid ml-5"  xs={3}></Grid>
          <Grid item className="drive-grid "  xs={3}>
            <button className="activate" color="apple" variant="contained" >Activate</button> <Checkbox name="bussAddress"  checked={isChecked} onClick={() =>updateIsActive()} />
            <button className="deactivate" >Deactivate</button> <Checkbox name="bussAddress2" checked={isChecked2} onChange={() =>updateIsActive2()} /> 
          </Grid>
          <Grid item className="drive-grid "  xs={1.5}>
        
                  {/* Hidden file input triggered by the EditIcon click */}
                  <input
                    autoComplete="off"
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleImageUpload3}
                  />
            
               {
                Fronts ? (
              <img src={Fronts} style={{ borderRadius: '100%'}} className="id-image" alt="Image 1" />
                ) : (
                  <img src={backendimg} style={{ borderRadius: '100%'}} className="id-image" alt="Image 1" />
                )
}
                
          </Grid>
          <Grid item className="drive-grid " style={{ marginLeft:'-30px'}}>
          <div className="img-icon" onClick={handleEditClick}>
            
                    {/* Render your EditIcon component here */}
                    <EditIcon />
            </div>
            </Grid>
        </Grid>


        <div className="form-sec-head">Pharmacy Detail</div>
        <Grid container className="form-grid" spacing={2}>
          {/* First Row */}
          <Grid item className="drive-grid" xs={3}>
            <label className="pharmacy-field-label">Pharmacy Name</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="name"
              placeholder="First Name"
              value={pharmacyDetails.name}
              onChange={handleInputChange}
              required
            />
            {fieldErrors.name && <span className="error-message">{fieldErrors.name}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={9}>
            <label className="pharmacy-field-label">Address</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="location"
              placeholder="Address"
              value={pharmacyDetails.location}
              onChange={handleInputChange}
            />
            {fieldErrors.location && <span className="error-message">{fieldErrors.location}</span>}
          </Grid>

          {/* Second Row */}
          <Grid item className="drive-grid" xs={3}>
            <label className="pharmacy-field-label">Phone Number</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="contact_no"
              placeholder="Contact Number"
              value={pharmacyDetails.contact_no}
              onChange={handleInputChange}
            />
            {fieldErrors.contact_no && <span className="error-message">{fieldErrors.contact_no}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="pharmacy-field-label">Fax Number</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="fax_number"
              placeholder="Fax Number"
              value={pharmacyDetails.fax_number}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="pharmacy-field-label">Emergency Contact</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="emergency_contact"
              placeholder="Emergency Contact"
              value={pharmacyDetails.emergency_contact}
              onChange={handleInputChange}
            />
                        {fieldErrors.emergency_contact && <span className="error-message">{fieldErrors.emergency_contact}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="pharmacy-field-label">Email</label>
            <input
              autoComplete="off"
              type="email"
              className="pharmacy-field"
              name="email"
              placeholder="Email"
              value={pharmacyDetails.email}
              onChange={handleInputChange}
              required
            />
            {fieldErrors.email && <span className="error-message">{fieldErrors.email}</span>}
          </Grid>
        </Grid>

        <div className="form-sec-head">Emergency Contact</div>
        <Grid container className="form-grid" spacing={2}>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">Designation</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="designation"
              placeholder="Designation"
              value={pharmacyDetails.designation}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="pharmacy-field-label">First Name</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="first_name"
              placeholder="First Name"
              value={pharmacyDetails.first_name}
              onChange={handleInputChange}
              required
            />
            {fieldErrors.first_name && <span className="error-message">{fieldErrors.first_name}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            <label className="pharmacy-field-label">Last Name</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="last_name"
              placeholder="Last Name"
              value={pharmacyDetails.last_name}
              onChange={handleInputChange}
              required
            />
            {fieldErrors.last_name && <span className="error-message">{fieldErrors.last_name}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">Contact</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="employ_contact"
              placeholder="Contact"
              value={pharmacyDetails.employ_contact}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">Email</label>
            <input
              autoComplete="off"
              type="email"
              className="pharmacy-field"
              name="employ_email"
              placeholder="Email"
              value={pharmacyDetails.employ_email}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <div className="form-sec-head">Account Credentials</div>
        <Grid container className="form-grid" spacing={2}>
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Account Type</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="PharmacyOwner"
              placeholder="pharmacy owner"
              value={pharmacyDetails.PharmacyOwner}
              // onChange={handleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">User Name</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="username"
              placeholder="User Name"
              value={pharmacyDetails.username}
              onChange={handleInputChange}
            />
                        {fieldErrors.username && <span className="error-message">{fieldErrors.username}</span>}

          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Email</label>
            <input
              autoComplete="off"
              type="email"
              className="pharmacy-field"
              name="user_email"
              placeholder="Email"
              value={pharmacyDetails.user_email}
              onChange={handleInputChange}
            />
             {fieldErrors.user_email && <span className="error-message">{fieldErrors.user_email}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Password</label>
            <input
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              className="pharmacy-field"
              name="password"
              placeholder="Password"
              value={pharmacyDetails.password}
              onChange={handleInputChange}
            />
            {fieldErrors.password && <span className="error-message">{fieldErrors.password}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Confirm Password</label>
            <div style={{display: 'flex'}}>
              <input
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                className="pharmacy-field"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={pharmacyDetails.confirmPassword}
                onChange={handleInputChange}
              />
              {fieldErrors.confirmPassword && <span className="error-message">{fieldErrors.confirmPassword}</span>}
              <button
                // className="show-hide-password"
                onClick={togglePasswordVisibility}
              >
                {showPassword 
                ? 
                  <VisibilityOffIcon />
                :
                  <RemoveRedEyeIcon />
                }
              </button>
            </div>
          </Grid>
         
        </Grid>

        <div className="form-sec-head">Delivery Preferences</div>
          <Grid container className="form-grid" spacing={2}>
          <Grid item className="drive-grid" xs={2}>
          <InputLabel id="deliveryMethodLabel" style={{fontSize:'12px',fontWeight: 700,color:'black', marginLeft: 3, marginTop: 3, marginBottom: 3}}  className=" pharmacy-field-label mt-2 text-dark">Preferred Way Of Delivery</InputLabel>
            <div className="search-filter-container">
              {/* <FormControl fullWidth> */}
            <Select
              className="filter-input"
              sx={{
                height: 26,
              }}
              id="deliveryMethod"
              value={selectedOption}
              // label="Select Delivery Method"
              onChange={handleDropDownChange}
            >
              <MenuItem className="dropdown-text" value="FACE TO FACE">FACE TO FACE</MenuItem>
              <MenuItem className="dropdown-text" value="LEAVE WITH RECEPTIONIST">LEAVE WITH RECEPTIONIST</MenuItem>
              <MenuItem className="dropdown-text" value="LEAVE AT DOOR">LEAVE AT DOOR</MenuItem>
              <MenuItem className="dropdown-text" value="LEAVE AT DOOR IF PT E-SIGNS">LEAVE AT DOOR IF PT E-SIGNS</MenuItem>
              <MenuItem className="dropdown-text" value="LEAVE IN THE MAILBOX">LEAVE IN THE MAILBOX</MenuItem>
              <MenuItem className="dropdown-text" value="LEAVE IN MAIL BOX IF PT E-SIGNS">LEAVE IN MAIL BOX IF PT E-SIGNS</MenuItem>
            </Select>
              {/* </FormControl> */}
            </div>
          </Grid>
          <Grid item className="drive-grid" xs={3}>
            {/* <label className="pharmacy-field-label">Call To Notify Failed Attempt</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="call_to_notify_failed_attempt"
              placeholder="Call To Notify Failed Attempt"
              value={pharmacyDetails.call_to_notify_failed_attempt}
              onChange={handleInputChange}
            /> */}
              <div>
                {/* <FormControl fullWidth>
                  <Input
                    autoComplete="off"Label id="deliveryMethodLabel" style={{fontSize:'16px',color:'black'}}  className=" pharmacy-field-label mt-2 text-dark">Call To Notify Failed Attempt</InputLabel>
                          <Select
                            className="pharmacy-field mt-5"
                    labelId="deliveryMethodLabel"
                    id="deliveryMethod"
                    value={selectedOption2}
                    label="Select Delivery Method"
                    onChange={handleDropDownChange2}
                  >
                    <MenuItem value="first attempt">First Attempt</MenuItem>
                    <MenuItem value="secound attempt">Secound Attempt</MenuItem>
                    <MenuItem value="third attempt">Third Attempt</MenuItem>
                  
                  </Select>
                </FormControl> */}
                <label className="pharmacy-field-label">Call To Notify Failed Attempt</label>
                    
                <Multiselect
                  // className="multiselect-dropdown"
                  options={options}
                  displayValue="name"
                  showCheckbox={true}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  
                  showArrow={true}
                  selectedValues={selectedOptions4}
                />
              </div>
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">Preferred Number</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="preferred_number"
              placeholder="Preferred Number"
              value={pharmacyDetails.preferred_number}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={2}>
           
            <FormControl fullWidth>
              <InputLabel id="deliveryMethodLabel" style={{fontSize:'16px',fontWeight: 700,color:'black'}}  className=" pharmacy-field-label mt-2 text-dark">Delivered By</InputLabel>
                      <Select
                        className="mt-5"
                        sx={{borderRadius: 13, height: 30, fontSize: '13px',}}
                labelId="deliveryMethodLabel"
                id="deliveryMethod"
                value={selectedOption2}
                // label="Select Delivery Method"
                onChange={handleDropDownChange3}
              >
                <MenuItem className="dropdown-text" value="PD">PD</MenuItem>
                <MenuItem className="dropdown-text" value="Driver">Driver</MenuItem>
                <MenuItem className="dropdown-text" value="None">None</MenuItem>
              </Select>
            </FormControl>

          </Grid>
          <Grid item className="drive-grid" xs={3} sx={{marginTop: -1}}>
            <label className="pharmacy-field-label">Cash Collector</label>
            <div className="driver-search-filter-container">
              <MUIAutocomplete
                style={{ padding: "0" }}
                disablePortal
                id="combo-box-demo"
                options={driverList}
                className="filter-input"
                sx={{
                  width: 300,
                  "& .MuiOutlinedInput-root": {
                    p: 0,
                  },
                  "& .MuiAutocomplete-tag": {
                    bgcolor: "primary.lighter",
                    border: "0px solid",
                    borderColor: "transparent",
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth placeholder="Select Driver" sx={{
                    "& input::placeholder": {
                      fontSize: "14px",
                      opacity:1,
                      fontFamily: "Saira Extra Condensed"
                    },
                    "& input": {
                      fontSize: '14px !important',
                      fontFamily: "Saira Extra Condensed"
                    }
                  }}/>
                )}
                popupIcon={<ArrowDropDownCircle sx={{color: 'black', width: 20}}/>}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <p {...props} style={{ fontSize: '13px' }}>
                    {option.name}
                  </p>
                )}
                onChange={handleDriverChange}
                IconComponent={ExpandCircleDownIcon}
              />
            </div>
          </Grid>
          <Grid item className="drive-grid" xs={2}>
           
            <FormControl fullWidth>
              <InputLabel id="postypeLabel" style={{fontSize:'16px',fontWeight: 700,color:'black'}}  className=" pharmacy-field-label mt-2 text-dark">POS Type</InputLabel>
                      <Select
                        className="mt-5"
                        sx={{borderRadius: 13, height: 30, fontSize: '13px',}}
                labelId="postypeLabel"
                id="postype"
                value={selectedOptionrx}
                onChange={handleDropDownChange4}
              >
                <MenuItem className="dropdown-text" value="BestRx">BestRx</MenuItem>
                <MenuItem className="dropdown-text" value="PrimeRX">PrimeRx</MenuItem>
                <MenuItem className="dropdown-text" value="PioneerRx">PioneerRx</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <div className="form-sec-head">Pharmacy Account Info</div>
        <Grid container className="form-grid" spacing={2}>
          {/* First Row */}
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Bank Name</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="name"
              placeholder="Bank Name"
              value={bankInfo.name}
              onChange={handleBankInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Title on Account</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="account_title"
              placeholder="Account Title"
              value={bankInfo.account_title}
              onChange={handleBankInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Account Number</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="account_number"
              placeholder="Account Number"
              value={bankInfo.account_number}
              onChange={handleBankInputChange}
            />
          </Grid>

          {/* Second Row */}
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">ACH Routing</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="ach_routing"
              placeholder="ACH Routing"
              value={bankInfo.ach_routing}
              onChange={handleBankInputChange}
            />
          </Grid>
          <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Credit Card</label>
            <input
              autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="credit_crad"
              placeholder="Credit Card "
              value={bankInfo.credit_crad}
              onChange={handleBankInputChange}
            />
          </Grid>
          {/* <Grid item className="drive-grid" xs={4}>
            <label className="pharmacy-field-label">Credit Card 2</label>
            <input
                  autoComplete="off"
              type="text"
              className="pharmacy-field"
              name="accountno"
              placeholder="Credit Card 2"
              value={bankInfo.accountno}
              onChange={handleBankInputChange}
            />
          </Grid> */}
        </Grid>

        <div className="form-sec-head">Billing Rates</div>
        <Grid container className="form-grid" spacing={2}>
          {/* First Row */}
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">Next Day</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="pharmacy-field"
              name="nextDayPrice"
              placeholder="Next Day Price"
              value={pharmacyDetails.nextDayPrice}
              onChange={handleInputChange}
            />
            {fieldErrors.nextDayPrice && <span className="error-message">{fieldErrors.nextDayPrice}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label-2">Next Day (Time Window)</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="pharmacy-field"
              name="timeWindowPrice"
              placeholder="Next Day"
              value={pharmacyDetails.timeWindowPrice}
              onChange={handleInputChange}
            />
            {fieldErrors.timeWindowPrice && <span className="error-message">{fieldErrors.timeWindowPrice}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">Same Day</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="pharmacy-field"
              name="sameDayPrice"
              placeholder="Same Day Price"
              value={pharmacyDetails.sameDayPrice}
              onChange={handleInputChange}
            />
            {fieldErrors.sameDayPrice && <span className="error-message">{fieldErrors.sameDayPrice}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">Urgent</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="pharmacy-field"
              name="urgentPrice"
              placeholder="urgentPrice"
              value={pharmacyDetails.urgentPrice}
              onChange={handleInputChange}
            />
            {fieldErrors.urgentPrice && <span className="error-message">{fieldErrors.urgentPrice}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">1st Attempt</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="pharmacy-field"
              name="f_attemptPrice"
              placeholder="Attempt Price"
              value={pharmacyDetails.f_attemptPrice}
              onChange={handleInputChange}
            />
            {fieldErrors.f_attemptPrice && <span className="error-message">{fieldErrors.f_attemptPrice}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label"> 2nd Attempt</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="pharmacy-field"
              name="s_attemptPrice"
              placeholder="Attempt Price"
              value={pharmacyDetails.s_attemptPrice}
              onChange={handleInputChange}
            />
            {fieldErrors.s_attemptPrice && <span className="error-message">{fieldErrors.s_attemptPrice}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">3rd Attempt</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="pharmacy-field"
              name="t_attemptPrice"
              placeholder="Attempt Price"
              value={pharmacyDetails.t_attemptPrice}
              onChange={handleInputChange}
            />
            {fieldErrors.t_attemptPrice && <span className="error-message">{fieldErrors.t_attemptPrice}</span>}
          </Grid>
          <Grid item className="drive-grid" xs={2}>
            <label className="pharmacy-field-label">Cancel/Return</label>
            <input
              autoComplete="off"
              type="number"
              step="0.01"
              className="pharmacy-field"
              name="cancelPrice"
              placeholder="cancel Price"
              value={pharmacyDetails.cancelPrice}
              onChange={handleInputChange}
            />
            {fieldErrors.cancelPrice && <span className="error-message">{fieldErrors.cancelPrice}</span>}
          </Grid>
        </Grid>

        <Grid container className="form-grid" spacing={2}>
          <Grid item xs={6}>
            <div className="form-sec-head">Notes</div>
            <Grid container className="form-grid" spacing={2}>
              {/* Four Fields in a 3-3-3-3 Configuration */}
              <Grid item className="drive-in-grid" xs={12}>
                <textarea
                  rows="8" 
                  className="pharmacy-textarea-field"
                  name="notes"
                  placeholder="Pharmacy Notes"
                  value={pharmacyDetails.notes}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item className="drive-grid" xs={6}>
            <div className="form-sec-head">Document</div>
            <Grid container className="form-grid" spacing={2}>
              
              <Grid item className="drive-in-grid" xs={12}>
                <textarea
                  rows="8" 
                  className="pharmacy-textarea-field"
                  name="document"
                  value={documents.document}
                  onChange={handleInputChangeDocument}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>

        <div className="form-sec-head">Add Hipaa Notes</div>
        <Checkbox name="bussAddress" checked={true} onChange={""} />
        <Grid container className="form-grid" spacing={2}>
          {/* Four Fields in a 3-3-3-3 Configuration */}
          <Grid item className="drive-in-grid" xs={12}>
            <textarea
              rows="8" 
              className="pharmacy-textarea-field-2"
              name="hippa_notes"
              placeholder="Hipaa Note"
              value={pharmacyDetails.hippa_notes}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <div className="pharmacy-btn">
          {/* <button type="button" className="del-pharmacy">Delete Account</button> */}
          <button type="submit" onClick={handleSubmit} className="save-pharmacy">Save Details</button>
        </div>
    </div>
  );
}

export default AddPharmacy;

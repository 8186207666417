import React, {useEffect, useState} from "react";
import { 
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  landline,
  mobile,
  pharmacyCall,
  landline_white,
  text,
  pharmacyAddress
} from '../../../assests';

import { useNavigate } from 'react-router';

import { toast } from 'react-toastify';

import "./pharmacyOrderListObject.css";

function PharmacyOrderListObject({pharmacyOrder}) {


  const navigate = useNavigate();


  return (
    <div className="pharmacyOrder-div">
      <div className="pharmacyOrder-first">
        <h3 className="pharmacyOrder-date">{pharmacyOrder.pharmacy.name}</h3>
        <div className="detail-div-pharmacyOrder">
          <div className="d-flex-div">
            <img className="detail-in-icon-ph" src={pharmacyCall} alt="Logo" />
            <h3 className="detail-in-text-pharmacy">{pharmacyOrder.pharmacy.contact_no}</h3>
          </div>
          <div className="d-flex-div">
            <img className="detail-in-icon-ph" src={landline_white} alt="Logo" />
            <h3 className="detail-in-text-pharmacy">{pharmacyOrder.pharmacy.fax_number}</h3>
          </div>
        </div>
        <div  className="d-flex-div" style={{marginBottom: '5px'}}>
          <img className="detail-in-icon-ph" src={text} alt="Logo" />
          <h3 className="detail-in-text-pharmacy">{pharmacyOrder.pharmacy.email}</h3>
        </div>
        <div className="detail-div-pharmacy" style={{marginBottom: '5px'}}>
          <img className="detail-in-icon-ph" src={pharmacyAddress} alt="Logo" />
          <h3 className="detail-in-text-pharmacy">{pharmacyOrder.pharmacy.location}</h3>
        </div>
      </div>
      <div className="pharmacyOrder-order-list">
        <div className="pharmacyOrder-order-obj">
          <h3 className="pharmacyOrder-order-head">Same day <span style={{color: '#0B6699'}}>Urgent</span></h3>
          <h3 className="pharmacyOrder-order-first">Ready for pickup: <span style={{color: '#0B6699'}}>{pharmacyOrder.orders.Urgent.received_at_facility}</span></h3>
          <h3 className="pharmacyOrder-order-second">Picked up: {pharmacyOrder.orders.Urgent.picked_up}</h3>
        </div>
        <div className="pharmacyOrder-order-obj">
          <h3 className="pharmacyOrder-order-head">Same day <span style={{color: '#0B6699'}}>2pm to 6pm</span></h3>
          <h3 className="pharmacyOrder-order-first">Ready for pickup: <span style={{color: '#0B6699'}}>{pharmacyOrder.orders['2pm-6pm'].received_at_facility}</span></h3>
          <h3 className="pharmacyOrder-order-second">Picked up: {pharmacyOrder.orders['2pm-6pm'].picked_up}</h3>
        </div>
        <div className="pharmacyOrder-order-obj">
          <h3 className="pharmacyOrder-order-head">Same day <span style={{color: '#0B6699'}}>5pm to 9pm</span></h3>
          <h3 className="pharmacyOrder-order-first">Ready for pickup: <span style={{color: '#0B6699'}}>{pharmacyOrder.orders['5pm-9pm'].received_at_facility}</span></h3>
          <h3 className="pharmacyOrder-order-second">Picked up: {pharmacyOrder.orders['5pm-9pm'].picked_up}</h3>
        </div>
        <div className="pharmacyOrder-order-obj">
          <h3 className="pharmacyOrder-order-head">Next day <span style={{color: '#FEB018'}}>Anytime</span></h3>
          <h3 className="pharmacyOrder-order-first">Ready for pickup: <span style={{color: '#FEB018'}}>{pharmacyOrder.orders.Anytime.received_at_facility}</span></h3>
          <h3 className="pharmacyOrder-order-second">Picked up: {pharmacyOrder.orders.Anytime.picked_up}</h3>
        </div>
        <div className="pharmacyOrder-order-obj">
          <h3 className="pharmacyOrder-order-head">Next day <span style={{color: '#FEB018'}}>9am to 3pm</span></h3>
          <h3 className="pharmacyOrder-order-first">Ready for pickup: <span style={{color: '#FEB018'}}>{pharmacyOrder.orders['9am-3pm'].received_at_facility}</span></h3>
          <h3 className="pharmacyOrder-order-second">Picked up: {pharmacyOrder.orders['9am-3pm'].picked_up}</h3>
        </div>
      </div>
      
    </div>
  );
}

export default PharmacyOrderListObject;

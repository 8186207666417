import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router";
import {
  Toolbar,
  Box,
  IconButton,
  Typography,
  Avatar,
  Button,
  Badge,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import {
  AccountCircle,
  Notifications,
  Message,
  Flag,
  NotificationsActive,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";

import { removeToken } from "../../../utils/localStorage";

import {
  chatTop,
  notification,
  bell,
  text,
  flag,
  announce,
  mainlogo,
} from "../../../assests";
import "./FullNavbar.css";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: "84px",
}));

const FullNavbar = () => {
  // Define state and functions for the dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const {pid} = useParams();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const updateDate = () => {
      const now = new Date();
      const day = String(now.getDate()).padStart(2, "0");
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = now.getFullYear();
      const options = { weekday: "long" };
      const dayOfWeek = now.toLocaleDateString(undefined, options);
      const formattedDate = `${dayOfWeek} ${day}-${month}-${year}`;
      setCurrentDate(formattedDate);
    };

    updateDate();
    const intervalId = setInterval(updateDate, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleLogoutMenu = () => {
    removeToken();
    localStorage.removeItem("userType");
    localStorage.removeItem("route_startaddress");
    localStorage.removeItem("pharmacyId");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("profilePic");
    localStorage.removeItem("deviceId");
    localStorage.removeItem("tokenAdded");
    localStorage.removeItem("show_report");
    localStorage.removeItem("show_team");

    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        open={isSidebarOpen}
        position="fixed"
        style={{ backgroundColor: "#0B6699" , justifyContent: 'center', height: 50}}
      >
        <Toolbar>
          {/* Center */}
          <div onClick={() => (pid !== undefined ? navigate(`/pharmacy/${pid}/list/order`) : navigate('/list/order'))} style={{ flex: 1, textAlign: "left" }}>
            <img className="top-logo" style={{height: "40px", width: "200px"}} src={mainlogo} alt="Logo" />
          </div>

          {/* Right side */}
          {/* <IconButton color="inherit">
            <img className="top-icon" src={announce} alt="Logo" />
          </IconButton>
          <IconButton color="inherit">
            <img className="top-icon" src={notification} alt="Logo" />
          </IconButton>
          <IconButton color="inherit">
            <img className="top-icon" src={text} alt="Logo" />
          </IconButton>
          <IconButton color="inherit">
            <img className="top-icon" src={flag} alt="Logo" />
          </IconButton>
          <IconButton color="inherit">
            <img className="top-icon" src={chatTop} alt="Logo" />
          </IconButton>
          <IconButton color="inherit">
            <img className="top-icon" src={bell} alt="Logo" />
          </IconButton> */}
          {/* User avatar */}

          {/* Username dropdown */}
          
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default FullNavbar;

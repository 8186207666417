import React, {useState, useEffect} from 'react'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';
import { toast } from 'react-toastify';
import { ArrowDropDownCircle, CheckBox } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  Autocomplete  as MUIAutocomplete,
  TextField,
  Select,
  MenuItem,
  Button,
  Menu,
  Paper,
  Grid,
  DialogContent,
  IconButton,
  FormControl,
  Checkbox
} from "@mui/material";
import { DateRange } from "react-date-range";
import { 
  PHARMACY_REPORT,
  PHARMACY_REPORT_RANGE,
  PHARMACY_REPORT_DETAIL,
  PHARMACY_REPORT_COUNT,
  ALL_PHARMACY_LIST
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth, postApiWithAuth, putApiWithAuth,
} from "../../../utils/api";

import PharmacyReportObject from '../../MiniComponents/PharmacyReportObject';
import './PharmacyReport.css';

const isCurrentWeek = (dateRange) => {
  const [startDate, endDate] = dateRange.split(' - ');
  const currentDate = new Date();

  // Parse the date strings into Date objects
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Check if the current date is within the date range
  return currentDate >= startDateObj && currentDate <= endDateObj;
};

function PharmacyReport() {
  const [loading, setLoading] = useState(false);
  const [pharmacyOrderList, setPharmacyOrderList] = useState([]);
  const [pharmacy, setPharmacy] = useState("");
  const [duration, setDuration] = React.useState(1);
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [deliveryTime, setDeliveryTime] = React.useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [deliveryTimeFrom, setDeliveryTimeFrom] = React.useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  // const today = new Date('2024-07-21T00:00:00Z'); // Hardcoding today date to 21st July 2024 for testing
  const today = new Date();
  today.setTime(today.getTime() - today.getTimezoneOffset() * 60 * 1000); // Adjust for local time zone
  let endDate;
  if (today.getDay() === 0) { // If today is Sunday
      endDate = new Date(today);
      endDate.setDate(today.getDate() - 7); // Last Saturday
  } else { // Otherwise
      endDate = new Date(today);
      endDate.setDate(today.getDate() - today.getDay()); // Last Sunday
  }
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - 13); // Two weeks before endDate
  
  const [dateRange, setDateRange] = useState([
      {
          startDate,
          endDate,
          key: "selection",
      },
  ]);
  const filteredPharmacyOrderList = pharmacyOrderList?.filter((pharmacyOrder) => !isCurrentWeek(pharmacyOrder.dateRange));

  // const getpharmacyOrderList = async () => {
  //   setLoading(true);
  //   // const formData = { from_date: deliveryTime, to_date: deliveryTimeFrom, pharmacy: 1 };
  //   const formData = {data: 'all',
  //   from: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
  //   to: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
  //   pharmacy: pharmacy,
  // }
  //   // const formData = { data: duration }
  //   const response = await putApiWithAuth(PHARMACY_REPORT_DETAIL, formData);
  //   console.log(response)
  //   if (response.data !== undefined) {
  //     setPharmacyOrderList(response.data)
  //     // setPageIndex(response.data.data.pagination.pageIndex - 1);
  //     // setPageSize(response.data.data.pagination.pageSize);
  //     // setTotalPages(response.data.data.pagination.totalPages);
  //     // setTotalItems(response.data.data.pagination.totalItems);
  //     setLoading(false);
  //   } else {
  //     setLoading(false);
  //   }
  // };
  const getpharmacyOrderList = async () => {
    setLoading(true);
  
    let formData = {
      data: pharmacy.length === 0 ? 'all' : 'specific',
      from: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
      to: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
    };
  
    if (pharmacy.length > 0) {
      formData = { ...formData, pharmacy: pharmacy };
    }
  
    const response = await putApiWithAuth(PHARMACY_REPORT_DETAIL, formData);
    if (response.data !== undefined) {
      setPharmacyOrderList(response.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  
  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, {'justList': true});
      if (response.data !== undefined) {
        setUniquePharmacy(response.data.data.map((item) => ({id: item.id, name: item.name})));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getpharmacyOrderList();
    fetchPharmacies();
  }, [dateRange, pharmacy]);
  useEffect(() => {
    fetchPharmacies();
  }, []);
  const handleSelect = (ranges) => {
    if (ranges.selection.endDate) {
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };
  const [filterName, setFilterName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterName(
      typeof value === 'string' ? value.split(',') : value,
    );

    const pharmacyIds = uniquePharmacy
      .filter(pharmacy => value.includes(pharmacy.name))
      .map(pharmacy => pharmacy.id);
    setPharmacy(pharmacyIds);
  };
  return (
    <div>
       <div className="second-row">
        <div className="filter-container">
          {/* <div className="search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Order ID, Name, Address, Phone, Rx"
              className="search-input"
              autoComplete="off"
              onKeyPress={handleKeyPress}
            />
          </div> */}
          <div className="search-filter-container">
            <FormControl className="filter-input">
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                IconComponent={(props) => <ArrowDropDownCircle {...props} sx={{ color: 'black', width: 20 }} />}
                multiple
                displayEmpty
                sx={{
                  fontSize: '11px',
                  "& .MuiSvgIcon-root": {
                    color: 'black'
                  }
                }}
                MenuProps={{
                  sx: {
                    mt: '8px'
                  }
                }}
                value={filterName}
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p style={{ fontSize: '14px', opacity: 1,fontFamily: "Saira Extra Condensed" }}>Filter Pharmacy</p>;
                  }
                  return <p style={{ fontSize: '14px', opacity: 1,fontFamily: "Saira Extra Condensed" }}>{selected.length > 1 ? `${selected.slice(0, 1)}  ...` : selected}</p>
                }}
              >
                {uniquePharmacy?.map((pharmacy, index) => (
                  <MenuItem
                    key={index}
                    value={pharmacy.name}
                    sx={{
                      fontSize: '13px',
                      fontFamily: "Saira Extra Condensed"
                    }}
                  >
                    <Checkbox size="small" checked={filterName.indexOf(pharmacy.name) > -1} />
                    {pharmacy.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <MUIAutocomplete
              style={{ padding: "0" }}
              id="tags-outlined"
              multiple
              className="filter-input"
              options={uniquePharmacy}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px' }}>
                  {option.name}
                </p>
              )}
              onChange={handlePharmacyChange}
              filterSelectedOptions
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Filter Pharmacy"
                  sx={{
                    "& input::placeholder": {
                      fontSize: "14px",
                      opacity: 1
                    },
                    "& input": {
                      fontSize: '14px !important'
                    }
                  }}
                />
              )}
              sx={{
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              popupIcon={<ArrowDropDownCircle sx={{color: 'black', width: 20}}/>}
            /> */}
          </div>
        </div>
        <div className="date-input-container">
          <div type="text" className="date-input">
            <CalendarMonthIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={toggleCalendar}
            />
            {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
            <ForwardIcon style={{ fontSize: "32px" }} />
            {dateRange[0].endDate
              ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
              : ""}
          </div>
          {isCalendarVisible && (
            <div className="calendar-container">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )}
        </div>
      </div>
      <div className='list-head'>
        <h2 className='listPharmacyOrderHeader'>WEEKLY REPORTS</h2>
      </div>
      <div class='tab-total'>
        
      </div>
      <div className='user-obj'>
        {filteredPharmacyOrderList?.length > 0 &&
          filteredPharmacyOrderList.slice().reverse().map((pharmacyOrder, i) => (
            <>
            <h1 style={{textAlign: 'center', fontWeight: '700', fontSize: '16px'}}>{pharmacyOrder.dateRange}</h1>
            { pharmacyOrder.data?.length > 0 &&
            pharmacyOrder.data.map((orderReport, i) => 
            <PharmacyReportObject pharmacyOrder={orderReport} deliveryTime={deliveryTime} deliveryTimeFrom={deliveryTimeFrom} dateRange={pharmacyOrder.dateRange} />
            )}
            </>
          ))
        }
      </div>
      {/* <div className='bottom-tab'>
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div> */}
    </div>
  )
}

export default PharmacyReport



import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Menu as BaseMenu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem } from "@mui/base/MenuItem";
import { Dropdown } from "@mui/base/Dropdown";
import { announce, text } from "../../../../assests";
import { Badge, IconButton } from "@mui/material";
import moment from "moment";
import { BiCheckDouble } from "react-icons/bi";
import { useNavigate, useParams, } from "react-router-dom";
import {
  ALL_ORDERS,
  ALL_PHARMACY_LIST,
  URGENT_ORDER,
} from "../../../../utils/apiUrls";
import { getApiWithAuth } from "../../../../utils/api";

export default function NewSpeakDropdown({
  isNavDropdownsOpen,
  setIsNavDropdownsOpen,
}) {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false); // Separate state for dropdown open/close
  const [orders, setOrders] = useState([]);
  const { pid } = useParams();
  const dropdownRef = useRef(null);

  const userType = localStorage.getItem("userType");

  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownOpen && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }
  
    if (dropdownOpen) {
      window.addEventListener("click", handleClickOutside);
    }
  
    // Remove event listener when dropdown is closed or component unmounts
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownOpen]);
  const handleClick = () => {
    setDropdownOpen(false);
    localStorage.setItem("urgentOrder", true);
    const randomQueryParam = `${Math.random().toString(36).substring(2)}`;
    if (userType === "PharmacyOwner" || userType === "PharmacyEmployee") {
      navigate(`/pharmacy/${pid}/list/order/${true}?${randomQueryParam}`);
    } else {
      navigate(`/list/order/${true}?${randomQueryParam}`);
    }
  };

  const handleClear = () => {
    setDropdownOpen(false);
  };

  const handleButtonClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getOrders = async () => {
    const response = await getApiWithAuth(URGENT_ORDER);
    if (response.data !== undefined) {
      const allOrders = response.data.data.data;
      setOrders(allOrders);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div ref={dropdownRef}>
      <Dropdown
        open={dropdownOpen} // Use separate state for dropdown open/close
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuButton>
          <IconButton color="inherit" onClick={handleButtonClick}>
            <img className="top-icon" src={announce} alt="Logo" />
            {userType === "PharmacyOwner" || userType === "PharmacyEmployee" ? (
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginBottom: "18px" }}
                badgeContent={orders?.length}
                color="error"
              ></Badge>
            ) : (
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ marginBottom: "18px" }}
                badgeContent={orders?.length}
                color="error"
              ></Badge>
            )}
          </IconButton>
        </MenuButton>
        <Menu className="z-[1500] left-[-11%] bg-gray py-md px-md min-h-[60px] max-w-[650px] rounded-sm flex items-center justify-center">
          <div className="flex flex-col gap-md">
            <p className="text-red text-3xl font-[700]">Urgent ORDERS</p>
            {userType === "PharmacyOwner" || userType === "PharmacyEmployee" ? (
              orders && orders.length > 0 ? (
                orders?.slice(0, 3).map((order) => (
                  <MenuItem
                    className="bg-white py-sm px-md rounded-xs"
                    style={{ width: "350px" }}
                    onClick={createHandleMenuClick("Profile")}
                  >
                    <div className="flex flex-col gap-sm">
                      <div className="flex items-center justify-between">
                        <p className="text-md font-[700]">Status: {order.deliverydate}</p>
                        <p className="text-md font-[700]">Client: {order.name}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-md font-[700]">Pharmacy Name: {order.pharmacy_name}</p>
                        <p className="text-md font-[700] text-golden">
                          {moment(order.createdAt).format("MM-DD-YYYY")}&nbsp;
                          {moment(order.createdAt).format("HH:mm:ss")}
                        </p>
                      </div>
                    </div>
                  </MenuItem>
                ))
              ) : (
                <p>No data</p>
              )
            ) : orders && orders.length > 0 ? (
              orders?.slice(0, 3).map((order) => (
                <MenuItem
                  className="bg-white py-sm px-md rounded-xs"
                  style={{ width: "350px" }}
                  onClick={createHandleMenuClick("Profile")}
                >
                  <div className="flex flex-col gap-sm">
                    <div className="flex items-center justify-between">
                      <p className="text-md font-[700]">Order ID: {order.slug}</p>
                      <p className="text-md font-[700]">Client: {order.name}</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-md font-[700]">Pharmacy Name: {order.pharmacy_name}</p>
                      <p className="text-md font-[700] text-golden">
                        {moment(order.createdAt).format("MM-DD-YYYY")}&nbsp;
                        {moment(order.createdAt).format("HH:mm:ss")}
                      </p>
                    </div>
                  </div>
                </MenuItem>
              ))
            ) : (
              <p>No data</p>
            )}

            <div className="flex justify-end gap-sm">
              <button
                onClick={handleClear}
                className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-red rounded-[2px]"
              >
                Clear
              </button>

              <button
                onClick={handleClick}
                className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-[#0455A6] rounded-[2px]"
              >
                See All
              </button>
            </div>
          </div>
        </Menu>
      </Dropdown>
    </div>
  );
}

NewSpeakDropdown.propTypes = {};

const Menu = BaseMenu;

Menu.propTypes = {
  open: true,
  defaultOpen: true,
  slotProps: PropTypes.shape({
    listbox: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    root: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
};

const MenuButton = BaseMenuButton;

MenuButton.propTypes = {
  className: PropTypes.string,
};

const MenuItem = BaseMenuItem;

MenuItem.propTypes = {
  className: PropTypes.string,
};

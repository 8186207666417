import React, {useState, useEffect} from 'react'
import './routeStatus.css';
import FiltersTab from '../../RouteStatusComponents/FiltersTab/FiltersTab';
import RouteName from '../../RouteStatusComponents/RouteName/RouteName';
import RouteProgressTime from '../../RouteStatusComponents/RouteProgressTime/RouteProgressTime';
import RouteStatusMap from '../../RouteStatusComponents/RouteStatusMap/RouteStatusMap';
import RouteStatusStops from '../../RouteStatusComponents/RouteStatusStops/RouteStatusStops';
import {
  ALL_ROUTES,
  ROUTE_DETAIL
} from "../../../utils/apiUrls";
import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";
import { route } from '../../../assests';
import RouteStatusMapList from '../../RouteStatusComponents/RouteStatusMap/RouteStatusMapList';
import CustomLoader from '../../CommonComponents/Loader/CustomLoader';


const RouteStatus = () => {
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [routeOrders, setRouteOrders] = useState([]);
  const [routeOrdersMap, setRouteOrdersMap] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showMap, setShowMap] = useState(0);
  const [selectedRoute, setSelectedRoute] = useState({});
  const [selectedRouteList, setSelectedRouteList] = useState([]);
  const [routeStatusOrder, setRouteStatusOrder] = useState([]);

  const [routeColors, setRouteColors] = useState({});
  
  const getRoutes = async () => {
    setLoading(true);
    const response = await getApiWithAuth(
      ALL_ROUTES + `get_routes_for_status/?&page=${pageIndex + 1}&pageSize=${pageSize}`
    );
    if (response.data !== undefined) {
      setRoutes(response.data.data.data);
      setShowMap(0);
      setPageIndex(response.data.data.pagination.pageIndex - 1);
      setPageSize(response.data.data.pagination.pageSize);
      setTotalPages(response.data.data.pagination.totalPages);
      setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
      setRouteStatusOrder([])
      setSelectedRouteList([])
      for (const route of response.data.data.data) {
        await getRouteDetail(route.id);  // You might want to handle errors or rejections here
      }
      fetchRouteDetail(response.data.data.data[0].id);
    } else {
      setLoading(false);
    }
    setLoading(true);
    const response2 = await getApiWithAuth(ROUTE_DETAIL);
    if (response2.data !== undefined) {
      setRouteOrders(response2.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoutes();
  }, [pageIndex, pageSize]);


  const fetchRouteDetail = async (id) => {

    try {
      const response = await getApiWithAuth(ROUTE_DETAIL + `?route=${id}`);
      if (response.data) {   
        // Set only the current route's details
        setRouteOrdersMap(response.data.data.orders);
        setSelectedRoute(response.data.data.route);
      }
    } catch (error) {
      console.error('Failed to fetch route details:', error);
    }
  };

  const getRouteDetail = async (routeId) => {
    setLoading(true);
    const response = await getApiWithAuth(ROUTE_DETAIL + `?route=${routeId}`);
    if (response.data !== undefined) {
      setRouteStatusOrder(prevOrders => {
        // Check if the route details are already included to prevent duplicates
        const existingIndex = prevOrders.findIndex(orders => orders.some(order => order.route === routeId));
        if (existingIndex !== -1) {
            // If existing, replace or update the list at that index
            let newOrdersList = [...prevOrders];
            newOrdersList[existingIndex] = response.data.data.orders;
            return newOrdersList;
        } else {
            // If not existing, append new list
            return [...prevOrders, response.data.data.orders];
        }
      });
      setSelectedRouteList(prevRoutes => {
        // Check if the route is already included to prevent duplicates
        const existingRoute = prevRoutes.find(route => route.id === response.data.data.route.id);
        return existingRoute ? prevRoutes : [...prevRoutes, response.data.data.route];
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (showMap !== 0) {
      fetchRouteDetail(showMap, false);
    } else {
      Promise.all(routes.map(route => fetchRouteDetail(route.id, true)))
        .then(() => {
          console.log('All route details fetched');
        })
        .catch(error => {
          console.error('Error fetching all routes details:', error);
        });
    }
  
  }, [showMap, routes]);


  return (
    <div>
      {/* <FiltersTab /> */}

      {loading && <CustomLoader />}
      
      <div className='route-status-second-container'>
        <RouteName routeStatusData={routes} setShowMap={setShowMap} setRouteColors={setRouteColors} />
        {showMap === 0 ?
          <RouteStatusMapList routeOrders={routeStatusOrder} route={selectedRouteList} routeColors={routeColors}/>
          :
          <RouteStatusMap routeOrders={routeOrdersMap} route={selectedRoute} routeColors={routeColors} all={showMap === 0}/>
        }
      </div>

      <RouteStatusStops routes={routes} routeOrders={routeOrders} />
    </div>
  )
}

export default RouteStatus
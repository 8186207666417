import React, {useEffect, useState} from "react";
import { 
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router';

import { toast } from 'react-toastify';

import { 
  ALL_ZONES_LIST
} from "../../../utils/apiUrls";
import {
  getApiWithAuth, postApiWithAuth, putApiWithAuth, deleteApiWithAuth
} from "../../../utils/api";

import "./boroughObject.css";

function BoroughObject({borough, getBorough}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [boroughmodalOpen, setBoroughModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState('');
  const [formState, setFormState] = useState({
    name: "",
    borro: borough.id
  });

  const navigate = useNavigate();


  const handleInputChange = (e) => {
    const { name, value, code } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      [code]: value,
    });
  };

  const handleAddZone = () => {
    setModalOpen(true);
  };

  const handleAddZoneClose = () => {
    setModalOpen(false);
  };

  const handleSaveZone = async () => {
    if (!edit) {
      if (!borough.zones.some(item => item.name === formState.name)) {
        const response = await postApiWithAuth(ALL_ZONES_LIST, formState);
        console.log(response);
        if (response.status !== 400) {
          toast.success('Zone Created Successfully!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error('Backend Error!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setModalOpen(false);
        setFormState({
          name: "",
          borro: borough.id,
        });
        getBorough();
      } else {
        toast.error('Name already present!', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setModalOpen(false);
        setFormState({
          name: "",
          borro: borough.id,
        });
        getBorough();
      }
    } else {
      const existingObject = borough.zones.find(item => item.id === id);
      console.log(existingObject.name, existingObject.name !== formState.name, 'eeeeeeeeeeeeeee', formState.name);
      if (existingObject.name !== formState.name || existingObject.code !== formState.code) {
        console.log("formStateformState1",formState)
        const response = await putApiWithAuth(ALL_ZONES_LIST + `ZonemdList/${id}/update`, formState);
        console.log(response);
        if (response.status !== 400) {
          toast.success('Zone Edited Successfully!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error('Backend Error!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setModalOpen(false);
        setFormState({
          name: "",
          borro: borough.id,
        });
        getBorough();
        setEdit(false);
        setId('');
      } else {
        toast.error('Name and/or Zipcode Already Present!', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setModalOpen(false);
        setFormState({
          name: "",
          borro: borough.id,
        });
        getBorough();
        setEdit(false);
        setId('');
      }
    }
  };
  

  const handleDeleteZone = async (zid) => {
    console.log('work')
    const response = await deleteApiWithAuth(ALL_ZONES_LIST + `ZonemdList/${zid}/delete`);
    console.log(response)
    if (response.status !== 400) {
      toast.success('Zone Delete Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setModalOpen(false);
    setFormState({
      name: "",
      borro: borough.id
    })
    getBorough();
    setEdit(false);
    setId('')
  };

  const handleDeleteCity = async (zid) => {
    const response = await deleteApiWithAuth(ALL_ZONES_LIST + `borros/${zid}/`);
    console.log(response)
    if (response.status !== 400) {
      toast.success('City Delete Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setModalOpen(false);
    setFormState({
      name: "",
      borro: borough.id
    })
    getBorough();
    setEdit(false);
    setId('')
  };
  const handleAddBorough = () => {
    setBoroughModalOpen(true);
    console.log(borough,'brough')
  };

  const handleAddBoroughClose = () => {
    setBoroughModalOpen(false);
  };

  const handleSaveBorough = async (bid) => {
    console.log(borough,'brough')
    const response = await putApiWithAuth(ALL_ZONES_LIST + `borros/${bid}/`, formState);
    console.log(response)
    if (response.status !== 400) {
      toast.success('Borough Updated Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setBoroughModalOpen(false);
    setFormState({
      name: "",
      comment: "Comment will be here!"
    });
    getBorough();
  };
  return (
    <div className="borough-div">
      <div className="borough-first" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
      <h2 className="borough-date">{borough.code}</h2>
        <h2 className="borough-date">{borough.name}</h2>
        <div style={{display: 'flex'}}>
                  <ModeEditIcon style={{cursor: 'pointer'}} onClick={() => (handleAddBorough(), setEdit(true), setId(borough.id), setFormState({
                    ...formState,
                    "name": borough.name,
                    "code": borough.code,
                    "comment": "blank",
                  }))} />
        <DeleteIcon style={{color:"red", cursor: 'pointer'}} onClick={() => (handleDeleteCity(borough.id))} />
        </div>
      </div>
      <div className="borough-zone-list">
        <Grid container>
        {borough.zones.map((zone, i) => (
          <Grid item xs={2}>
            <div className="zone-obj">
              <div className="zone-head">
                <h3>Zone</h3>
                <div style={{display: 'flex'}}>
                  <ModeEditIcon style={{cursor: 'pointer'}} onClick={() => (handleAddZone(), setEdit(true), setId(zone.id), setFormState({
                    ...formState,
                    "name": zone.name,
                    "code": zone.code,
                  }))} />
                  <DeleteIcon style={{color:"red", cursor: 'pointer'}} onClick={() => (handleDeleteZone(zone.id))} />
                </div>
              </div>
              <h3 className="zone-name" onClick={() => navigate(`/list/zone/${zone.id}`)}>{zone.code} {zone.name}</h3>
            </div>
          </Grid>
        ))}
        </Grid>
      </div>
      <div className="borough-last">
        <button className="borough-add-zone" onClick={handleAddZone}>Add Zone</button>
      </div>
      <Dialog open={boroughmodalOpen} onClose={handleAddBoroughClose}>
        <DialogTitle>Edit City</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Name</label>
          <input
            type="text"
            className="modal-field"
            name="name"
            placeholder="Name"
            style={{marginBottom: '10px'}}
            value={formState.name}
            autoFocus
            onChange={handleInputChange}
            autoComplete="off"
          />
          <label className="pharmacy-field-label">Code</label>
          <input
            type="text"
            className="modal-field"
            name="code"
            placeholder="Code"
            style={{marginBottom: '10px'}}
            value={formState.code}
            autoFocus
            onChange={handleInputChange}
            autoComplete="off"
          />
          <Button onClick={() => handleSaveBorough(borough.id)} variant="contained" style={{marginRight: '10px'}} color="primary">
  Save
</Button>
          <Button onClick={handleAddBoroughClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={modalOpen} onClose={handleAddZoneClose}>
        <DialogTitle>Add Zone</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Name</label>
          <input
            type="text"
            className="modal-field"
            name="name"
            placeholder="Name"
            style={{marginBottom: '10px'}}
            value={formState.name}
            autoFocus
            autoComplete="off"
            onChange={handleInputChange}
          />
          <label className="pharmacy-field-label">Code</label>
          <input
            type="text"
            className="modal-field"
            name="code"
            placeholder="Code"
            style={{marginBottom: '10px'}}
            value={formState.code}
            autoFocus
            autoComplete="off"
            onChange={handleInputChange}
          />
          <Button onClick={handleSaveZone} variant="contained" style={{marginRight: '10px'}} color="primary">
            Save
          </Button>
          <Button onClick={handleAddZoneClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default BoroughObject;

import React from "react";
import { ArrowDropDownCircle, AcUnit } from "@mui/icons-material";
import moment from "moment";
import "./times-new-roman-reg.ttf";

const RouteSlipRoute = ({ routes }) => {
  const today = moment().format("MM-DD-YYYY"); // Format the date as "MM-DD-YYYY"
  const dayOfWeek = moment().format("dddd"); // Get the day of the week

  const styles = {
    page: {
      width: "7.7in",
      height: "9.25in",
      margin: "5px", // Reduced margin
      position: "relative",
      display: "flex",
      flexDirection: "column",
      fontFamily: "Times New Roman, sans-serif",
    },
    pageHead: {
      display: "flex",
      justifyContent: "space-between",
      fontFamily: "Times New Roman, sans-serif",
      fontSize: "11px",
      fontWeight: "600",
      marginBottom: "10px", // Add margin bottom to separate sections
    },
    orderList: {
      display: "flex",
      flexDirection: "column",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      border: "1px gray", // Add border to the table
    },
    tableHeader: {
      borderBottom: "1px solid gray",
      border: "1px solid gray", // Add border to the table header cells
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      padding: "5px",
    },
    tableRow: {
      textAlign: "center",
      fontSize: "12px",
      border: "1px solid gray", // Add border to the table row cells
    },
    icon: {
      marginLeft: "5px",
      fontSize: "12px",
    },
  };

  return (
    <div style={styles.page}>
      <div style={styles.pageHead}>
        <p>
          {dayOfWeek}, {today}
        </p>
        <p>Route Report</p>
        <p>Page 1</p>
      </div>
      <div style={styles.orderList}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={{ ...styles.tableHeader, width: "250px" }}>
                Route Name
              </th>
              <th style={{ ...styles.tableHeader, width: "250px" }}>Driver</th>
              <th style={{ ...styles.tableHeader, width: "100px" }}>
                Total Stops
              </th>
              <th style={{ ...styles.tableHeader, width: "100px" }}>Done</th>
              <th style={{ ...styles.tableHeader, width: "100px" }}>Pending</th>
              <th style={{ ...styles.tableHeader, width: "100px" }}>Failed</th>
              <th style={{ ...styles.tableHeader, width: "180px" }}>
                Total Copay
              </th>
              <th style={{ ...styles.tableHeader, width: "180px" }}>
                Collected
              </th>
              <th style={{ ...styles.tableHeader, width: "180px" }}>
                Waived/Paid to Pharmacy
              </th>
              <th style={{ ...styles.tableHeader, width: "180px" }}>
                Start Time
              </th>
              <th style={{ ...styles.tableHeader, width: "180px" }}>
Last Order              </th>
            </tr>
          </thead>
          <tbody>
            {routes.map((route, i) => (
              <tr key={i} style={styles.tableRow}>
                <td style={{ fontWeight: "700", border: "1px solid gray" }}>
                  {route.detail_name}
                </td>
                <td style={{ fontWeight: "700", border: "1px solid gray" }}>
                  {route?.driver?.name}
                </td>
                <td style={{ fontWeight: "400", border: "1px solid gray" }}>
                  {route.totalOrderStop}
                </td>
                <td style={{ fontWeight: "400", border: "1px solid gray" }}>
                  {route.totalDelivered}
                </td>
                <td style={{ fontWeight: "400", border: "1px solid gray" }}>
                  {route.totalPending}
                </td>
                <td style={{ fontWeight: "400", border: "1px solid gray" }}>
                  {route.totalFailed}
                </td>
                <td style={{ fontWeight: "400", border: "1px solid gray" }}>
                  {route.totalRouteOrderTotalCopay.toFixed(2)}
                </td>
                <td style={{ fontWeight: "400", border: "1px solid gray" }}>
                  {route.totalCollectedTotalCopay.toFixed(2)}
                </td>
                <td style={{ fontWeight: "400", border: "1px solid gray" }}>
                  {route.totalPaidPhamracyTotalCopay.toFixed(2)}
                </td>
                <td style={{ fontWeight: '400', border: '1px solid gray' }}>
    {route?.startTime ? moment(route.startTime).format('MM-DD-YYYY hh:mm A') : ''}
</td>
<td style={{ fontWeight: '400', border: '1px solid gray' }}>
                                {route.lastOrderWithArrival?.driver_arrival ? moment(route.lastOrderWithArrival.driver_arrival).format('MM-DD-YYYY hh:mm A') : ''}
                            </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RouteSlipRoute;

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForwardIcon from "@mui/icons-material/Forward";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Cardicon1 from "../../../assests/cardIcon1.png";
import Cardicon2 from "../../../assests/cardIcon2.png";
import Cardicon3 from "../../../assests/cardIcon3.png";
import Cardicon4 from "../../../assests/cardIcon4.png";
import Cardicon5 from "../../../assests/cardIcon5.png";
import Cardicon6 from "../../../assests/cardIcon6.png";
import "../../MainComponents/OrderList/orderList.css";
import "../../MainComponents/OrderList/OrderList.scss";
import { Pie } from "react-chartjs-2";
import LineChart from "./LineChart";
import ArrowUp from "../../../assests/barChartArrowUp.png";
import ArrowDown from "../../../assests/barChartArrowDown.png";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import {
  GET_DASHBOARD
} from "../../../utils/apiUrls";
import {
  getApiWithAuth,
  postApiWithAuth,
  putApiWithAuth,
} from "../../../utils/api";
const OrderSummary = () => {
  const [orderNum, setOrderNum] = useState({});
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      key: "selection",
    },
  ]);  
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const data1 = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        data: [orderNum !== undefined ? orderNum["Monday_price"] : 0, orderNum !== undefined ? orderNum["Tuesday_price"] : 0, orderNum !== undefined ? orderNum["Wednesday_price"] : 0,orderNum !== undefined ? orderNum["Thursday_price"] : 0,orderNum !== undefined ? orderNum["Friday_price"] : 0,orderNum !== undefined ? orderNum["Saturday_price"] : 0,orderNum !== undefined ? orderNum["Sunday_price"] : 0],
        backgroundColor: [
          "#AB54DB26",
          "#AB54DB26",
          "#AB54DB26",
          "#0B6699",
          "#AB54DB26",
          "#AB54DB26",
          "#AB54DB26",
        ],
        borderColor: "#AB54DB26",
        borderWidth: 1,
        borderRadius: 5,
      },
    ],
  };

  const options1 = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        position: "nearest",
        xAlign: "center",
        yAlign: "bottom",

        bodyAlign: "center",
        padding: {
          left: 20,
          right: 20,
          top: 10,
          bottom: 10,
        },
        displayColors: false,
      },
    },

    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
  };

  const canvasRef1 = useRef(null);

  useEffect(() => {
    if (canvasRef1.current) {
      const bar_ctx = canvasRef1.current.getContext("2d");

      var background_1 = bar_ctx.createLinearGradient(0, 0, 0, 400);
      background_1.addColorStop(0, "#FF5454");
      background_1.addColorStop(1, "#FFBB54");

      const data2 = {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            data: [
              orderNum?.Monday_price || 0,
              orderNum?.Tuesday_price || 0,
              orderNum?.Wednesday_price || 0,
              orderNum?.Thursday_price || 0,
              orderNum?.Friday_price || 0,
              orderNum?.Saturday_price || 0,
              orderNum?.Sunday_price || 0
            ],
            backgroundColor: background_1,
            borderColor: "#AB54DB26",
            borderWidth: 1,
            borderRadius: 5,
          },
        ],
      };

      const options2 = {
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            position: "nearest",
            xAlign: "center",
            yAlign: "bottom",
            bodyColor: "#B7DBF9",
            bodyAlign: "center",
            padding: {
              left: 20,
              right: 20,
              top: 10,
              bottom: 10,
            },
            displayColors: false,
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          // y: {
          //   beginAtZero: true,
          //   ticks: {
          //     stepSize: 1,
          //     callback: function (value) {
          //       return `$${value}`; // Assuming the prices are in dollars
          //     },
          //   },
          // },
        },
      };

      const myChart = new Chart(bar_ctx, {
        type: "bar",
        data: data2,
        options: options2,
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [orderNum]);
  const canvasRef2 = useRef(null);

  useEffect(() => {
    if (canvasRef2.current) {
      const bar_ctx = canvasRef2.current.getContext("2d");

      var background_1 = bar_ctx.createLinearGradient(0, 0, 0, 400);
      background_1.addColorStop(0, "#216BDB");
      background_1.addColorStop(1, "#1BD5FE");

      const data3 = {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            // Ensure correct data mapping
            data: [
              orderNum?.Monday_price || 0,
              orderNum?.Tuesday_price || 0,
              orderNum?.Wednesday_price || 0,
              orderNum?.Thursday_price || 0,
              orderNum?.Friday_price || 0,
              orderNum?.Saturday_price || 0,
              orderNum?.Sunday_price || 0
            ],
            backgroundColor: background_1,
            borderColor: "#AB54DB26",
            borderWidth: 1,
            borderRadius: 5,
          },
        ],
      };

      const options3 = {
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            position: "nearest",
            xAlign: "center",
            yAlign: "bottom",
            bodyColor: "#B7DBF9",
            bodyAlign: "center",
            padding: {
              left: 20,
              right: 20,
              top: 10,
              bottom: 10,
            },
            displayColors: false,
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          // y: {
          //   beginAtZero: true, // Set to true to show y-axis from 0
          //   ticks: {
          //     stepSize: 1,
          //     callback: function (value) {
          //       return `$${value}`; // Assuming the prices are in dollars
          //     },
          //   },
          // },
        },
      };

      const myChart = new Chart(bar_ctx, {
        type: "bar",
        data: data3,
        options: options3,
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [orderNum]);
  const cardsData = [
    {
      quantity: orderNum !== undefined ? orderNum["1"] : 0,
      icon: "icon",
      text: "Ready for pickup",
      background: "#0D4DF2",
      cardIcon: Cardicon1,
    },
    {
      quantity: orderNum !== undefined ? orderNum["3"] : 0,
      icon: "icon",
      text: "Received at facility",
      background: "#0094FF",
      cardIcon: Cardicon2,
    },
    {
      quantity: orderNum !== undefined ? orderNum["2"] : 0,
      icon: "icon",
      text: "Order picked up",
      background: "#6E7E7E",
      cardIcon: Cardicon3,
    },
    {
      quantity: orderNum !== undefined ? orderNum["5"] : 0,
      icon: "icon",
      text: "In transit",
      background: "#FE9901",
      cardIcon: Cardicon4,
    },
    {
      quantity: orderNum !== undefined ? orderNum["6"] : 0,
      icon: "icon",
      text: "Delivered",
      background: "#2CB04B",
      cardIcon: Cardicon5,
    },
    {
      quantity: orderNum !== undefined ? orderNum["7"] : 0,
      icon: "icon",
      text: "Cancelled",
      background: "#FF110A",
      cardIcon: Cardicon6,
    },
  ];

  const calendarRef = useRef(null);

  const fetchOrderNum = async () => {
    try {
      const response = await getApiWithAuth(GET_DASHBOARD+`?from=${moment(
        dateRange[0].startDate
      ).format("MM-DD-YYYY")}&to=${moment(dateRange[0].endDate).format(
        "MM-DD-YYYY"
      )}`);
      if (response.data !== undefined) {
        setOrderNum(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (ranges) => {
    if (ranges.selection.endDate) {
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        setCalendarVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    fetchOrderNum();
  }, [dateRange]);

  return (
    <>
    {/* Orders Count */}
    <div className="flex flex-col">
      <div className="flex w-full mb-4 justify-between">
        <div>
          <p className="font-bold text-2xl">Orders Summary</p>
        </div>
        <div>
          <div className="date-input-container">
            <div type="text" className="date-input">
              <CalendarMonthIcon
                style={{ fontSize: "28px", cursor: "pointer" }}
                onClick={toggleCalendar}
              />
              {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
              <ForwardIcon style={{ fontSize: "32px" }} />
              {dateRange[0].endDate
                ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
                : ""}
            </div>

            {isCalendarVisible && (
              <div className="calendar-container">
                <DateRange
                  ranges={dateRange}
                  onChange={handleSelect}
                  retainEndDateOnFirstSelection={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        {cardsData.map((card, index) => (
          <div
            key={index}
            style={{ backgroundColor: card.background }}
            className={`flex text-white p-3 w-1/6 rounded-lg`}
          >
            <div className="flex flex-col w-full">
              <div className="flex w-full justify-between items-center">
                <p className="font-bold text-xl tracking-wider">
                  {card.quantity}
                </p>
                <img src={card.cardIcon} className="w-[20px] h-[20px]" />
              </div>
              <div>
                <p>{card.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
   <div className="flex w-full justify-between gap-4">
        <LineChart />
        <div className="flex-1">
          <p>Pharmacy Orders</p>
        </div>
     <div className="flex w-full justify-end w-[300px] h-[300px]">
     <div className="flex flex-col">
       <div className="flex w-full justify-between">
         <p className="text-lg">Chart summary</p>
         <p className="text-[#AB54DB] text-sm">Download Report</p>
       </div>
       <div>
         <Pie
           width={300}
           height={300}
           data={{
            //  labels: ["fixed servers", "down servers", "running"],

             datasets: [
               {
                 // label: "My First Dataset",
                 data: [orderNum !== undefined ? orderNum["1"] : 0, orderNum !== undefined ? orderNum["3"] : 0, orderNum !== undefined ? orderNum["2"] : 0,orderNum !== undefined ? orderNum["5"] : 0,orderNum !== undefined ? orderNum["6"] : 0,orderNum !== undefined ? orderNum["7"] : 0],
                 backgroundColor: [
                   "#0D4DF2",
                   "#0094FF",
                   "#6E7E7E",
                   "#FE9901",
                   "#2CB04B",
                   "#FF110A",
                 ],
                 hoverOffset: 4,
               },
             ],
           }}
           options={{
             plugins: {
               legend: {
                 position: "bottom",
                 labels: {
                   boxWidth: 10,
                   usePointStyle: true,
                 },
               },
               tooltip: {
                 bodyAlign: "center",
                 displayColors: false,
                 padding: {
                   left: 20,
                   right: 20,
                   top: 10,
                   bottom: 10,
                 },
               },
             },
             cutout: 110,
           }}
         />
       </div>
     </div>
   </div>
  </div>
    <div className="flex w-full mt-9 justify-between">
      <div className="flex flex-col gap-2 w-[400px]">
        <div className="flex justify-between w-full">
          <p className=" font-bold text-3xl tracking-wide">Deliveries</p>
          {/* <p className="text-[#AB54DB] text-sm font-semibold tracking-wide">
            View More
          </p> */}
        </div>
        <div className="flex w-1/2 justify-between">
          <p className="text-[#00A389] text-4xl tracking-wider">${orderNum !== undefined ? orderNum["totalprice"] : 0}</p>
          <div className="flex flex-col ">
            {/* <p className="text-[#464255] text-sm">+0.4%</p>
            <p className="text-[#464255] text-xs tracking-wide">
              Than Last Week
            </p> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-[400px]">
        <div className="flex justify-between w-full">
          <p className=" font-bold text-3xl tracking-wide">Deliveries Cost</p>
          {/* <p>View More</p> */}
        </div>
        <div className="flex w-full justify-between">
          <div className="flex gap-5 items-center">
            <img src={ArrowDown} className="w-[15px] h-[10px]" />
            <div className="flex flex-col ">
              {/* <p className="text-[#FF5B5B] text-sm">+2,5%</p>
              <p className="text-[#464255] text-xs tracking-wide">
                Up from last week
              </p> */}
            </div>
          </div>
          <div>
            <p className=" text-4xl tracking-wider">${orderNum !== undefined ? orderNum["totaldriverprice"] : 0}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-[400px]">
        <div className="flex justify-between w-full">
          <p className=" font-bold text-3xl tracking-wide">Income</p>
          {/* <p>View More</p> */}
        </div>
        <div className="flex w-full justify-between">
          <div className="flex gap-5 items-center">
            <img src={ArrowUp} className="w-[15px] h-[10px]" />
            <div className="flex flex-col ">
              {/* <p className="text-[#00A389] text-sm">+4,6%</p>
              <p className="text-[#464255] text-xs tracking-wide">
                Up from last week
              </p> */}
            </div>
          </div>
          <div>
          <p className="text-4xl tracking-wider">
  ${orderNum !== undefined ? (orderNum["totalprice"] - orderNum["totaldriverprice"]) : 0}
</p>
          </div>
        </div>
      </div>
    </div>
    <div className="flex w-full justify-between">
      <div className="w-[400px] h-[300px]">
        <Bar width={400} height={300} data={data1} options={options1} />
      </div>
      <div className="w-[400px] h-[300px]">
        <canvas
          style={{ width: "400px", height: "300px" }}
          ref={canvasRef1}
          id="chart"
        ></canvas>
      </div>
      <div className="w-[400px] h-[300px]">
        <canvas
          style={{ width: "400px", height: "300px" }}
          ref={canvasRef2}
          id="chart"
        ></canvas>
      </div>
    </div>
   </>
  );
};

export default OrderSummary;

import React from "react";
import "./Terms.css";

const Terms = () => (
  <div className="term">
    <header>
        <h1>Terms and Conditions</h1>
        <p>PLEASE READ THESE TERMS OF SERVICE AGREEMENT CAREFULLY. BY ACCESSING OR USING OUR WEBSITE (THE "SITE") OR OUR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS, PLEASE DO NOT ACCESS OR USE OUR SITE OR OUR SERVICES.</p>
    </header>

    <section>
        <h2>Introduction</h2>
        <p>This Agreement ("Terms") is a legal agreement between you and ("Drug Drop LLC. DBA Pill Drop"), office located at 1535 Stillwell Ave, Bronx, NY 10461-2211, United States. This Agreement supersedes all previous agreements.</p>
    </section>

    <section>
        <h2>Overview</h2>
        <p>The Site (<a href="https://pilldrop.ai">https://pilldrop.ai</a>) is operated by Pill Drop. Throughout the Site, the terms "we," "us," and "our" refer to Pill Drop. We offer this Site, including all information, tools, and services available from this Site to you, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.</p>
    </section>

    <section>
        <h2>General Terms</h2>
        <p>By agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence, or that you are of the age of majority and have given consent for any minor dependents to use this Site. You may not use our products or Site for any illegal or unauthorized purpose, nor may you, in the use of our products or Site, violate any laws in your jurisdiction.</p>
        <p>A breach or violation of any of the Terms will result in immediate termination of your account and right to use our Service. We reserve the right to modify or discontinue any part of our Site or Service without prior notice.</p>
    </section>

    <section>
        <h2>Account Creation</h2>
        <p>To use certain features of the Site, you must create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate and complete information and to update it as necessary.</p>
    </section>

    <section>
        <h2>Conduct</h2>
        <p>You agree not to use the Site for any unlawful purpose or to violate any laws. You are responsible for all content you post and agree not to upload harmful or illegal content. We reserve the right to remove any content that violates these Terms.</p>
    </section>

    <section>
        <h2>Intellectual Property</h2>
        <p>All content on the Site, including trademarks, service marks, logos, and copyrighted material, is the property of Pill Drop or our licensors. You agree not to use any content without our express written permission.</p>
    </section>

    <section>
        <h2>Indemnification</h2>
        <p>You agree to indemnify and hold Pill Drop and its affiliates harmless from any claims arising from your use of the Site or violation of these Terms.</p>
    </section>

    <section>
        <h2>Limitation of Liability</h2>
        <p>Pill Drop is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the Site or Services.</p>
    </section>

    <section>
        <h2>Modifications and Termination</h2>
        <p>We reserve the right to modify these Terms at any time. Your continued use of the Site following any changes indicates your acceptance of the new Terms. We may terminate your access to the Site at our discretion, without notice.</p>
    </section>

    <section>
        <h2>Governing Law</h2>
        <p>These Terms are governed by the laws of the State of New York. Any disputes arising from these Terms will be resolved in the courts of New York.</p>
    </section>

    <section>
        <h2>Privacy Policy</h2>
        <p>Your use of the Site is also governed by our Privacy Policy, available at <a href="https://pilldrop.ai/privacy-policy">https://pilldrop.ai/privacy-policy</a>.</p>
    </section>

    <section>
        <h2>Communication Consent</h2>
        <p>By providing your phone number during the order process, you consent to receive calls and text messages from us for delivery updates and any necessary instructions regarding your medication delivery. This communication is essential for ensuring timely and accurate delivery of your order. Pharmacy employees will obtain consent from patients during delivery confirmation and when orders are created. If you wish to opt-out of these communications, please contact our customer service.</p>
    </section>

    <section>
        <h2>Contact Information</h2>
        <p>For any questions about these Terms, please contact us at:</p>
        <address>
          Pill Drop<br />
          1535 Stillwell Ave, Bronx, NY 10461-2211, United States<br />
          Phone: (845) 314-0341<br />
          Email: <a href="mailto:info@pilldrop.ai">info@pilldrop.ai</a>
        </address>
    </section>

    <footer>
        <p>By using our Site and Services, you acknowledge that you have read and agree to these Terms and Conditions.</p>
    </footer>
  </div>
);

export default Terms;
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useParams, useNavigate, useLocation } from "react-router";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DirectionsIcon from "@mui/icons-material/Directions";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForwardIcon from "@mui/icons-material/Forward";
import { v4 as uuidv4 } from 'uuid';
import ChatModal from "../../ChatComponents/ChatModal";
import {
  Dialog,
  DialogActions,
  Autocomplete,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import {
  mainlogo,
  minilogo,
  chat,
  customOrder,
  customerService,
  dashboard,
  driver,
  finance,
  homepage,
  logout,
  redlogout,
  pharmacy,
  boroughs,
  route,
  routeStatus,
  action3,
  rxOrder,
  user,
} from "../../../assests";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage} from "firebase/messaging";
import { DateRange } from "react-date-range";
import {
  ALL_ORDERS,
  FCM_TOKEN,
  ALL_PHARMACY_LIST,
  ALL_ZONES_LIST,
  CREATE_ROUTE,
  CREATE_ORDER,
  CREATE_ROUTE_ZONE,
  CREATE_ROUTE_BOROUGH,
  ORDER_FILE_UPLOAD,
  UPLOAD_FILE,
} from "../../../utils/apiUrls";
import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";

import OrderObject from "../../MiniComponents/OrderObject";

import { upload } from "../../../assests";

import "./pharmacyOrderList.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function PharmacyOrderList() {
  const [file, setFile] = useState('');
  const [filter, setFilter] = useState('');
  const [timer, setTimer] = useState('');
  const [modalCreateRouteOpen, setModalCreateRouteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderNum, setOrderNum] = useState({});
  const [status, setStatus] = useState("1");
  const [search, setSearch] = useState("");
  const [rx, setrx] = useState("");
  const [pharmacy, setPharmacy] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [hasMore, setHasMore] = useState(null);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
      key: "selection",
    },
  ]);

  const orderObjRef = useRef(null);
  
  const firebaseConfig = {
    apiKey: "AIzaSyD9HaluoaEn0toQShTwIS4WU6ECWd3Ib6Y",
    authDomain: "pilldrop-2ba33.firebaseapp.com",
    projectId: "pilldrop-2ba33",
    storageBucket: "pilldrop-2ba33.appspot.com",
    messagingSenderId: "714498636709",
    appId: "1:714498636709:web:bc1143a7d59a84b9e7864c",
    measurementId: "G-0YKXMT2HKY",
  };
// Initialize Firebase app at the root level of your application
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey: "BJuixTt0nsnzUKL-vdE-8vJ1wojNg4_6vmDDEnpr-ns0W8ZyESBVJxcLkMhYPlrTBfhR6R3rh7UHn5VdbrEFHOs",
      }).then((currentToken) => {
        if (currentToken) {
          sendTokenToBackend(currentToken);
        } else {
          console.log("Can't get token");
        }
      });
    } else {
      console.log("Don't have permission");
    }
  });
}
const userId = localStorage.getItem('UID');

useEffect(() => {
  requestPermission();
  
  onMessage(messaging, (payload) => {

    const { title, body, icon, image } = payload.notification;
    const notificationIcon = icon || "https://www.test.pilldrop.ai/logo192.png";
    const notificationImage = image;
    
    const notificationOptions = {
      body,
      icon: notificationIcon,
      image: notificationImage,
      data: {
        url: payload.data?.openUrl || "https://www.pilldrop.ai",
      }
    };


    const notification = new Notification(title, notificationOptions);

    notification.onclick = (event) => {
      event.preventDefault(); // Prevent the browser from focusing the Notification's tab
      const url = notificationOptions.data.url;
      if (url) {
        window.open(url, '_blank'); // Open the URL in a new tab
      }
    };
  });
}, []);



// const sendTokenToBackend = async (token) => {
//   try {
//     const user_id = localStorage.getItem('UID')
//     const pharmacyId = localStorage.getItem('pharmacyId')
//     let deviceId = localStorage.getItem('deviceId');
//     // If device ID doesn't exist, generate a new one
//     if (!deviceId) {
//         deviceId = uuidv4();
//         localStorage.setItem('deviceId', deviceId);
//     }
//     const response = await postApiWithAuth(FCM_TOKEN, { token,user_id,pharmacyId, deviceId}); 
//     return response.data; 
//   } catch (error) {
//     console.error('Error sending token to backend:', error);
//     throw error; 
//   }
// };
const sendTokenToBackend = async (token) => {
  try {
    // Check if token has already been added
    const tokenAdded = localStorage.getItem('tokenAdded');
    
    if (tokenAdded === 'true') {
      return;
    }
    
    const user_id = localStorage.getItem('UID');
    const pharmacyId = localStorage.getItem('pharmacyId');
    let deviceId = localStorage.getItem('deviceId');
    
    // If device ID doesn't exist, generate a new one
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('deviceId', deviceId);
    }
    
    const response = await postApiWithAuth(FCM_TOKEN, { token, user_id, pharmacyId, deviceId });
    
    
    // If token was successfully sent to the backend, set tokenAdded to true
    if (response.data.status === 200 || response.data.status === 201) {
    localStorage.setItem('tokenAdded', 'true');
  }
    return response.data;
  } catch (error) {
    throw error; 
  }
};
  

const [selectedTab, setSelectedTab] = useState('readyToPickup')

  const { pid, Urgent, Status_Id } = useParams();
  const location = useLocation();
  const navigation = useNavigate();

  const calendarRef = useRef(null);

  const handleSelect = (ranges) => {
    if (ranges.selection.endDate) {
      orderObjRef.current.scrollTop = 0;
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  let isFetching = false;
  const getOrders = async (statusId, pageIndexData=pageIndex, loadMore=false) => {
    setLoading(true);
    setStatus(statusId)
    setLoadingOrder(true);
    if (loadingOrder) return;
    if (isFetching) return;
    isFetching = true;
    if (!loadMore) {
      orderObjRef.current.scrollTop = 0;
      setPageIndex(1); // Reset to the first page if it's not a "load more" request
    }
    const response = await getApiWithAuth(ALL_ORDERS+`?status=${statusId}&pharmacy=${pid}&filter=${
      filter === "0" ? "" : filter
    }&timer=${timer === "0" ? "" : timer}&from=${moment(dateRange[0].startDate)
      .format('MM-DD-YYYY')}&to=${moment(dateRange[0].endDate)
        .format('MM-DD-YYYY')}&pageIndex=${!loadMore ? 1 : pageIndexData}&pageSize=${pageSize}&search=${search}&rx=${rx}`);
    if (response.data !== undefined) {
      if (loadMore) {
        setOrders(prevOrders => [...prevOrders, ...response.data.data.data]); // Append new orders
      } else {
        setOrders(response.data.data.data); // Set new orders
      }
      setPageIndex(prevPageIndex => prevPageIndex + 1);
      setHasMore(response.data.data.hasMore);
      setPageSize(response.data.data.pagination.pageSize);
      setTotalPages(response.data.data.pagination.totalPages);
      setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
      setLoadingOrder(false)
      isFetching = false;
    } else {
      setLoading(false);
      setLoadingOrder(false)
      isFetching = false;
    }
  };


  const getOrdersUrgent = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_ORDERS+`?status=1&pharmacy=${pid}&filter=${
      filter === "0" ? "" : filter
    }&timer=Urgent&from=${moment(dateRange[0].startDate).format('MM-DD-YYYY')}&to=${moment(dateRange[0].endDate).format('MM-DD-YYYY')}&pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}&rx=${rx}`);
    if (response.data !== undefined) {
      setOrders(response.data.data.data);
      setPageIndex(response.data.data.pagination.nextPageIndex);
      setPageSize(response.data.data.pagination.pageSize);
      setTotalPages(response.data.data.pagination.totalPages);
      setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };


  const getPharmacy = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_PHARMACY_LIST+`/${pid}`);
    if (response.data !== undefined) {
      setPharmacy(response.data.data)
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const uploadFile = async (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const content = reader.result;
      localStorage.setItem("File", content);
    };
    reader.readAsDataURL(selectedFile);
    if (!selectedFile || selectedFile.type !== "application/pdf") {
    } else {
      try {
        const formData = new FormData();
        formData.append("file_uploaded", selectedFile);
        setFile(selectedFile);
        const response = await postApiWithAuth(ORDER_FILE_UPLOAD, formData);
        const responseUpload = await postApiWithAuth(UPLOAD_FILE, formData);

        if (responseUpload.data !== undefined) {
          localStorage.setItem("myFile", responseUpload.data.data.file_name);
        }

        if (response.data !== undefined) {
          const createDate = new Date();
          const orderDetailData = {
            name: response.data.data.client?.name || "",
            address: response.data.data.client?.address || "",
            phoneNumber: response.data.data.client?.phone_number || "",
            cellNumber: response.data.data.client?.cell_number || "",
            workNumber: response.data.data.client?.work_number || "",
            zip: response.data.data.client?.zip || "",
            timeWindow: "Anytime",
            parcelPlace: "Face to face",
            signRequired: true,
            reqPhotoId: false,
            registed: false,
            collectCopay: false,
            hippaForm: false,
            lineForm: false,
            nfForm: false,
            bussAddress: false,
            deliveryDate: new Date(createDate.getTime() + 24 * 60 * 60 * 1000),
            totalCopay: 0,
            language: "english",
            apart: "",
            note: "",
            lat: "",
            lng: "",
            rxList: response.data.data.text || [],
          };

          const orderDetailData2 = {
            name: response.data.data.client?.name || "",
            address: response.data.data.client?.address || "",
            phoneNumber: response.data.data.client?.phone_number || "",
            cellNumber: response.data.data.client?.cell_number || "",
            workNumber: response.data.data.client?.work_number || "",
            zip: response.data.data.client?.zip || "",
            timeWindow: "Anytime",
            parcelPlace: "Face to face",
            signRequired: true,
            reqPhotoId: false,
            registed: false,
            collectCopay: false,
            hippaForm: false,
            lineForm: false,
            nfForm: false,
            bussAddress: false,
            deliveryDate: new Date(createDate.getTime() + 24 * 60 * 60 * 1000),
            totalCopay: 0,
            language: "english",
            apart: "",
            note: "",
            lat: "",
            lng: "",
            rxLists: response.data.data.text || [],
          };

          // Store orderDetailData in local storage
          localStorage.setItem("orderDetail", JSON.stringify(orderDetailData));
          localStorage.setItem("orderDetails", JSON.stringify(orderDetailData2));
        }
        navigation(`/pharmacy/${pid}/add/order`);
      } catch (error) {
      }
    }
  };

  const startItem = pageIndex * pageSize + 1;
  const endItem = Math.min(startItem + pageSize - 1, totalItems);

  const fetchOrderNum = async () => {
    try {
      const response = await getApiWithAuth(CREATE_ORDER+`?from=${moment(
        dateRange[0].startDate
      ).format("MM-DD-YYYY")}&to=${moment(dateRange[0].endDate).format(
        "MM-DD-YYYY"
      )}`);
      if (response.data !== undefined) {
        setOrderNum(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      const searchTerm = inputValue.toLowerCase(); // Convert input to lowercase for case-insensitive matching
      if (searchTerm.includes("rx")) {
        setrx(inputValue); // Set search term to "rx" if input contains "Rx" or "RX"
      } else {
        setSearch(inputValue); // Set search term to input value otherwise
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = orderObjRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 250 && hasMore) {
        getOrders(status, pageIndex, true);
      }
    };

    const div = orderObjRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, loadingOrder, pageIndex]);

  useEffect(() => {
    if (pharmacy !== "") {
      if (Urgent === 'true') {
        getOrdersUrgent();
        setSelectedTab('readyToPickup');
      } else if (Status_Id) {
        getOrders(Status_Id);
        setSelectedTab('delivered');
      } else {
        getOrders(status);
      }
    }
  }, [search,rx, pharmacy, dateRange, filter, timer, Urgent, Status_Id, location]);

  useEffect(() => {
    fetchOrderNum();
    getPharmacy();
  }, [dateRange]);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        setCalendarVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    const pharmacyId = localStorage.getItem('pharmacyId')
    if (parseInt(pid) !== parseInt(pharmacyId)) {
      navigation('/404')
    }
  }, [pid])

  return (
    <div className="OrderList">
      <div className="main-tab">
        <div className="tabs-container">
          <button
            onClick={() => {
              getOrders("all");
              setSelectedTab("allOrders");
              if (Urgent === 'true') {
                navigation(`/pharmacy/${pid}/list/order`)
              }
              orderObjRef.current.scrollTop = 0;
            }}
            className={`tabs ${selectedTab == "allOrders" && "bg-slate-300"}`}
          >
            All Orders (
            <span>{orderNum !== undefined ? orderNum["all"] : 0}</span>)
          </button>
          <button
            onClick={() => {
              getOrders("1");
              setSelectedTab("readyToPickup");
              if (Urgent === 'true') {
                navigation(`/pharmacy/${pid}/list/order`)
              }
              orderObjRef.current.scrollTop = 0;
            }}
            className={`tabs ${
              selectedTab == "readyToPickup" && "bg-slate-300"
            }`}
          >
            Ready for Pickup (
            <span className="readyToPick">
              {orderNum !== undefined ? orderNum["1"] : 0}
            </span>
            )
          </button>
          <button
            onClick={() => {
              getOrders("2");
              setSelectedTab("pickUpOccured");
              if (Urgent === 'true') {
                navigation(`/pharmacy/${pid}/list/order`)
              }
              orderObjRef.current.scrollTop = 0;
            }}
            className={`tabs ${
              selectedTab == "pickUpOccured" && "bg-slate-300"
            }`}
          >
            Pickup Occured (
            <span className="readyToPick">
              {orderNum !== undefined ? orderNum["2"] : 0}
            </span>
            )
          </button>
          <button
            onClick={() => {
              getOrders("3");
              setSelectedTab("receivedAtFacility");
              if (Urgent === 'true') {
                navigation(`/pharmacy/${pid}/list/order`)
              }
              orderObjRef.current.scrollTop = 0;
            }}
            className={`tabs ${
              selectedTab == "receivedAtFacility" && "bg-slate-300"
            }`}
          >
            Received at Facility (
            <span className="receivedAtFacility">
              {orderNum !== undefined ? orderNum["3"] : 0}
            </span>
            )
          </button>
          <button
            onClick={() => {
              getOrders("4");
              setSelectedTab("inTransit");
              orderObjRef.current.scrollTop = 0;
            }}
            className={`tabs ${selectedTab == "inTransit" && "bg-slate-300"}`}
          >
            In Transit (
            <span className="inTransit">
              {orderNum !== undefined ? orderNum["4"] : 0}
            </span>
            )
          </button>
          <button
            onClick={() => {
              getOrders("5");
              setSelectedTab("delivered");
              if (Urgent === 'true') {
                navigation(`/pharmacy/${pid}/list/order`)
              }
              orderObjRef.current.scrollTop = 0;
            }}
            className={`tabs ${selectedTab == "delivered" && "bg-slate-300"}`}
          >
            Delivered (
            <span className="delivered">
              {orderNum !== undefined ? orderNum["5"] : 0}
            </span>
            )
          </button>
          <button
            onClick={() => {
              getOrders("6");
              setSelectedTab("cancelled");
              if (Urgent === 'true') {
                navigation(`/pharmacy/${pid}/list/order`)
              }
              orderObjRef.current.scrollTop = 0;
            }}
            className={`tabs ${selectedTab == "cancelled" && "bg-slate-300"}`}
          >
            Cancelled (
            <span className="cancel">
              {orderNum !== undefined ? orderNum["6"] : 0}
            </span>
            )
          </button>
        </div>
        <div>
          <input
            accept="application/pdf"
            id="pdf-upload"
            autoComplete="off"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => uploadFile(e)}
          />
          <label htmlFor="pdf-upload">
            {/* <button >
              <img className="upload-icon" src={upload} alt="Logo" />
              Click to Upload
            </button> */}
            <Button
              className="upload-btn"
              component="span"
              startIcon={
                <img
                  className="upload-icon"
                  src={upload}
                  alt="Logo"
                  style={{width: 25, height: 25, marginRight: 0}}
                />
              }
              sx={{textTransform: "none", height: "38px", marginBottom: 0.7}}
            >
              Upload
            </Button>
          </label>
        </div>
      </div>
      <div className="second-row">
        <div className="filter-container">
          <div className="search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              autoComplete="off"
              id="search"
              placeholder="Order ID, Name, Address, Phone, Rx"
              className="search-input"
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className="search-filter-container">
            <Select
              id="search"
              className="filter-input"
              defaultValue="0"
              onChange={(e) => setFilter(e.target.value)}
              style={{
                width: "300px",
                padding: "0",
                backgroundColor: "primary.lighter", // Adjust the background color as needed
                border: "0px solid",
                borderColor: "transparent",
              }}
              // IconComponent={() => null}
            >
              <MenuItem value="0">Select Filter</MenuItem>
              <MenuItem value="1">Refrigerator</MenuItem>
              <MenuItem value="2">Photo ID</MenuItem>
              <MenuItem value="3">Copay</MenuItem>
              {/* <MenuItem value="Other">Other</MenuItem> */}
            </Select>
          </div>
          <div className="search-filter-container">
            <Select
              defaultValue="0"
              className="filter-input"
              style={{
                width: "300px",
                padding: "0",
                backgroundColor: "primary.lighter", // Adjust the background color as needed
                border: "0px solid",
                borderColor: "transparent",
              }}
              onChange={(e) => setTimer(e.target.value)}
            >
              <MenuItem value="0" disabled>Time Window</MenuItem>
              <MenuItem value="Anytime">Anytime</MenuItem>
              <MenuItem value="9am-3pm">9am-3pm</MenuItem>
              <MenuItem value="2pm-6pm">2pm-6pm</MenuItem>
              <MenuItem value="5pm-9pm">5pm-9pm</MenuItem>
              <MenuItem value="Urgent">Urgent</MenuItem>
            </Select>
          </div>
        </div>
        <div className="date-input-filter-container">
          {/* <input
            type="date"
            placeholder="MM-DD-YYYY"
            className='date-input'
            id="customDatePicker"
            value={selectedDate}
            onClick={toggleDatePicker}
            onChange={handleDateChange}
          />
          <p style={{fontWeight: "500", fontSize: "22px", margin: '0px 10px'}}>TO</p>
          <input
            type="date"
            placeholder="MM-DD-YYYY"
            className='date-input'
            id="customDatePicker"
            value={selectedDate}
            onClick={toggleDatePicker}
            onChange={handleDateChange}
          /> */}
          <div type="text" className="date-input-pharm">
            <CalendarMonthIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={toggleCalendar}
            />
            {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
            <ForwardIcon style={{ fontSize: "32px" }} />
            {dateRange[0].endDate
              ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
              : ""}
          </div>
          {isCalendarVisible && (
            <div className="calendar-container-pharm">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )}
          {/* <DateRange
            editableDateInputs={true}
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          /> */}
          {/* <ForwardIcon style={{fontSize: "32px"}} /> */}
        </div>
      </div>
      <div className="order-obj" ref={orderObjRef}>
        {orders?.length > 0 &&
          orders.map((order) => (
            <OrderObject order={order} getOrders={getOrders} type="pharmacy" statusId={status} />
          ))}
      </div>
      {/* <ChatModal userId={userId} /> */}
      <div className="tabs-container" style={{justifyContent: "flex-end"}}>
        <div className="pagination">
          <button className="page-row">Order Count{orders.length > 0 && ' 1'} - {orders.length}</button>
          {/* <div className="page-row">
            {pageIndex > 0 && (
              <ExpandCircleDownIcon
                className="pag-icon-prev"
                onClick={() => setPageIndex(pageIndex - 1)}
              />
            )}
            <p className="text-[18px]">
              Page {startItem} - {endItem}
            </p>
            {pageIndex < totalPages - 1 && (
              <ExpandCircleDownIcon
                className="pag-icon"
                onClick={() => setPageIndex(pageIndex + 1)}
              />
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PharmacyOrderList;

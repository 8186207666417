import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField
} from '@mui/material';

import { ExcelRenderer } from 'react-excel-renderer';
import { toast } from 'react-toastify';

import { 
  ALL_ZONES_LIST
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth,
  postApiWithAuth,
  deleteApiWithAuth,
  putApiWithAuth
} from "../../../utils/api";

import BoroughObject from '../../MiniComponents/BoroughObject';
import './zoneList.css'


const EXTENSIONS = ['xlsx', 'xls', 'csv', 'html', 'sql'];

function getExtension(filename) {
  return EXTENSIONS.some((item) => filename.split('.').pop() === item);
}

function ZoneList() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [zone, setZone] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [formState, setFormState] = useState({
    zipcode: "",
    zone: zone.id
  });

  const {zid} = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleEditZone = () => {
    setModalOpen(true);
  };

  const handleEditZoneClose = () => {
    setModalOpen(false);
    setFormState({
      zipcode: "",
      zone: zone.id
    })
  };

  const handleAddZone = () => {
    setModalOpenAdd(true);
  };

  const handleAddZoneClose = () => {
    setModalOpenAdd(false);
    setFormState({
      zipcode: "",
      zone: zone.id
    })
  };
  console.log(formState)

  const handleSaveZone = async () => {
    if (edit === false) {
      if (!zone.zipcode.some(item => item.zipcode === formState.zipcode)) {
        const response = await postApiWithAuth(ALL_ZONES_LIST + `ZipmdList/`, formState);
        console.log(response)
        if (response.status !== 400) {
          toast.success('Zip Created Successfully!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else if (response.status === 400) {
          toast.error(response.data[0], {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else {
          toast.error('Backend Error!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setModalOpenAdd(false);
        setFormState({
          zipcode: "",
          zone: zone.id
        })
      }
      else {
        toast.error(`Zipcode already exist in zone ${zone.name} and city ${zone.borro.name}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setModalOpenAdd(false);
      setFormState({
        zipcode: "",
        zone: zone.id
      })
      
      getZips();
    }
    else {
      const existingObject = zone.zipcode.find(item => item.id === id);
      console.log(existingObject.name,existingObject.name!==formState.name,'eeeeeeeeeeeeeee',formState.name)
      if (existingObject.zipcode !== formState.zipcode) {
        const response = await putApiWithAuth(ALL_ZONES_LIST + `ZipmdList/${id}/update`, formState);
        console.log(response)
        if (response.status !== 400) {
          toast.success('Zip Edit Successfully!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else {
          toast.error('Backend Error!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setModalOpen(false);
        setFormState({
          zipcode: "",
          zone: zone.id
        })
      } else {
        toast.error('Name Already Present!', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setModalOpen(false);
      setFormState({
        zipcode: "",
        zone: zone.id
      })
      getZips();
      setEdit(false);
      setId('')
    }
  };

  const UploadData = async (e) => {
    const selectedFile = e.target.files[0];

    if (getExtension(selectedFile.name)) {
      ExcelRenderer(selectedFile, async (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          const data = resp.rows;
          console.log(data)
          const dataWithZipId = data.map(row => ({ ...row, "zip_id": zid }));
          console.log(dataWithZipId)
          const response = await postApiWithAuth(ALL_ZONES_LIST + 'process_data', JSON.stringify({ dataWithZipId }));
          if (response.status === 404) {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
          }
          else if (response.data.data[0].status !== "already exist") {
            toast.success('Zip Added Successfully!', {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
          }
          else {
            toast.error('Zip Already Exist', {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
          }
          getZips();
        }
      });
    } else {
      toast.error('Selected File type is wrong!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  const getZips = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_ZONES_LIST + `ZonemdList/${zid}`);
    console.log(response)
    if (response.data !== undefined) {
      setZone(response.data.data)
      setFormState({
        zipcode: "",
        zone: response.data.data.id
      })
      // setPageIndex(response.data.data.pagination.pageIndex - 1);
      // setPageSize(response.data.data.pagination.pageSize);
      // setTotalPages(response.data.data.pagination.totalPages);
      // setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleDeleteZip = async (zid) => {
    console.log('work')
    const response = await deleteApiWithAuth(ALL_ZONES_LIST + `ZipmdList/${zid}/delete`);
    console.log(response)
    if (response.status !== 400) {
      toast.success('Zip Delete Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    getZips();
  };

  useEffect(() => {
    getZips();
  }, []);

  return (
    <div >
      <div className='list-head'>
        <h2 className='listHeader'>{zone?.borro?.name}/{zone?.name}</h2>
        {/* <button >Upload CSV</button> */}
        <button className='borough-add-btn' onClick={handleAddZone}>Add Zip</button>
        <input style={{ display: 'none' }} id="pdf-upload" type="file" onChange={(e) => UploadData(e)} autoComplete="off"/>
        <label htmlFor="pdf-upload">
          <Button className='borough-add-btn' variant="dashed" color="primary" component="span" startIcon={<UploadFileIcon />} sx={{ textTransform: 'none' }}>
            Upload CSV
          </Button>
        </label>
      </div>
      <div className='user-obj'>
        <Grid container spacing={2}>
          {zone?.zipcode?.map((zip, i) => (
            <Grid item xs={3}>
              <div className='zip-object'>
                <p className='zip-id'>{i  + 1}</p>
                <input type='number' autoFocus disable value={zip.zipcode} className='zip-input' autoComplete="off"/>
                <div style={{display: 'flex'}}>
                  <ModeEditIcon style={{cursor: 'pointer'}} onClick={() => (handleEditZone(), setEdit(true), setId(zip.id), setFormState({
                    ...formState,
                    "zipcode": zip.zipcode,
                  }))} />
                  <DeleteIcon style={{color:"red", cursor: 'pointer'}} onClick={() => (handleDeleteZip(zip.id))} />
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      {/* <div className='bottom-tab'>
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div> */}
      <Dialog open={modalOpen} onClose={handleEditZoneClose}>
        <DialogTitle>Edit Zip</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Zip Code</label>
          <input
            type="text"
            className="modal-field"
            name="zipcode"
            placeholder="Zip Code"
            style={{marginBottom: '10px'}}
            value={formState.zipcode}
            autoFocus
            autoComplete="off"
            onChange={handleInputChange}
          />
          <Button onClick={handleSaveZone} variant="contained" style={{marginRight: '10px'}} color="primary">
            Save
          </Button>
          <Button onClick={handleEditZoneClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={modalOpenAdd} onClose={handleAddZoneClose}>
        <DialogTitle>Add Zip</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Zip Code</label>
          <input
            type="text"
            className="modal-field"
            name="zipcode"
            placeholder="Zip Code"
            style={{marginBottom: '10px'}}
            value={formState.zipcode}
            onChange={handleInputChange}
            autoFocus
            autoComplete="off"
          />
          <Button onClick={handleSaveZone} variant="contained" style={{marginRight: '10px'}} color="primary">
            Save
          </Button>
          <Button onClick={handleAddZoneClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ZoneList
import React from 'react';
import moment from 'moment';

import './times-new-roman-reg.ttf';

const Printdoc = ({ orderDetail, pharmacy }) => {
   const styles = {
      page: {
         width: '7.7in',
         height: '9.25in',
         margin: '10px',
         position: 'relative',
         display: 'flex',
         flexDirection: 'column',
         fontFamily: 'Times New Roman, sans-serif',
      },
      pageHead: {
         display: 'flex',
         justifyContent: 'space-between',
         fontFamily: 'Times New Roman, sans-serif',
         fontSize: '9px',
      },
      pageMainText: {
         textAlign: 'center',
         fontSize: '14px',
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         fontFamily: 'Times New Roman, sans-serif',
      },
      bar: {
         height: '3px',
         widht: '100%',
         backgroundColor: 'black',
         boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)'
      },
      bar2: {
         height: '1px',
         widht: '100%',
         backgroundColor: 'black',
         marginTop: '15px'
      },
      orderDetail: {
         fontWeight: '400',
         fontSize: '10px',
         width: '50%',
         marginLeft: '20px',
         fontFamily: 'Times New Roman, sans-serif',
      },
      orderList: {
         display: 'flex',
         flexDirection: 'column',
         marginLeft: '15px',
         marginRight: "50px",
         fontFamily: 'Times New Roman, sans-serif',
      },
      orderResult: {
         display: 'flex',
         justifyContent: 'space-between',
         marginLeft: '20px',
         marginRight: "80px",
         fontWeight: '800',
         fontFamily: 'Times New Roman, sans-serif',
         fontSize: '12px',
      },
      orderBottom: {
         display: 'flex',
         justifyContent: 'space-between',
         marginLeft: '20px',
         marginTop: '20px',
         marginRight: "30px",
         fontFamily: 'Times New Roman, sans-serif',
         fontSize: '12px',
      }

   };

   return (
      <>
         <div style={styles.page}>
            <div style={styles.pageHead}>
               <p>Date: {moment(orderDetail.createdAt).format('MM/DD/YYYY')}</p>
               <p>Page 1/1</p>
            </div>
            <div style={styles.pageMainText}>
               <h1 style={{ fontWeight: '700', }}>{pharmacy?.name}</h1>
               <h1 style={{ fontWeight: '500', }}>{pharmacy?.location}</h1>
               <h1 style={{ fontWeight: '500', }}>Phone: {pharmacy?.contact_no}<span style={{ marginLeft: '10px' }}>Fax: {pharmacy?.fax_number}</span></h1>
               <h2 style={{ fontWeight: '800', fontSize: '14px', border: '3px solid black', marginBottom: '10px', width: '200px' }}>Delivery Slip</h2>
            </div>
            <div style={styles.bar} />
            <div style={styles.orderDetail}>
               <h1 style={{ fontWeight: '700', fontSize: '12px', textAlign: 'left' }}>Client:</h1>
               <h1 style={{ fontWeight: '800', fontSize: '12px', textAlign: 'left' }}>{orderDetail?.name}</h1>
               <h1 style={{ textAlign: 'left' }}>{orderDetail?.address}</h1>
               <h1 style={{ textAlign: 'left' }}>Phone# : {orderDetail?.contact}</h1>
               <h1 style={{ textAlign: 'left' }}>Cell# : {orderDetail?.cell}</h1>
               <h1 style={{ textAlign: 'left' }}>Work# : {orderDetail?.workNumber}</h1>
            </div>
            <div style={styles.orderList}>
               <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '5px' }}>
                  <thead>
                     <tr>
                        <th style={{ borderBottom: '1px solid black', fontSize: '12px', width: '60px' }}>Date</th>
                        <th style={{ borderBottom: '1px solid black', fontSize: '12px', width: '160px' }}>Rx. Barcode</th>
                        <th style={{ borderBottom: '1px solid black', fontSize: '12px', width: '100px' }}>Rx. #</th>
                        <th style={{ borderBottom: '1px solid black', fontSize: '12px', width: '30px' }}>Rf. #</th>
                        <th />
                        <th style={{ width: '10px' }} />
                        <th />
                        <th style={{ borderBottom: '1px solid black', fontSize: '12px', width: '50px' }}>Qty</th>
                        <th style={{ borderBottom: '1px solid black', fontSize: '12px', width: '80px' }}>Copay</th>
                     </tr>
                  </thead>
                  <tbody>
                     {orderDetail.orderdetail.map((rxOrder, i) => (
                        <tr style={{ fontSize: '12px', textAlign: 'center' }}>
                           <td>{rxOrder?.date}</td>
                           <td style={{ maxWidth: '70px' }}>
                              {rxOrder.barcode_image && (
                                 <img style={{ height: '18px' }} src={rxOrder.barcode_image} />
                              )}
                           </td>
                           <td>{rxOrder?.rxnumber}</td>
                           {/* <td style={{ borderBottom: '1px solid black', maxWidth: '200px' }}><Barcode value={extractNumberAfterHyphen(rxOrder.rxnumber)} displayValue="false" width="2" height="20px" /></td> */}
                           <td>{rxOrder?.rfnumber}</td>
                           <td></td>
                           <td></td>
                           <td></td>
                           <td>{rxOrder?.quantity}</td>
                           <td>{rxOrder?.copay}</td>
                        </tr>
                     ))}
                     {/* Add more <tr> elements for additional rows */}
                  </tbody>
               </table>
            </div>
            <div style={styles.bar2} />
            <div style={styles.orderResult}>
               <p>Total Rx Count : {orderDetail?.orderdetail !== undefined && orderDetail?.orderdetail?.length > 0 ? orderDetail?.orderdetail?.length : 0}</p>
               <p>Total Pat. Amt: ${orderDetail?.orderdetail?.reduce((total, rx) => total + parseFloat(rx?.copay), 0).toFixed(2)}</p>
            </div>
            <>
               <p style={{ marginLeft: '20px', marginTop: '10px', marginBottom: '10px', fontSize: '12px', textAlign: 'left' }}>Patient is requesting Counseling:<span style={{ marginLeft: '10px' }}>Yes: <span style={{ borderBottom: '1px solid black' }}>{orderDetail?.requestCouncelling ? "✓" : " "}</span></span><span style={{ marginLeft: '10px' }}>No: <span style={{ borderBottom: '1px solid black' }}>{!orderDetail?.requestCouncelling ? "✓" : " "}</span></span></p>
               <p style={{ marginLeft: '20px', marginRight: '30px', fontSize: '12px', textAlign: 'left' }}>
                  {pharmacy?.hippa_notes === null ?
                     `I certify that I requested and received my medication listed above from ${pharmacy?.name} Pharmacy (${pharmacy?.location} - the "Pharmacy"). I further certify that I have a patient relationship with the ordering medical provider indicated on the prescription label and that I requested that the prescriber send this prescription to the Pharmacy. The foregoing is certified as true and accurate under the penalty of perjury.`
                     : pharmacy?.hippa_notes
                  }
               </p>
               <div style={styles.orderBottom}>
                  <p>Receiver Name <span style={{ paddingLeft: '30px', fontWeight: '800', paddingRight: '30px', borderBottom: '1px solid black' }}>{orderDetail?.name}</span></p>
                  <div style={{ display: 'flex' }}>
                     <p>Date <span style={{ paddingLeft: '10px', fontWeight: '800', paddingRight: '10px', borderBottom: '1px solid black' }}>{moment(orderDetail?.deliverAt).format('MM/DD/YYYY')}</span></p>
                     <p>Time <span style={{ paddingLeft: '10px', fontWeight: '800', paddingRight: '10px', borderBottom: '1px solid black' }}>{moment(orderDetail?.deliverAt).format('hh:mm:ss a')}</span></p>
                  </div>
               </div>
               <div style={styles.orderBottom}>
                  {/* <p>Receiver Signature <span style={{paddingLeft: '30px', fontWeight: '800', paddingRight: '30px', borderBottom: '1px solid black'}}><img src={process.env.REACT_APP_LINK_BASE_IMAGE_URL + orderDetail.signature_img} alt="signature_img" /></span></p> */}
                  <p style={{ display: 'flex' }}>Receiver Signature <span style={{ paddingLeft: '30px', fontWeight: '800', paddingRight: '30px' }}><img style={{ height: "60px", width: '80px' }} src={orderDetail.ordermaster_route4me[orderDetail?.ordermaster_route4me?.length - 1]?.signature_image} alt="signature_img" /></span></p>
                  <p>Delivered by <span style={{ paddingLeft: '30px', fontWeight: '800', paddingRight: '30px', borderBottom: '1px solid black' }}>{pharmacy?.delivered_by === "PD" ? "PD" : pharmacy?.delivered_by === "Driver" ? orderDetail?.driver?.name : ""}</span></p>
               </div>
            </>
         </div>
      </>
   );
};

export default Printdoc;
import React from "react";
import { Outlet } from 'react-router-dom';

import "./AuthComponent.css";

const AuthComponent = () => {

  return (
    <>
      <Outlet />
    </>
  );
};

export default AuthComponent;

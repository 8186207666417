import React, {useState, useEffect} from 'react'
import { 
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';
import { toast } from 'react-toastify';

import { 
  PHARMACY_ORDER_COUNT
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth, postApiWithAuth,
} from "../../../utils/api";

import PharmacyOrderListObject from '../../MiniComponents/PharmacyOrderListObject';
import './pharmacyOrderList.css';

function PharmacyOrderListCount() {
  const [loading, setLoading] = useState(false);
  const [pharmacyOrderList, setPharmacyOrderList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const getpharmacyOrderList = async () => {
    setLoading(true);
    const response = await getApiWithAuth(PHARMACY_ORDER_COUNT);
    console.log(response)
    if (response.data !== undefined) {
      setPharmacyOrderList(response.data.data)
      // setPageIndex(response.data.data.pagination.pageIndex - 1);
      // setPageSize(response.data.data.pagination.pageSize);
      // setTotalPages(response.data.data.pagination.totalPages);
      // setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getpharmacyOrderList();
  }, []);

  return (
    <div >
      <div className='list-head'>
        <h2 className='listPharmacyOrderHeader'>DAILY ORDERS BY PHARMACIES</h2>
      </div>
      <div class='tab-total'>
        
      </div>
      <div className='user-obj'>
        {pharmacyOrderList?.length > 0 &&
          pharmacyOrderList.map((pharmacyOrder, i) => (
            <PharmacyOrderListObject pharmacyOrder={pharmacyOrder} />
          ))
        }
      </div>
      <div className='bottom-tab'>
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div>
    </div>
  )
}

export default PharmacyOrderListCount
import React, {useState} from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router';

import {
  loginLogo
} from '../../../assests';

import { 
    FORGOT_PASSWORD,
 } from "../../../utils/apiUrls";
import {
  postApiWithoutAuth
} from "../../../utils/api";


import './forgetPassword.css';
import { Link } from 'react-router-dom';

const ForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const navigate = useNavigate();

  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handlerSubmit = async () => {
    setLoading(true);
    const response = await postApiWithoutAuth(FORGOT_PASSWORD, data);
    if (response.status === 200) {
      setLoading(false);
      // navigate('/list/order')
    } else {
      setLoading(false);
    }
  };


  return (
    <div className="forget-container">
      <div className="background-image"></div>
      <div className="forget-form">
        <div className="form-content">
          <img src={loginLogo} alt="Logo" className="logo" />
          <h2 className="forget-text">Forget Password</h2>
          <div className="input-container">
            <label htmlFor="email">Email</label>
            <input autoComplete="off" type="email" name="email" onChange={onChangeHandle} id="email" placeholder="Enter your email" />
          </div>
          <button className="forget-button" onClick={handlerSubmit}>Forget Password</button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

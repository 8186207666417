import React from 'react';
import { ArrowDropDownCircle, AcUnit } from "@mui/icons-material";
import moment from 'moment';
import './times-new-roman-reg.ttf';

const RouteSlip = ({ routeOrders, route }) => {
    const today = moment().format('MM-DD-YYYY'); // Format the date as "MM-DD-YYYY"
    const dayOfWeek = moment().format('dddd'); // Get the day of the week

    const styles = {
        page: {
            width: '7.7in',
            height: '9.25in',
            margin: '10px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Times New Roman, sans-serif',
        },
        pageHead: {
            display: 'flex',
            justifyContent: 'space-between',
            fontFamily: 'Times New Roman, sans-serif',
            fontSize: '11px',
            fontWeight: '600',
            marginBottom: '10px', // Add margin bottom to separate sections
        },
        orderList: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '15px',
            marginRight: '50px',
        },
        table: {
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: '5px',
        },
        tableHeader: {
            borderBottom: '1px solid black',
            fontSize: '14px',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '5px',
        },
        tableRow: {
            textAlign: 'center',
            fontSize: '12px',
        },
        icon: {
            marginLeft: '5px',
            fontSize: '12px',
        },
    };

    return (
        <div style={styles.page}>
            <div style={styles.pageHead}>
                <p>{dayOfWeek}, {today}</p>
                <p>Route {route.detail_name}</p>
            </div>
            <div style={styles.pageHead}>
                <p>Assigned Driver Name: {route?.driver?.name}</p>
            </div>
            <div style={styles.orderList}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={{ ...styles.tableHeader, width: '80px' }}>Stop #</th>
                            <th style={{ ...styles.tableHeader, width: '140px' }}>Order Id</th>
                            <th style={{ ...styles.tableHeader, width: '280px' }}>Name</th>
                            <th style={{ ...styles.tableHeader, width: '280px' }}>Pharmacy Name</th>
                            <th style={{ ...styles.tableHeader, width: '140px' }}>Refrigerated</th>
                            <th style={{ ...styles.tableHeader, width: '210px' }}>Copay $</th>
                            <th style={{ ...styles.tableHeader, width: '140px' }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {routeOrders.map((order, i) => (
                            <tr key={i} style={styles.tableRow}>
                                <td>{order.number}</td>
                                <td style={{ fontWeight: '400' }}>{order.order.slug}</td>
                                <td>{order.order.name}</td>
                                <td>{order.order.pharmacy_name}</td>
                                <td>
                                    {order.order.refrigrated ?
                                        <>
                                            Yes
                                            <AcUnit style={styles.icon} /> {/* Example of using AcUnit icon */}
                                        </>
                                        :
                                        'No'
                                    }
                                </td>
                                <td>
                {order.order.totalcopay > 0 && (
                    <>
                        $ {order.order.totalcopay}
                        {order.order.iscollected && ' Collected'}
                        {order.order.iswaivedpharmacy && ' Waived'}
                    </>
                )}
            </td>
                                <td>{order.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RouteSlip;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import {
  Menu,
  MenuItem,
  Box,
  Stack,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useReactToPrint } from 'react-to-print';
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import { ArrowDropDownCircle, AcUnit } from "@mui/icons-material";

import { toast } from "react-toastify";

import {
  action8,
  delete_bin,
  driver_notes,
  resecdule_route,
  merge_route,
  split_route,
  edit_route,
  open_route,
} from "../../../assests";

import "./routeObject.css";
import { deleteApiWithAuth, getApiWithAuth, patchApiWithAuth } from "../../../utils/api";
import { SPLIT_ROUTE, ALL_DRIVERS, ALL_ROUTES, ROUTE_DETAIL, GET_HISTORY, GET_ROUTE_HISTORY } from "../../../utils/apiUrls";
import RouteSlip from "../../CommonComponents/PrintDoc/RouteSlip";

function floatToTime(floatValue) {

  console.log(floatValue)
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }
  if (seconds > 0) {
    parts.push(`${seconds} second${seconds !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

function RouteObject({ route, setMergerRoute, getRoutes, driverList }) {
  const [doneCount, setDoneCount] = useState(13);
  const [failCount, setFailCount] = useState(3);
  const [pendingCount, setPendingCount] = useState(4);
  const [anchorEl, setAnchorEl] = useState(null);
  const [driver, setDriver] = useState({});
  const [routeDriver, setRouteDriver] = useState(route.driver);
  const [openDriverConfirm, setOpenDriverConfirm] = useState(false);
  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [modalDateOpen, setModalDateOpen] = useState(false);
  const [routeOrders, setRouteOrders] = useState([]);
  const [modalHistoryOpen, setModalHistoryOpen] = useState(false);
  const [history, setHistory] = useState({});

  const token = localStorage.getItem("access_token", "");
  
  const [formState, setFormState] = useState({
    detail_name: "",
  });
  const [formStateDate, setFormStateDate] = useState({
    createdAt: "",
  });

  useEffect(() => {
    setDoneCount(route.totalDelivered);
    setFailCount(route.totalFailed);
    setPendingCount(route.totalPending);
  }, [route]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleInputChangeDate = (e) => {
    const { name, value } = e.target;
    setFormStateDate({
      ...formState,
      [name]: value,
    });
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateHistory = async () => {
    const response = await getApiWithAuth(GET_ROUTE_HISTORY + `${route.id}/`);
    console.log("history response", response.data.data);
    if (response.data !== undefined) {
      setHistory(response.data.data);
      setModalHistoryOpen(true);
    } else {
      console.log("error");
    }
  };

  const handleModalHistoryClose = () => {
    setModalHistoryOpen(false);
    setHistory({})
  };

  const closeDriverConfirmModal = () => {
    setOpenDriverConfirm(false);
  };

  const navigate = useNavigate();

  const componentRef2 = useRef();

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  const getRouteDetail = async (rid) => {
    const response = await getApiWithAuth(ROUTE_DETAIL + `?route=${rid}`);
    if (response.data !== undefined) {
      setRouteOrders(response.data.data.orders);
    } else {
      console.log("Error")
    }
  };

  useEffect(() => {
    handlePrintSlip();
  }, [routeOrders]);

  const totalOrders = doneCount + failCount + pendingCount;

  const donePercentage = (doneCount / totalOrders) * 100;
  const failPercentage = (failCount / totalOrders) * 100;
  const pendingPercentage = (pendingCount / totalOrders) * 100;

  const handleSplitRoute = async () => {
    const routeOrderResponse = await getApiWithAuth(
      SPLIT_ROUTE + `${route.id}/`
    );
    console.log(routeOrderResponse)
    if (routeOrderResponse.data.status === 201) {
      toast.success(`Route split successfully!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setModalCreateRouteOpen(false);
      getRoutes();
      handleCloseMenu()
      // setMergerRoute(false);
    } else if (routeOrderResponse.data.status === 200) {
      toast.error(routeOrderResponse.data.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setModalCreateRouteOpen(false);
      getRoutes();
      handleCloseMenu()
      // setMergerRoute(false);
    } else {
      toast.error(`Backend Error!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      handleCloseMenu()
      // setMergerRoute(false);
    }
  };

  const handleDriverChange = (e, value) => {
    console.log(value);
    if (value !== null) {
      setOpenDriverConfirm(true);
      setDriver(value);
    } else {
      setRouteDriver(null);
    }
  };

  const handleDriverAssign = async () => {
    try {
      const response = await patchApiWithAuth(
        `${ALL_ROUTES}${route.id}/update_driver/`,
        { driver_id: driver.id }
      );
      // await (ALL_DRIVERS)
      // console.log(response.data);
      if (response.data) {
        setOpenDriverConfirm(false);
        toast.success(`Driver assign successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
        setRouteDriver(driver);
      }
    } catch (error) {
      toast.error(`Backend Error during assign driver!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      setOpenDriverConfirm(false);
    }
  };

  const handleOpenDetailModal = (name) => {
    setModalDetailOpen(true);
    setFormState({
      ...formState,
      detail_name: name,
    });
  };

  const handleOpenDateModal = (name) => {
    setModalDateOpen(true);
    setFormStateDate({
      ...formStateDate,
      createdAt: name,
    });
  };

  const handleCloseDatelModal = () => {
    setModalDateOpen(false);
    setFormStateDate({
      ...formStateDate,
      createdAt: "",
    });
  };

  const handleCloseDetailModal = () => {
    setModalDetailOpen(false);
    setFormState({
      ...formState,
      detail_name: "",
    });
  };

  const handleDeleteRoute = async () => {
    try {
      const response = await deleteApiWithAuth(
        `${ALL_ROUTES}${route.id}/`
      );
      if (response.data) {
        setOpenDriverConfirm(false);
        toast.success(`Route deleted successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
      }
    } catch (error) {
      toast.error(`Backend Error during delete route!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
    }
  };

  const handleUpdateDetailName = async () => {
    try {
      const response = await patchApiWithAuth(
        `${ALL_ROUTES}${route.id}/update_detail_name/`,
        formState
      );
      if (response.data) {
        setOpenDriverConfirm(false);
        toast.success(`Route detail name updated successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
        setModalDetailOpen(false);
        setFormState({
          ...formState,
          detail_name: "",
        });
      }
    } catch (error) {
      toast.error(`Backend Error during assign driver!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      setModalDetailOpen(false);
      setFormState({
        ...formState,
        detail_name: "",
      });
    }
  };

  console.log(routeOrders)

  const handleUpdateDate = async () => {
    try {
      const response = await patchApiWithAuth(
        `${ALL_ROUTES}${route.id}/reschedule/`,
        formStateDate
      );
      if (response.data) {
        setModalDateOpen(false);
        toast.success(`Route detail name updated successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
        setModalDateOpen(false);
        setFormStateDate({
          ...formStateDate,
          createdAt: "",
        });
      }
    } catch (error) {
      toast.error(`Backend Error during assign driver!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
      setModalDateOpen(false);
      setFormStateDate({
        ...formStateDate,
        createdAt: "",
      });
    }
  };

  return (
    <div className="route-div">
      <div className="route-first">
        <div className="route-main">
          <h2 className="route-date">{route.detail_name}</h2>
          <EditIcon
            style={{ cursor: "pointer", marginLeft: "10px" }}
            onClick={() => handleOpenDetailModal(route.detail_name)}
          />
        </div>
        <h3 className="route-object-cust-name">{route.name}</h3>
        <h3 className="addres-text" style={{ display: "inline" }}>
          Created at: {moment(route.createdAt).format("hh:mm:ss A")} by {route.createdby}
        </h3>
      </div>
      <div className="route-second">
        <div className="route-status-div">
          <p className="route-status-tag" 
            style={{backgroundColor: route.routeStatus === 'Ready' 
            ? '#0094FF'
            : route.routeStatus === 'In Progress'
            ? '#B0B0B0'
            : route.routeStatus === 'Pending'
            ? '#FE9604'
            : route.routeStatus === 'Done'
            ? '#05B321'
            : '#0094FF', minWidth: '100px'}}
          >{route.routeStatus}</p>
          {/* <p className="route-status-txt">Route</p>
          <ExploreIcon sx={{width: 20}}/> */}
        </div>
        <a className="route-assign">
          {/* Assign Driver <span className="route-span-icon"><ExpandCircleDownIcon className="route-icon" /></span> */}
          <Autocomplete
            style={{ padding: "0", width: '170px' }}
            disablePortal
            id="combo-box-demo"
            options={driverList}
            value={routeDriver}
            disabled={route.routeStatus !== "Start" && route.routeStatus !== "Ready" && routeDriver}
            className="filter-input"
            sx={{
              // width: 300,
              "& .MuiOutlinedInput-root": {
                p: 0,
              },
              "& .MuiAutocomplete-tag": {
                bgcolor: "primary.lighter",
                border: "0px solid",
                borderColor: "transparent",
              },
              "& .MuiAutocomplete-input": {
                minWidth: "100px !important"
              }
            }}
            renderOption={(props, option) => (
              <p {...props} style={{ fontSize: '13px' }}>
                {option.name}
              </p>
            )}
            renderInput={(params) => (
              <TextField {...params} fullWidth placeholder="Assign Driver" style={{minWidth: '110px'}} sx={{
                "& input::placeholder": {
                  fontSize: "14px",
                  opacity: 1,
                  fontFamily: "Saira Extra Condensed !important"
                },
                "& input": {
                  fontSize: '14px !important',
                  minWidth: "120px",
                  fontFamily: "Saira Extra Condensed !important"
                }
              }}/>
            )}
            getOptionLabel={(option) => option.name}
            onChange={handleDriverChange}
            popupIcon={routeDriver === null && <ArrowDropDownCircle sx={{color: 'black', width: 20}}/>}
          />
        </a>
      </div>
      <div className="route-third">
        {route.totalRouteOrderTotalCopay !== 0 && route.totalRouteOrderTotalCopay !== null  &&
        <p className="route-copay-tag">
          Copay ${route.totalRouteOrderTotalCopay.toFixed(2)}
        </p>
        }
        {route.totalCollectedTotalCopay !== 0 && route.totalCollectedTotalCopay !== null && <p className="route-copay-txt">
          Collected
          <span className="route-top-copay-col">
            ${route.totalCollectedTotalCopay.toFixed(2)}
          </span>
        </p>}
        {route.totalPaidPhamracyTotalCopay !== 0 && route.totalPaidPhamracyTotalCopay !== null &&
          <p className="route-copay-txt">
            Paid To Pharmacy 
            <span className="route-top-copay-col">
              ${route.totalPaidPhamracyTotalCopay.toFixed(2)}
            </span>
          </p>
        }
      </div>
      <div className="route-forth">
        <p className="route-copay-txt">Stop {route.totalOrderStop}</p>
        <p className="route-copay-txt">Distance {(parseFloat(route?.distanceCal) * 0.621371).toFixed(2)} mi</p>
      </div>
      <div className="route-fifth">
      <p className="route-atc">ETC {floatToTime(route.estimatedTimeCal)}</p>
        {route.routeStatus === "Done" && <p className="route-etc">ATC {floatToTime(route.completedTimeCal)}</p>}
      </div>
      <div className="route-six">
        {/* <p className="route-reschedule">
          Created By 
        </p> */}
        <div className="route-comp-status">
          <p>
            Done <span className="route-done">{route.totalDelivered}</span>
          </p>
          <p>
            Fail <span className="route-fail">{route.totalFailed}</span>
          </p>
          <p>
            Pending <span className="route-pending">{route.totalPending}</span>
          </p>
        </div>
        <div className="route-progress-bar">
          <div className="done" style={{ width: `${donePercentage}%` }}>
            {donePercentage.toFixed(2)} %
          </div>
          <div className="fail" style={{ width: `${failPercentage}%` }}>
            {failPercentage.toFixed(2)} %
          </div>
          <div className="pending" style={{ width: `${pendingPercentage}%` }}>
            {pendingPercentage.toFixed(2)} %
          </div>
        </div>
      </div>
      <div className="route-seven" onClick={handleOpenMenu}>
        <p className="route-action">Actions</p>
        <TuneIcon sx={{width: 20}}/>
      </div>

      <Dialog open={openDriverConfirm} onClose={closeDriverConfirmModal}>
        <DialogTitle>
        Assign this route to
          <span style={{ fontWeight: 800 }}> {driver.name}</span>?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDriverAssign}
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
          >
            Assign
          </Button>
          <Button
            onClick={closeDriverConfirmModal}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalDetailOpen} onClose={handleCloseDetailModal}>
        <DialogTitle>Update Route Name</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Detail Name</label>
          <input
            type="text"
            className="modal-field"
            name="detail_name"
            placeholder="Detail Name"
            style={{ marginBottom: "10px" }}
            value={formState.detail_name}
            onChange={handleInputChange}
            autoComplete="off"
          />
          <Button
            onClick={handleUpdateDetailName}
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handleCloseDetailModal}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={modalDateOpen} onClose={handleCloseDatelModal}>
        <DialogTitle>Reschedule Route</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Change Date</label>
          <input
            type="date"
            autoComplete="off"
            className="facility-field"
            name="createdAt"
            placeholder="YYYY/MM/DD"
            value={formStateDate.createdAt}
            onChange={handleInputChangeDate}
          />
          <Button
            onClick={handleUpdateDate}
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
          >
            Rescheduled
          </Button>
          <Button
            onClick={handleCloseDatelModal}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
          // minWidth="lg"
          className="hisotryModal"
          open={modalHistoryOpen}
          onClose={handleModalHistoryClose}
          // style={{minWidth: "600px"}}
          // sx={{ '& .MuiDialog-paper': { p: 0 }, '& .MuiBackdrop-root': { opacity: '0.5 !important' } }}
          fullWidth
          scroll="body"
          PaperProps={{ sx: { verticalAlign: 'bottom', position: 'fixed', right: 0, bottom: 0 } }}
        >
          <DialogTitle>
            <div className="routeHistoryline1">
              <h5 className="historyLine1">
                <span style={{color: '#FE9604', fontSize: '20px'}}>
                  {history?.routeDetail?.routeStatus}
                </span>
              </h5>
            </div>
            <Box className='history-icon-and-text-container'>
              <img src={action8} className="history-icon" />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >

                <Typography className="history-text">History</Typography>
              </Stack>
            </Box>
            <div className="routeHistoryline1">
              <h5 className="historyLine1">
                Route Name:
                <span style={{ marginLeft: 10, textDecoration: "underline", fontSize: '14px' }}>
                  {history?.routeDetail?.name}
                </span>
              </h5>
              <h5 className="historyLine1">
                Driver:
                {history?.routeDetail?.driver === null ?
                  <span style={{ marginLeft: 10, textDecoration: "underline", fontSize: '14px' }}>
                    Not Assigned
                  </span>
                  :
                  <span style={{ marginLeft: 10, textDecoration: "underline", fontSize: '14px' }}>
                    {history?.routeDetail?.driver?.name}
                  </span>
                }
              </h5>
            </div>
            <div className="orderHistoryline1">
              <p className="historyLine2">
                Total Stops: {history?.routeDetail?.totalOrderStop}
              </p>
              <p className="historyLine2">
                Total Distance: {(parseFloat(history?.routeDetail?.distanceCal) * 0.621371).toFixed(2)}
              </p>
            </div>
            <div className="orderHistoryline1">
              <p className="historyLine2">
                ETC: {floatToTime(history?.routeDetail?.estimatedTimeCal)}
              </p>
              <p className="historyLine2">
                ATC: {history?.routeDetail?.routeStatus === "Done" 
                ? (parseFloat(history?.routeDetail?.completedTimeCal) * 0.621371).toFixed(2)
                : "Not Completed Yet"}
              </p>
            </div>
          </DialogTitle>
          {/* <Divider /> */}
          <div style={{ height: '400px', overflowY: 'auto' }}>
            <Timeline
              sx={{
                "& .MuiTimelineItem-root": { minHeight: 35 },
                "& .MuiTimelineOppositeContent-root": { mt: 0.5 },
                "& .MuiTimelineDot-root": {
                  borderRadius: 1.25,
                  boxShadow: "none",
                  margin: 0,
                  ml: 1.25,
                  mr: 1.25,
                  p: 1,
                  "& .MuiSvgIcon-root": { fontSize: "1.2rem" },
                },
                "& .MuiTimelineContent-root": {
                  borderRadius: 1,
                  bgcolor: "secondary.lighter",
                },
                "& .MuiTimelineConnector-root": {
                  border: "1px dashed",
                  borderColor: "secondary.light",
                  bgcolor: "transparent",
                },
              }}
            >
              {history?.routeHistory?.length > 0 && (
                history.routeHistory.map((item, i, array) => {                 
                  return (
                    <React.Fragment key={i}>
                      <div style={{ width: '100%', height: '8px', backgroundColor: '#E2E2E2' }}></div>
                      <TimelineItem>
                        <TimelineContent>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ fontSize: '12px', fontWeight: '700', textTransform: 'capitalize' }}
                          >
                            {item.display_name}
                          </Typography>
                          {/* <Typography color="textSecondary" style={{ fontSize: '12px', fontWeight: '400' }}>
                            {item.comment}
                          </Typography> */}
                        </TimelineContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <div
                            style={{
                              backgroundColor: item.icon_bg,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '30px',
                              width: '30px',
                            }}
                          >
                            <img src={item.icon_url} style={{ fontSize: '1rem' }} />
                          </div>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineOppositeContent
                          align="right"
                          style={{ fontSize: '12px', fontWeight: '400' }}
                        >
                          At: {moment(item.date).format("MM-DD-YY")} &nbsp; {moment(item.date).format("hh:mm:ssa")}
                          <br />
                          By: {item.updatedBy}
                        </TimelineOppositeContent>
                      </TimelineItem>
                    </React.Fragment>
                  );
                })
              )}
              <div style={{ width: '100%', height: '8px', backgroundColor: '#E2E2E2' }}></div>
            </Timeline>
          </div>
          {/* <Divider /> */}
          <DialogActions sx={{ p: 2.5 }}>
            <Button
              onClick={handleModalHistoryClose}
              color="primary"
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>


      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <a
            href={`/detailHere/route/${route.id}`}
            // href={`${process.env.REACT_APP_ROUTE_DOMAIN}/${route.id}/${token}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex gap-xs text-sm">
              <img src={open_route} alt="open_route" className="icon-size"/>
              Open Route
            </div>
          </a>
        </MenuItem>

        <MenuItem>
          <a
            href={`/detail/route/${route.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex gap-xs text-sm">
              <img src={open_route} alt="open_route" className="icon-size"/>
              Open Google Route
            </div>
          </a>
        </MenuItem>


        <MenuItem onClick={() => navigate(`/update/route/${route.id}`)}>
          <div className="flex gap-xs text-sm">
            <img src={edit_route} alt="edit_route" className="icon-size"/>
            Edit Route
          </div>
        </MenuItem>

        <MenuItem onClick={() => handleSplitRoute()}>
          <div className="flex gap-xs text-sm">
            <img src={split_route} alt="split_route" className="icon-size"/>
            Split Route
          </div>
        </MenuItem>

        <MenuItem onClick={() => setMergerRoute(true)}>
          <div className="flex gap-xs text-sm">
            <img src={merge_route} alt="merge_route" className="icon-size"/>
            Merge Route
          </div>
        </MenuItem>

        <MenuItem onClick={() => handleOpenDateModal(route.createdAt)}>
          <div className="flex gap-xs text-sm">
            <img src={resecdule_route} alt="resecdule_route" className="icon-size"/>
            Reschedule
          </div>
        </MenuItem>

        {/* <MenuItem>
          <div className="flex gap-xs text-sm">
            <img src={duplicate_route} alt="duplicate_route" className="icon-size"/>
            Duplicate Route
          </div>
        </MenuItem> */}

        <MenuItem onClick={() => navigate(`/detail/route/${route.id}/copay`)}>
          <div className="flex gap-xs text-sm">
            <img src={edit_route} alt="edit_route" className="icon-size"/>
            Copay Stops
          </div>
        </MenuItem>

        <MenuItem onClick={() => navigate(`/detail/route/${route.id}/note`)}>
          <div className="flex gap-xs text-sm">
            <img src={driver_notes} alt="driver_notes" className="icon-size"/>
            Driver Notes
          </div>
        </MenuItem>

        <MenuItem onClick={() => getRouteDetail(route.id)}>
          <div className="flex gap-xs text-sm">
            Print Route Slip
          </div>
        </MenuItem>

        {/* <MenuItem>
          <div className="flex gap-xs text-sm">
            <img src={share_route} alt="share_route" className="icon-size"/>
            Share Route
          </div>
        </MenuItem>

        <MenuItem>
          <div className="flex gap-xs text-sm">
            <img src={route_report} alt="route_report" className="icon-size"/>
            Route Report
          </div>
        </MenuItem> */}

        {/* <MenuItem>
          <div className="flex gap-xs text-sm">
            <img src={route_history} alt="route_history" className="icon-size"/>
            History
          </div>
        </MenuItem> */}

        <MenuItem>
          <div onClick={() => handleDeleteRoute()} className="flex gap-xs text-sm">
            <img src={delete_bin} alt="delete_bin" className="icon-size"/>
            Delete Route
          </div>
        </MenuItem>
        <MenuItem>
          <div onClick={() => handleCreateHistory()} className="flex gap-xs text-sm">
            <img src={action8} alt="delete_bin" className="icon-size"/>
            Route History
          </div>
        </MenuItem>
        {/* <MenuItem onClick={() => navigate(`/detail/route/${route.id}`)} >List</MenuItem> */}
      </Menu>
      <div style={{display: 'none'}}>
          {routeOrders && routeOrders.length > 0 && 
            <div ref={componentRef2}>
                <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px', pageBreakBefore: 'always', pageBreakAfter: 'always', pageBreakInside: 'avoid'}} >
                  <RouteSlip routeOrders={routeOrders} route={route} />
                </div>
            </div>
          }
      </div>   
    </div>
  );
}

export default RouteObject;

import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getToken } from "../utils/localStorage";

const PublicRoutes = () => (
  getToken()
    ? <Outlet />
    : <Navigate to="/list/order" />
);

export default PublicRoutes;

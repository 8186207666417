import React, {useState} from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useParams, useNavigate } from 'react-router';

import {
  loginLogo
} from '../../../assests';

import { 
    RESET_PASSWORD,
 } from "../../../utils/apiUrls";
import {
  putApiWithoutAuth
} from "../../../utils/api";


import './resetPassword.css';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const navigate = useNavigate();
  const {id, resetToken} = useParams()

  console.log(id, resetToken)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showPassword);
  };

  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handlerSubmit = async () => {
    setLoading(true);
    const response = await putApiWithoutAuth(RESET_PASSWORD + `${id}/${resetToken}/`, data);
    if (response.status === 200) {
      setLoading(false);
      // navigate('/list/order')
    } else {
      setLoading(false);
    }
  };


  return (
    <div className="forget-container">
      <div className="background-image"></div>
      <div className="forget-form">
        <div className="form-content">
          <img src={loginLogo} alt="Logo" className="logo" />
          <h2 className="forget-text">Reset Password</h2>
           <div className="input-container">
            <label htmlFor="password">Password</label>
            <div className="password-input-container">
            <input
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Enter your password"
              onChange={onChangeHandle}
            />
            <button
              className="show-hide-password"
              onClick={togglePasswordVisibility}
            >
              {showPassword 
              ? 
                <VisibilityOffIcon />
              :
                <RemoveRedEyeIcon />
              }
            </button>
          </div>
          </div>
           <div className="input-container">
            <label htmlFor="password">Confirm Password</label>
            <div className="password-input-container">
            <input
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password_confirm"
              placeholder="Enter your password"
              onChange={onChangeHandle}
            />
            <button
              className="show-hide-password"
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword 
              ? 
                <VisibilityOffIcon />
              :
                <RemoveRedEyeIcon />
              }
            </button>
          </div>
          </div>
          <button className="forget-button" onClick={handlerSubmit}>Reset Password</button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useState, useEffect, useRef } from "react";

import H from '@here/maps-api-for-javascript';
import moment from "moment";
import { text } from "../../../assests";

const mapStyles = {
  width: "100%",
  height: "100%",
};

function floatToTime(floatValue) {
  console.log(floatValue);
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }
  if (seconds > 0) {
    parts.push(`${seconds} second${seconds !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

function modifyDateTime(dateTimeString, addedMinutes) {
  const dateTime = new Date(dateTimeString);
  dateTime.setMinutes(dateTime.getMinutes() + Math.floor(addedMinutes));
  return dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

const apikey = process.env.REACT_APP_HERE_API;

const RouteStatusMapList = ({routeOrders, route, routeColors}) => {

  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const ui = useRef(null);

  useEffect(() => {
    if (!map.current && route[1]?.latitude) {
      platform.current = new H.service.Platform({ apikey });
      const defaultLayers = platform.current.createDefaultLayers({
        pois: true
      });

      map.current = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map, {
          zoom: 10,
          center: { lat: route[1]?.latitude, lng: route[1]?.longitude },
        }
      );

      ui.current = H.ui.UI.createDefault(map.current, defaultLayers);
      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(map.current)
      );

    }
    if (routeOrders?.length > 0 && map.current) {
      routeOrders.forEach(routeOrder => {
        routeResponseHandler(map.current, routeOrder);
      });
    }
  }, [apikey, routeOrders]);

  async function routeResponseHandler(map, response) {
    const fetchPolyline = async (url) => {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch polyline');
      const data = await response.text();
      return H.geo.LineString.fromFlexiblePolyline(data);
    };
  
    const lineStrings = await Promise.all(response.map(section => fetchPolyline(section.polyline)));
  
    lineStrings.forEach((lineString, i) => {
      const multiLineString = new H.geo.MultiLineString([lineString]); // Make sure each polyline is treated separately
      const routePolyline = new H.map.Polyline(multiLineString, { style: { lineWidth: 4, strokeColor: routeColors[response[0]?.route] } });
      map.addObject(routePolyline);
    });

    const targetRoute = route.find(r => r.id === response[0].route);

    const startMarker = createMarker({ order: { latitude: targetRoute?.latitude, longitude: targetRoute?.longitude, route: targetRoute } }, routeColors[response[0]?.route], "S", true);
    // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');

    let lastWaypoint = null;
    let lastIndex = 0;
    let indices = [];

    response?.forEach((waypoint, i) => {
        const currentLatLng = `${parseFloat(waypoint.order.latitude).toFixed(5)},${parseFloat(waypoint.order.longitude).toFixed(5)}`;
        
        if (lastWaypoint && currentLatLng === lastWaypoint) {
            indices.push(i + 1);
        } else {
            if (indices.length) {
                const markerLabel = indices.join(',');
                const waypointMarker = createMarker(response[lastIndex], routeColors[response[0]?.route], markerLabel, false, indices.length);
                map.addObject(waypointMarker);
            }
            indices = [i + 1];
            lastIndex = i;
        }
        lastWaypoint = currentLatLng;
    });

    if (indices.length) {
        const markerLabel = indices.join(',');
        const waypointMarker = createMarker(response[lastIndex], routeColors[response[0]?.route], markerLabel, false, indices.length);
        map.addObject(waypointMarker);
    }
    map.addObjects([startMarker]);
    // map.getViewModel().setLookAtData({ bounds });

  }

  function createMarker(coords, color, number, home, count=1) {
    let fillColor = home ? color : '#FFFFFF'; // Inside color white for non-home markers
    let textColor = home ? '#FFFFFF' : '#000000'; // Text color black for non-home markers
    const borderColor = color;
    if (!home) {
      if (coords?.status === 'failed') {
        fillColor = 'red';
      } else if (coords?.status === 'delivered') {
        fillColor = 'green';
      } else {
        fillColor = '#ffffff'
        textColor = "#000000"
      }
    }
    const icon = getMarkerIcon(fillColor, borderColor, number, textColor);
    const marker = new H.map.Marker({ lat: coords.order.latitude, lng: coords.order.longitude }, { icon });
    const infoContent = `
    <div style="width: 400px; font-size: 12px;">
      ${home ? (
        `<div>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${coords.order.route?.address}
          </p>
          ${coords.order.route?.routeStatus === "In Progress" ? (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(coords.order.route?.startTime, 0.0)}
            </p>`
          ) : (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              Not Started Yet
            </p>`
          )}
        </div>`
      ) : (
        `<div>
          <p>
            <span style="font-weight: 700;">Order Id: </span>
            ${coords.order.slug}
          </p>
          <p>
            <span style="font-weight: 700;">Name: </span>
            ${coords.order.name}
          </p>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${coords.order.address}
          </p>
          ${coords.order.route?.routeStatus === "In Progress" ? (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(route?.startTime, coords.estimatedTimeCal)}
            </p>`
          ) : coords.order.route?.routeStatus === "Done" ? (
            `<div>
              <p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${modifyDateTime(coords.order.route?.startTime, coords.estimatedTimeCal)}
              </p>
              <p>
                <span style="font-weight: 700;">
                  Delivery Time: 
                </span>
                ${moment(coords.order.deliverAt).format("hh:mm A")}
              </p>
            </div>`
          ) : (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${floatToTime(coords.estimatedTimeCal)} (after start)
            </p>`
          )}
        </div>`
      )}
    </div>
    `;

    marker.addEventListener('tap', () => showInfoBubble(marker, infoContent));
    
    const markers = [];
    for (let i = 0; i < count; i++) {
      markers.push(marker)
    }

    return marker;
  }

  function getMarkerIcon(fillColor, borderColor, number, textColor) {
    return new H.map.Icon(
      `<svg width="30" height="20" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="30" height="20" fill="${fillColor}" stroke="${borderColor}" stroke-width="2" />
        <path d="M189.5 350L111.441 257.726L267.559 257.726L189.5 350Z" fill="${textColor}"/>
        <text x="50%" y="50%" font-family="Arial" font-size="10" font-weight="bold" text-anchor="middle" alignment-baseline="middle" fill="${textColor}">${number}</text>
      </svg>`, { anchor: { x: 15, y: 15 } }
      );
  }

  function showInfoBubble(marker, text) {
    const bubble = new H.ui.InfoBubble(marker.getGeometry(), { content: text });
    ui.current.addBubble(bubble);
  }

  return (
    <div style={{ width: "100%", height: "60vh" }} ref={mapRef} />
  );
};

export default RouteStatusMapList;

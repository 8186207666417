import React, {useState, useEffect} from 'react'
import { 
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';
import { toast } from 'react-toastify';

import { 
  PHARMACY_REPORT,
  PHARMACY_REPORT_RANGE,
  PHARMACY_REPORT_DETAIL,
  PHARMACY_REPORT_COUNT,
  ALL_DRIVERS_SCANNED,
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth, postApiWithAuth, putApiWithAuth,
} from "../../../utils/api";

import DriverScanObject from '../../MiniComponents/DriverScanObject';
import './DriverScan.css';

function formatDate(dateString) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const date = new Date(dateString);
  const formattedDate = `${days[date.getDay()]} ${date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).replace(/\//g, '-')}`;
  return formattedDate;
}


function DriverScan() {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [doneCount, setDoneCount] = useState(13);
  const [failCount, setFailCount] = useState(3);
  const [pendingCount, setPendingCount] = useState(4);
  const [duration, setDuration] = React.useState(1);
  const [deliveryTime, setDeliveryTime] = React.useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [deliveryTimeFrom, setDeliveryTimeFrom] = React.useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);


  const getDrivers = async () => {
    try {
      setLoading(true); 
      const response = await getApiWithAuth(ALL_DRIVERS_SCANNED); 
      if (response.data !== undefined) {
        console.log(response.data)
        const driversData = response.data.data;
        console.log( typeof(response.data.data))
        console.log( typeof(driversData), driversData)
        setDrivers(driversData)
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
 
  useEffect(() => {
    getDrivers();
  }, []);


  return (
    <div >
      <div className='list-head'>
        <h2 className='listPharmacyOrderHeader'>Driver Scans</h2>
      </div>
      <div class='tab-total'>
        
      </div>
      <div className='driver-obj'>
        {Object.entries(drivers).map(([date, driverArray]) => (
          <>
<h3 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '16px !important' }}>
            {formatDate(date)}
            </h3>            
            {driverArray.map((driver) => (
              <DriverScanObject key={driver.driver_id} driver={driver} />
            ))}
          </>
        ))}
      </div>
      <div className='bottom-tab'>
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div>
    </div>
  )
}

export default DriverScan



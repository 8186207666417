import { useState } from "react";
import PropTypes from "prop-types";
import { Menu as BaseMenu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem } from "@mui/base/MenuItem";
import { Dropdown } from "@mui/base/Dropdown";
import { notification } from "../../../../assests";
import { IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Badge from '@mui/material/Badge';
import React, {  useEffect, useRef } from "react";
import {
  getApiWithAuth,
  postApiWithAuth,
  putApiWithAuth,
} from "../../../../utils/api";

import { toast } from "react-toastify";
import {
  ALL_ORDERS,
  ALL_PHARMACY_LIST,
  ALL_ZONES_LIST,
  CREATE_ROUTE,
  CREATE_ROUTE_ZONE,
  CREATE_ROUTE_BOROUGH,
  CREATE_ORDER,
  CHANGE_ORDER_STATUS,
  CREATE_ROUTE_ORDER,
  CANCEL_ORDER,
} from "../../../../utils/apiUrls";

export default function NotificationDropdown({isNavDropdownsOpen, setIsNavDropdownsOpen}) {
  const navigate = useNavigate();
  const { pid } = useParams();
  const [orders, setOrders] = useState([]);
  const dropdownRef = useRef(null);

  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  const handleClick = () => {
    setIsNavDropdownsOpen({...isNavDropdownsOpen, notifications: false});
    console.log("Dropdown closed");
    const isAuthenticated = localStorage.getItem("userType") === "PharmacyEmployee" || localStorage.getItem("userType") === "PharmacyOwner";
    if (isAuthenticated) {
      console.log("Navigating to pharmacy cancelnotes");
      navigate(`pharmacy/${pid}/cancelnotes`);
    } else {
      console.log("Navigating to cancelnotes");
      navigate("/cancelnotes");
    }
  };

  const handleButtonClick = (property) => {
    const updatedDropdowns = {};
    Object.keys(isNavDropdownsOpen).forEach((key) => {
      updatedDropdowns[key] = key === property;
    });
    setIsNavDropdownsOpen(updatedDropdowns);
    console.log(`Button clicked: ${property}`);
  };

  const getOrders = async () => {
    try {
      const response = await getApiWithAuth(CANCEL_ORDER);
      if (response?.data?.data) {
        setOrders(response.data.data.data);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsNavDropdownsOpen({...isNavDropdownsOpen, notifications: false});
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavDropdownsOpen, setIsNavDropdownsOpen]);

  const latestThreeOrders = orders.slice(0, 3);

  return (
    <div>
      <Dropdown
        open={isNavDropdownsOpen.notifications}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuButton>
          <IconButton color="inherit" onClick={() => handleButtonClick('notifications')}>
            <Badge badgeContent={orders.length} color="error">
              <img className="top-icon" src={notification} alt="Logo" />
            </Badge>
          </IconButton>
        </MenuButton>
        <Menu ref={dropdownRef} className="z-[1500] left-[-11%] bg-gray py-sm px-md min-h-[60px] max-w-[650px] min-w-[400px] rounded-sm flex items-center justify-center">
          <div className="flex flex-col gap-md">
            <p className="text-red text-3xl">Cancel Orders</p>
            {latestThreeOrders.map((order) => (
              <MenuItem key={order.id} className="bg-white py-sm px-md rounded-xs" onClick={createHandleMenuClick(order.slug)}>
                <div className="flex flex-col gap-sm">
                  <div className="flex items-center justify-between">
                    <p className="text-md font-[700]">{order.slug}</p>
                    <p className="text-md font-[700]">Client: {order.name}</p>
                    <p className="text-md font-[700] text-red">Canceled</p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-md font-[400]">{/* by : {order.createdby} */}</p>
                    <p className="text-md font-[400]">{moment(order.createdAt).format("MM-DD-YYYY")} {moment(order.createdAt).format("hh:mm:ss A")}</p>
                  </div>
                </div>
              </MenuItem>
            ))}
            <div className="flex justify-end gap-sm">
              <button onClick={() => setIsNavDropdownsOpen({...isNavDropdownsOpen, notifications: false})} className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-red rounded-[2px]">Clear</button>
              <button onClick={handleClick} className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-[#0455A6] rounded-[2px]">See All</button>
            </div>
          </div>
        </Menu>
      </Dropdown>
    </div>
  );
}

NotificationDropdown.propTypes = {
  isNavDropdownsOpen: PropTypes.object.isRequired,
  setIsNavDropdownsOpen: PropTypes.func.isRequired,
};

const Menu = BaseMenu;

Menu.propTypes = {
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  slotProps: PropTypes.shape({
    listbox: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    root: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
};

const MenuButton = BaseMenuButton;

MenuButton.propTypes = {
  className: PropTypes.string,
};

const MenuItem = BaseMenuItem;

MenuItem.propTypes = {
  className: PropTypes.string,
};

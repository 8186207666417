import React, { useEffect, useState, useRef } from "react";
import Icon from '@material-ui/core/Icon';
import {
  Table,
  // TextField,
  // Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  // FormControl,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PrintDocList from "../../CommonComponents/PrintDoc/PrintDocList";
import moment from "moment";
import {
  action1,
  pharmacyCall,
  route_report,
  pilldropLogo,
  landline_white,
  text,
  pharmacyAddress,
  driver,
} from "../../../assests";
import { useReactToPrint } from "react-to-print";

import { useNavigate } from "react-router";

import { toast } from "react-toastify";

import "./DriverReportObject.css";

import {
  DRIVER_REPORT_COUNT,
  ORDER_DETAIL_LIST,
} from "../../../utils/apiUrls";

import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";

// const columns2 = [
//   { id: "date", label: "Date", minWidth: 170 },
//   { id: "shift1", label: "9am to 3pm", minWidth: 170 },
//   { id: "shift2", label: "2pm to 6pm", minWidth: 170 },
//   { id: "shift3", label: "5pm to 9pm", minWidth: 170 },
//   { id: "totalamount", label: "Daily Amount", minWidth: 170 },

// ];
const columns2 = [
  { id: "date", label: "Date", minWidth: 120 },
  { id: "shift1", label: "Routes", minWidth: 120 },
  { id: "shift2", label: "Delivered", minWidth: 120 },
  { id: "shift3", label: "Failed", minWidth: 120 },
  { id: "totalamount", label: "Delivered Amount", minWidth: 120 },
  { id: "amount", label: "Failed Amount", minWidth: 120 },
];

const useStyles = makeStyles(() => ({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    fontWeight: "700",
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    marginTop: "0px",
    fontWeight: "700",
    textAlign: "center",
    border: "1px solid black",
    minWidth: "30%",
    padding: "0 10px",
  },
  header2: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: "700",
    textAlign: "center",
  },
  header3: {
    fontSize: 18,
    marginBottom: 0,
    marginTop: "0px",
    fontWeight: "400",
    textAlign: "center",
  },
  header4: {
    fontSize: 18,
    marginBottom: 8,
    marginTop: "0px",
    fontWeight: "400",
    textAlign: "center",
  },
  Total: {
    fontSize: 24,
    float: "right",
    marginBottom: 8,
    marginRight: 0,
    marginTop: "0px",
    fontWeight: "700",
    textAlign: "center",
  },
  Sums: {
    fontSize: 18,
    marginBottom: "1px",
    marginTop: "0px",
    fontWeight: "700",
  },
  table: {
    width: "100%",
    border: "1px solid black",
    marginBottom: 0,
    fontWeight: "700",
  },
  tableHeader: {
    // backgroundColor: '#f0f0f0',
    fontWeight: "900",
    borderBottomWidth: "2px",
  },
  tableRow: {},
  tableCell: {
    padding: 5,
    fontSize: 12,
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    fontWeight: "700",
  },
  firstColumn: {
    fontWeight: "700",
    // borderBottomWidth: 2,
  },
  firstRow: {
    fontWeight: "700",
    borderBottomWidth: "2px",
  },
}));

function DriverReportObject({ pharmacyOrder }) {
  console.log("DriverReportObject",pharmacyOrder)
  const [invoice, setInvoice] = useState([]);
  const [signedOrders, setSignedOrders] = useState([]);
  const [loading, setLoading] = useState(null);
  const PDFJS = window.pdfjsLib;

  const createDate = new Date();

  const navigate = useNavigate();

  const classes = useStyles();
  const componentRef = useRef();
  const componentRef2 = useRef();

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });
  function formatDateToMMDDYYYY(inputDate) {
    if (!inputDate) {
      return ""; // Return an empty string if the date is undefined or empty
    }
    const parts = inputDate.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];
    return `${month}/${day}/${year}`;
  }
  let totalDeliveredAmount = 0;
  let totalFailedAmount = 0;
  const fetchInvoice = async (dateRange, driver_id) => {
    console.log("fetchinvoice", dateRange, driver_id)
    // setByDate(true);
    const [startDate, endDate] = dateRange.split(" - ");
    console.log(startDate, endDate);
    const deliveryTime = new Date(startDate);
    const deliveryTimeFrom = new Date(endDate);
    const formData = {
      from_date: startDate,
      to_date: endDate,
      driver: driver_id,
    };
    // const formData = { data: duration }
    const response = await postApiWithAuth(DRIVER_REPORT_COUNT, formData);
    console.log("resp",response);
    if (response.data !== undefined) {
      setInvoice(response.data.data);
      console.log("Invoice state:", invoice);
      console.log(response.data.data)
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    if (invoice.length > 0) {
      console.log("invoiceeee",invoice, invoice.length)
      handleDownloadPDF();
      setInvoice([]);
    }
  }, [invoice]);

  const handleDownloadPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Order_File.pdf",
  });


  useEffect(() => {
    if (signedOrders.length > 0) {
      setLoading(true);
      const timer = setTimeout(() => {
        handlePrintSlip();
        setLoading(false);
      }, 30000); // 20000 milliseconds = 20 seconds

      // Cleanup function to clear the timeout if the component unmounts or the dependencies change
      return () => clearTimeout(timer);
    }
  }, [signedOrders]);

  return (
    <>
      {loading && <CustomLoader />}
      <div>

      <h1 style={{textAlign: 'center', fontWeight: '700', fontSize: '16px'}}>{pharmacyOrder.monthDates.month_start} - {pharmacyOrder.monthDates.month_end}</h1>

      {pharmacyOrder.driverReports.map(driverReport => (
      <div className="pharmacyOrder-div">
        <div className="pharmacyOrder-first">
          <h3 className="pharmacyOrder-date">
            {driverReport.pharmacyDetail.name}
          </h3>
          <div className="detail-div-pharmacy" style={{ marginBottom: "5px" }}>
            <h3 className="detail-in-text-pharmacy">
              {driverReport.pharmacyDetail.email}
            </h3>
          </div>
        </div>

        <div className="pharmacyOrder-order-list">    
  {driverReport.weeklyData.map((weeklyItem, index) => (
    <div key={index} className="pharmacyReport-weekly">
      <h3 className="pharmacyReport-head">{weeklyItem.dateRange.replace(' - ', ' To ')}</h3>
      <div className="pharmacyReport-icon">
       <img
          className="detail-in-icon-ph"
          style={{ cursor: "pointer" }}
          onClick={() => fetchInvoice(weeklyItem.dateRange,driverReport.pharmacyDetail.id )}
          src={route_report}
          alt="Route Report"
        />
      </div>
    </div>
  ))}
  {driverReport.weeklyData.length === 2 && (
  <>
    <div className="pharmacyReport-weekly">
      {/* Blank div 1 */}
      <h3 className="pharmacyReport-head"></h3>
      <div className="pharmacyReport-icon">
        {/* Empty icon or placeholder */}
      </div>
    </div>
    <div className="pharmacyReport-weekly">
      {/* Blank div 2 */}
      <h3 className="pharmacyReport-head"></h3>
      <div className="pharmacyReport-icon">
        {/* Empty icon or placeholder */}
      </div>
    </div>
    <div className="pharmacyReport-weekly">
      {/* Blank div 1 */}
      <h3 className="pharmacyReport-head"></h3>
      <div className="pharmacyReport-icon">
        {/* Empty icon or placeholder */}
      </div>
    </div>
  </>
)}
{driverReport.weeklyData.length === 2 && (
  <>
    <div className="pharmacyReport-weekly">
      {/* Blank div 1 */}
      <h3 className="pharmacyReport-head"></h3>
      <div className="pharmacyReport-icon">
        {/* Empty icon or placeholder */}
      </div>
    </div>
    <div className="pharmacyReport-weekly">
      {/* Blank div 2 */}
      <h3 className="pharmacyReport-head"></h3>
      <div className="pharmacyReport-icon">
        {/* Empty icon or placeholder */}
      </div>
    </div>
    <div className="pharmacyReport-weekly">
      {/* Blank div 1 */}
      <h3 className="pharmacyReport-head"></h3>
      <div className="pharmacyReport-icon">
        {/* Empty icon or placeholder */}
      </div>
    </div>
  </>
)}
</div> 
</div>
      ))}

        <div style={{ display: "none" }}>
          <div ref={componentRef} style={{ margin: 20 }}>
            <div className={classes.page}>
              <div className={classes.section}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "400" }}>
                    Date Issued: {moment(createDate).format("MM/DD/YYYY")}
                  </p>
                  <p style={{ fontWeight: "400" }}>Page 1/1</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    height: "80px",
                  }}
                >
                  <div style={{ minWidth: "550px" }}>
                    <img
                      src={pilldropLogo}
                      alt="Mantis"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <h4 className={classes.header}>
                    Invoice{" "}
                    <span style={{ marginLeft: "10px" }}>
                      ({invoice[0]?.dateRange}
                      )
                    </span>
                  </h4>
                </div>
                <h4 className={classes.header2}>
                  {invoice[0]?.pharmacyDetail?.name}
                </h4>
                {/* <Table>
    <TableHead>
      <TableRow>
        {columns2.map((column) => (
          <TableCell
            key={column.id}
            className={`${classes.tableCell} ${classes.tableHeader}`}
            align="center"
            style={{ border: "1px solid black", fontWeight: "bold" }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell
          className={`${classes.tableCell} ${classes.firstColumn}`}
          align="center"
          style={{ border: "1px solid black", fontWeight: "bold" }}
        >
          $Amount
        </TableCell>
        {[
          invoice[0]?.pharmacyDetail?.shift1Price,
          invoice[0]?.pharmacyDetail?.shift2Price,
          invoice[0]?.pharmacyDetail?.shift3Price,
          invoice[0]?.pharmacyDetail?.shift4Price,
        ].map((price, index) => (
          <TableCell
            key={index}
            className={`${classes.tableCell}`}
            align="center"
            style={{ border: "1px solid black" }}
          >
            {price?.toFixed(2)}
          </TableCell>
        ))}
      </TableRow>
      {invoice &&
        invoice[0]?.dailyData &&
        invoice[0].dailyData.map((dailyItem, k) => {
          const dailyAmount =
            (dailyItem.phase1Orders * invoice[0]?.pharmacyDetail?.shift1Price || 0) +
            (dailyItem.phase2Orders * invoice[0]?.pharmacyDetail?.shift2Price || 0) +
            (dailyItem.phase3Orders * invoice[0]?.pharmacyDetail?.shift3Price || 0);
          
          return (
            <TableRow key={k}>
              <TableCell
                className={`${classes.tableCell} ${classes.firstColumn}`}
                align="center"
                style={{ border: "1px solid black" }}
              >
                {formatDateToMMDDYYYY(dailyItem.date)}
              </TableCell>
              <TableCell
                className={`${classes.tableCell}`}
                align="center"
                style={{ border: "1px solid black", fontWeight: "bold" }}
              >
                {dailyItem.phase1Orders}
              </TableCell>
              <TableCell
                className={`${classes.tableCell}`}
                align="center"
                style={{ border: "1px solid black", fontWeight: "bold" }}
              >
                {dailyItem.phase2Orders}
              </TableCell>
              <TableCell
                className={`${classes.tableCell}`}
                align="center"
                style={{ border: "1px solid black", fontWeight: "bold" }}
              >
                {dailyItem.phase3Orders}
              </TableCell>
              <TableCell
                className={`${classes.tableCell}`}
                align="center"
                style={{ border: "1px solid black", fontWeight: "bold" }}
              >
                {dailyAmount.toFixed(2)}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  </Table> */}
 <Table>
      <TableHead>
        <TableRow>
          {columns2.map((column) => (
            <TableCell
              key={column.id}
              className={`${classes.tableCell} ${classes.tableHeader}`}
              align="center"
              style={{ border: "1px solid black", fontWeight: "bold" }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            className={`${classes.tableCell} ${classes.firstColumn}`}
            align="center"
            style={{ border: "1px solid black", fontWeight: "bold" }}
          >
            $Amount
          </TableCell>
          {[
            invoice[0]?.pharmacyDetail?.shift4Price,
            invoice[0]?.pharmacyDetail?.shift4Price,
            invoice[0]?.pharmacyDetail?.shift4Price,
            invoice[0]?.pharmacyDetail?.shift1Price,
            invoice[0]?.pharmacyDetail?.failedDeliveryPrice,
          ].map((price, index) => (
            <TableCell
              key={index}
              className={`${classes.tableCell}`}
              align="center"
              style={{ border: "1px solid black" }}
            >
              {price?.toFixed(2)} $
            </TableCell>
          ))}
        </TableRow>
        {invoice &&
          invoice[0]?.dailyData &&
          invoice[0].dailyData.map((dailyItem, k) => {
            const dailyAmount =
              (dailyItem.completed_orders * invoice[0]?.pharmacyDetail?.shift1Price || 0);
            const dailyFailedAmount =
              (dailyItem.failed_orders * invoice[0]?.pharmacyDetail?.failedDeliveryPrice || 0);

            totalDeliveredAmount += dailyAmount;
            totalFailedAmount += dailyFailedAmount;

            return (
              <TableRow key={k}>
                <TableCell
                  className={`${classes.tableCell} ${classes.firstColumn}`}
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  {formatDateToMMDDYYYY(dailyItem.date)}
                </TableCell>
                <TableCell
                  className={`${classes.tableCell}`}
                  align="center"
                  style={{ border: "1px solid black", fontWeight: "bold" }}
                >
                  {dailyItem.dailyroutes}
                </TableCell>
                <TableCell
                  className={`${classes.tableCell}`}
                  align="center"
                  style={{ border: "1px solid black", fontWeight: "bold" }}
                >
                  {dailyItem.completed_orders}
                </TableCell>
                <TableCell
                  className={`${classes.tableCell}`}
                  align="center"
                  style={{ border: "1px solid black", fontWeight: "bold" }}
                >
                  {dailyItem.failed_orders}
                </TableCell>
                <TableCell
                  className={`${classes.tableCell}`}
                  align="center"
                  style={{ border: "1px solid black", fontWeight: "bold" }}
                >
                  {dailyAmount.toFixed(2)} $
                </TableCell>
                <TableCell
                  className={`${classes.tableCell}`}
                  align="center"
                  style={{ border: "1px solid black", fontWeight: "bold" }}
                >
                  {dailyFailedAmount.toFixed(2)} $
                </TableCell>
              </TableRow>
            );
          })}
        {/* Add a row for totals */}
        <TableRow>
          <TableCell
            className={`${classes.tableCell}`}
            align="center"
            style={{ border: "1px solid black", fontWeight: "bold" }}
            colSpan={4}
          >
            Total
          </TableCell>
          <TableCell
            className={`${classes.tableCell}`}
            align="center"
            style={{ border: "1px solid black", fontWeight: "bold" }}
          >
            {totalDeliveredAmount.toFixed(2)} $
          </TableCell>
          <TableCell
            className={`${classes.tableCell}`}
            align="center"
            style={{ border: "1px solid black", fontWeight: "bold" }}
          >
            {totalFailedAmount.toFixed(2)} $
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>

                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 15px 5px 30px",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                >
                  <p>Urgent Orders =  {invoice[0]?.urgentOrders}</p>
                  <p>Failed Attempts =  {invoice[0]?.failedOrders}</p>
                  <p>Failed Attempts = {invoice[0]?.failedOrders * invoice[0]?.pharmacyDetail?.failedDeliveryPrice}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 15px 5px 30px",
                    borderBottom: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                >
                  <p>Total Orders =  {invoice[0]?.totalorders}</p>
                  <p>Total Amount =  {invoice[0]?.totalorders}</p>
                </div> */}
                {/* <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "600", marginTop: "20px"}}>
  Form of Payment: <span style={{ marginLeft: "5px",textDecoration: "underline" }}>Check/Cash/Credit Card/Bank Account</span>
</p>

                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DriverReportObject;

import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = () => {
  return (
    <div className="w-[700px] h-[300px]">
      <Line
        width={700}
        height={300}
        data={{
          labels: [
            "Mon",
            "Tue",
            "Web",
            "Thu",
            "Fri",
            "Sat",
            "Sun",
            "Mon",
            "Tue",
            "Wed",
          ],
          datasets: [
            {
              label: "Daily Orders",

              data: [17, 32, 39, 50, 30, 65, 70, 40, 45, 60],
              fill: false,
              borderColor: "#00A389",
              tension: 0.4,
            },
            {
              label: "Rx Orders",
              data: [15, 29, 35, 45, 25, 60, 65, 35, 42, 55],
              fill: false,
              borderColor: "red",
              tension: 0.4,
            },
            {
              label: "Custom Orders",

              // data: [15, 29, 35, 45, 25, 40, 35, 35, 42, 55],
              fill: false,
              borderColor: "#1AA937",
              tension: 0.4,
            },
          ],
        }}
        options={{
          plugins: {
            title: {
              display: true,
              text: "All Orders", // Title text
              align: "start", // Align the title to the start (left) of the chart
              padding: {
                top: 0, // Reduce the top padding to bring the title closer to the legend
                bottom: -20, // Add some bottom padding to the title
              },
              font: {
                size: 18, // Adjust the font size of the title
                weight: "bold", // Make the title bold
              },
            },
            legend: {
              position: "top",

              align: "end",
              labels: {
                boxWidth: 10, // Adjust the width of the legend color boxes
                usePointStyle: true,
                pointStyle: "dash",
              },
            },
            tooltip: {
              titleAlign: "center",
              bodyAlign: "center",
              displayColors: false,

              padding: {
                left: 20,
                right: 20,
                top: 10,
                bottom: 10,
              },
            },
          },
          scales: {
            y: {
              ticks: {
                stepSize: 25, // Set the interval between ticks to 25
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;

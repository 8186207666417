import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useParams, useLocation, useNavigate } from "react-router";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DirectionsIcon from "@mui/icons-material/Directions";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForwardIcon from "@mui/icons-material/Forward";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Autocomplete from 'react-google-autocomplete';
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  Dialog,
  DialogActions,
  Autocomplete as MUIAutocomplete,
  TextField,
  Select,
  MenuItem,
  Button,
  Menu,
  Paper,
  Grid,
  DialogContent,
  IconButton,
  FormControl,
  Checkbox,
} from "@mui/material";
import { DateRange } from "react-date-range";
import { v4 as uuidv4 } from 'uuid';

import {
  ALL_ORDERS,
  ALL_PHARMACY_LIST,
  ALL_ZONES_LIST,
  CREATE_ROUTE,
  CREATE_ROUTE_ZONE,
  CREATE_ROUTE_BOROUGH,
  CREATE_ORDER,
  CHANGE_ORDER_STATUS,
  CREATE_ROUTE_ORDER,
  ALL_USERS,
  FCM_TOKEN,
  CHECK_ROUTE_TASK_STATUS,
} from "../../../utils/apiUrls";
import {
  deleteApiWithAuth,
  getApiWithAuth,
  postApiWithAuth,
  putApiWithAuth,
} from "../../../utils/api";

import { toast } from "react-toastify";
import OrderObject from "../../MiniComponents/OrderObject";
import ChatModal from "../../ChatComponents/ChatModal";

import { upload } from "../../../assests";

import "./orderList.css";
import "./OrderList.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ArrowDropDownCircle, CheckBox, Task } from "@mui/icons-material";


const GOOGLE_MAPS_API_KEY = 'AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ';

function OrderList() {
  const [filter, setFilter] = useState("");
  const [timer, setTimer] = useState("");
  const [modalCreateRouteOpen, setModalCreateRouteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [edit, setEdit] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderNum, setOrderNum] = useState({});
  const [status, setStatus] = useState("all");
  const [search, setSearch] = useState("");
  const [rx, setrx] = useState("");
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [zone, setZone] = useState([]);
  const [pharmacy, setPharmacy] = useState("");
  const [zip, setZip] = useState([]);
  const [borough, setBorough] = useState([]);
  const [boroughZone, setBoroughZone] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [defaultMark, setDefaultMark] = useState(true);
  const [routeCreation, setRouteCreation] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [callToNotify, setCallToNotify] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
      key: "selection",
    },
  ]);

  const navigation = useNavigate();
  const firebaseConfig = {
    apiKey: "AIzaSyD9HaluoaEn0toQShTwIS4WU6ECWd3Ib6Y",
    authDomain: "pilldrop-2ba33.firebaseapp.com",
    projectId: "pilldrop-2ba33",
    storageBucket: "pilldrop-2ba33.appspot.com",
    messagingSenderId: "714498636709",
    appId: "1:714498636709:web:bc1143a7d59a84b9e7864c",
    measurementId: "G-0YKXMT2HKY",
  };
  // Initialize Firebase app at the root level of your application
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  function requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const messaging = getMessaging(app);
        getToken(messaging, {
          vapidKey: "BJuixTt0nsnzUKL-vdE-8vJ1wojNg4_6vmDDEnpr-ns0W8ZyESBVJxcLkMhYPlrTBfhR6R3rh7UHn5VdbrEFHOs",
        }).then((currentToken) => {
          if (currentToken) {
            sendTokenToBackend(currentToken);
          } else {
            console.log("Can't get token");
          }
        });
      } else {
        console.log("Don't have permission");
      }
    });
  }
  const userId = localStorage.getItem('UID');

  useEffect(() => {
    requestPermission();

    onMessage(messaging, (payload) => {

      const { title, body, icon, image } = payload.notification;
      const notificationIcon = icon || "https://www.test.pilldrop.ai/logo192.png";
      const notificationImage = image;

      const notificationOptions = {
        body,
        icon: notificationIcon,
        image: notificationImage,
        data: {
          url: payload.data?.openUrl || "https://www.pilldrop.ai",
        }
      };

      const notification = new Notification(title, notificationOptions);

      notification.onclick = (event) => {
        event.preventDefault(); // Prevent the browser from focusing the Notification's tab
        const url = notificationOptions.data.url;
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        }
      };
    });
  }, []);

  useEffect(() => {
    let intervalId = null;

    const fetchTaskStatus = async () => {
      if (routeCreation) {
        setLoading(true);
        try {
          const response = await getApiWithAuth(CHECK_ROUTE_TASK_STATUS + `${taskId}/`);
          if (response.data.data.status === 'SUCCESS') {
            clearInterval(intervalId); // Clear the interval once successful
            getOrders("7");
            setStatus("7");
            setSelectedTab('inTransit');
            fetchOrderNum();
            setModalCreateRouteOpen(false);
            setLoading(false);
            setRouteCreation(false);
            setTaskId("");
            toast.success(`Route created successfully!`, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            // Schedule the next check if the status is not 'SUCCESS'
            intervalId = setTimeout(fetchTaskStatus, 5000);
          }
        } catch (error) {
          console.error('Failed to fetch task status:', error);
          clearInterval(intervalId); // Ensure to clear the interval on error
          setLoading(false);
          toast.error(`Failed to fetch status: ${error.message}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    };

    fetchTaskStatus();

    return () => clearInterval(intervalId);
  }, [routeCreation, taskId]);



  const sendTokenToBackend = async (token) => {
    try {
      // Check if token has already been added
      const tokenAdded = localStorage.getItem('tokenAdded');

      if (tokenAdded === 'true') {
        return;
      }

      const user_id = localStorage.getItem('UID');
      const pharmacyId = localStorage.getItem('pharmacyId');
      let deviceId = localStorage.getItem('deviceId');

      // If device ID doesn't exist, generate a new one
      if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('deviceId', deviceId);
      }

      const response = await postApiWithAuth(FCM_TOKEN, { token, user_id, pharmacyId, deviceId });


      // If token was successfully sent to the backend, set tokenAdded to true
      if (response.data.status === 200 || response.data.status === 201) {
        localStorage.setItem('tokenAdded', 'true');
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const { Urgent, Status_Id } = useParams();
  const location = useLocation();

  const [address, setAddress] = useState(localStorage.getItem("route_startaddress") ? localStorage.getItem("route_startaddress") : '214 BEACH 3RD ST, Far Rockaway, NY 11691');
  const [anchorElRecieve, setAnchorElRecieve] = useState(null);
  const [anchorElDispatch, setAnchorElDispatch] = useState(null);
  const [hasMore, setHasMore] = useState(null);
  const [scanReceivingNum, setScanReceivingNum] = useState(0);
  const [scanDispatchingNum, setScanDispatchingNum] = useState(0);
  const [selectedTab, setSelectedTab] = useState('allOrders')
  const userType = localStorage.getItem("userType");
  const handleOpenMenuRecieve = (event) => {
    setAnchorElRecieve(event.currentTarget);
  };

  const orderObjRef = useRef(null);

  const handleCloseMenuRecieve = () => {
    setAnchorElRecieve(null);
    setScanReceivingNum(0);
    setOrderDetail({})
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      const searchTerm = inputValue.toLowerCase(); // Convert input to lowercase for case-insensitive matching
      if (searchTerm.includes("rx")) {
        setrx(inputValue); // Set search term to "rx" if input contains "Rx" or "RX"
      } else {
        setSearch(inputValue); // Set search term to input value otherwise
      }
    }
  };

  const handlePlaceSelect = (place) => {
    setAddress(place.formatted_address)
    localStorage.setItem("route_startaddress", place.formatted_address)
  };

  const options = {
    types: ['address'],
    componentRestrictions: { country: 'us' },
    bounds: {
      north: 42.0505,  // Northernmost point roughly in CT
      south: 38.9285,  // Southernmost point roughly in NJ
      west: -79.7624,  // Westernmost point roughly in NY
      east: -71.1851   // Easternmost point roughly in CT
    },
    strictBounds: true
  };


  let debounceTimeout = null;

  const handleDebouncedScan = async (data) => {
    clearTimeout(debounceTimeout);
    if (data) {
      try {
        const response = await putApiWithAuth(
          CHANGE_ORDER_STATUS + `${data.target.value}`,
          { id: "3", current: "3" }
        );
        if (response.data.error != undefined) {
          toast.error(`Order ${response.data.error}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data !== undefined) {
          getOrders("3");
          fetchOrderNum();
          toast.success(
            `QR Code scanned order ${data.target.value} Successfully!`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          setSelectedTab('receivedAtFacility')
          setScanReceivingNum(parseInt(scanReceivingNum) + 1)
        }
      } catch (error) {
        if (error.response.data.error === "already on that status") {
          toast.error(
            `Order ${data.target.value} ${error.response.data.error}`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else if (error.response.data.error === "delivered") {
          toast.error(`Order ${data.target.value} is delivered`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (error.response.data.error === "cancelled") {
          toast.error(`Order ${data.target.value} is cancelled`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`Order ${data.target.value} have backend error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const handleChangeRecieve = (event) => {
    clearTimeout(debounceTimeout); // Clear the previous debounce timeout
    debounceTimeout = setTimeout(() => {
      handleDebouncedScan(event);
      getOrderDetail(event.target.value);
      debounceTimeout = null;
      event.target.value = "";
    }, 1000); // Set a new timeout of 2 seconds
  };

  const handleOpenMenuDispatch = (event) => {
    setAnchorElDispatch(event.currentTarget);
  };

  const handleCloseMenuDispatch = () => {
    setAnchorElDispatch(null);
    setScanDispatchingNum(0);
    setOrderDetail({})
  };

  let debounceTimeout2 = null;

  const handleDebouncedScan2 = async (data) => {
    clearTimeout(debounceTimeout2);
    if (data) {
      try {
        const response = await putApiWithAuth(
          CHANGE_ORDER_STATUS + `${data.target.value}`,
          { id: "4", current: "3" }
        );
        if (response.data.error != undefined) {
          toast.error(`Order ${response.data.error}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data !== undefined) {
          getOrders("4");
          fetchOrderNum();
          toast.success(
            `QR Code scanned order ${data.target.value} Successfully!`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          setSelectedTab('dispatching')
          setScanDispatchingNum(parseInt(scanDispatchingNum) + 1)
        }
      } catch (error) {
        if (error.response.data.error === "already on that status") {
          toast.error(
            `Order ${data.target.value} ${error.response.data.error}`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else if (error.response.data.error === "delivered") {
          toast.error(`Order ${data.target.value} is delivered`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (error.response.data.error === "cancelled") {
          toast.error(`Order ${data.target.value} is cancelled`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`Order ${data.target.value} have backend error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const handleChangeDispatch = (event) => {
    clearTimeout(debounceTimeout2);
    debounceTimeout2 = setTimeout(() => {
      handleDebouncedScan2(event);
      getOrderDetail(event.target.value);
      debounceTimeout2 = null;
      event.target.value = "";
    }, 1000);
  };

  const calendarRef = useRef(null);

  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  useEffect(() => {
    if (Boolean(anchorElRecieve)) {
      // Timeout is used to ensure that the menu is fully rendered before focusing
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [anchorElRecieve]);

  useEffect(() => {
    if (Boolean(anchorElDispatch)) {
      // Timeout is used to ensure that the menu is fully rendered before focusing
      setTimeout(() => {
        inputRef2.current.focus();
      }, 0);
    }
  }, [anchorElDispatch]);

  const handleEdit = () => {
    setEdit(!edit)
  };

  const handleSaveEdit = async () => {
    setEdit(false);
    if (defaultMark) {
      localStorage.setItem("route_startaddress", address)
      const UID = localStorage.getItem("UID")
      const updatedPersonalDetail = new FormData();
      updatedPersonalDetail.append("user_id", UID);

      updatedPersonalDetail.append("action", "update_details");
      updatedPersonalDetail.append("route_startaddress", address);

      const responsePersonal = await putApiWithAuth(
        ALL_USERS + `all`,
        updatedPersonalDetail
      );
    }

  };

  const getOrderDetail = async (id) => {
    try {
      const response = await getApiWithAuth(
        CREATE_ORDER + `/${id}`
      );
      // dispatch(getPdfData(formData));
      setOrderDetail(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (ranges) => {
    if (ranges.selection.endDate) {
      orderObjRef.current.scrollTop = 0;
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  let isFetching = false;
  const getOrders = async (statusId, pageIndexData = pageIndex, loadMore = false) => {
    console.log(loadMore, pageIndex, "==================", loadingOrder)
    setStatus(statusId);
    setLoadingOrder(true);
    if (loadingOrder) return;
    if (isFetching) return;
    isFetching = true;
    if (!loadMore) {
      orderObjRef.current.scrollTop = 0;
      setPageIndex(1); // Reset to the first page if it's not a "load more" request
    }
    if (callToNotify) {
      setSelectedTab('allOrders');
      const response = await getApiWithAuth(
        ALL_ORDERS +
        `?status=all&pharmacy=${pharmacy}&filter=${filter === "0" ? "" : filter
        }&timer=${timer === "0" ? "" : timer}&zip=${zip}&from=${moment(
          dateRange[0].startDate
        ).format("MM-DD-YYYY")}&to=${moment(dateRange[0].endDate).format(
          "MM-DD-YYYY"
        )}&callToNotify=${callToNotify}&pageIndex=${!loadMore ? 1 : pageIndexData}&pageSize=${pageSize}&search=${search}&rx=${rx}`
      );
      if (response.data !== undefined) {
        if (response.data.data !== undefined) {
          if (loadMore) {
            setOrders(prevOrders => [...prevOrders, ...response.data.data.data]); // Append new orders
          } else {
            setOrders(response.data.data.data); // Set new orders
          }
          setPageIndex(prevPageIndex => prevPageIndex + 1);
          setHasMore(response.data.data.hasMore);
          setPageSize(response.data.data.pagination.pageSize);
          setTotalPages(response.data.data.pagination.totalPages);
          setTotalItems(response.data.data.pagination.totalItems);
          setLoading(false);
          setLoadingOrder(false)
          isFetching = false;
        } else {
          setLoadingOrder(false)
          setLoading(false);
          isFetching = false;
        }
      } else {
        setLoadingOrder(false)
        setLoading(false);
      }
    } else {
      const response = await getApiWithAuth(
        ALL_ORDERS +
        `?status=${statusId}&pharmacy=${pharmacy}&filter=${filter === "0" ? "" : filter
        }&timer=${timer === "0" ? "" : timer}&zip=${zip}&from=${moment(
          dateRange[0].startDate
        ).format("MM-DD-YYYY")}&to=${moment(dateRange[0].endDate).format(
          "MM-DD-YYYY"
        )}&pageIndex=${!loadMore ? 1 : pageIndexData}&pageSize=${pageSize}&search=${search}&rx=${rx}`
      );
      if (response.data !== undefined) {
        if (response.data.data !== undefined) {
          // setOrders(response.data.data.data);
          if (loadMore) {
            setOrders(prevOrders => [...prevOrders, ...response.data.data.data]); // Append new orders
          } else {
            setOrders(response.data.data.data); // Set new orders
          }
          setPageIndex(prevPageIndex => prevPageIndex + 1);
          setHasMore(response.data.data.hasMore);
          setPageSize(response.data.data.pagination.pageSize);
          setTotalPages(response.data.data.pagination.totalPages);
          setTotalItems(response.data.data.pagination.totalItems);
          setLoadingOrder(false)
          setLoading(false);
        } else {
          setLoadingOrder(false)
          setLoading(false);
        }
      } else {
        setLoadingOrder(false)
        setLoading(false);
      }
      setLoadingOrder(false)
      setLoading(false);
    }
  };

  const getOrdersUrgent = async () => {
    // setLoading(true);
    const response = await getApiWithAuth(
      ALL_ORDERS +
      `?status=1&pharmacy=${pharmacy}&filter=${filter === "0" ? "" : filter
      }&timer=Urgent&zip=${zip}&pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}&rx=${rx}`
    );
    if (response.data !== undefined) {
      if (response.data.data !== undefined) {
        setOrders(response.data.data.data);
        setPageIndex(response.data.data.pagination.nextPageIndex);
        setPageSize(response.data.data.pagination.pageSize);
        setTotalPages(response.data.data.pagination.totalPages);
        setTotalItems(response.data.data.pagination.totalItems);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    setLoading(false);
  };

  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, { 'justList': true });
      if (response.data !== undefined) {
        setUniquePharmacy(response.data.data.map((item) => ({ id: item.id, name: item.name })));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOrderNum = async () => {
    try {
      const response = await getApiWithAuth(CREATE_ORDER + `?from=${moment(
        dateRange[0].startDate
      ).format("MM-DD-YYYY")}&to=${moment(dateRange[0].endDate).format(
        "MM-DD-YYYY"
      )}&callToNotify=${callToNotify}`);
      if (response.data !== undefined) {
        setOrderNum(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBorough = async () => {
    try {
      const response = await getApiWithAuth(ALL_ZONES_LIST + "borros/", { "justList": true });
      if (response.data !== undefined) {
        setBorough(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePharmacyChange = (event, values) => {
    const pharmacyIds = values.map(pharmacy => pharmacy.id);
    setPharmacy(pharmacyIds);
  };

  const handleZipChange = (e, value) => {
    if (value?.length > 0) {
      const selectedZones = value?.map((selectedOption) =>
        zone.find((zone) => zone.id === selectedOption.id)
      );
      const zipcodes = selectedZones.flatMap((selectedZone) =>
        selectedZone ? selectedZone.zipcode.map((item) => item.zipcode) : []
      );
      setZip(zipcodes);
    } else {
      setZip("");
    }
  };

  const handleBoroughChange = async (e, value) => {
    if (value !== null) {
      const selectedBoroughs = value;

      const response = await getApiWithAuth(ALL_ZONES_LIST + `borros/${selectedBoroughs.id}/`);
      if (response.data !== undefined) {
        setZone(response.data.data.zones);
      }

      const zones = response.data.data.zones

      // Use flatMap to extract zipcodes from selected zones within selected boroughs
      const zipcodes = zones
        .filter((zone) => zone.zipcode.length > 0)
        .flatMap((zone) => zone.zipcode.map((zipcode) => zipcode.zipcode));
      setZip(zipcodes);
    } else {
      setZip([]);
      setZone([]);
    }
  };

  const startItem = pageIndex * pageSize + 1;
  const endItem = Math.min(startItem + pageSize - 1, totalItems);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = orderObjRef.current;
      console.log(scrollTop + clientHeight >= scrollHeight - 250)
      if (scrollTop + clientHeight >= scrollHeight - 250 && hasMore) {
        getOrders(status, pageIndex, true);
      }
    };

    const div = orderObjRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, loadingOrder, pageIndex]);

  useEffect(() => {
    if (Urgent === 'true') {
      getOrdersUrgent();
      setSelectedTab('readyToPickup');
    } else if (Status_Id) {
      getOrders(Status_Id);
      setSelectedTab('delivered');
    } else {
      getOrders(status);
    }
  }, [search, rx, pharmacy, zip, dateRange, filter, timer, Urgent, Status_Id, location, callToNotify]);

  useEffect(() => {
    fetchPharmacies();
    // fetchZone();
    fetchBorough();
    fetchOrderNum();
  }, [dateRange, callToNotify]);

  const openCreateRouteModal = () => {
    setModalCreateRouteOpen(true);
  };

  const closeCreateRouteModal = () => {
    setModalCreateRouteOpen(false);
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        setCalendarVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const HandleRouteCreateByZone = async () => {
    if (status === "4") {
      if (orders.length > 0) {
        closeCreateRouteModal();
        setLoading(true);
        const response = await postApiWithAuth(CREATE_ROUTE_ZONE, {});
        if (response.data.status === 201) {
          toast.success(`Route creation process started and Orders set by zone!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRouteCreation(true);
          setTaskId(response.data.data.task_id)
        } else {
          setLoading(false);
          toast.error(`Backend Error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setModalCreateRouteOpen(false);
        }
        setLoading(false);
      } else {
        toast.error(`You don't have any order for route`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    } else {
      toast.error(`please go on dispatching tab first for creating route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const HandleRouteCreate = async () => {
    if (status === "4") {
      if (orders.length > 0) {
        let routePayload = {
          name: "All Orders Route",
          detail_name: "All Route Detail",
          address: address,
          driver_status: "Start",
        };
        closeCreateRouteModal();
        setLoading(true);
        const response = await postApiWithAuth(CREATE_ROUTE, routePayload);
        if (response.data.data != undefined) {
          const routeId = response.data.data.id;
          let routeOrderPayload = {
            route: routeId,
          };

          const routeOrderResponse = await postApiWithAuth(
            CREATE_ROUTE_ORDER,
            routeOrderPayload
          );

          if (routeOrderResponse.data.status === 201) {
            toast.success(`Route Created and Orders Set!`, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setModalCreateRouteOpen(false);
            setLoading(false);
            getOrders("7")
            setStatus("7")
            setSelectedTab('inTransit')
            fetchOrderNum();
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(`Backend Error!`, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            await deleteApiWithAuth(CREATE_ROUTE + `${response.data.data.id}/`)
            setModalCreateRouteOpen(false);
            setLoading(false);
          }
          setLoading(false);
        }
        setLoading(false);
      } else {
        toast.error(`You don't have any order for route`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
    else {
      toast.error(`please go on dispatching tab first for creating route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const HandleRouteCreateByBorough = async () => {
    if (status === "4") {
      if (orders.length > 0) {
        closeCreateRouteModal();
        setLoading(true);
        const response = await postApiWithAuth(CREATE_ROUTE_BOROUGH, {});
        if (response.data.status === 201) {
          setLoading(false);
          toast.success(`Route creation process started and Orders set by cities!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRouteCreation(true);
          setTaskId(response.data.data.task_id)
        } else {
          setLoading(false);
          toast.error(`Backend Error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setModalCreateRouteOpen(false);
          setLoading(false);
        }
        setLoading(false);
      } else {
        toast.error(`You don't have any order for route`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    } else {
      toast.error(`please go on dispatching tab first for creating route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };


  const [filterName, setFilterName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterName(
      typeof value === 'string' ? value.split(',') : value,
    );

    const pharmacyIds = uniquePharmacy
      .filter(pharmacy => value.includes(pharmacy.name))
      .map(pharmacy => pharmacy.id);
    setPharmacy(pharmacyIds);
  };

  return (
    <div className="OrderList">
      {loading && <CustomLoader />}
      <div className="main-tab">
        <div className="tabs-container">
          <button onClick={() => {
            getOrders("all")
            setStatus("all")
            setSelectedTab('allOrders')
            if (Urgent === 'true') {
              navigation(`/list/order`)
            }
            orderObjRef.current.scrollTop = 0;
          }}
            className={`tabs ${selectedTab == 'allOrders' && 'bg-slate-300'}`}>
            All Orders (
            <span>{orderNum !== undefined ? orderNum["all"] : 0}</span>)
          </button>
          <button onClick={() => {
            getOrders("1")
            setStatus("1")
            setSelectedTab('readyToPickup')
            if (Urgent === 'true') {
              navigation(`/list/order`)
            }
            orderObjRef.current.scrollTop = 0;
          }} className={`tabs ${selectedTab == 'readyToPickup' && 'bg-slate-300'}`}>
            Ready for Pickup (
            <span className="readyToPick">
              {orderNum !== undefined ? orderNum["1"] : 0}
            </span>
            )
          </button>
          <button onClick={() => {
            getOrders("2")
            setStatus("2")
            setSelectedTab('pickUpOccured')
            if (Urgent === 'true') {
              navigation(`/list/order`)
            }
            orderObjRef.current.scrollTop = 0;
          }} className={`tabs ${selectedTab == 'pickUpOccured' && 'bg-slate-300'}`}>
            Pickup Occured (
            <span className="readyToPick">
              {orderNum !== undefined ? orderNum["2"] : 0}
            </span>
            )
          </button>
          <button onClick={() => {
            getOrders("3")
            setStatus("3")
            setSelectedTab('receivedAtFacility')
            if (Urgent === 'true') {
              navigation(`/list/order`)
            }
            orderObjRef.current.scrollTop = 0;
          }} className={`tabs ${selectedTab == 'receivedAtFacility' && 'bg-slate-300'}`}>
            Received at Facility (
            <span className="receivedAtFacility">
              {orderNum !== undefined ? orderNum["3"] : 0}
            </span>
            )
          </button>
          <button onClick={() => {
            getOrders("4")
            setStatus("4")
            setSelectedTab('dispatching')
            if (Urgent === 'true') {
              navigation(`/list/order`)
            }
            setLoading(true)
            orderObjRef.current.scrollTop = 0;
          }} className={`tabs ${selectedTab == 'dispatching' && 'bg-slate-300'}`}>
            Dispatching (
            <span className="dispatch">
              {orderNum !== undefined ? orderNum["4"] : 0}
            </span>
            )
          </button>
          <button onClick={() => {
            getOrders("7")
            setStatus("7")
            setSelectedTab('inTransit')
            if (Urgent === 'true') {
              navigation(`/list/order`)
            }
            orderObjRef.current.scrollTop = 0;
          }} className={`tabs ${selectedTab == 'inTransit' && 'bg-slate-300'}`}>
            In Transit (
            <span className="inTransit">
              {orderNum !== undefined ? orderNum["5"] : 0}
            </span>
            )
          </button>
          {userType === "Admin" || userType === "Staff" || userType === "SubAdmin" ? (
            <button
              onClick={() => {
                getOrders("8");
                setStatus("8");
                setSelectedTab('Failed');
                if (Urgent === 'true') {
                  navigation(`/list/order`)
                }
                orderObjRef.current.scrollTop = 0;
              }}
              className={`tabs ${selectedTab === 'Failed' && 'bg-slate-300'}`}
            >
              Failed (
              <span className="inTransit">
                {orderNum !== undefined ? orderNum["8"] : 0}
              </span>
              )
            </button>
          ) : null}
          <button onClick={() => {
            getOrders("5")
            setStatus("5")
            setSelectedTab('delivered')
            if (Urgent === 'true') {
              navigation(`/list/order`)
            }
            orderObjRef.current.scrollTop = 0;
          }} className={`tabs ${selectedTab == 'delivered' && 'bg-slate-300'}`}>
            Delivered (
            <span className="delivered">
              {orderNum !== undefined ? orderNum["6"] : 0}
            </span>
            )
          </button>
          <button onClick={() => {
            getOrders("6")
            setStatus("6")
            setSelectedTab('cancelled')
            if (Urgent === 'true') {
              navigation(`/list/order`)
            }
            orderObjRef.current.scrollTop = 0;
          }} className={`tabs ${selectedTab == 'cancelled' && 'bg-slate-300'}`}>
            Cancelled (
            <span className="cancel">
              {orderNum !== undefined ? orderNum["7"] : 0}
            </span>
            )
          </button>
        </div>
      </div>

      <div className="second-row">
        <div className="filter-container">
          <div className="search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              // onChange={(e) => setSearch(e.target.value)}
              placeholder="Order ID, Name, Address, Phone, Rx"
              className="search-input"
              autoComplete="off"
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className="search-filter-container">
            <FormControl className="filter-input">
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                IconComponent={(props) => <ArrowDropDownCircle {...props} sx={{ color: 'black', width: 20 }} />}
                multiple
                displayEmpty
                sx={{
                  fontSize: '11px',
                  "& .MuiSvgIcon-root": {
                    color: 'black'
                  }
                }}
                MenuProps={{
                  sx: {
                    mt: '8px'
                  }
                }}
                value={filterName}
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p style={{ fontSize: '14px', opacity: 1, fontFamily: "Saira Extra Condensed" }}>Filter Pharmacy</p>;
                  }
                  return <p style={{ fontSize: '14px', opacity: 1, fontFamily: "Saira Extra Condensed" }}>{selected.length > 1 ? `${selected.slice(0, 1)}  ...` : selected}</p>
                }}
              >
                {uniquePharmacy?.map((pharmacy, index) => (
                  <MenuItem
                    key={index}
                    value={pharmacy.name}
                    sx={{
                      fontSize: '13px',
                      fontFamily: "Saira Extra Condensed"
                    }}
                  >
                    <Checkbox size="small" checked={filterName.indexOf(pharmacy.name) > -1} />
                    {pharmacy.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <MUIAutocomplete
              style={{ padding: "0" }}
              id="tags-outlined"
              multiple
              className="filter-input"
              options={uniquePharmacy}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px' }}>
                  {option.name}
                </p>
              )}
              onChange={handlePharmacyChange}
              filterSelectedOptions
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Filter Pharmacy"
                  sx={{
                    "& input::placeholder": {
                      fontSize: "14px",
                      opacity: 1
                    },
                    "& input": {
                      fontSize: '14px !important'
                    }
                  }}
                />
              )}
              sx={{
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              popupIcon={<ArrowDropDownCircle sx={{color: 'black', width: 20}}/>}
            /> */}
          </div>
          <div className="search-filter-container">
            <MUIAutocomplete
              style={{ padding: "0" }}
              disablePortal
              id="combo-box-demo"
              options={borough}
              className="filter-input"
              sx={{
                width: 300,
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="Select Borough" sx={{
                  "& input::placeholder": {
                    fontSize: "14px",
                    opacity: 1,
                    fontFamily: "Saira Extra Condensed !important"

                  },
                  "& input": {
                    fontSize: '14px !important',
                    fontFamily: "Saira Extra Condensed !important"
                  }
                }} />
              )}
              popupIcon={<ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px', fontFamily: "Saira Extra Condensed" }}>
                  {option.name}
                </p>
              )}
              onChange={handleBoroughChange}
              IconComponent={ExpandCircleDownIcon}
            />

            {/* <ExpandCircleDownIcon /> */}
          </div>
          <div className="search-filter-container">
            <MUIAutocomplete
              style={{ padding: "0" }}
              disablePortal
              id="combo-box-demo"
              options={zone}
              className="filter-input"
              sx={{
                width: 300,
                "& .MuiOutlinedInput-root": {
                  p: 0,
                },
                "& .MuiAutocomplete-tag": {
                  bgcolor: "primary.lighter",
                  border: "0px solid",
                  borderColor: "transparent",
                },
              }}
              popupIcon={<ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="Select Zone"
                  sx={{
                    "& input::placeholder": {
                      fontSize: "14px",
                      opacity: 1,
                      fontFamily: "Saira Extra Condensed !important"
                    },
                    "& input": {
                      fontSize: '14px !important',
                      fontFamily: "Saira Extra Condensed !important"
                    }
                  }} />
              )}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <p {...props} style={{ fontSize: '13px', fontFamily: "Saira Extra Condensed" }}>
                  {option.name}
                </p>
              )}
              onChange={handleZipChange}
              IconComponent={ExpandCircleDownIcon}
            />
            {/* <ExpandCircleDownIcon /> */}
          </div>
          {/* <div className='search-filter-container'>
            <input
              type="text"
              id="search"
              placeholder="Filter Driver..."
              className="filter-input"
            />
            <ExpandCircleDownIcon />
          </div> */}
          <div className="search-filter-container">
            <Select
              id="search"
              className="filter-input"
              defaultValue="0"
              onChange={(e) => setFilter(e.target.value)}
              style={{
                width: "300px",
                padding: "0",
                backgroundColor: "primary.lighter", // Adjust the background color as needed
                border: "0px solid",
                borderColor: "transparent",
              }}
              IconComponent={() => <ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
            // IconComponent={() => null}
            >
              <MenuItem className="dropdown-text" value="0">Select Filter</MenuItem>
              <MenuItem className="dropdown-text" value="1">Refrigerator</MenuItem>
              <MenuItem className="dropdown-text" value="2">Photo ID</MenuItem>
              <MenuItem className="dropdown-text" value="3">Copay</MenuItem>
              {/* <MenuItem value="Other">Other</MenuItem> */}
            </Select>
          </div>
          <div className="search-filter-container">
            <Select
              defaultValue="0"
              className="filter-input"
              style={{
                width: "300px",
                padding: "0",
                backgroundColor: "primary.lighter", // Adjust the background color as needed
                // border: "0px solid",
                // borderColor: "transparent",
              }}
              onChange={(e) => setTimer(e.target.value)}
              IconComponent={() => <ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
            >
              <MenuItem className="dropdown-text" value="0" disabled>Time Window</MenuItem>
              <MenuItem className="dropdown-text" value="Anytime">Anytime</MenuItem>
              <MenuItem className="dropdown-text" value="9am-3pm">9am-3pm</MenuItem>
              <MenuItem className="dropdown-text" value="2pm-6pm">2pm-6pm</MenuItem>
              <MenuItem className="dropdown-text" value="5pm-9pm">5pm-9pm</MenuItem>
              <MenuItem className="dropdown-text" value="Urgent">Urgent</MenuItem>
            </Select>
          </div>
          <div className={`search-container ${callToNotify && "filter-color"}`} onClick={() => setCallToNotify(!callToNotify)} style={{ width: '150px', paddingLeft: '5px', marginLeft: '10px', display: 'flex', cursor: 'pointer' }}>
            <a type="text"
              id="search"
              className="search-input">Call to Notify</a>
          </div>
        </div>
        <div className="date-input-container-ol">
          <div type="text" className="date-input-ol">
            <CalendarMonthIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={toggleCalendar}
            />
            {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
            <ForwardIcon style={{ fontSize: "32px" }} />
            {dateRange[0].endDate
              ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
              : ""}
          </div>
          {isCalendarVisible && (
            <div className="calendar-container-ol">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )}
        </div>
      </div>
      <div className="order-obj" ref={orderObjRef}>

        {orders?.length > 0 &&
          orders.map((order) => (
            <OrderObject order={order} getOrders={getOrders} type="admin" statusId={status} />
          ))}
      </div>
      {/* <ChatModal userId={userId} /> */}

      <div className="footer-container">
        <div className="tab-options flex items-center gap-sm">
          <button onClick={handleOpenMenuRecieve} className="tabs-btn">
            Receiving Scan <QrCodeScannerIcon />
          </button>
          <button onClick={handleOpenMenuDispatch} className="tabs-btn">
            Dispatching Scan <QrCodeScannerIcon />
          </button>
          <button className={selectedTab !== "dispatching" ? "tabs-btn disable" : "tabs-btn"} onClick={() => selectedTab === "dispatching" && openCreateRouteModal()}>
            Create Route <DirectionsIcon />
          </button>
        </div>

        <div className="pagination">
          <button className="page-row">Order Count{orders.length > 0 && ' 1'} - {orders.length}</button>
          {/* <button className="page-row">Row per page - {pageSize}</button>
          <div className="page-row">
            {pageIndex > 0 && (
              <ExpandCircleDownIcon
                className="pag-icon-prev"
                onClick={() => setPageIndex(pageIndex - 1)}
              />
            )}
            <p className="text-[18px]">
              Page {startItem} - {endItem}
            </p>
            {pageIndex < totalPages - 1 && (
              <ExpandCircleDownIcon
                className="pag-icon"
                onClick={() => setPageIndex(pageIndex + 1)}
              />
            )}
          </div> */}
        </div>
      </div>

      <Dialog
        // fullWidth
        maxWidth={600}
        open={modalCreateRouteOpen}
        onClose={closeCreateRouteModal}
        className="RouteModalBtns"
      >
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItem: 'center', justifyContent: 'center' }}>
          <div className="action-btns">
            <button
              className="route-btn text-[16px] font-[700]"
              disabled={edit}
              onClick={HandleRouteCreate}
            >
              All In One Route <DirectionsIcon className="btm-icon" />
            </button>
            <button
              className="route-btn text-[16px] font-[700]"
              disabled={edit}
              onClick={HandleRouteCreateByBorough}
            >
              Create According To Cities <DirectionsIcon className="btm-icon" />
            </button>
            <button
              className="route-btn text-[16px] font-[700]"
              disabled={edit}
              onClick={HandleRouteCreateByZone}
            >
              Create According To Zones <DirectionsIcon className="btm-icon" />
            </button>
          </div>
          <div className="action-btns-text">
            <p>Starting Address</p>
            <div style={{ display: 'flex' }}>
              <p style={{ marginRight: '10px', border: '1px solid black', padding: '0 5px', borderRadius: '15px', background: 'white' }}>Make this default</p>
              <input type="checkbox" checked={defaultMark} onChange={(e) => setDefaultMark(e.target.checked)} autoComplete="off" />
            </div>
          </div>
          <div className="action-btns">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <input
                  type="text"
                  className="modal-field"
                  name="name"
                  placeholder="Pilldrop Facility"
                  disabled
                  value="Pilldrop Facility"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={8}>
                {/* <input
                  type="text"
                  className="modal-field"
                  name="name"
                  placeholder="Address"
                  disabled={!edit}
                  value={orderDetail?.id}
                /> */}
                <Autocomplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={handlePlaceSelect}
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value)
                  }}
                  disabled={!edit}
                  style={{ zIndex: 2000 }}
                  // value={orderDetail?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      type="text"
                      name="address"
                      id="address"
                      className="modal-field"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  )}
                  // style={{
                  //   border: '0px',
                  //   width: '100%',
                  //   borderRadius: '5px',
                  //   font: 'inherit',
                  //   letterSpacing: 'inherit',
                  //   color: 'currentColor',
                  //   boxSizing: 'content-box',
                  //   background: 'none',
                  //   height: '1.4375em',
                  //   margin: 0,
                  //   display: 'block',
                  //   minWidth: 0,
                  //   animationName: 'mui-auto-fill-cancel',
                  //   animationDuration: '10ms',
                  //   padding: '10.5px 14px 10.5px 12px'
                  // }}
                  className="order-field"
                  inputProps={{
                    style: {
                      '&:hover': {
                        borderColor: '#69c0ff'
                      },
                      '&:active': {
                        borderColor: '#69c0ff'
                      },
                      '&:focus': {
                        borderColor: '#69c0ff'
                      }
                    }
                  }}
                  options={options}
                />
              </Grid>
              <Grid item xs={1} onClick={handleEdit} style={{ cursor: 'pointer' }}>
                <ModeEditIcon style={{ marginRight: '5px', cursor: 'pointer' }} />
                Edit
              </Grid>
            </Grid>
          </div>
          {edit &&
            <div style={{ display: 'flex', justifyContent: 'end', marginRight: '50px' }}>
              <button onClick={handleSaveEdit} style={{ border: '1px solid black', padding: '0 10px', borderRadius: '15px', background: 'white' }}>Save</button>
            </div>
          }
        </DialogContent>

      </Dialog>

      <Menu
        anchorEl={anchorElRecieve}
        open={Boolean(anchorElRecieve)}
        onClose={handleCloseMenuRecieve}

        anchorOrigin={{
          vertical: "top", // Position of the menu relative to the anchor
          horizontal: "left", // Position of the menu relative to the anchor
        }}
        transformOrigin={{
          vertical: "bottom", // Position of the menu relative to the menu
          horizontal: "left", // Position of the menu relative to the menu
        }}
        PaperComponent={Paper}
      >
        <MenuItem>
          <Grid maxWidth={'sm'} container spacing={2} alignItems="center" bgcolor={'#0B6699'} padding={2}>
            <Grid item xs={12}>
              <h4 style={{ color: "#fff", fontWeight: 700 }}>Receiving Scan</h4>
            </Grid>
            <Grid item xs={2} >
              <p className="input-field-header">Scan #</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Order Id"
                // autoFocus
                disabled
                value={scanReceivingNum}
              />
            </Grid>
            <Grid item xs={2}>
              <p className="input-field-header">Order ID</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Order Slug"
                // autoFocus
                disabled
                value={orderDetail?.slug}
              />
            </Grid>
            <Grid item xs={4}>
              <p className="input-field-header">Client</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Name"
                disabled
                value={orderDetail?.name}
              />
            </Grid>
            <Grid item xs={4}>
              <p className="input-field-header">Pharmacy</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Pharmacy"
                disabled
                value={orderDetail?.pharmacy?.name}
              />
            </Grid>
            <Grid item xs={8}>
              <p className="input-field-header">City/Zone</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Zone / City"
                disabled
                style={{ textTransform: "capitalize", fontWeight: '700' }}
                value={orderDetail?.zone_name + " / " + orderDetail?.city_name}
              />
            </Grid>
            <Grid item xs={4}>
              <p className="input-field-header">Scan</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Scan Recieving Order"
                autoFocus
                ref={inputRef}
                // value={formState.name}
                onChange={handleChangeRecieve}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorElDispatch}
        open={Boolean(anchorElDispatch)}
        onClose={handleCloseMenuDispatch}
        anchorOrigin={{
          vertical: "top", // Position of the menu relative to the anchor
          horizontal: "left", // Position of the menu relative to the anchor
        }}
        transformOrigin={{
          vertical: "bottom", // Position of the menu relative to the menu
          horizontal: "left", // Position of the menu relative to the menu
        }}
        PaperComponent={Paper}
      >
        <MenuItem>
          <Grid maxWidth={'sm'} container spacing={2} alignItems="center" bgcolor={'#0B6699'} padding={2}>
            <Grid item xs={12}>
              <h4 style={{ color: "#fff", fontWeight: 700 }}>Dispatching Scan</h4>
            </Grid>
            <Grid item xs={2}>
              <p className="input-field-header">Scan #</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Order Id"
                // autoFocus
                disabled
                value={scanDispatchingNum}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={2}>
              <p className="input-field-header">Order ID</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Order Slug"
                // autoFocus
                disabled
                value={orderDetail?.slug}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4}>
              <p className="input-field-header">Client</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Name"
                disabled
                value={orderDetail?.name}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4}>
              <p className="input-field-header">Pharmacy</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Pharmacy"
                disabled
                autoComplete="off"
                value={orderDetail?.pharmacy?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <p className="input-field-header">City/Zone</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Zone"
                autoComplete="off"
                disabled
                style={{ textTransform: "capitalize", fontWeight: '700' }}
                value={orderDetail?.zone_name + " / " + orderDetail?.city_name}
              />
            </Grid>
            <Grid item xs={6}>
              <p className="input-field-header">Scan</p>
              <input
                type="text"
                className="modal-field"
                name="name"
                placeholder="Scan Dispatch Order"
                autoFocus
                ref={inputRef2}
                // value={formState.name}
                autoComplete="off"
                onChange={handleChangeDispatch}
              />
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default OrderList;

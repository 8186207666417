import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';

import { 
  ALL_DRIVERS,
  ALL_USERS,
  ALL_PHARMACY_LIST,
  ALL_ZONES_LIST,
  DRIVERS_API,
  facility_data
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth,
} from "../../../utils/api";

import  FacilityObject from '../../MiniComponents/FacilityObject';
import './driverList.css'

function FacilityList() {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [modalCreateDriverOpen, setModalCreateDriverOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      setSearch(inputValue);
      }
    }
  const getUsers = async () => {
    setLoading(true);

    const response = await getApiWithAuth(facility_data + `?page=${1}&search=${search}`);
 
      setDrivers(response.data.data);
    
   
  };

  useEffect(() => {
    getUsers();
  }, [search]);

  const openCreateDriverModal = () => {
    setModalCreateDriverOpen(true);
  };

  const closeCreateDriverModal = () => {
    setModalCreateDriverOpen(false);
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  useEffect(() => {
    const inputElement = document.getElementById('customDatePicker');

    if (inputElement) {
      inputElement.addEventListener('click', () => {
        inputElement.click();
      });
    }
  }, []);
  const handleDelete = () => {
    getUsers();
  };
  return (
    <div >
      <div className="second-row">
        <div className="filter-container">
          <div className="route-search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="slug"
              autoComplete="off"
              onKeyDown={handleSearchKeyDown}
              placeholder="Name, Email, Contact"
              className="search-input"
            />
          </div>
        </div>
        </div>
      <div className='list-head'>
        <h2 className='listHeader'>PillDrop Facility Team</h2>
        <button className='driver-add-btn' onClick={() =>{
           navigate("/add/facility-team")
           localStorage.setItem('driverData', JSON.stringify({}));
           localStorage.setItem('userIdData', JSON.stringify({}));
           }}>Add Facility</button>
      </div>
      <div className='driver-obj'>
        {drivers?.length > 0 &&
          drivers.map((driver) => (
            <FacilityObject driver={driver} onDelete={handleDelete} />
          ))
        }
      </div>
      {/* <div className='bottom-tab'>
       
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div> */}
    </div>
  )
}

export default FacilityList
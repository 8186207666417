import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
// import { makeStyles } from '@mui/styles';
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { Link } from "react-router-dom";
import {
  USER_PERMISSIONS
} from "../../../utils/apiUrls";
import {
  postApiWithAuth,
  getApiWithAuth,
  postFormDataAPI,
  patchApiWithAuth,
} from "../../../utils/api";
import {
  mainlogo,
  minilogo,
  chat,
  customOrder,
  customerService,
  dashboard,
  driver,
  finance,
  homepage,
  logout,
  redlogout,
  pharmacy,
  boroughs,
  route,
  routeStatus,
  action3,
  rxOrder,
  user,
} from "../../../assests";
import "./sidebar.css";
import { useNavigate } from "react-router";
import { removeToken } from "../../../utils/localStorage";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: "84px",
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  backgroundColor: "rgb(4, 85, 16)",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const sidebarItems = [
  {
    name: "Homepage",
    img: homepage,
    path: "homepage",
    group: false,
    visibleTo: [],
  },
  {
    name: "Dashboard",
    img: dashboard,
    path: "dashboard",
    group: false,
    visibleTo: ["Admin", "Staff"],
  },
  {
    name: "Custom Orders",
    img: customOrder,
    path: "list/order",
    group: false,
    visibleTo: [],
  },
  {
    name: "Rx Orders",
    img: rxOrder,
    path: "list/order",
    pharmacyPath: "list/order",
    group: true,
    visibleTo: ["PharmacyOwner", "PharmacyEmployee"],
    subComponents: [
      { name: "Orders List", pharmacyPath: "list/order" },
      { name: "Create Order", pharmacyPath: "add/order" },
    ],
  },
  {
    name: "Pharmacy Team",
    img: user,
    path: "list/employee",
    group: true,
    visibleTo: ["PharmacyOwner","PharmacyEmployee"],
    subComponents: [
      { name: "Team List", pharmacyPath: "list/employee" },
      { name: "Add Employee", pharmacyPath: "add/employee" },
    ],
  },
  {
    name: "Rx Orders",
    img: rxOrder,
    path: "list/order",
    group: false,
    visibleTo: ["Admin", "Staff", "SubAdmin"],
  },
  {
    name: "Pharmacies",
    img: pharmacy,
    path: "list/pharmacy",
    group: true,
    visibleTo: ["Admin", "Staff"],
    subComponents: [
      { name: "Pharmacy List", path: "list/pharmacy" },
      { name: "Create Pharmacy", path: "add/pharmacy" },
    ],
  },
  {
    name: "Facility Users",
    img: user,
    path: "list/facility",
    group: true,
    visibleTo: ["Admin", "Staff"],
    subComponents: [
      { name: "Facility Users List", path: "list/facility" },
      { name: "Create Facility User", path: "add/facility-team" },
    ],
  },
  {
    name: "Drivers",
    img: driver,
    path: "list/driver",
    group: true,
    visibleTo: ["Admin", "Staff"],
    subComponents: [
      { name: "Drivers List", path: "list/driver" },
      { name: "Create Driver", path: "add/driver" },
    ],
  },
  {
    name: "Routes",
    img: route,
    path: "list/route",
    group: true,
    visibleTo: ["Admin", "Staff", "SubAdmin"],
    subComponents: [
      { name: "Routes List", path: "list/route" },
      { name: "Routes Status", path: "list/routes-status" },
    ],
  },
  {
    name: "Cities",
    img: boroughs,
    path: "list/cities",
    group: true,
    visibleTo: ["Admin", "Staff"],
    subComponents: [
      { name: "Routes List", path: "list/route" },
      { name: "Routes Status", path: "list/routes-status" },
    ],
  },
  {
    name: "Reports",
    img: finance,
    path: "pharmacy/report",
    group: true,
    visibleTo: ["Admin", "Staff"],
    subComponents: [
      { name: "Weekly Report", path: "pharmacy/report" },
      { name: "Driver Weekly Report", path: "pharmacy/driverreport" },
      { name: "Pharmacy Daily Orders ", path: "pharmacy/order/count" },
      { name: "Driver Scan", path: "pharmacy/driverscan" },
    ],
  },
  {
    name: "Reports",
    img: finance,
    pharmacyPath: "pharmacy/report",
    group: false,
    visibleTo: ["PharmacyOwner", "PharmacyEmployee"],
  },
  {
    name: "Log Out",
    img: redlogout,
    path: "/",
    group: false,
    visibleTo: ["Admin", "Staff", "PharmacyOwner", "PharmacyEmployee", "SubAdmin"],
    specialAction: "logout", // Use this to identify logout action separately
  },
  // {
  //   name: "Tech Support",
  //   img: customerService,
  //   path: "list/support",
  //   group: false,
  //   visibleTo: ["Admin", "Staff"],
  // },
];

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [open, setOpen] = useState({});
  const [showReport, setShowReport] = useState(false);
  const [showTeam, setShowTeam] = useState(false);
  const [forAdmin, setForAdmin] = useState(false);
  const [showDashboard, setshowDashboard] = useState(false);
  const [showRx, setshowRx] = useState(false);
  const [showFacility, setshowFacility] = useState(false);
  const [showDriver, setshowDriver] = useState(false);
  const [showPharm, setshowPharm] = useState(false);
  const [showRoutes, setshowRoutes] = useState(false);
  const [showCities, setshowCities] = useState(false);
  const [showReports, setshowReports] = useState(false);


  const { pid } = useParams();

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    if (userType === "Admin") {
      setForAdmin(true);
    }
    if (userType === "PharmacyEmployee") {
      const showReportValue = localStorage.getItem("show_report") === 'true';
      const showTeamValue = localStorage.getItem("show_team") === 'true';
      console.log("showrepteam",showReport, showTeam)
      setShowReport(showReportValue);
      setShowTeam(showTeamValue);
    }
    if (userType === "Staff" || userType === "SubAdmin") {
      fetchPermissions();    }
  }, [userType]);
  const fetchPermissions = async () => {
    try {
      const response = await getApiWithAuth(USER_PERMISSIONS);
      const permissions = response.data.data;

      setshowDashboard(permissions.show_dashboard);
      setshowFacility(permissions.show_facuser);
      setshowReports(permissions.show_reports);
      setshowDriver(permissions.show_driver);
      setshowPharm(permissions.show_pharm);
      setshowRx(permissions.show_rx);
      setshowCities(permissions.show_cities);
      setshowRoutes(permissions.show_routes);
    } catch (error) {
      console.error("Failed to fetch permissions", error);
    }
  };


  console.log("forAdmin",forAdmin);

  const navigate = useNavigate();

  const handleLogoutMenu = () => {
    removeToken();
    localStorage.removeItem("userType");
    localStorage.removeItem("route_startaddress");
    localStorage.removeItem("pharmacyId");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("profilePic");
    localStorage.removeItem("deviceId");
    localStorage.removeItem("tokenAdded");
    localStorage.removeItem("show_report");
    localStorage.removeItem("show_team");
    console.log("nodee");
    navigate("/");
  };

  // const sidebarItems = [
  //   {
  //     name: "Homepage",
  //     img: homepage,
  //     path: "homepage",
  //     group: false,
  //     admin: forAdmin,
  //   },
  //   {
  //     name: "Dashboard",
  //     img: dashboard,
  //     path: "dashboard",
  //     group: false,
  //     admin: forAdmin,
  //   },
  //   {
  //     name: "Custom Orders",
  //     img: customOrder,
  //     path: "list/order",
  //     group: false,
  //     admin: forAdmin,
  //   },
  //   {
  //     name: "Rx Orders",
  //     img: rxOrder,
  //     path: "list/order",
  //     group: true,
  //     admin: "pharmacy",
  //     subComponents: [
  //       { name: "Orders List", path: "list/order" },
  //       { name: "Create Order", path: "add/order" },
  //     ]
  //   },
  //   {
  //     name: "Pharmacy Team",
  //     img: user,
  //     path: "list/employee",
  //     group: true,
  //     admin: "pharmacy",
  //     subComponents: [
  //       { name: "Team List", path: "list/employee" },
  //       { name: "Add Employee", path: "add/employee" },
  //     ]
  //   },
  //   {
  //     name: "Rx Orders",
  //     img: rxOrder,
  //     path: "list/order",
  //     group: false,
  //     admin: forAdmin
  //   },
  //   {
  //     name: "Pharmacies",
  //     img: pharmacy,
  //     path: "list/pharmacy",
  //     group: true,
  //     admin: forAdmin,
  //     subComponents: [
  //       { name: "Pharmacy List", path: "list/pharmacy" },
  //       { name: "Create Pharmacy", path: "add/pharmacy" },
  //     ]
  //   },
  //   {
  //     name: "Facility Users",
  //     img: user,
  //     path: "list/facility",
  //     group: true,
  //     admin: forAdmin,
  //     subComponents: [
  //       { name: "Facility Users List", path: "list/facility" },
  //       { name: "Create Facility User", path: "add/facility-team" },
  //     ]
  //   },
  //   {
  //     name: "Drivers",
  //     img: driver,
  //     path: "list/driver",
  //     group: true,
  //     admin: forAdmin,
  //     subComponents: [
  //       { name: "Drivers List", path: "list/driver" },
  //       { name: "Create Driver", path: "add/driver" },
  //     ]
  //   },
  //   {
  //     name: "Routes",
  //     img: route,
  //     path: "list/route",
  //     group: true,
  //     admin: forAdmin,
  //     subComponents: [
  //       { name: "Routes List", path: "list/route" },
  //       { name: "Routes Status", path: "list/routes-status" },
  //     ]
  //   },
  //   // {
  //   //   name: "Routes Status",
  //   //   img: routeStatus,
  //   //   path: "list/routes-status",
  //   //   group: true,
  //   //   admin: forAdmin,
  //   // },
  //   {
  //     name: "Cities",
  //     img: boroughs,
  //     path: "list/cities",
  //     group: true,
  //     admin: forAdmin,
  //     subComponents: [
  //       { name: "Routes List", path: "list/route" },
  //       { name: "Routes Status", path: "list/routes-status" },
  //     ]
  //   },
  //   // { name: 'Users', img: user, path: 'list/users', group: true, admin: true  },

  //   {
  //     name: "Reports",
  //     img: finance,
  //     path: "pharmacy/report",
  //     group: true,
  //     admin: forAdmin,
  //     subComponents: [
  //       { name: "Weekly Report", path: "pharmacy/report" },
  //       { name: "Counts", path: "pharmacy/order/count" },
  //     ]
  //   },
  //   {
  //     name: "Reports",
  //     img: finance,
  //     path: "pharmacy/report",
  //     group: false,
  //     admin: "pharmacy",
  //     // subComponents: [
  //     //   { name: "Weekly Report", path: "pharmacy/report" },
  //     //   { name: "Counts", path: "pharmacy/order/count" },
  //     // ]
  //   },
  //   // {
  //   //   name: "Chats",
  //   //   img: chat,
  //   //   path: "list/chats",
  //   //   group: false,
  //   //   admin: forAdmin,
  //   // },
  //   {
  //     name: "Log Out",
  //     img: redlogout,
  //     path: "/",
  //     group: false,
  //     admin: true,
  //     pharmacyNot: true,
  //     onClick: handleLogoutMenu,
  //   },
  //   {
  //     name: "Tech Support",
  //     img: customerService,
  //     path: "list/support",
  //     group: false,
  //     admin: forAdmin,
  //   },
  // ];

  const handleClick = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  const isItemVisible = (item) => {
    if (userType === "PharmacyEmployee") {
      console.log("show report and team", showTeam, showReport )
      if (item.name === "Reports" && showReport === false) return false;
      if (item.name === "Pharmacy Team" && showTeam === false) return false;
    }
    if (userType === "Staff" || userType === "SubAdmin" ) {
      console.log("show permissions", showDashboard, showCities,showDriver,showPharm, showRoutes, showRx, showFacility,showReports )
      if (item.name === "Dashboard" && showDashboard === false) return false;
      if (item.name === "Rx Orders" && showRx === false) return false;
      if (item.name === "Facility Users" && showFacility === false) return false;
      if (item.name === "Pharmacies" && showPharm === false) return false;
      if (item.name === "Drivers" && showDriver === false) return false;
      if (item.name === "Routes" && showRoutes === false) return false;
      if (item.name === "Cities" && showCities === false) return false;
      if (item.name === "Reports" && showReports === false) return false;
    }
    return item.visibleTo.includes(userType);
  };
  

  const getItemPath = (item) => {
    return userType.includes('Pharmacy') && item.specialAction !== "logout" ? `pharmacy/${pid}/${item.pharmacyPath || item.path}` : item.path;
  };

  return (
    <Drawer
      open={isOpen}
      variant="permanent"
      className="drawer"
      style={{ backgroundColor: "#0B6699" }}
    >
      <DrawerHeader style={{ width: "100%", backgroundColor: "#0B6699" }}>
        {isOpen ? (
          <img
            className="sidebar-main-logo"
            src={mainlogo}
            alt="Logo"
            onClick={toggleSidebar}
          />
        ) : (
          <img
            className="sidebar-mini-logo"
            src={minilogo}
            alt="Logo"
            onClick={toggleSidebar}
          />
        )}
        {/* {isOpen && <IconButton onClick={toggleSidebar}>
                    <ChevronLeftIcon style={{color: 'white'}}/>
                </IconButton>} */}
      </DrawerHeader>
      {/* <Divider className='sidebar-divider' /> */}
      <List
        style={{ backgroundColor: "#0B6699", height: "100%", marginTop: -12 }}
      >
        {sidebarItems.map((item, index) => isItemVisible(item) && (
          <React.Fragment key={index}>
            <ListItem component={Link} to={getItemPath(item)} onClick={item.name === "Log Out" && handleLogoutMenu }>
              <ListItemIcon className="side-list">
                <img src={item.img} alt={item.name} className="sidebar-list-logo" />
              </ListItemIcon>
              {isOpen && <ListItemText primary={item.name} className="side-text bold"/>}
              {isOpen && item.group && (
                <ExpandCircleDownIcon sx={{ width: 18, color: "white" }} onClick={() => setOpen({ ...open, [index]: !open[index] })} />
              )}
            </ListItem>
            {isOpen && item.group && (
              <Collapse in={open[index]} timeout="auto" unmountOnExit>
                <List component="div">
                  {item.subComponents.map((subComponent, subIndex) => (
                    <ListItemButton key={subIndex} sx={{ pl: 6 }}>
                      <Link
                          to={userType.includes('Pharmacy') && subComponent.pharmacyPath ? `pharmacy/${pid}/${subComponent.pharmacyPath}` : subComponent.path}
                          onClick={toggleSidebar}
                          className="nav-link-style"
                        >
                        <ListItemText primary={subComponent.name} sx={{ color: "white" }} />
                      </Link>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
            <Divider />
          </React.Fragment>
        ))}


      </List>
    </Drawer>
  );
};

export default Sidebar;

import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';

import { 
  ALL_DRIVERS_LIST,
  ALL_PHARMACY_LIST,
  ALL_ZONES_LIST,
  DRIVERS_API,
  ROUTE_DETAIL 
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth,
} from "../../../utils/api";

import DriverObject from '../../MiniComponents/DriverObject';
import './driverList.css'

function DriverList() {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      setSearch(inputValue);
      }
    }
  const getDrivers = async () => {
    try {
      setLoading(true);
  
      const response = await getApiWithAuth(ALL_DRIVERS_LIST + `?page=${1}&search=${search}`);
  
      if (response.data !== undefined) {
        const driversData = response.data.data;
        setDrivers(driversData)
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
 
  useEffect(() => {
    getDrivers();
  }, [search]);


  useEffect(() => {
    const inputElement = document.getElementById('customDatePicker');

    if (inputElement) {
      inputElement.addEventListener('click', () => {
        inputElement.click();
      });
    }
  }, []);

  const handleDelete = () => {
    getDrivers();
  };
  
  return (
    <div >
      <div className="second-row">
        <div className="filter-container">
          <div className="route-search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="slug"
              autoComplete="off"
              onKeyDown={handleSearchKeyDown}
              placeholder="Name, Email, Contact"
              className="search-input"
            />
          </div>
        </div>
        </div>
      <div className='list-head'>
        <h2 className='listHeader'>PillDrop Delivery Team</h2>
        <button className='driver-add-btn' onClick={() =>{
           navigate("/add/driver")
           localStorage.setItem('driverData', JSON.stringify({}));
           localStorage.setItem('userIdData', JSON.stringify({}));
           }}>Add Driver</button>
      </div>
      <div className='driver-obj'>
        {drivers?.length > 0 &&
          drivers.map((driver) => (
            <DriverObject driver={driver} onDelete={handleDelete} />
          ))
        }
      </div>
    </div>
  )
}

export default DriverList
// Loader.js
import React from 'react';
import Loader from "react-js-loader";


const CustomLoader = () => {
  return (
    <div className="loader">
      <Loader type="spinner-default" bgColor="black" size={100} />
    </div>
  );
};

export default CustomLoader;

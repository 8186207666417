import React from "react";
import "./routeStatusStops.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";


const RouteStatusStops = ({routes, routeOrders}) => {

  console.log(routes)
  console.log(routeOrders)
  function createData(
    stop,
    orderId,
    name,
    address,
    copay,
    copayStatus,
    pharmacy,
    status,
    statusDetails,
    updatedAt,
    updatedAtTime,
    updatedBy
  ) {
    return {
      stop,
      orderId,
      name,
      address,
      copay: {copay, copayStatus},
      pharmacy,
      status: {status, statusDetails},
      updated: {updatedAt, updatedAtTime},
      updatedBy,
    };
  }


  const totalDone = routes?.reduce((acc, route) => acc + parseInt(route.totalDelivered), 0);
  const totalFailed = routes?.reduce((acc, route) => acc + parseInt(route.totalFailed), 0);
  const totalPending = routes?.reduce((acc, route) => acc + parseInt(route.totalPending), 0);

  const formattedTotalDone = totalDone.toFixed(2);
  const formattedTotalFailed = totalFailed.toFixed(2);
  const formattedTotalPending = totalPending.toFixed(2);

  const totalOrders = formattedTotalDone + formattedTotalFailed + formattedTotalPending;

  // Calculate overall percentages
  const overallDonePercentage = (formattedTotalDone / totalOrders) * 100;
  const overallFailedPercentage = (formattedTotalFailed / totalOrders) * 100;
  const overallPendingPercentage = (formattedTotalPending / totalOrders) * 100;
  
  return (
    <div className="route-stops-container">
      <div className="route-stops-first-tab-container">
        <div className="route-stops-tabs">
          <div className="route-stops-tab">
            <p>ALL/ANY SPECIFIC DRIVER</p>
          </div>
          <div className="route-stops-tab">
            <p>
              TOTAL STOPS <span className="stops-color">{totalOrders}</span>
            </p>
          </div>
          <div className="route-stops-tab">
            <p>
              DONE STOPS <span className="stops-color">{formattedTotalDone}</span>
            </p>
          </div>
          <div className="route-stops-tab">
            <p>
              FAILED STOPS <span className="stops-color">{formattedTotalFailed}</span>
            </p>
          </div>
          <div className="route-stops-tab">
            <p>
              PENDING STOPS <span className="stops-color">{formattedTotalPending}</span>
            </p>
          </div>
        </div>

        {/*        Progress Barr      */}
        <div className="route-status-progress-bar-container">
          <div className="route-status-progress-values">
            <p className="value-text">
              Done <span className="done-value">{formattedTotalDone}</span>
            </p>
            <p className="value-text">
              Failed <span className="failed-value">{formattedTotalFailed}</span>
            </p>
            <p className="value-text">
              Pending <span className="pending-value">{formattedTotalPending}</span>
            </p>
          </div>
          <div className="progress-bars">
            <div className="done-progress" style={{width: `${overallDonePercentage}%`}}>
              {overallDonePercentage}%
            </div>
            <div className="failed-progress" style={{width: `${overallFailedPercentage}%`}}>
              {overallFailedPercentage}%
            </div>
            <div className="pending-progress" style={{width: `${overallPendingPercentage}%`}}>
              {overallPendingPercentage}%
            </div>
          </div>
        </div>
      </div>

      {/*         Table        */}
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="table-heading">STOP #</TableCell>
              <TableCell className="table-heading" align="left">
                Order Id
              </TableCell>
              <TableCell className="table-heading" align="left">
                Name
              </TableCell>
              <TableCell className="table-heading" align="left">
                Address
              </TableCell>
              <TableCell className="table-heading" align="left">
                Copay
              </TableCell>
              <TableCell className="table-heading" align="left">
                Pharmacy
              </TableCell>
              <TableCell className="table-heading" align="left">
                Status
              </TableCell>
              <TableCell className="table-heading" align="left">
                Updated At
              </TableCell>
              <TableCell className="table-heading" align="left">
                Updated By
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {routeOrders?.map((row, index) => (
              <TableRow
                key={index}
                sx={{"&:last-child td, &:last-child th": {border: 0}}}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className="table-row-data"
                >
                  {index+1}
                </TableCell>
                <TableCell align="left" className="table-row-data">
                  {row.order.slug}
                </TableCell>
                <TableCell align="left" className="table-row-data">
                  {row.order.name}
                </TableCell>
                <TableCell align="left" className="table-row-data">
                  {row.order.address}
                </TableCell>

                <TableCell align="left" className="table-row-data">
                  <p>$ {row.order.totalcopay.toFixed(2)}</p>
                  {/* <p>{row.order.copayStatus}</p> */}
                </TableCell>

                <TableCell align="left" className="table-row-data">
                  {row.order.pharmacy_name}
                </TableCell>
                <TableCell align="left" className="table-row-data">
                  <p style={{textTransform: "capitalize"}}>{row.status}</p>
                </TableCell>
                <TableCell align="left" className="table-row-data">
                  <p>{moment(row.updatedAt).format('MM-DD-YYYY')}</p>
                  <p>{moment(row.updatedAt).format('HH:MM A')}</p>
                </TableCell>
                <TableCell align="left" className="table-row-data">
                  {row.route?.driver?.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RouteStatusStops;

import { Button } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import { phone, userBlack, landline, mobile } from "../../../../assests";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import Checkbox from "@mui/material/Checkbox";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

import landline_white from "../../../../assests/landline_white.svg";

export default function OrderCard() {
  return (
    <div className="OrderCard p-md">
      <div className="grid grid-cols-9 gap-4">
        {/* <div className="flex flex-col justify-around gap-md md:col-span-3 lg:col-span-2">
          <div className="flex justify-between">
            <div className="flex items-center gap-sm">
              <img width="20px" height="auto" src={userBlack} alt="user" />
              <p className="text-[20px] font-[700]">SARA, JOSEPH</p>
            </div>
            <p className="text-[20px] font-[700]">Order ID 01-69548</p>
          </div>

          <div className="flex justify-between">
            <div className="flex items-center gap-sm">
              <img width="20px" height="auto" src={phone} alt="phone" />
              <p className="text-md font-[400]">(909) 786-9999</p>
            </div>
            <div className="flex items-center gap-sm">
              <img width="20px" height="auto" src={mobile} alt="mobile" />
              <p className="text-md font-[400]">(909) 786-9999</p>
            </div>
            <div className="flex items-center gap-sm">
              <img width="20px" height="auto" src={landline} alt="landline" />
              <p className="text-md font-[400]">(909) 786-9999</p>
            </div>
          </div>

          <div className="flex justify-between">
            <p>
              24-07 94th street, first floor east elmhurst, Forest Hills,Queens,
              NY 11369
            </p>
          </div>
        </div> */}

        <div className="relative bg-blue flex flex-col rounded-xs p-md md:col-span-4 lg:col-span-3">
          <div className="absolute bg-blue top-[-10px] left-[-22px] rounded-full border border-white text-white w-[30px] h-[30px] flex items-center justify-center">
            <p className="text-[16px] flex items-center justify-center">01</p>
          </div>
          <div>
            <p className="text-white text-[20px] font-[700]">SJS PHARMACY</p>
          </div>
          <div className="flex gap-sm">
            <div className="flex gap-sm">
              <PhoneInTalkIcon style={{ color: "white" }} />
              {/* <img width="20px" height="auto" src={phone} alt="phone" /> */}
              <p className="text-white">(909) 000 9999</p>
            </div>
            <div className="flex gap-sm">
              {/* <PhoneInTalkIcon style={{ color: "white" }} /> */}

              <img
                width="20px"
                height="auto"
                src={landline_white}
                alt="landline"
              />
              <p className="text-white">(909) 000 9999</p>
            </div>
          </div>
          <div className="flex items-center gap-sm">
            <LocalPostOfficeIcon style={{ color: "white" }} />
            <p className="text-white">pharmacy@gmail.com</p>
          </div>
          <div className="flex items-center gap-sm">
            <HomeWorkIcon style={{ color: "white" }} />
            <p className="text-white">
              24-07 94th street, first floor east elmhurst, Forest Hills, NY
              10010
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center md:col-span-2 lg:col-span-1">
          <p className="text-[16px] rounded-sm py-xs px-md text-center">
            SAME DAY <span className="text-blue">2PM TO 6PM</span>
          </p>

          <p className="text-[16px] font-[400] text-center">
            READY FOR PICKUP : <span className="text-blue">0</span>
          </p>
          <p className="text-[16px] font-[400] text-center">PICKED UP : 15</p>
          <p className="text-md">By : Jose Arturo</p>
        </div>

        <div className="flex flex-col items-center md:col-span-2 lg:col-span-1">
          <p className="text-[16px] rounded-sm py-xs px-md text-center">
            SAME DAY <span className="text-blue">2PM TO 6PM</span>
          </p>

          <p className="text-[16px] font-[400] text-center">
            READY FOR PICKUP : <span className="text-blue">0</span>
          </p>
          <p className="text-[16px] font-[400] text-center">PICKED UP : 15</p>
        </div>

        <div className="flex flex-col items-center md:col-span-2 lg:col-span-1">
          <p className="text-[16px] rounded-sm py-xs px-md text-center">
            NEXT DAY ANY TIME
          </p>

          <p className="text-[16px] font-[400] text-center">
            READY FOR PICKUP : <span className="text-blue">55</span>
          </p>
          <p className="text-[16px] font-[400] text-center">PICKED UP : 15</p>
        </div>

        <div className="flex flex-col items-center md:col-span-2 lg:col-span-1">
          <p className="text-[16px] rounded-sm py-xs px-md text-center">
            NEXT DAY <span className="text-[#FE9604]">9AM TO 2PM</span>
          </p>

          <p className="text-[16px] font-[400] text-center">
            READY FOR PICKUP : 0
          </p>
          <p className="text-[16px] font-[400] text-center">PICKED UP : 15</p>
        </div>

        <div className="flex flex-col items-center md:col-span-2 lg:col-span-1">
          <p className="text-[16px] rounded-sm py-xs px-md text-center">
            NEXT DAY <span className="text-[#FE9604]">2PM TO 6PM</span>
          </p>

          <p className="text-[16px] font-[400] text-center">
            READY FOR PICKUP : 0
          </p>
          <p className="text-[16px] font-[400] text-center">PICKED UP : 15</p>
        </div>

        <div className="flex flex-col items-center md:col-span-2 lg:col-span-1">
          <p className="text-[16px] rounded-sm py-xs px-md text-center">
            NEXT DAY <span className="text-[#FE9604]">2PM TO 6PM</span>
          </p>

          <p className="text-[16px] font-[400] text-center">
            READY FOR PICKUP : 0
          </p>
          <p className="text-[16px] font-[400] text-center">PICKED UP : 15</p>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./ChatModal.css"; 
import { mainlogo, minilogo, chat } from "../../assests";
import { GET_USERS_LIST, USER_CHAT_ROOMS } from "../../utils/apiUrls";
import {
  getApiWithAuth,
  postApiWithAuth,
  putApiWithAuth,
} from "../../utils/api";
const UserListModal = ({ isOpen, toggleModal, onChatRoomCreated }) => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [users, setUsers] = useState([]);
  const userId = parseInt(localStorage.getItem("UID"), 10);
  const getusers = async () => {
    const response = await getApiWithAuth(GET_USERS_LIST);
    if (response.data !== undefined && response.data.data.length > 0) {
      if (response.data.data !== undefined) {
        setUsers(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getusers();
  }, []);

  const handleListItemClick = async (user) => {
    try {
      const memberIds = [userId, user.id];
      const response = await postApiWithAuth(USER_CHAT_ROOMS, {
        currentuser: userId,
        otheruser:user.id,
        members: memberIds,
      });
      const roomId = response.data.data.roomId;
      onChatRoomCreated(roomId, userId);
    } catch (error) {
      console.error("Error creating chat room:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="chat-modal-user">
      <div className="chat-modal-content">
        <div className="chat-header custom-font">
          <h2 className="custom-font">New Message</h2>
          <div className="close-icon" onClick={toggleModal}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12,2C6.47,2,2,6.47,2,12s4.47,10,10,10s10-4.47,10-10S17.53,2,12,2z M17,15.59L15.59,17L12,13.41L8.41,17L7,15.59 L10.59,12L7,8.41L8.41,7L12,10.59L15.59,7L17,8.41L13.41,12L17,15.59z"></path>
            </svg>
          </div>
        </div>
        <div className="chat-body">
          {loading ? (
            <p>Loading chat rooms...</p>
          ) : (
            <ul className="chat-room-list">
              {users.map((user) => (
                <li
                  key={user.id}
                  className="chat-room-item"
                  onClick={() => handleListItemClick(user)}
                >
                  <div className="chat-room-avatar">
                    <img
                      src={
                        user.profile_pic ||
                        "https://pilldrop-s3-bucket.s3.amazonaws.com/media/user/profile_pic/user.png"
                      }
                      alt="Avatar"
                    />
                  </div>
                  <div className="chat-room-info custom-font">
                    <h5 className="chat-room-name custom-font">{`${user.first_name} ${user.last_name}`} </h5>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
export default UserListModal;

import React, {useState, useEffect} from 'react'
import { 
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button } from '@mui/material';
import moment from "moment";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from "react-router";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { 
  PHARMACY_REPORT,
  PHARMACY_REPORT_RANGE,
  PHARMACY_REPORT_DETAIL,
  PHARMACY_REPORT_COUNT
 } from "../../../utils/apiUrls";
import { DateRange } from "react-date-range";
import {
  getApiWithAuth, postApiWithAuth, putApiWithAuth,
} from "../../../utils/api";

import PharmacyReportObject from '../../MiniComponents/PharmacyReportObject';
import './PharmPharmacyReport.css';

const isCurrentWeek = (dateRange) => {
  const [startDate, endDate] = dateRange.split(' - ');
  const currentDate = new Date();

  // Parse the date strings into Date objects
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Check if the current date is within the date range
  return currentDate >= startDateObj && currentDate <= endDateObj;
};

function PharmPharmacyReport() {
  const [loading, setLoading] = useState(false);
  const [pharmacyOrderList, setPharmacyOrderList] = useState([]);
  const [duration, setDuration] = React.useState(1);
  const [deliveryTime, setDeliveryTime] = React.useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [deliveryTimeFrom, setDeliveryTimeFrom] = React.useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const today = new Date();
  const endOfWeek = today.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const endDate = new Date(today); // Create a new date object with the current date
  endDate.setDate(today.getDate() - endOfWeek); // Set the date to the last day of the current week
  
  const startDate = new Date(endDate); // Create a new date object for the start date
  startDate.setDate(endDate.getDate() - 27); // Subtract 27 days to get to the start of the previous completed four weeks
  
  const [dateRange, setDateRange] = useState([
    {
      startDate,
      endDate,
      key: "selection",
    },
  ]);
  const filteredPharmacyOrderList = pharmacyOrderList?.filter((pharmacyOrder) => !isCurrentWeek(pharmacyOrder.dateRange));
 console.log("pharmacyOrderList",pharmacyOrderList)
  console.log("filteredPharmacyOrderList",filteredPharmacyOrderList)
  const { pid } = useParams();
  const navigation = useNavigate();
  const handleSelect = (ranges) => {
    if (ranges.selection.endDate) {
      console.log("ranges",ranges)
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };
  const getpharmacyOrderList = async () => {
    setLoading(true);
    // const formData = { from_date: deliveryTime, to_date: deliveryTimeFrom, pharmacy: 1 };
    const formData = {
      pharmacy: pid,
      from: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
      to: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
    };    // const formData = { data: duration }
    const response = await putApiWithAuth(PHARMACY_REPORT_DETAIL, formData);
    console.log(response)
    if (response.data !== undefined) {
      setPharmacyOrderList(response.data)
      // setPageIndex(response.data.data.pagination.pageIndex - 1);
      // setPageSize(response.data.data.pagination.pageSize);
      // setTotalPages(response.data.data.pagination.totalPages);
      // setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  console.log(pharmacyOrderList)

  useEffect(() => {
    getpharmacyOrderList();
  }, [dateRange]);

  useEffect(() => {
    const pharmacyId = localStorage.getItem('pharmacyId')
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigation('/404')
      }
    }
  }, [pid])

  return (
    <div >
        <div className="second-row">
        <div className="filter-container">
          {/* <div className="search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Order ID, Name, Address, Phone, Rx"
              className="search-input"
              autoComplete="off"
              onKeyPress={handleKeyPress}
            />
          </div> */}
        </div>
        <div className="date-input-container">
          <div type="text" className="date-input">
            <CalendarMonthIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={toggleCalendar}
            />
            {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
            <ForwardIcon style={{ fontSize: "32px" }} />
            {dateRange[0].endDate
              ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
              : ""}
          </div>
          {isCalendarVisible && (
            <div className="calendar-container">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )}
        </div>
      </div>
      <div className='list-head'>
        <h2 className='listPharmacyOrderHeader'>WEEKLY REPORTS</h2>
      </div>
      <div class='tab-total'>
        
      </div>
      <div className='user-obj'>
        {filteredPharmacyOrderList?.length > 0 &&
          filteredPharmacyOrderList.reverse().map((pharmacyOrder, i) => (
            <>
              <h1 style={{textAlign: 'center', fontWeight: '700', fontSize: '16px'}}>{pharmacyOrder.dateRange}</h1>
              <PharmacyReportObject pharmacyOrder={pharmacyOrder} deliveryTime={deliveryTime} deliveryTimeFrom={deliveryTimeFrom} />
            </>
          ))
        }
      </div>
      {/* <div className='bottom-tab'>
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div> */}
    </div>
  )
}

export default PharmPharmacyReport



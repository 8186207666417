import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';

import { 
  ALL_PHARMACY_LIST
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth,
} from "../../../utils/api";

import PharmacyObject from '../../MiniComponents/PharmacyObject';
import './pharmacyList.css'

function PharmacyList() {
  const [loading, setLoading] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      setSearch(inputValue);
      }
    }
  const getPharmacies = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_PHARMACY_LIST + `?page=${1}&search=${search}`);
    console.log(response)
    if (response.data !== undefined) {
      setPharmacies(response.data.data)
      // setPageIndex(response.data.data.pagination.pageIndex - 1);
      // setPageSize(response.data.data.pagination.pageSize);
      // setTotalPages(response.data.data.pagination.totalPages);
      // setTotalItems(response.data.data.pagination.totalItems);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPharmacies();
  }, [search]);

  const handleDelete = () => {
    getPharmacies();

  };
  return (
    <div >
       <div className="second-row">
        <div className="filter-container">
          <div className="route-search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="slug"
              autoComplete="off"
              onKeyDown={handleSearchKeyDown}
              placeholder="Name, Email, Contact"
              className="search-input"
            />
          </div>
        </div>
        </div>
      <div className='list-head'>
        <h2 className='listHeader'>Pharmacies</h2>
        <button className='pharmacy-add-btn' onClick={() =>{ 
          navigate("/add/pharmacy")
          localStorage.setItem('pharmacyData', JSON.stringify({}));
          localStorage.setItem('userData', JSON.stringify([]));
          }}>Add Pharmacy</button>
      </div>
      <div className='driver-obj'>
        {pharmacies?.length > 0 &&
          pharmacies.map((pharmacy) => (
            <PharmacyObject pharmacy={pharmacy} onDelete={handleDelete}/>
          ))
        }
      </div>
      {/* <div className='bottom-tab'>
       
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div> */}
    </div>
  )
}

export default PharmacyList
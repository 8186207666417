import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from 'react-google-autocomplete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PrintIcon from '@mui/icons-material/Print';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import {
  Avatar,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Box
} from "@material-ui/core";
import { BiCheckDouble } from "react-icons/bi";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Printdoc from '../../CommonComponents/PrintDoc/PrintDoc';
import PrintDocDown from '../../CommonComponents/PrintDoc/PrintDocDown';
import jsPDF from 'jspdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
// import { pdfjs } from 'react-pdf';
import PDFfile from '../../../../src/assests/samplepdf.pdf'

import axios from "axios";
import moment from "moment";

import { 
    CREATE_ORDER,
    ALL_PHARMACY_LIST
 } from "../../../utils/apiUrls";
import {
  putApiWithAuth,
  getApiWithAuth,
  patchApiWithAuth
} from "../../../utils/api";

import {
  landline,
  iphone,
  route_report,
  pharmacyCallBlack,
  buildings
} from '../../../assests';

import "./RescheduleOrder.css";
import { Checkbox } from "@mui/material";
import ShowPDF from "../../CommonComponents/ShowPDF/ShowPDF";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  chatContainer: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    background: "#E2E2E2",
    display: "flex",
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
}));

const GOOGLE_MAPS_API_KEY = 'AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ';

function RescheduleOrder() {

  const { pid, oid } = useParams();
  const navigate = useNavigate()
  const classes = useStyles();

  const createDate = new Date();

  const [orderDetail, setOrderDetail] = useState({
    createdAt: '',
    deliveryDate: new Date(),
    timeWindow: 'Anytime',
    name: '',
    language: 'english',
    address: '',
    apart: '',
    phoneNumber: '',
    cellNumber: '',
    workNumber: '',
    zip: '',
    note: '',
    location_image: "",
    totalCopay: 0,
    signRequired: false,
    reqPhotoId: false,
    registed: false,
    collectCopay: false,
    hippaForm: false,
    lineForm: false,
    nfForm: false,
    bussAddress: false,
    parcelPlace: 'Face to face',
    lat: '',
    lng: '',
    deliverAt: "",
    requestCouncelling: false,
    status: "",
    driver: "",
    signature_img: "",
    active: false,
    delivery_imgs: [],
    orderticket: {},
    pharmacyId: "",
    order_file: "",
    rxList: [{
      id: '',
      copay: '',
      rxnumber: '',
      rfnumber: '',
      quantity: '',
      date: '',
      masterid: '',
      select: false,
    }]
  });
  const [selectedOrderFile, setSelectedOrderFile] = useState(null);
  const [streetViewUrl, setStreetViewUrl] = useState('');
  const [mapError, setMapError] = useState(false);
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const [activeTab, setActiveTab] = useState('Upload slip');
  const [modalCancelNoteOpen, setModalCancelNoteOpen] = useState(false);
  const [address, setAddress] = useState('');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleCreateCancelNote = () => {
    setModalCancelNoteOpen(true);
  };

  const handleModalCancelNoteClose = () => {
    setModalCancelNoteOpen(false);
  };

  useEffect(() => {
    getDetailOrder(oid)
  }, [oid]);

  const getPharmacyDetail = async(id) => {
    let response
    if (pid !== undefined) {
      response = await getApiWithAuth(ALL_PHARMACY_LIST+`/${pid}`);
    } else {
      response = await getApiWithAuth(ALL_PHARMACY_LIST+`/${id}`);
    }
    if (response.data !== undefined) {
      setPharmacyDetail(response.data.data)
    } else {
      console.log(response.status)
    }
  };

  const options = {
    types: ['address'],
    componentRestrictions: { country: 'us' },
    bounds: {
      north: 42.0505,  // Northernmost point roughly in CT
      south: 38.9285,  // Southernmost point roughly in NJ
      west: -79.7624,  // Westernmost point roughly in NY
      east: -71.1851   // Easternmost point roughly in CT
    },
    strictBounds: true
  };

  const handlePlaceSelect = (place) => {
    setOrderDetail({
      ...orderDetail,
      address: place.formatted_address
    });
    setAddress(place.formatted_address)
  };

  const getDetailOrder = async (id) => {
    try
    {
      const response = await getApiWithAuth(CREATE_ORDER+`/${oid}`);
      // dispatch(getPdfData(formData));
      console.log(response.data.data);
      setOrderDetail({
        name: response.data.data?.name || '',
        address: response.data.data?.address || '',
        phoneNumber: response.data.data?.contact || '',
        cellNumber: response.data.data?.cell || '',
        workNumber: response.data.data?.workNumber || '',
        zip: response.data.data?.zip || '',
        timeWindow: response.data.data?.deliverydate,
        parcelPlace: response.data.data?.parcelPlace,
        signRequired: response.data.data?.signature,
        reqPhotoId: response.data.data?.photoid,
        registed: response.data.data?.refrigrated,
        collectCopay: response.data.data?.collectcopay,
        hippaForm: response.data.data?.hippaform,
        lineForm: response.data.data?.lineform,
        nfForm: response.data.data?.nfaqbform,
        bussAddress: response.data.data?.addresstype,
        deliveryDate: response.data.data?.deliverAt,
        createdAt: response.data.data?.createdAt,
        totalCopay: response.data.data?.totalcopay,
        language: response.data.data?.language,
        apart: response.data.data?.apart,
        note: response.data.data?.notes,
        lat: response.data.data?.latitude,
        lng: response.data.data?.longitude,
        lng: response.data.data?.longitude,
        status: response.data.data?.status,
        location_image: response.data.data?.location_image,
        requestCouncelling: response.data.data?.requestCouncelling,
        signature_img: response.data.data?.ordermaster_route4me !== null ? response.data.data?.ordermaster_route4me[response.data.data?.ordermaster_route4me?.length - 1]?.signature_image: undefined,
        delivery_imgs: response.data.data?.ordermaster_delivery,
        orderticket: response.data.data?.orderticket !== null ? response.data.data?.orderticket[response.data.data?.orderticket?.length - 1] : undefined,
        pharmacyId: response.data.data?.pharmacy?.id,
        order_file: response.data.data?.order_file,
        driver: response.data.data?.driver?.name,
        deliverAt: response.data.data?.deliverAt,
        active: response.data.data?.isActive,
        rxList: response.data.data?.orderdetail || [] // Store 'text' data in rxList
      });
      setAddress(response.data.data?.address || '');
      getPharmacyDetail(response.data.data?.pharmacy?.id);
    } catch (error) 
    {
      console.log(error)
    }
  }

  const handleOrderChange = (event, index) => {
    const { name, value, type, checked } = event.target;
    console.log(value,type, name)

    let updatedValue = value;
    

    // If the input type is checkbox, update accordingly
    if (type === 'checkbox') {
      updatedValue = checked;
    }

    if (name.includes('.')) {
      const [list, rxIndex, fieldName] = name.split('.');
      console.log(list, rxIndex, fieldName)
      const updatedRxList = [...orderDetail.rxList];
      updatedRxList[index][fieldName] = updatedValue;

      let totalCopay = 0;

      orderDetail.rxList.forEach((rx) => {
        if(rx.select){
          totalCopay += parseFloat(rx.copay);
        }
      });

      setOrderDetail({
        ...orderDetail,
        rxList: updatedRxList,
        totalCopay: totalCopay,
      });
    } else if (name === 'collectCopay'){
      
      const updatedRxList = orderDetail.rxList.map((rx) => ({
        ...rx,
        select: checked,
      }));

      let totalCopay = 0;

      if (checked) {
        orderDetail.rxList.forEach((rx) => {
          totalCopay += parseFloat(rx.copay);
        });
      }

      setOrderDetail({
        ...orderDetail,
        [name]: updatedValue,
        rxList: updatedRxList,
        totalCopay: totalCopay,
      });
    } else {
      // It's a top-level field in orderDetail
      setOrderDetail({
        ...orderDetail,
        [name]: updatedValue,
      });
    }

  };

  const handleDeliveryDateChange = (date) => {
    // Convert the date to the desired format (MM/DD/YYYY)
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    // Update the orderDetail state with the formatted date
    setOrderDetail({
      ...orderDetail,
      deliveryDate: formattedDate,
    });
  };

  const handleApartmentChange = (event) => {
    const apartmentNumber = event.target.value;
    // const lastCommaIndex = orderDetail.address.lastIndexOf(',');
    // console.log(orderDetail.address)
    // const firstPart = orderDetail.address.substring(0, lastCommaIndex).trim();
    // const secondPart = orderDetail.address.substring(lastCommaIndex + 1).trim();
    // console.log(firstPart, secondPart)

    // let updatedAddress;
    // if (add1 !== '' || add2 !== '') {
    //   console.log('workkff')
    //   if (apartmentNumber === '') {
    //     updatedAddress = `${add1}, ${add2}`;
    //   } else {
    //     updatedAddress = `${add1} ${apartmentNumber}, ${add2}`;
    //   }
    // } else {
    //   updatedAddress = `${firstPart} ${apartmentNumber}, ${secondPart}`;
    //   setAdd1(firstPart);
    //   setAdd2(secondPart);
    // }
    // console.log(updatedAddress);
    // Update the apartment number in the resulting array and join it back into a string with the comma separator
    setOrderDetail({
      ...orderDetail,
      // address: updatedAddress,
      apart: apartmentNumber
    });
  };



  // useEffect(() => {
  //   const streetViewUrlApi = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${orderDetail.lat},${orderDetail.lng}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
  //   setStreetViewUrl(streetViewUrlApi);
  // }, [orderDetail.address, orderDetail.lat, orderDetail.lng]);

  // useEffect(() => {
  //   if (orderDetail.address !== '') {
  //     const fetchLocation = async () => {
  //       try {
  //         const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(orderDetail.address)}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
  //         const response = await axios.get(geocodingUrl);
  //         const { results } = response.data;
  //         if (results.length > 0) {
  //           const { lat, lng } = results[0].geometry.location;
  //           setOrderDetail({
  //             ...orderDetail, // Spread the existing state
  //             lat: lat, // Update the lat property with the new value
  //             lng: lng, // Update the lng property with the new value
  //           });
  //           setMapError(false);
  //         } else {
  //           setMapError(true);
  //           setOrderDetail({
  //             ...orderDetail, // Spread the existing state
  //             lat: "", // Update the lat property with the new value
  //             lng: "", // Update the lng property with the new value
  //           });
  //         }
  //       } catch (error) {
  //         console.log("Google map give error yet please try again later!")
  //       }
  //     };

  //     fetchLocation();
  //   }
  // }, [orderDetail.address, orderDetail.lat, orderDetail.lng]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Order Details:', orderDetail);

    const formData = new FormData();

    // Add orderDetail data to formData order_file_name
    formData.append('deliverydate', orderDetail.timeWindow);
    formData.append('order_file_name', ""); 
    formData.append('language', orderDetail.language);
    formData.append('apart', orderDetail.apart);
    formData.append('deliveryTime', moment(orderDetail.deliveryDate).format('MM/DD/YYYY'));
    formData.append('readyPickAt', moment(createDate).format('MM/DD/YYYY'));
    formData.append('name', orderDetail.name);
    formData.append('address', orderDetail.address);
    formData.append('contact', orderDetail.phoneNumber);
    formData.append('cell', orderDetail.cellNumber);
    formData.append('workNumber', orderDetail.workNumber);
    formData.append('zip', orderDetail.zip);
    formData.append('notes', orderDetail.note);
    formData.append('status', "3");
    formData.append('location_image', orderDetail.location_image);
    formData.append('signature', orderDetail.signRequired);
    formData.append('photoid', orderDetail.reqPhotoId);
    formData.append('refrigrated', orderDetail.registed);
    formData.append('collectcopay', orderDetail.collectCopay);
    formData.append('hippaform', orderDetail.hippaForm);
    formData.append('lineform', orderDetail.lineForm);
    formData.append('nfaqbform', orderDetail.nfForm);
    formData.append('totalcopay', orderDetail.totalCopay);
    formData.append('addresstype', orderDetail.bussAddress);
    formData.append('parcelPlace', orderDetail.parcelPlace);
    formData.append('lat', orderDetail.lat);
    formData.append('lng', orderDetail.lng);
    formData.append('active', orderDetail.active);
    // Add selectedOrderFile to formData
    formData.append('order_file', selectedOrderFile);

    formData.append('products', JSON.stringify(orderDetail.rxList));

    const responsePersonal = await putApiWithAuth(CREATE_ORDER + `/${oid}`, formData);

    if (responsePersonal.data !== undefined) {
      pid !== undefined ? navigate(`/pharmacy/${pid}/list/order`) : navigate("/list/order")
    }

  };

  const componentRef2 = useRef();

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  return (
    <div className="addOrderMain">
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div className="addOrder-top">
              <div className="topFields">
                <p className="topFieldLabel">Created Date</p>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-top-field"
                  name="name"
                  placeholder="Created Date"
                  value={moment(orderDetail.createdAt).format('MM-DD-YYYY')}
                  readOnly
                />
              </div>
              <div className="topFields">
                <p className="topFieldLabel">Delivery Date</p>
                {/* <input
                  autoComplete="off"
                  type="date"
                  className="order-top-field"
                  name="deliveryDate"
                  placeholder="Delivery Date"
                  value={orderDetail.deliveryDate}
                  onChange={handleOrderChange}
                /> */}
                <DatePicker
                  className="order-top-field"
                  selected={new Date(orderDetail.deliveryDate)}
                  onChange={handleDeliveryDateChange}
                  dateFormat="MM/dd/yyyy"
                />
              </div>
              <div className="topFields">
                <p className="topFieldLabel">Time Window</p>
                <select name="timeWindow" className="order-top-field" id="timeWindow" value={orderDetail.timeWindow} onChange={handleOrderChange}>
                  <option value="Anytime">Anytime</option>
                  <option value="9am-3pm">9am-3pm</option>
                  <option value="2pm-6pm">2pm-6pm</option>
                  <option value="5pm-9pm">5pm-9pm</option>
                  <option value="Urgent">Urgent</option>
                </select>
              </div>
              {/* <div className="topFields">
                <select className="order-top-field" name="language" id="language" value={orderDetail.language}>
                  <option value="">
                    <em>Select Language</em>
                  </option>
                  <option value="english">English</option>
                  <option value="french">French</option>
                  <option value="spanish">Spanish</option>
                </select>
              </div> */}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="orderBasicDiv">
              <div className="orderBasicInfo">
                <h2>To</h2>
                <div className="orderMainDiv">
                  <i className="fa fa-user detail-in-icon" />
                  <h3 className="orderMainText">{orderDetail.name}</h3>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={buildings} alt="Logo" />
                  <p className="orderMainText">{orderDetail.address}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={pharmacyCallBlack} alt="Logo" />
                  <p className="orderMainText">{orderDetail.phoneNumber}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={iphone} alt="Logo" />
                  <p className="orderMainText">{orderDetail.cellNumber}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={landline} alt="Logo" />
                  <p className="orderMainText">{orderDetail.workNumber}</p>
                </div>
              </div>
              <div className="locImgDiv">
                <h1>Location View</h1>
                  {orderDetail.lat && orderDetail.lng && (
                    <img
                      src={orderDetail.location_image}
                      alt="Google Maps Street View"
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                  )}
                {/* <img className="locatioImg" src={locationImg} alt="location img" /> */}
              </div>
            </div>
            
            <Grid container className="order-form-grid" spacing={2}>
              {/* First Row */}
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Name</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="name"
                  placeholder="Name"
                  value={orderDetail.name}
                 
                />
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Phone Number</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={orderDetail.phoneNumber}
                 
                />
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Cell Number</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="cellNumber"
                  placeholder="Cell Number"
                  value={orderDetail.cellNumber}
                 
                />
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Work Number</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="workNumber"
                  placeholder="Work Number"
                  value={orderDetail.workNumber}
                 
                />
              </Grid>

              {/* Second Row */}
              <Grid item className="order-grid" xs={6}>
                <label className="order-field-label">Address</label>
                {/* <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="address"
                  placeholder="Address"
                  value={orderDetail.address}
                 
                /> */}
                <Autocomplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={handlePlaceSelect}
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      type="text"
                      name="address"
                      id="address"
                      className="order-field"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value)
                      }}
                    />
                  )}
                  // style={{
                  //   border: '0px',
                  //   width: '100%',
                  //   borderRadius: '5px',
                  //   font: 'inherit',
                  //   letterSpacing: 'inherit',
                  //   color: 'currentColor',
                  //   boxSizing: 'content-box',
                  //   background: 'none',
                  //   height: '1.4375em',
                  //   margin: 0,
                  //   display: 'block',
                  //   minWidth: 0,
                  //   animationName: 'mui-auto-fill-cancel',
                  //   animationDuration: '10ms',
                  //   padding: '10.5px 14px 10.5px 12px'
                  // }}
                  className="order-field"
                  inputProps={{
                    style: {
                      '&:hover': {
                        borderColor: '#69c0ff'
                      },
                      '&:active': {
                        borderColor: '#69c0ff'
                      },
                      '&:focus': {
                        borderColor: '#69c0ff'
                      }
                    }
                  }}
                  options={options}
                />
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Zip Code</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="zip"
                  placeholder="Zip Code"
                  value={orderDetail.zip}
                  disabled
                />
                {orderDetail.zip === "" && <p style={{color: "red"}}>ZipCode is mandatory!</p>}
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Apt Number</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="apart"
                  placeholder="Apt Number"
                  value={orderDetail.apart}
                  onChange={(e) => handleApartmentChange(e)}
                />
              </Grid>
            </Grid>


            <div className="textLine">
              <p className="textLine-p"><span style={{fontWeight: '700'}}>Please Confirm</span> with the patient for apartment number if they don’t live in a private house </p>
              <div className="textCheckDiv">
                <p>Business Address</p>
                <Checkbox name="bussAddress" checked={orderDetail.bussAddress} />
              </div>
            </div>

            <div className="rxDetail">
              <h2>Details</h2>
              <Grid container className="order-form-grid" spacing={2}>
                {/* First Row */}
                <Grid item className="order-grid" xs={1.5}>
                  <h3>Count</h3>
                </Grid>
                <Grid item className="order-grid" xs={4}>
                  <h3>RX Number</h3>
                </Grid>
                <Grid item className="order-grid" xs={3}>
                  <h3>Date</h3>
                </Grid>
                <Grid item className="order-grid" xs={3}>
                  <h3>Copay</h3>
                </Grid>
              </Grid>
              {orderDetail.rxList.map((rx, i) => (
                <Grid container className="order-form-grid" spacing={2}>
                  {/* First Row */}
                  <Grid item className="order-grid" xs={1.5}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="i"
                      style={{textAlign: 'center'}}
                      placeholder="Count"
                      value={i + 1}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-grid" xs={4}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.rxnumber`}
                      placeholder="RX Number"
                      value={rx.rxnumber}
                      
                    />
                  </Grid>
                  <Grid item className="order-grid" xs={3}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.date`}
                      placeholder="Date Filled"
                      value={rx.date}
                      
                    />
                  </Grid>
                  <Grid item className="order-grid" style={{display: 'flex', alignItems: 'center'}}  xs={3}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.copay`}
                      placeholder="Copay"
                      value={rx.copay}
                      
                    />
                    {/* <Checkbox name={`rxList.${i}.select`} checked={rx.select}  /> */}
                  </Grid>
                  {/* <Grid item style={{textAlign: 'center'}}  className="order-grid" xs={2}>
                    <DeleteIcon style={{color: '#000000', textAlign: 'center', cursor: 'pointer'}}/>
                  </Grid> */}
                </Grid>
              ))}
              {/* <div className="addRx">
                <AddBoxIcon />
                Add Item
              </div> */}
            </div>

            <Grid container className="order-form-grid" spacing={2}>
              <Grid item className="order-grid" xs={6}>
                <h2>Delivery Note</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid" xs={12}>
                    <textarea
                      rows="9" 
                      className="order-textarea-field"
                      name="note"
                      placeholder="Write any indications / missing information for delivery driver and facility"
                      value={orderDetail.note}
                     
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <h2>Actions</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600}} className="order-field">Signature Required</p>
                    <Checkbox style={{padding: '0px'}} name="signRequired" checked={orderDetail.signRequired} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#FF950F", color: "#FF950F", fontSize: "16px", fontWeight: 600}} className="order-field">Required Photo ID</p>
                    <Checkbox style={{padding: '0px'}} name="reqPhotoId" checked={orderDetail.reqPhotoId} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#0094FF", color: "#0094FF", fontSize: "16px", fontWeight: 600}} className="order-field">Refrigerated</p>
                    <Checkbox style={{padding: '0px'}} name="registed" checked={orderDetail.registed} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#05B321", color: "#05B321", fontSize: "16px", fontWeight: 600}} className="order-field">Copay ${orderDetail.totalCopay.toFixed(2)}</p>
                    <Checkbox style={{padding: '0px'}} name="collectCopay" checked={orderDetail.collectCopay} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <select name="parcelPlace" id="parcelPlace" style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600, backgroundColor: "#fff"}} className="order-field" value={orderDetail.parcelPlace}>
                      <option value="">
                        <em>Select Parcel Place</em>
                      </option>
                      <option value="Face to face">FACE TO FACE</option>
                      <option value="Leave with receptionist">LEAVE WITH RECEPTIONIST</option>
                      <option value="Leave at door if pt e-signs">LEAVE AT DOOR IF PT E-SIGNS</option>
                      <option value="Leave at door">LEAVE AT DOOR</option>
                      <option value="Leave in the mailbox">LEAVE IN THE MAILBOX</option>
                      <option value="Leave in mail box if pt e-signs">LEAVE IN MAIL BOX IF PT E-SIGNS</option>
                    </select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <h2>Get Forms Signed</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600}} className="order-field">Hipaa Form</p>
                    <Checkbox style={{padding: '0px'}} name="hippaForm" checked={orderDetail.hippaForm} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600}} className="order-field">Lien Form</p>
                    <Checkbox style={{padding: '0px'}} name="lineForm" checked={orderDetail.lineForm} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600}} className="order-field">NF AOB Form</p>
                    <Checkbox style={{padding: '0px'}} name="nfForm" checked={orderDetail.nfForm} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <div className="order-btn">
              {/* <button type="button" className="del-order" onClick={handleCreateCancelNote}>Cancel Order</button>
              <button type="button" className="del-order" onClick={handleDeleteOrder}>Delete Order</button> */}
              <button type="button" className="del-order" onClick={() => pid !== undefined ? navigate(`/pharmacy/${pid}/list/order`) : navigate("/list/order")}>Back</button>
              <button type="submit" className="save-order">Reschedule<PrintIcon style={{color: '#0455A6', marginLeft: '10px'}} /></button>
            </div>
          </form>
        </Grid>
        <Grid item xs={5}>
          <div className="actiontabsmain">
            <div className="actiontabs">
              <p
                className={`actiontabButtons ${activeTab === 'Upload slip' ? 'activetab' : ''}`}
                onClick={() => handleTabClick('Upload slip')}
              >
                Upload slip
              </p>
              <p
                className={`actiontabButtons ${activeTab === 'Signed slip' ? 'activetab' : ''}`}
                onClick={() => handleTabClick('Signed slip')}
              >
                Signed slip
              </p>
              <p className={orderDetail.status === "5" ? `actiontabButtons ${activeTab === 'Delivery Proof' ? 'activetab' : ''}` : "actiontabButtons disable"}
                onClick={() => {orderDetail.status === "5" && handleTabClick('Delivery Proof')}}>Delivery Proof</p>
              <p className={orderDetail.orderticket !== undefined ? `actiontabButtons ${activeTab === 'Conversation' ? 'activetab' : ''}` : "actiontabButtons disable"}
               onClick={() => {orderDetail.orderticket !== undefined && handleTabClick('Conversation')}}>Conversation</p>
            </div>
            
            {/* <PDFDownloadLink document={<PrintDocDown orderDetail={orderDetail} edit={true} view={false} pharmacy={pharmacyDetail} />} fileName="client-details.pdf">
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : error ? error : <img src={route_report} alt="print" />)}
            </PDFDownloadLink> */}
            <img src={route_report} onClick={() => handlePrintSlip()} alt="print" />
          </div>
          {activeTab === 'Upload slip' && (
            <div style={{ display: 'contents' }}>
              {/* <PDFViewer style={{ width: '100%', height: '100%' }}> */}
              {/* <Printdoc orderDetail={orderDetail} edit={true} upload={true} view={false} pharmacy={pharmacyDetail} /> */}
              {/* </PDFViewer> */}
              {/* <ShowPDF /> */}
              {/* <iframe src={orderDetail.order_file} width={'100%'} height={'100%'} style={{marginTop: 10}}/> */}
              {/* <ShowPDF pdf={orderDetail.order_file} /> */}
              <iframe src={`${orderDetail.order_file}#toolbar=0&navpanes=0&scrollbar=0`} width='100%' height='100%' style={{marginTop: 10}} />
            </div>
          )}

          {activeTab === 'Signed slip' && (
            <div style={{ display: 'contents' }}>
              <Printdoc orderDetail={orderDetail} edit={true} upload={false} view={false} pharmacy={pharmacyDetail} />
            </div>
          )}

          {activeTab === 'Delivery Proof' && (
            orderDetail.delivery_imgs.map((delivery, i) => (
              <>
                <h3>{i+1}</h3>
                <img style={{height: "auto", marginBottom: "10px"}} src={delivery.delivery_proof_image} alt={`delivery_img + ${i}`} />
              </>
            ))
          )}

          {activeTab === 'Conversation' && (
            <div className={`classes.root col-span-8`}>
              <div className="flex flex-col gap-md items-end w-full p-md">
                <div>
                  <div
                    className="flex items-center gap-sm"
                  >
                    {orderDetail.orderticket !== undefined && orderDetail.orderticket.ticket_status === "Completed" ? (
                      <p className="text-[16px] font-[700] text-center" style={{color: 'red'}}>
                        Ticket Closed
                      </p>
                    ) : (
                      <>
                        {/* <button onClick={() => handleCloseTicket(ticketId)} className="text-[16px] font-[700] text-center">
                          Issue Resolved <br /> Close Ticket
                        </button>
                        <CloseRounded className="cursor-pointer" /> */}
                        <p className="text-[16px] font-[700] text-center" style={{color: 'red'}}>
                          Ticket Pending
                        </p>
                      </>
                    )}
                    
                    {/* <img width="20px" height="auto" src={closeIconRed} alt="phone" /> */}
                  </div>
                </div>
      
                <div className="w-full flex flex-col justify-between max-h-screen min-h-[80vh] height-auto">
                  <Paper elevation={0} className={classes.chatContainer}>
                    {orderDetail.orderticket !== undefined && orderDetail.orderticket.ticketcomment_set !== undefined && orderDetail.orderticket.ticketcomment_set.length > 0 && orderDetail.orderticket.ticketcomment_set.map((message, index) => (
                      <div
                        key={index}
                        className={
                          message.sender === "user"
                            ? `classes.message flex gap-sm mb-sm`
                            : `classes.message flex gap-sm mb-sm flex-row-reverse`
                        }
                      >
                        <Avatar className={classes.avatar}>
                          {message.sender === "user" ? "U" : "R"}
                        </Avatar>
      
                        <div className={"flex flex-col w-full"}>
                          <div
                            className={
                              message.sender === "user"
                                ? "flex items-center justify-between"
                                : "flex items-center justify-between flex-row-reverse"
                            }
                          >
                            <p
                              className={
                                message.sender === "user"
                                  ? "text-blue text-[20px] font-[700] text"
                                  : "text-text text-[20px] font-[700] text-end"
                              }
                            >
                              {message.comment_by.first_name} {message.comment_by.last_name}
                            </p>
                            <p
                              className={
                                message.sender === "user"
                                  ? "flex gap-sm text-blue text"
                                  : "flex gap-sm flex-row-reverse text-text text-end"
                              }
                            >
                              Read <BiCheckDouble color="#2757FF" /> {moment(message.created_at).format('MM-DD-YYYY')} | {moment(message.created_at).format('hh:mm:ss a')}
                            </p>
                          </div>
                          <div
                            className={
                              message.sender === "user"
                                ? "bg-blue text-white p-sm rounded-sm h-[50px] flex items-center"
                                : "bg-gray text-text p-sm rounded-sm flex items-center h-[50px]"
                            }
                          >
                            {message.comment}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Paper>
                  {/* <Divider /> */}
      
                </div>
              </div>
            </div>
          )}

        <div style={{display: 'none'}}>
          <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px'}} ref={componentRef2}>
            <Printdoc orderDetail={orderDetail} edit={true} upload={false} view={false} pharmacy={pharmacyDetail} />
          </div>
        </div>
          
        </Grid>
      </Grid>
    </div>
  );
}

export default RescheduleOrder;

import React, {useEffect, useState} from "react";
import moment from "moment";
import { useNavigate } from 'react-router';

import "./orderObject.css";


function PharmacyEmployeeObject({ pharmacyEmployee }) {


  const navigate = useNavigate();

  return (
    <div className="driver-div">
      <div className="driver-first">
        <h3 className="driver-date">{pharmacyEmployee.username}</h3>
        <h2 className="cust-name-driver"> {pharmacyEmployee.first_name} { pharmacyEmployee.last_name}</h2>
      </div>
      <div className="driver-second">
        <h3 className="driver-detail">Contact: {pharmacyEmployee.contact}</h3>
        <h2 className="driver-detail">Email: {pharmacyEmployee.email}</h2>
      </div>
    
      <div className="driver-forth">
        <div className="driver-comp-status">
          {/* <p className="driver-status-text">Address:{pharmacyEmployee.address}</p> */}
          <p className="driver-status-text">phone:{pharmacyEmployee.contact}</p>
          </div>
        {/* <div className="driver-progress-bar">
          <div className="done" style={{ width: `${donePercentage}%` }}>{donePercentage.toFixed(2)} %</div>
          <div className="fail" style={{ width: `${failPercentage}%` }}>{failPercentage.toFixed(2)} %</div>
          <div className="pending" style={{ width: `${pendingPercentage}%` }}>{pendingPercentage.toFixed(2)} %</div>
        </div> */}
      </div>
      <div className="driver-fifth">
        <h3 className="driver-detail">Status </h3>
        <p className={`driver-status ${pharmacyEmployee.is_active ? "actived" : "deactivated"}`}>{pharmacyEmployee.is_active ? "Active" : "Deactivated"}</p>
      </div>
      <div>
        {/* <button className="pharmacy-action-delete" onClick={() => deleteDriver(pharmacyEmployee.id)}>
          delete
        </button> */}
      </div>
      <div className="driver-six">
        <button className="driver-action"  onClick={() => navigate(`/pharmacy/${pharmacyEmployee.pharmacy_id}/edit/employee/${pharmacyEmployee.id}`)}>
          Account Setting
        </button>
        {/* <h3 className="driver-detail-date">From {moment(driver.createdAt).format('MM-DD-YYYY')}</h3> */}
      </div>
    </div>
  );
}

export default PharmacyEmployeeObject;

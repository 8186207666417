import React, { useState, useEffect, useRef } from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useParams, useNavigate } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { toast } from "react-toastify";
import { getApiWithAuth, postApiWithAuth, patchApiWithAuth, putApiWithAuth } from "../../utils/api";
import { CREATE_TICKET, CREATE_COMMENT, ALL_PHARMACY_LIST} from "../../utils/apiUrls";
import { AttachFile, CloseRounded, Send } from "@mui/icons-material";
import {
  Avatar,
  Paper,
  InputBase,
  IconButton,
  Divider,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
} from "@material-ui/core";
import TicketCard from "../MainComponents/TicketList/TicketCard/TicketCard";
const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    chatContainer: {
      flex: 1,
      overflowY: "auto",
      padding: theme.spacing(2),
    },
    message: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    inputContainer: {
      background: "#E2E2E2",
      display: "flex",
      padding: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    input: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
  }));
const Chat = ({ roomName, userId }) => {
    const classes = useStyles();
    const { pid, oid } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [ticketOrders, setTicketOrders] = useState([]);
    const [ticketId, setTicketId] = useState("");
    const [ticketDetail, setTicketDetail] = useState();
    const [search, setSearch] = useState("");
    const [uniquePharmacy, setUniquePharmacy] = useState([]);
    const [pharmacy, setPharmacy] = useState("");
    const [timer, setTimer] = useState("");
    const [selectedTicketId, setSelectedTicketId] = useState(null);
    const [formState, setFormState] = useState({
        ticket: ticketId,
        comment: "",
      });



    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const clientRef = useRef(null);
    const handleSaveTicket = async () => {
        console.log(formState)
        const formDataComment = new FormData();
        formDataComment.append("comment", formState.comment);
        formDataComment.append("ticket", ticketId);
        const responseComment = await postApiWithAuth(
          CREATE_COMMENT,
          formDataComment
        );
        if (responseComment.data !== undefined) {
          getTicketOrders();
          getTicketDetail(ticketId);
    
          setFormState({
            ticket: ticketId,
            comment: ""
          })
        } else {
          console.log("Error");
          getTicketOrders();
          getTicketDetail(ticketId);
          setFormState({
            ticket: ticketId,
            comment: ""
          })
        }
    
      };

  const handleCloseTicket = async (ticketId) => {
    // Send a PUT request to update the ticket status to 'Completed'
    const response = await patchApiWithAuth(CREATE_TICKET + `${ticketId}/`, {
      ticket_status: "Completed",
    });
    if (response.data !== undefined) {
      getTicketOrders();
      setTicketDetail(ticketId);
      toast.success("Ticket Closed Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      console.log("Error");
      toast.error("Some Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getTicketOrders();
      setTicketDetail(ticketId);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSaveTicket();
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
      const handleTicketClick = (ticketId) => {
        setSelectedTicketId(ticketId);
        // getTicketDetail(ticketId); // Assuming you want to call this here
      };
      const getTicketOrders = async () => {
        setLoading(true);
          let apiUrl = `${CREATE_TICKET}?pending=true`;
          if (timer === "Pending") {
            apiUrl += "&pending=true";
          } else if (timer === "Completed") {
            apiUrl += "&completed=true";
          }
          const response = await getApiWithAuth(apiUrl);
          if (response.data && response.data.data && response.data.data.length > 0) {
              console.log(response.data.data);
              setTicketOrders(response.data.data);
              setLoading(false);
              if (ticketId === "" && response.data.data[0].id) {
                  setTicketId(response.data.data[0].id);
                  getTicketDetail(response.data.data[0].id);
              }
          } else {
          setLoading(false);
        }
      };
      
      const getTicketDetail = async (id) => {
        setLoading(true);
        const response = await getApiWithAuth(CREATE_TICKET+`${id}/`);
        if (response.data !== undefined) {
          console.log(response.data)
          setTicketId(id)
          setTicketDetail(response.data.data)
          setLoading(false)
        } else {
          setTicketId(id)
          setLoading(false);
        }
      };    
      const HandleReopenTicket = async (id) => {
        setLoading(true);
        const response = await patchApiWithAuth(CREATE_TICKET+`${id}/`, {"ticket_status": "Pending"});
        console.log(response)
        if (response.data !== undefined) {
          setTicketId(id)
          setLoading(false)
          getTicketOrders();
          setTicketDetail(ticketId);
          toast.success("Ticket Re-Open Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          setTicketId(id)
          setLoading(false);
          getTicketOrders();
          setTicketDetail(ticketId);
        }
      };
      useEffect(() => {
        getTicketOrders();
      }, []);
    roomName= "808ae81d-ab22-463b-b409-3d6a90c9de88"
    userId=localStorage.getItem("UID")
    const userType = localStorage.getItem("userType");
    useEffect(() => {
        if (!roomName) {
            console.error("Room name is undefined!");
            return;
        }
        // socket = new WebSocket(import.meta.env.VITE_BACKENDURLWS+'ws/users/'+user_id+'/chat/');
        clientRef.current = new W3CWebSocket(`ws://127.0.0.1:8000/ws/users/${userId}/chat/`);


        clientRef.current.onopen = () => {
            console.log('WebSocket Client Connected');
        };

        clientRef.current.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            setMessages((prevMessages) => [...prevMessages, dataFromServer]);
        };

        clientRef.current.onclose = () => {
            console.log('WebSocket Client Disconnected');
        };

        return () => {
            if (clientRef.current) {
                clientRef.current.close();
            }
        };
    }, [roomName]);

    const sendMessage = () => {
        if (clientRef.current.readyState === W3CWebSocket.OPEN) {
            clientRef.current.send(JSON.stringify({
                'message': input,
                'user_id': userId
            }));
            setInput('');
        } else {
            console.error('WebSocket is not open');
        }
    };

    return (
        <div className="OrderList">
            <div className="customer_support max-h-screen grid grid-cols-12">
      <div className="ticket col-span-4 max-h-screen min-h-[80vh] height-auto overflow-auto">
        <div className="flex flex-col gap-sm">
          {ticketOrders.length > 0 && ticketOrders.map((ticket, i) => (
            <a   className={`ticket-link ${selectedTicketId === ticket.id ? 'ticket-selected' : ''}`}
             onClick={() => {
              handleTicketClick(ticket.id);
              getTicketDetail(ticket.id);
            }} >
              <TicketCard ticket={ticket} />
            </a>
          ))}
        </div>
      </div>

      <div className={`classes.root col-span-8`}>
        <div className="flex flex-col gap-md items-end w-full p-md">
          <div>
          </div>
          <div className="w-full flex flex-col justify-between max-h-screen min-h-[80vh] height-auto">
            <Paper elevation={0} className={classes.chatContainer}>
              {ticketDetail !== undefined && ticketDetail.comments !== undefined && ticketDetail.comments.length > 0 && ticketDetail.comments.map((message, index) => (
                <div
                  key={index}
                  className={
                    message.sender === "user"
                      ? `classes.message flex gap-sm mb-sm`
                      : `classes.message flex gap-sm mb-sm flex-row-reverse`
                  }
                >
                  <Avatar className={classes.avatar}>
                    {message.sender === "user" ? "U" : "R"}
                  </Avatar>

                  <div className={"flex flex-col w-full"}>
                    <div
                      className={
                        message.sender === "user"
                          ? "flex items-center justify-between"
                          : "flex items-center justify-between flex-row-reverse"
                      }
                    >
                      <p
                        className={
                          message.sender === "user"
                            ? "text-blue text-[20px] font-[700] text"
                            : "text-text text-[20px] font-[700] text-end"
                        }
                      >
                        {message.comment_by.first_name} {message.comment_by.last_name}
                      </p>
                      <p
                        className={
                          message.sender === "user"
                            ? "flex gap-sm text-blue text"
                            : "flex gap-sm flex-row-reverse text-text text-end"
                        }
                      >
                        {/* Read <BiCheckDouble color="#2757FF" /> */}
                         {moment(message.created_at).format('MM-DD-YYYY')} | {moment(message.created_at).format('hh:mm:ss a')}
                      </p>
                    </div>
                <div
  className={`${
    (message.comment_by.type.type === "PharmacyOwner" || message.comment_by.type.type === "PharmacyEmployee")
      ? "bg-gray text-text "
      : (message.comment_by.type.type === "Admin" || message.comment_by.type.type === "Staff")
        ? "bg-blue text-white"
        : ""
  } p-sm rounded-sm h-[50px] flex items-center`}
>
  {message.comment}
</div>


                  </div>
                </div>
              ))}
            </Paper>

            <div className="relative rounded-md mx-auto w-full max-w-[800px] flex justify-center bg-[#E2E2E2]">

              {ticketDetail !== undefined && ticketDetail.ticket_status !== "Completed" ?
              <Paper
                elevation={0}
                className={`classes.inputContainer background bg-[#E2E2E2] items-start flex justify-between p-sm w-full`}
              >
                <InputBase
                  placeholder="Type a message..."
                  name="comment"
                  value={formState.comment}
                  autoComplete="off"
                  onChange={handleInputChange}
                  className={"classes.input bg-[#E2E2E2] w-[80%]"}
                  onKeyPress={handleKeyPress}
                />

                <div className="bg-[#E2E2E2]">
                  <IconButton color="primary" onClick={handleSaveTicket}>
                    <Send />
                  </IconButton>
                </div>
              </Paper>
              :
              userType === "PharmacyOwner" ?
              <button onClick={() => HandleReopenTicket(ticketId)}  style={{color: 'red'}} className="text-[16px] font-[700] text-center">
                Re-Open Ticket
              </button>
              :
              userType === "PharmacyEmployee" &&
              <button onClick={() => HandleReopenTicket(ticketId)}  style={{color: 'red'}} className="text-[16px] font-[700] text-center">
                Re-Open Ticket
              </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
        // <div>
        //     <div>
        //         {messages.map((message, index) => (
        //             <div key={index}>
        //                 <b>{message.user}</b>: {message.message} <i>{message.timestamp}</i>
        //             </div>
        //         ))}
        //     </div>
        //     <input
        //         type="text"
        //         value={input}
        //         onChange={(e) => setInput(e.target.value)}
        //     />
        //     <button onClick={sendMessage}>Send</button>
        // </div>
    );
};

export default Chat;

import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="Partner with Pill Drop to revolutionize your pharmacy operations and elevate customer satisfaction by providing doorstep medication delivery. Our service guarantees fast, reliable, and secure prescription deliveries, enabling your business to grow seamlessly. Focus on delivering exceptional healthcare while we handle the logistics. Join Pill Drop today and witness the transformative impact on your business!" />
      <meta name="keywords" content="Pilldrop ai, medication management, health, pharmacy" />
      <meta name="author" content="Pill Drop" />
      <title>Pilldrop</title>
    </Helmet>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();

import React, {useState, useEffect} from 'react'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ForwardIcon from '@mui/icons-material/Forward';
import { toast } from 'react-toastify';
import { ArrowDropDownCircle, CheckBox } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Button,
  Menu,
  Paper,
  Grid,
  DialogContent,
  IconButton,
  FormControl,
  Checkbox
} from "@mui/material";
import { DateRange } from "react-date-range";
import { 
  PHARMACY_REPORT,
  PHARMACY_REPORT_RANGE,
  ALL_DRIVERS_REPORTS,
  PHARMACY_REPORT_COUNT,
  ALL_PHARMACY_LIST,
  DRIVERS_ROUTE
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth, postApiWithAuth, putApiWithAuth,
} from "../../../utils/api";

import DriverReportObject from '../../MiniComponents/DriverReportObject';
import './DriverReport.css';

const isCurrentWeek = (dateRange) => {
  const [startDate, endDate] = dateRange.split(' - ');
  const currentDate = new Date();

  // Parse the date strings into Date objects
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Check if the current date is within the date range
  return currentDate >= startDateObj && currentDate <= endDateObj;
};

function DriverReport() {
  const [loading, setLoading] = useState(false);
  const [driverReports, setDriverReports] = useState([]);
  const [pharmacyOrderList, setPharmacyOrderList] = useState([]);
  const [pharmacy, setPharmacy] = useState("");
  const [duration, setDuration] = React.useState(1);
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [deliveryTime, setDeliveryTime] = React.useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [deliveryTimeFrom, setDeliveryTimeFrom] = React.useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [pageIndex, setPageIndex] = useState(0);
  const [driverList, setDriverList] = useState([]);
  const [driver, setDriver] = useState(null);
  const [activeDriverList, setActiveDriverList] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  // const today = new Date();
  // const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  // const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
  // // Calculate the start date as the first Monday of the month
  // const firstDayOfWeek = firstDayOfMonth.getDay(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  // const daysToAdd = firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek; // Days to add to get to the first Monday
  // const startDate = new Date(firstDayOfMonth);
  // startDate.setDate(firstDayOfMonth.getDate() + daysToAdd);
  
  // // Calculate the end date as the last Sunday of the month
  // const lastDayOfWeek = lastDayOfMonth.getDay(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  // const daysToSubtract = lastDayOfWeek === 0 ? 0 : lastDayOfWeek; // Days to subtract to get to the last Sunday
  // const endDate = new Date(lastDayOfMonth);
  // endDate.setDate(lastDayOfMonth.getDate() - daysToSubtract);
  
  // // Assuming you are using React hooks like useState
  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate,
  //     endDate,
  //     key: "selection",
  //   },
  // ]);
  const today = new Date();
  const endDate = new Date(today);
  // Calculate the first day of the current month
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  // Calculate the start date as the Monday of the week in which the 1st of the month falls
  const firstDayOfWeek = firstDayOfMonth.getDay(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const daysToSubtract = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1; // Days to subtract to get to the previous Monday
  const startDate = new Date(firstDayOfMonth);
  startDate.setDate(firstDayOfMonth.getDate() - daysToSubtract);

  // Set the date range state
  const [dateRange, setDateRange] = useState([
       {
        startDate,
        endDate,
        key: "selection",
      },
    ]);
  const getDrivers = async () => {
    try {
      const Response = await getApiWithAuth(DRIVERS_ROUTE, {"justList": true});;
      setDriverList(Response.data.data);
      const filteredDrivers = Response.data.data.filter(item => item.isactive);
      setUniquePharmacy(Response.data.data.map((item) => ({id: item.id, name: item.name})));
      setActiveDriverList(filteredDrivers);
    } catch {
      toast.error(`Backend Error for get driver list!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setMergerRoute(false);
    }
  };

  useEffect(() => {
    getDrivers();
  }, []);
  const getpharmacyOrderList = async () => {
    setLoading(true);
    const driverList = driver || [];
    let formData = {
      data: driverList.length === 0 ? 'all' : 'specific',
      from: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
      to: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
      driver: driverList,
    };
  
  
    const response = await putApiWithAuth(ALL_DRIVERS_REPORTS, formData);
    if (response.data !== undefined) {
      console.log("api response",response.data)
    const { driverReports } = response.data;
    setDriverReports(driverReports);
      setPharmacyOrderList(response.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getpharmacyOrderList();
  }, [dateRange, pharmacy, driver]);

  const handleSelect = (ranges) => {
    if (ranges.selection.endDate) {
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };
  const handleDriverChange = (e, value) => {
    if (value !== null) {
      setDriver(value.id);
    } else {
      setDriver(null);
    }
  };
  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };
  const [filterName, setFilterName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterName(
      typeof value === 'string' ? value.split(',') : value,
    );

    const pharmacyIds = uniquePharmacy
      .filter(pharmacy => value.includes(pharmacy.name))
      .map(pharmacy => pharmacy.id);
    setDriver(pharmacyIds);
  };
  return (
    <div>
       <div className="second-row">
        <div className="filter-container">
           <div className="search-filter-container">
            <FormControl className="filter-input">
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                IconComponent={(props) => <ArrowDropDownCircle {...props} sx={{ color: 'black', width: 20 }} />}
                multiple
                displayEmpty
                sx={{
                  fontSize: '11px',
                  "& .MuiSvgIcon-root": {
                    color: 'black'
                  }
                }}
                MenuProps={{
                  sx: {
                    mt: '8px'
                  }
                }}
                value={filterName}
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p style={{ fontSize: '14px', opacity: 1,fontFamily: "Saira Extra Condensed" }}>Select Driver</p>;
                  }
                  return <p style={{ fontSize: '14px', opacity: 1,fontFamily: "Saira Extra Condensed" }}>{selected.length > 1 ? `${selected.slice(0, 1)}  ...` : selected}</p>
                }}
              >
                {uniquePharmacy?.map((driver, index) => (
                  <MenuItem
                    key={index}
                    value={driver.name}
                    sx={{
                      fontSize: '13px',
                      fontFamily: "Saira Extra Condensed"
                    }}
                  >
                    <Checkbox size="small" checked={filterName.indexOf(driver.name) > -1} />
                    {driver.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> 
        </div>
        <div className="date-input-container">
          <div type="text" className="date-input">
            <CalendarMonthIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={toggleCalendar}
            />
            {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
            <ForwardIcon style={{ fontSize: "32px" }} />
            {dateRange[0].endDate
              ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
              : ""}
          </div>
          {isCalendarVisible && (
            <div className="calendar-container">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )}
        </div>
      </div>
      <div className='list-head'>
        <h2 className='listPharmacyOrderHeader'>WEEKLY REPORTS</h2>
      </div>
      <div class='tab-total'>
        
      </div>
      <div className='user-obj'>
      {driverReports?.length > 0 && (
  <>
    {/* <h1 style={{textAlign: 'center', fontWeight: '700', fontSize: '16px'}}>{OverallDateRange.overall_dateRange}</h1> */}
    {driverReports.slice().reverse().map((pharmacyOrder, i) => (
      <div key={i}>
        {pharmacyOrder.driverReports
 && (
          <DriverReportObject pharmacyOrder={pharmacyOrder} deliveryTime={deliveryTime} deliveryTimeFrom={deliveryTimeFrom} />
        )}
      </div>
    ))}
  </>
)}
      </div>
      {/* <div className='bottom-tab'>
        <div className='btm-pagi'>
          <button className='pag-row'>Row per page - 100</button>
          <a href="/" className="pag-per">Page 1 - 98 <ExpandCircleDownIcon className="pag-icon" /></a>
        </div>
      </div> */}
    </div>
  )
}

export default DriverReport



import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router";
import {
  ADD_DRIVER,
  ADD_BANK,
  ADD_VEHICLE,
  USER_TYPE_URL,
  FACILITY_TEAM_URL,
  FACILITY_Team_api,
  FACILITY_Update_api,
  FACILITY_Team_bank,
  FacilityTeam_updatebank,
  FACILITY_Team_york,
  FACILITY_Team_document,
  FacilityTeam_updateyork,
  FACILITY_Team_document_update,
} from "../../../utils/apiUrls";
import {
  postApiWithAuth,
  getApiWithAuth,
  postFormDataAPI,
  patchApiWithAuth,
} from "../../../utils/api";

import "./addFacilityTeam.css";
function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}
function AddFacilityTeam() {
  const [pharmacyId, setPharmacyId] = useState(null);
  const navigate = useNavigate();
  const [idImage, setIdImage] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [idImage1, setIdImage1] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [backendimg, setbackendimg] = useState(
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
  );
  let currentDate = new Date();
  let tenYearsAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 10));
  const [workSchedule, setWorkSchedule] = useState({});
  const [Fronts, setFronts] = useState("");
  const [Front, setFront] = useState("");
  const [Back, setBack] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [personalDetail, setPersonalDetail] = useState({
    firstName: "",
    lastName: "",
    show_dashboard: true,
  show_rx: true,
  show_pharm: true,
  show_driver: true,
  show_routes: true,
  show_cities: true,
  show_facuser: true,
  show_reports: true,
    address: "",
    dateOfBirth: tenYearsAgo,
    phoneNumber: "",
    cellNumber: "",
    isActive: true,
    logo: "",
    email: "",
    socialSecurityNo: "",
    idIssued: "",
    idExpired: "",
    hourPrice: 0,
    overTimePrice: 0,
    type: "Staff",
    username: "",
    password: "",
    addtionalNote: "",
    pictureIdNo: "",
    regularHourPrice: "",
    id1card: "",
    id2card: "",
    user: 1,
  });
  const [userTypes, setUserTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  

  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountTitle: "",
    accountNo: "",
    achRouting: "",
  });

  const [facility_team_work, setfacility_team_work] = useState({
    day: "s",
    active: true,
    time: "s",
  });
  const [facility_team_documents, setfacility_team_documents] = useState({
    description: " ",
    document: null,
  });

  const [fieldErrors, setFieldErrors] = useState({
    address: '',
    phoneNumber: '',
    cellNumber: '',
    email: '',
    firstName: '',
    lastName: '',
    password:""
  });


  const handleCheckboxChange = (day) => {
    setWorkSchedule((prev) => ({
      ...prev,
      [day]: { ...prev[day], active: !prev[day].active },
    }));
  };

  const handleTimeChange = (day, time) => {
    setWorkSchedule((prev) => ({
      ...prev,
      [day]: { ...prev[day], time },
    }));
  };

  const getUserType = async () => {
    setLoading(true);

    const response = await getApiWithAuth(USER_TYPE_URL);

    if (response.data !== undefined) {
      const filteredData = response.data.data.filter(
        (item) => item.type === "Admin" || item.type === "Staff" || item.type === "SubAdmin"
      );
      setUserTypes(filteredData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserType();
  }, []);

  const handlePersonalInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalDetail({
      ...personalDetail,
      [name]: value,
    });
    setFieldErrors({ ...fieldErrors, [name]: '' });
  };

  const handleBankInputChange = (event) => {
    const { name, value } = event.target;
    setBankDetails({
      ...bankDetails,
      [name]: value,
    });
  };
  const handleDocumentInputChange = (event) => {
    const { name, value } = event.target;
    setfacility_team_documents({
      ...facility_team_documents,
      [name]: value,
    });
  }


  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Check if fields are empty
    if (!personalDetail.address) {
      errors.address = 'Address is required';
      isValid = false;
    }
    if (!personalDetail.phoneNumber) {
      errors.phoneNumber = 'Phone Number is required';
      isValid = false;
    }
    if (!personalDetail.cellNumber) {
      errors.cellNumber = 'Cell Number is required';
      isValid = false;
    }
    if (!personalDetail.email) {
      errors.email = 'Email is required';
      isValid = false;
    }
    if (!personalDetail.firstName) {
      errors.firstName = 'First name is required';
      isValid = false;
    }
    if (!personalDetail.username) {
      errors.username = 'User name is required';
      isValid = false;
    }
    if (!personalDetail.lastName) {
      errors.lastName = 'Last name is required';
      isValid = false;
    }
    if (!personalDetail.password) {
      errors.password = 'Password is required';
      isValid = false;
    }
    if (!personalDetail.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
      isValid = false;
    }
    if (!personalDetail.overTimePrice) {
      errors.overTimePrice = 'Over Time Price is required';
      isValid = false;
    }
    if (!personalDetail.hourPrice) {
      errors.hourPrice = 'Hourly Price is required';
      isValid = false;
    }
    if (!personalDetail.regularHourPrice) {
      errors.regularHourPrice = 'Regular Hour Price is required';
      isValid = false;
    }

    setFieldErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (validateForm()) {
      // console.log("personalDetail", personalDetail);
      const updatedPersonalDetail = new FormData();
      updatedPersonalDetail.append("firstName", personalDetail.firstName);
      updatedPersonalDetail.append("lastName", personalDetail.lastName);
      updatedPersonalDetail.append("address", personalDetail.address);
      updatedPersonalDetail.append("dateOfBirth", personalDetail.dateOfBirth);
      updatedPersonalDetail.append("phoneNumber", personalDetail.phoneNumber);
      updatedPersonalDetail.append("cellNumber", personalDetail.cellNumber);
      updatedPersonalDetail.append("email", personalDetail.email);
      updatedPersonalDetail.append(
        "socialSecurityNo",
        personalDetail.socialSecurityNo
      );
      updatedPersonalDetail.append("idIssued", personalDetail.idIssued);
      updatedPersonalDetail.append("idExpired", personalDetail.idExpired);
      updatedPersonalDetail.append("isactive", personalDetail.isactive);
      updatedPersonalDetail.append("hourPrice", personalDetail.hourPrice);
      updatedPersonalDetail.append(
        "overTimePrice",
        personalDetail.overTimePrice
      );
      updatedPersonalDetail.append("type", personalDetail.type);
      updatedPersonalDetail.append("name", personalDetail.username);
      updatedPersonalDetail.append("password", personalDetail.password);
      updatedPersonalDetail.append(
        "addtionalNote",
        personalDetail.addtionalNote
      );
      updatedPersonalDetail.append("pictureIdNo", personalDetail.pictureIdNo);
      updatedPersonalDetail.append(
        "regularHourPrice",
        personalDetail.regularHourPrice
      );
      updatedPersonalDetail.append("id1card", personalDetail.id1card);
      updatedPersonalDetail.append("show_dashboard", personalDetail.show_dashboard);
      updatedPersonalDetail.append("show_facuser", personalDetail.show_facuser);
      updatedPersonalDetail.append("show_reports", personalDetail.show_reports);
      updatedPersonalDetail.append("show_rx", personalDetail.show_rx);
      updatedPersonalDetail.append("show_pharm", personalDetail.show_pharm);
      updatedPersonalDetail.append("show_driver", personalDetail.show_driver);
      updatedPersonalDetail.append("show_routes", personalDetail.show_routes);
      updatedPersonalDetail.append("show_cities", personalDetail.show_cities);

      updatedPersonalDetail.append("id2card", personalDetail.id2card);
      updatedPersonalDetail.append("logo", personalDetail.logo);
      updatedPersonalDetail.append("isActive", personalDetail.isActive);
      
      const responsePersonal = await postApiWithAuth(
        FACILITY_Team_api,
        updatedPersonalDetail
      );

      if (responsePersonal != undefined){

        if (bankDetails.accountNo !== "") {
          const bankdata = new FormData();
          bankdata.append("achRouting", bankDetails.achRouting);
          bankdata.append("accountNo", bankDetails.accountNo);
          bankdata.append("accountTitle", bankDetails.accountTitle);
          bankdata.append("bankName", bankDetails.bankName);
          bankdata.append("facilityTeam", responsePersonal.data.data.id); // No need for parseInt
          const responseBank = await postApiWithAuth(FACILITY_Team_bank, bankdata);
        }
        if (facility_team_documents.description !== "") {
          const doctdata = new FormData();
          doctdata.append("description", facility_team_documents.description);
          // doctdata.append("document", facility_team_documents.document);
          doctdata.append("facilityTeam", responsePersonal.data.data.id);
          const responseDocument = await postApiWithAuth(
            FACILITY_Team_document,
            doctdata
          );
        }
        if (facility_team_work.day) {
          const workdata = new FormData();
          workdata.append("day", facility_team_work.day);

          workdata.append("time", facility_team_work.time);

          const responseWork = await postApiWithAuth(FACILITY_Team_york, workdata);
        }
      }

      navigate("/list/facility");
    }
  };
  const handleEditClick = () => {
    // Trigger the hidden file input when EditIcon is clicked
    document.getElementById("imageInput").click();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePersonalInputChangeChecked = (event) => {
    const { name, checked } = event.target;
    console.log("namevalue",name,checked)
    setPersonalDetail(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };
  const handleImageUpload = (event) => {

    const fileObject = event.target.files[0];
    setPersonalDetail({
      ...personalDetail,
      id1card: fileObject,
    });

    const { name, size, type, lastModified } = fileObject;
    const fileBlob = new Blob([fileObject], { type });

    const file = new File([fileBlob], name, { lastModified });
    // setFrontImage(file);
    if (file) {
      const reader = new FileReader();
      //

      reader.onloadend = () => {
        setFront(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClick2 = () => {
    // Trigger the hidden file input when EditIcon is clicked
    document.getElementById("imageInput2").click();
  };

  const handleImageUpload2 = (event) => {
    const fileObject = event.target.files[0];
    setPersonalDetail({
      ...personalDetail,
      id2card: fileObject,
    });
    const { name, size, type, lastModified } = fileObject;

    const fileBlob = new Blob([fileObject], { type });

    const file = new File([fileBlob], name, { lastModified });
    // setBackImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the image URL in the state
        setBack(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClickk = () => {
    // Trigger the hidden file input when EditIcon is clicked
    document.getElementById("imageInputt").click();
  };

  const handleImageUpload3 = (event) => {
    // const file = event.target.files[0];
    // setFrontImage(file);
    const fileObject = event.target.files[0];

    setPersonalDetail({
      ...personalDetail,
      logo: fileObject,
    });
    // Extract the file data from the object
    const { name, size, type, lastModified } = fileObject;

    // Create a Blob from the file data
    const fileBlob = new Blob([fileObject], { type });

    // Create a File instance
    const file = new File([fileBlob], name, { lastModified });

    if (file) {
      const reader = new FileReader();
      // Read the contents of the image file as a data URL
      reader.onloadend = () => {
        // Set the image URL in the state
        setFronts(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Grid container className="form-grid">
        <Grid item className="drive-grid" xs={3}>
          <div className="form-sec-head-facility">PD Staff Info</div>
        </Grid>{" "}
        <Grid item className="drive-grid ml-5" xs={3}></Grid>
        <Grid item className="drive-grid " xs={2}>
          {/* Hidden file input triggered by the EditIcon click */}
          <input
            autoComplete="off"
            type="file"
            id="imageInputt"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageUpload3}
          />
          {Fronts ? (
            <img
              src={Fronts}
              style={{ borderRadius: "100%", height: "50px", width: "50px" }}
              className="id-image"
              alt="Image 1"
            />
          ) : (
            <img
              src={backendimg}
              style={{ borderRadius: "100%", height: "50px", width: "50px" }}
              className="id-image"
              alt="Image 1"
            />
          )}{" "}
          <div className="img-icon" onClick={handleEditClickk}>
            {/* Render your EditIcon component here */}
            <EditIcon />
          </div>
        </Grid>
        <Grid item className="drive-grid "></Grid>
      </Grid>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}

        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">First Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="firstName"
            placeholder="First Name"
            value={personalDetail.firstName}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.firstName && <span className="error-message">{fieldErrors.firstName}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Last Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="lastName"
            placeholder="Last Name"
            value={personalDetail.lastName}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.lastName && <span className="error-message">{fieldErrors.lastName}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={6}>
          <label className="facility-field-label">Address</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="address"
            placeholder="Address"
            value={personalDetail.address}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.address && <span className="error-message">{fieldErrors.address}</span>}
        </Grid>

        {/* Second Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Date of Birth</label>
          <input
            autoComplete="off"
            type="date"
            className="facility-field"
            name="dateOfBirth"
            placeholder="YYYY/MM/DD"
            value={personalDetail.dateOfBirth}
            onChange={handlePersonalInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Phone Number</label>
          <input
            autoComplete="off"
            type="number"
            className="facility-field"
            name="phoneNumber"
            placeholder="Phone Number"
            value={personalDetail.phoneNumber}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.phoneNumber && <span className="error-message">{fieldErrors.phoneNumber}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Cell Number</label>
          <input
            autoComplete="off"
            type="number"
            className="facility-field"
            name="cellNumber"
            placeholder="Cell Number"
            value={personalDetail.cellNumber}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.cellNumber && <span className="error-message">{fieldErrors.cellNumber}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Email</label>
          <input
            autoComplete="off"
            type="email"
            className="facility-field"
            name="email"
            placeholder="Email"
            value={personalDetail.email}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.email && <span className="error-message">{fieldErrors.email}</span>}
        </Grid>

        {/* Third& Forth Row */}
        <Grid item className="drive-grid" xs={6} md={6}>
          <Grid container spacing={1}>
            {/* Four Fields in a 3-3-3-3 Configuration */}
            <Grid
              item
              className="drive-in-grid facility-sec-grid"
              xs={6}
              md={6}
            >
              <label className="facility-field-label">SSN</label>
              <input
                autoComplete="off"
                type="text"
                className="facility-field"
                name="socialSecurityNo"
                placeholder=" Number"
                value={personalDetail.socialSecurityNo}
                onChange={handlePersonalInputChange}
              />
            </Grid>
            <Grid item className="drive-grid-2" xs={6} md={6}>
              <label className="facility-field-label">
                Picture ID Number
              </label>
              <input
                autoComplete="off"
                type="text"
                className="facility-field"
                name="pictureIdNo"
                placeholder="Picture ID Number"
                value={personalDetail.pictureIdNo}
                onChange={handlePersonalInputChange}
              />
            </Grid>
            <Grid
              item
              className="drive-in-grid facility-sec-grid"
              xs={6}
              md={6}
            >
              <label className="facility-field-label">ID Issued</label>
              <input
                autoComplete="off"
                type="text"
                className="facility-field"
                name="idIssued"
                placeholder="License Issued"
                value={personalDetail.idIssued}
                onChange={handlePersonalInputChange}
              />
            </Grid>
            <Grid item className="drive-grid-2" xs={6} md={6}>
              <label className="facility-field-label">ID Expires</label>
              <input
                autoComplete="off"
                type="text"
                className="facility-field"
                name="idExpired"
                placeholder="License Expires"
                value={personalDetail.idExpired}
                onChange={handlePersonalInputChange}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Right Grid */}
        {/* <Grid item className="drive-grid" xs={6} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="img-sec-facility">
                <p>Picture ID Front</p>
                <div className="img-icon">
                  <VisibilityIcon />
                  <EditIcon />
                </div>
              </div>
              <img src={idImage} className="id-image" alt="Image 1" />
            </Grid>
            <Grid item xs={6}>
              <div className="img-sec-facility">
                <p>Picture ID Back</p>
                <div className="img-icon">
                  <VisibilityIcon />
                  <EditIcon />
                </div>
              </div>
              <img src={idImage} className="id-image" alt="Image 2" />
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
        {/* Right Grid */}
        <Grid item className="drive-grid" xs={6} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="img-sec-driver">
                <p>Picture Id Front</p>
                <div className="img-icon" onClick={handleEditClick}>
                  {/* Render your EditIcon component here */}
                  <EditIcon />
                </div>
                {/* Hidden file input triggered by the EditIcon click */}
                <input
                  autoComplete="off"
                  type="file"
                  id="imageInput"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>
              {Front ? (
                <img src={Front} className="id-image" alt="Image 1" />
              ) : (
                <img src={idImage} className="id-image" alt="Image 1" />
              )}
            </Grid>
            <Grid item xs={6}>
              <div className="img-sec-driver">
                <p>Picture Id Back</p>
                <div className="img-icon" onClick={handleEditClick2}>
                  {/* Render your EditIcon component here */}
                  <EditIcon />
                </div>
                {/* Hidden file input triggered by the EditIcon click */}
                <input
                  autoComplete="off"
                  type="file"
                  id="imageInput2"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleImageUpload2}
                />
              </div>
              {Back ? (
                <img src={Back} className="id-image" alt="Image 1" />
              ) : (
                <img src={idImage1} className="id-image" alt="Image 1" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="form-sec-head-facility">Account Credentials</div>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}
        {/* <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Account Type</label>
          <input
                autoComplete="off"
            type="text"
            className="facility-field"
            name="bankName"
            placeholder="PillDrop Delivery Team"
            value={bankDetails.bankName}
            onChange={handleBankInputChange}
          />
        </Grid> */}
        <Grid item className="drive-grid" xs={3}>
          <label className="driver-field-label">Type</label>
          <select
            className="driver-field"
            name="type"
            value={personalDetail.type || ""} // Added a fallback to empty string to handle null or undefined
            onChange={handlePersonalInputChange}
          >
            {userTypes.map((item, index) => (
              <option key={index} value={item.type}>
                {item.type}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">User Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="username"
            placeholder="User Name"
            value={personalDetail.username}
            onChange={handlePersonalInputChange}
            required
          />
          {fieldErrors.username && <span className="error-message">{fieldErrors.username}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Email</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="email"
            placeholder="Email"
            value={personalDetail.email}
            onChange={handlePersonalInputChange}
          />
                    {fieldErrors.email && <span className="error-message">{fieldErrors.email}</span>}
        </Grid>

        {/* Second Row */}
        <Grid item className="drive-grid" xs={6} md={6}>
          <label className="facility-field-label">Password</label>
          <input
            autoComplete="off"
            type={showPassword ? "text" : "password"}
            className="facility-field"
            name="password"
            placeholder="Password"
            value={personalDetail.password}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.password && <span className="error-message">{fieldErrors.password}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={6} md={6}>
          <label className="facility-field-label">Confirm Password</label>
          <div style={{display: 'flex'}}>
            <input
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              className="facility-field"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={personalDetail.confirmPassword}
              onChange={handlePersonalInputChange}
            />
            <button
              // className="show-hide-password"
              onClick={togglePasswordVisibility}
            >
              {showPassword 
              ? 
                <VisibilityOffIcon />
              :
                <RemoveRedEyeIcon />
              }
            </button>
          </div>
          {fieldErrors.confirmPassword && <span className="error-message">{fieldErrors.confirmPassword}</span>}
        </Grid>
      </Grid>

      <div className="form-sec-head-facility">Banking Details</div>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Bank Name</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="bankName"
            placeholder="Bank Name"
            value={bankDetails.bankName}
            onChange={handleBankInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Title on Account</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="accountTitle"
            placeholder="Title on Account"
            value={bankDetails.accountTitle}
            onChange={handleBankInputChange}
          />
        </Grid>

        {/* Second Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Account Number</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="accountNo"
            placeholder="Account Number"
            value={bankDetails.accountNo}
            onChange={handleBankInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">ACH Routing</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="achRouting"
            placeholder="ACH Routing"
            value={bankDetails.achRouting}
            onChange={handleBankInputChange}
          />
        </Grid>
      </Grid>

      <div className="form-sec-head-facility">Work Schedule</div>
      <Grid container className="form-grid" spacing={2}>
        {Object.keys(workSchedule).map((day) => (
          <Grid item className="drive-grid" xs={2}>
            <label className="facility-field-label">
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </label>
            <Checkbox
              checked={workSchedule[day].active}
              onChange={() => handleCheckboxChange(day)}
            />
            <input
              autoComplete="off"
              type="text"
              className="facility-field"
              placeholder="9am to 5pm"
              value={workSchedule[day].time}
              onChange={(e) => handleTimeChange(day, e.target.value)}
            />
          </Grid>
        ))}
      </Grid>

      {/* <Grid container className="form-grid" spacing={2}>
        
        <Grid item className="drive-grid" xs={2}>
          <label className="facility-field-label">Monday</label>
          <Checkbox name="bussAddress" checked={true} onChange={""} />
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="vehicleType"
            placeholder="9am to 5pm"
            value={facility_team_work.day}
            onChange={handleTeamWorkInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="facility-field-label">Tuesday</label>
          <Checkbox name="bussAddress" checked={true} onChange={""} />
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="make"
            placeholder="9am to 5pm"
            value={facility_team_work.day}
            onChange={handleTeamWorkInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="facility-field-label">Wednesday</label>
          <Checkbox name="bussAddress" checked={true} onChange={""} />
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="model"
            placeholder="9am to 5pm"
            value={facility_team_work.day}
            onChange={handleTeamWorkInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="facility-field-label">Thursday</label>
          <Checkbox name="bussAddress" checked={true} onChange={""} />
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="color"
            placeholder="9am to 5pm"
            value={facility_team_work.day}
            onChange={handleTeamWorkInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="facility-field-label">Friday</label>
          <Checkbox name="bussAddress" checked={true} onChange={""} />
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="modelYear"
            placeholder="9am to 5pm"
            value={facility_team_work.day}
            onChange={handleTeamWorkInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={2}>
          <label className="facility-field-label">Saturday</label>
          <Checkbox name="bussAddress" checked={true} onChange={""} />
            
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="plateNo"
            placeholder="9am to 5pm"
            value={facility_team_work.day}
            onChange={handleTeamWorkInputChange}
          />
        </Grid> */}
      {/* <Grid item className="drive-grid" xs={2}>
          <label className="facility-field-label">Sunday</label>
          <input
            autoComplete="off"
            type="text"
            className="facility-field"
            name="plateNo"
            placeholder="9am to 5pm"
            value={vehicleDetail.plateNo}
            onChange={handleVehicleInputChange}
          />
        </Grid> 
      </Grid>*/}

      <div className="form-sec-head-facility">Pay Rates</div>
      <Grid container className="form-grid" spacing={2}>
        {/* First Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Hourly Pay</label>
          <input
            autoComplete="off"
            type="number"
            className="facility-field"
            name="hourPrice"
            placeholder="$20"
            value={personalDetail.hourPrice}
            onChange={handlePersonalInputChange}
          />
         {fieldErrors.hourPrice && <span className="error-message">{fieldErrors.hourPrice}</span>}
        </Grid>
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Over Time</label>
          <input
            autoComplete="off"
            type="number"
            className="facility-field"
            name="overTimePrice"
            placeholder="$50"
            value={personalDetail.overTimePrice}
            onChange={handlePersonalInputChange}
          />
          {fieldErrors.overTimePrice && <span className="error-message">{fieldErrors.overTimePrice}</span>}
        </Grid>

        {/* Second Row */}
        <Grid item className="drive-grid" xs={3}>
          <label className="facility-field-label">Regular Hours / Week</label>
          <input
            autoComplete="off"
            type="number"
            className="facility-field"
            name="regularHourPrice"
            placeholder="$04"
            value={personalDetail.regularHourPrice}
            onChange={handlePersonalInputChange}
          />
           {fieldErrors.regularHourPrice && <span className="error-message">{fieldErrors.regularHourPrice}</span>}
        </Grid>
        {/* <Grid item className="drive-grid" xs={3}>
              <label className="facility-field-label">Failed Delivery</label>
              <input
                autoComplete="off"
                  type="number"
                  className="facility-field"
                  name="failedDeliveryPrice"
                  placeholder="Failed Delivery"
                  value={personalDetail.failedDeliveryPrice}
                  onChange={handlePersonalInputChange}
              />
          </Grid> */}
      </Grid>

      <Grid container className="form-grid" spacing={2}>
        {/* Four Fields in a 3-3-3-3 Configuration */}
        <Grid item className="facility-in-grid" xs={6}>
          <div className="form-sec-head-facility">Addtional Document</div>
          <textarea
            rows="8"
            className="facility-textarea-field"
            name="description"
            value={facility_team_documents.description}
            onChange={handleDocumentInputChange}
          />
        </Grid>
        <Grid item className="drive-grid" xs={6}>
          <div className="form-sec-head-facility ">Addtional Information</div>
          <textarea
            rows="8"
            className="facility-textarea-field"
            name="addtionalNote"
            value={personalDetail.addtionalNote}
            onChange={handlePersonalInputChange}
          />
        </Grid>
      </Grid>
      <div className="form-sec-head-facility">Permissions</div>
      <Grid container className="form-grid" spacing={2}>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Dashboard</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_dashboard"
          checked={personalDetail.show_dashboard}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Rx</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_rx"
          checked={personalDetail.show_rx}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Pharmacies</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_pharm"
          checked={personalDetail.show_pharm}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Drivers</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_driver"
          checked={personalDetail.show_driver}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Routes</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_routes"
          checked={personalDetail.show_routes}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Cities</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_cities"
          checked={personalDetail.show_cities}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Facility User</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_facuser"
          checked={personalDetail.show_facuser}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      <Grid item className="drive-grid" xs={3}>
        <label className="facility-field-label">Show Reports</label>
        <input
          autoComplete="off"
          type="checkbox"
          className="facility-field small-checkbox"
          name="show_reports"
          checked={personalDetail.show_reports}
          onChange={handlePersonalInputChangeChecked}
        />
      </Grid>
      </Grid>
      <div className="facility-btn">
        {/* <button type="button" className="del-facility">Delete Account</button> */}
        <button type="submit" onClick={handleSubmit} className="save-facility">
          Save Details
        </button>
      </div>
    </div>
  );
}

export default AddFacilityTeam;

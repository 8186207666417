import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Menu as BaseMenu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem } from "@mui/base/MenuItem";
import { Dropdown } from "@mui/base/Dropdown";
import { flag } from "../../../../assests";
import { IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import Badge from "@mui/material/Badge";
import { ALL_USERS, ORDER_NOT_UPDATE } from "../../../../utils/apiUrls";
import { getApiWithAuth, putApiWithAuth } from "../../../../utils/api";

export default function NewUpdatesDropdown({
  isNavDropdownsOpen,
  setIsNavDropdownsOpen,
}) {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState([]);
  const navigate = useNavigate();
  const { pid } = useParams();
  const dropdownRef = useRef(null);

  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  const userType = localStorage.getItem("userType");

  const handleClick = () => {
    setIsNavDropdownsOpen({ ...isNavDropdownsOpen, newUpdates: false });
    if (userType === "PharmacyOwner" || userType === "PharmacyEmployee") {
      navigate(`pharmacy/${pid}/ordersupdate`);
    } else {
      navigate("/ordersupdate");
    }
  };

  const handleButtonClick = (property) => {
    const updatedDropdowns = {};
    Object.keys(isNavDropdownsOpen).forEach((key) => {
      updatedDropdowns[key] = key === property;
    });
    setIsNavDropdownsOpen(updatedDropdowns);
    console.log(`Button clicked: ${property}`);
  };

  const getNotes = async () => {
    try {
      const response1 = await getApiWithAuth(ORDER_NOT_UPDATE);
      const allOrders = response1.data.data.data;
      console.log("response.data",allOrders)
      setOrders(response1.data.data.data);
      setCount(response1.data.data.total);
      const UID = localStorage.getItem("UID");
      const updatedPersonalDetail = new FormData();
      updatedPersonalDetail.append("user_id", UID);
      updatedPersonalDetail.append("action", "last_view_admin_notes");
      const responsePersonal = await putApiWithAuth(
        ALL_USERS + `all`,
        updatedPersonalDetail
      );
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsNavDropdownsOpen({ ...isNavDropdownsOpen, newUpdates: false });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavDropdownsOpen, setIsNavDropdownsOpen]);

  const latestThreeOrders = orders?.slice(0, 3);

  return (
    <div>
      <Dropdown
        open={isNavDropdownsOpen.newUpdates}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuButton>
          <IconButton
            color="inherit"
            onClick={() => handleButtonClick("newUpdates")}
          >
            <img className="top-icon" src={flag} alt="Logo" />
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ marginBottom: "18px" }}
              badgeContent={count}
              color="error"
            ></Badge>
          </IconButton>
        </MenuButton>

        <Menu
          ref={dropdownRef}
          className="z-[1500] left-[-11%] bg-gray py-sm px-md min-h-[60px] max-w-[650px] min-w-[400px] rounded-sm flex items-center justify-center"
        >
          <div className="flex flex-col gap-md">
            <p className="text-3xl" style={{ color: "#ee9c4a" }}>
              NEW UPDATE
            </p>
            {userType === "PharmacyOwner" || userType === "PharmacyEmployee" ? (
              orders && orders.length > 0 ? (
                orders?.slice(0, 3).map((order, index) => (
                  <MenuItem
                    key={index}
                    className="bg-white py-sm px-md rounded-xs"
                    onClick={createHandleMenuClick("Profile")}
                  >
                    <div className="flex flex-col gap-sm">
                      <div className="flex items-center justify-between">
                        <p className="text-md font-[700]">{order.slug}</p>
                        <p className="text-md font-[700]">
                          Client: {order.name}
                        </p>
                        <p
                          className="text-md font-[700]"
                          style={{ color: "#ee9c4a" }}
                        >
                          Update From Facility
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-md font-[400]">
                          {/* by : {order.createdby} */}
                        </p>
                        <p className="text-md font-[400]">
                          {moment(order.createdAt).format("MM-DD-YYYY")} -{" "}
                          {moment(order.createdAt).format("hh:mm:ss A")}
                        </p>
                      </div>
                    </div>
                  </MenuItem>
                ))
              ) : (
                <p>No data</p>
              )
            ) : orders && orders.length > 0 ? (
              orders?.slice(0, 3).map((order, index) => (
                <MenuItem
                  key={index}
                  className="bg-white py-sm px-md rounded-xs"
                  onClick={createHandleMenuClick("Profile")}
                >
                  <div className="flex flex-col gap-sm">
                    <div className="flex items-center justify-between">
                      <p className="text-md font-[700]">{order.slug}</p>
                      <p className="text-md font-[700]">
                        Client: {order.name}
                      </p>
                      <p className="text-md font-[700] text-red">
                        Update From Facility
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-md font-[400]">
                        {/* by : {order.createdby} */}
                      </p>
                      <p className="text-md font-[400]">
                        {moment(order.deliverAtSelected).format("MM-DD-YYYY")}
                        {moment(order.deliverAtSelected).format(
                          "hh:mm:ss A"
                        )}
                      </p>
                    </div>
                  </div>
                </MenuItem>
              ))
            ) : (
              <p>No data</p>
            )}
            <div className="flex justify-end gap-sm">
              <button
                onClick={() =>
                  setIsNavDropdownsOpen({
                    ...isNavDropdownsOpen,
                    newUpdates: false,
                  })
                }
                className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-red rounded-[2px]"
              >
                Clear
              </button>

              <button
                onClick={handleClick}
                className="w-[106px] h-[28px] text-[18px] font-[400] bg-white shadow-sm border border-[#0455A6] rounded-[2px]"
              >
                See All
              </button>
            </div>
          </div>
        </Menu>
      </Dropdown>
    </div>
  );
}

NewUpdatesDropdown.propTypes = {
  // You can add propTypes for your props here if needed
};

const Menu = BaseMenu;

Menu.propTypes = {
  open: true,
  defaultOpen: true,
  slotProps: PropTypes.shape({
    listbox: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    root: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
};

const MenuButton = BaseMenuButton;

MenuButton.propTypes = {
  className: PropTypes.string,
};

const MenuItem = BaseMenuItem;

MenuItem.propTypes = {
  className: PropTypes.string,
};

import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import { getApiWithAuth, deleteApiWithAuth } from "../../../utils/api";
import { 
  Table,
  // TextField,
  // Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import "./driverObject.css";
import { useReactToPrint } from 'react-to-print';

import { DRIVERS_API, USER_URL } from "../../../utils/apiUrls";

import {
  loginLogo,
  pharmacyAddress
} from '../../../assests';

const columns2 = [
  { id: 'date', label: 'Date', minWidth: 170 },
  { id: 'regularPay', label: 'Regular Pay', minWidth: 170 },
  { id: 'overTime', label: 'Over Time', minWidth: 170 },
  { id: 'hours', label: 'Hours', minWidth: 170 },
  { id: 'dailyAmount', label: 'Daily Amount', minWidth: 170 },
];

const useStyles = makeStyles(() => ({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff'
  },
  section: {
    margin: 10,
    padding: 10,
    fontWeight: '700',
    flexGrow: 1
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    marginTop: '0px',
    fontWeight: '700',
    textAlign: 'center',
    border: '1px solid black',
    minWidth: '30%',
    padding: '2px 10px'
  },
  driverName: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: '700',
    textAlign: 'center',
  },
  header2: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: '700',
    textAlign: 'center',
    border: '1px solid black',
    padding: '5px 10px',
  },
  Total: {
    fontSize: 24,
    float: 'right',
    marginBottom: 8,
    marginRight: 0,
    marginTop: '0px',
    fontWeight: '700',
    textAlign: 'center'
  },
  table: {
    width: '100%',
    border: '1px solid black',
    marginBottom: 0,
    fontWeight: '700',
    marginTop: "15px"
  }
}));

const generateRandomData = () => {
  const startDate = new Date('09/10/2023');
  const data = [{date: "$ Amount Per Hour", regularPay: "$17", overTime: "$25", hours: "", dailyAmount: 0}];

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);

    const randomHours = Math.floor(Math.random() * 8) + 1; // Random hours between 1 and 8
    const randomDailyAmount = Math.floor(Math.random() * 30) + 10; // Random daily amount between 10 and 40

    data.push({
      date: currentDate.toLocaleDateString(),
      regularPay: '8am To 2pm',
      overTime: 0,
      hours: randomHours,
      dailyAmount: randomDailyAmount,
    });
  }

  return data;
};


function DriverObject({ driver, onDelete, route }) {
  const [doneCount, setDoneCount] = useState(13);
  const [failCount, setFailCount] = useState(3);
  const [pendingCount, setPendingCount] = useState(4);

  const navigate = useNavigate();

  const componentRef = useRef();
  const classes = useStyles();
  const createDate = new Date();

  const data = generateRandomData();

  const totalSum = data.reduce((acc, row) => acc + row.dailyAmount, 0);

  function formatDateToMMDDYYYY(inputDate) {
    if (!inputDate) {
      return ''; // Return an empty string if the date is undefined or empty
    }
    const parts = inputDate.split('/');
    const day = parts[0].padStart(2, '0');
    const month = parts[1].padStart(2, '0');
    const year = parts[2];
    return `${month}/${day}/${year}`;
  }

  const donePercentage = (driver.deliveredOrders / driver.totalStops) * 100;
  const failPercentage = (driver.cancelledOrders / driver.totalStops) * 100;
  const pendingPercentage = (driver.pendingOrders / driver.totalStops) * 100;

  const handleDownloadPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Order_File.pdf'
    // onAfterPrint:()=>alert("sucess")
  });

  return (
    <div className="driver-div">
      <div className="driver-first">
        <h3 className="driver-date">Name</h3>
        <h2 className="cust-name-driver">{driver.name}</h2>
      </div>
      <div className="driver-second">
        <h3 className="driver-detail">Contact: {driver.phoneNumber}</h3>
        <h2 className="driver-detail">Email: {driver.email}</h2>
      </div>
      <div className="driver-third">
        <h3 className="driver-detail">Routes:{driver.routeCount} </h3>
        <h2 className="driver-detail">Stops: {driver.totalStops}</h2>
      </div>
      <div className="driver-forth">
        {/* <div className="driver-comp-status">
          <p className="driver-status-text">
            Done{" "}
            <span className="driver-done">
              {isNaN(driver.deliveredOrders) ? 0 : driver.deliveredOrders}
            </span>
          </p>
          <p className="driver-status-text">
            Fail{" "}
            <span className="driver-fail">
              {isNaN(driver.cancelledOrders) ? 0 : driver.cancelledOrders}
            </span>
          </p>
          <p className="driver-status-text">
            Pending{" "}
            <span className="driver-pending">
              {isNaN(driver.pendingOrders) ? 0 : driver.pendingOrders}
            </span>
          </p>
        </div>
        <div className="driver-progress-bar">
          <div
            className="done"
            style={{
              width: `${
                isNaN(donePercentage)
                  ? 0
                  : donePercentage
              }%`,
            }}
          >
            {isNaN(donePercentage)
              ? 0
              : donePercentage.toFixed(2)}{" "}
            %
          </div>
          <div
            className="fail"
            style={{
              width: `${
                isNaN(failPercentage)
                  ? 0
                  : failPercentage
              }%`,
            }}
          >
            {isNaN(failPercentage)
              ? 0
              : failPercentage.toFixed(2)}{" "}
            %
          </div>
          <div
            className="pending"
            style={{
              width: `${
                isNaN(pendingPercentage) ? 0 : pendingPercentage
              }%`,
            }}
          >
            {isNaN(pendingPercentage)
              ? 0
              : pendingPercentage.toFixed(2)}{" "}
            %
          </div>
        </div> */}
      </div>
      <div className="driver-fifth">
        <h3 className="driver-detail">Status </h3>
        <p
          className={`driver-status ${
            driver.isactive ? "actived" : "deactivated"
          }`}
        >
          {driver.isactive ? "Active" : "Deactivated"}
        </p>
      </div>
      <div>
        {/* <button className="pharmacy-action-delete" onClick={() => deleteDriver(driver.id)}>
          delete
        </button> */}
      </div>
      <div className="driver-six">
        <button
          className="driver-action"
          onClick={() => navigate(`/edit/driver/${driver.id}`)}
        >
          Account Setting
        </button>
        {/* <button
          className="driver-action"
          onClick={() => handleDownloadPDF()}
        >
          Print
        </button> */}
        <h3 className="driver-detail-date">From {moment(driver.createdAt).format('MM-DD-YYYY')}</h3>
      </div>
      <div style={{ display: 'none' }}>
        <div ref={componentRef} style={{ margin: 20 }}>
          <div className={classes.page}>
            <div className={classes.section}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{fontWeight: '400'}}>Date Issued: {moment(createDate).format('MM/DD/YYYY')}</p>
                <p style={{fontWeight: '400'}}>Page 1/1</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '80px' }}>
                <div style={{ minWidth: '550px' }}>
                  <img src={loginLogo} alt="Mantis" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
              </div>

              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <h4 className={classes.header}>
                  Invoice <span style={{marginLeft: '10px'}}>({data[1].date} To {data[data.length - 1].date})</span>
                </h4>
              </div>
              <h4 className={classes.driverName}>{driver.name}</h4>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header}>Date</TableCell>
                    <TableCell className={classes.header}>Regular Pay</TableCell>
                    <TableCell className={classes.header}>Over Time</TableCell>
                    <TableCell className={classes.header}>Hours</TableCell>
                    <TableCell className={classes.header}>Daily Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.header2}>{row.date}</TableCell>
                      <TableCell className={classes.header2}>{row.regularPay}</TableCell>
                      <TableCell className={classes.header2}>{row.overTime}</TableCell>
                      <TableCell className={classes.header2}>{row.hours}</TableCell>
                      <TableCell className={classes.header2}>{row.dailyAmount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={{display: 'flex', justifyContent: 'space-between', padding: '5px 15px 5px 30px', border: '1px solid black'}}>
                <p style={{fontSize: "22px"}}>Total Orders = 12</p>
                <p style={{fontSize: "22px"}}>Total Amount = ${(totalSum).toFixed(2)}</p>
              </div>
              <h3 style={{fontSize: "18px", fontWeight: 700, marginTop: '15px'}}>Form Of Payment: _________________________</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverObject;

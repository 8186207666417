import React, {useEffect, useState, useRef} from "react";
import { 
  Table,
  // TextField,
  // Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  // FormControl,
  MenuItem
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PrintDocList from "../../CommonComponents/PrintDoc/PrintDocList";
import moment from "moment";
import {
  action1,
  pharmacyCall,
  route_report,
  loginLogo,
  landline_white,
  text,
  pharmacyAddress
} from '../../../assests';
import { useReactToPrint } from 'react-to-print';

import { useNavigate } from 'react-router';

import { toast } from 'react-toastify';

import "./DriverScanObject.css";
import OrderDetailsModal from "./OrderDetailsModal";

import { 
  PHARMACY_REPORT_COUNT,
  ORDER_DETAIL_LIST,
 } from "../../../utils/apiUrls";

import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
const useStyles = makeStyles(() => ({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff'
  },
  section: {
    margin: 10,
    padding: 10,
    fontWeight: '700',
    flexGrow: 1
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    marginTop: '0px',
    fontWeight: '700',
    textAlign: 'center',
    border: '1px solid black',
    minWidth: '30%',
    padding: '0 10px'
  },
  header2: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: '700',
    textAlign: 'center'
  },
  header3: {
    fontSize: 18,
    marginBottom: 0,
    marginTop: '0px',
    fontWeight: '400',
    textAlign: 'center'
  },
  header4: {
    fontSize: 18,
    marginBottom: 8,
    marginTop: '0px',
    fontWeight: '400',
    textAlign: 'center'
  },
  Total: {
    fontSize: 24,
    float: 'right',
    marginBottom: 8,
    marginRight: 0,
    marginTop: '0px',
    fontWeight: '700',
    textAlign: 'center'
  },
  Sums: {
    fontSize: 18,
    marginBottom: '1px',
    marginTop: '0px',
    fontWeight: '700'
  },
  table: {
    width: '100%',
    border: '1px solid black',
    marginBottom: 0,
    fontWeight: '700'
  },
  tableHeader: {
    // backgroundColor: '#f0f0f0',
    fontWeight: '700',
    borderBottomWidth: "2px",
  },
  tableRow: {},
  tableCell: {
    padding: 5,
    fontSize: 12,
    borderBottomColor: '#000000',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    fontWeight: '700'
  },
  firstColumn: {
    fontWeight: '700',
    // borderBottomWidth: 2,
  },
  firstRow: {
    fontWeight: '700',
    borderBottomWidth: "2px",
  }
}));

function DriverScanObject({driver, onDelete, route}) {
  console.log("Driver props in DriverScanObject:", driver, typeof(driver)); // Add this line to check the props received  
  const [isFirstDriverForDate, setIsFirstDriverForDate] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);

  // Parse the date to get the day name and format it as desired
  const formattedDate = moment(driver.date).format("dddd MM-DD-YYYY");

  const navigate = useNavigate();

  const classes = useStyles();
  const componentRef = useRef();
  const componentRef2 = useRef(); 
  const handleIconClick = async (ids) => {
    setLoading(true);
    const chunkSize = 250;
    let allSignedOrders = [];
    const splitIntoChunks = (array, chunkSize) => {
      let result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        let chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
      }
      return result;
    };
  
    const idChunks = splitIntoChunks(ids, chunkSize);
  
    for (const chunk of idChunks) {
      try {
        const response = await getApiWithAuth(ORDER_DETAIL_LIST + `?order_master_ids=${chunk.join(',')}`);
        if (response.data !== undefined) {
          allSignedOrders = allSignedOrders.concat(response.data.data);
        } else {
          console.log("Error in chunk: ", chunk);
          // Handle the error appropriately
        }
      } catch (error) {
        console.log("API call error: ", error);
        // Handle the API call error appropriately
      }
    }
    
    // Set the selected order details
    setSelectedOrderDetails(allSignedOrders);
    
    // Open the modal
    setModalOpen(true);
  
    setLoading(false);
  };
  
  return (
    <>
      {loading && <CustomLoader />}
      <div className="driver-scan-container">
        <div className="driver-details">         
            <>
        <div className="pharmacyOrder-div">
      <div className="pharmacyOrder-first">
      <h3 className="driver-date">Delivery Team</h3>
        <h2 className="cust-name-driver">{driver.driver_name}</h2>
      </div>
      <div className="pharmacyReport-obj">
        <h3 className="pharmacyReport-head">Orders Picked From Pharmacy</h3>
      </div>
      <div className="pharmacyReport-obj2">
            <div className="pharmacyReport-obj3" >
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>Same Day 2PM to 6PM</h3>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>Same Day 5PM to 9PM</h3>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>Next Day All Orders</h3>
            </div>
      </div>
      <div className="pharmacyReport-obj2">
            <div className="pharmacyReport-obj3" >
              <h3 className="pharmacyReport-text" style={{marginRight: '20px'}}>{driver.details.picked_at_same_day_2pm_to_6pm} Orders</h3>
              <i className="fa fa-eye" aria-hidden="true" onClick={() => handleIconClick(driver.details.picked_at_same_day_2pm_to_6pm_order_ids)} style={{ fontSize: '12px' }}></i>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '20px'}}> {driver.details.picked_at_same_day_5pm_to_9pm} Orders</h3>
              <i className="fa fa-eye" aria-hidden="true" onClick={() => handleIconClick(driver.details.picked_at_same_day_5pm_to_9pm_order_ids)} style={{ fontSize: '12px' }}></i>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '20px'}}>{driver.details.picked_at_next_day_all_orders} Orders</h3>
            <i className="fa fa-eye" aria-hidden="true" onClick={() => handleIconClick(driver.details.driver.details.picked_at_next_day_all_orders_order_ids)}  style={{ fontSize: '12px' }}></i>
            </div>
      </div>
      <div className="pharmacyReport-obj">
        <h3 className="pharmacyReport-head">Orders Picked From Facility</h3>
      </div>
      <div className="pharmacyReport-obj2">
            <div className="pharmacyReport-obj3" >
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>7AM To 11AM</h3>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>11AM To 3PM</h3>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>3PM To  7PM</h3>
            </div>
      </div>
      <div className="pharmacyReport-obj2">
            <div className="pharmacyReport-obj3" >
              <h3 className="pharmacyReport-text" style={{marginRight: '20px'}}>{driver.details.recievedFaciltyAt_7am_to_11am} Orders</h3>
              <i className="fa fa-eye" aria-hidden="true" onClick={() => handleIconClick(driver.details.recievedFaciltyAt_7am_to_11am_order_ids)} style={{ fontSize: '12px' }}></i>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '20px'}}>{driver.details.recievedFaciltyAt_11am_to_3pm} Orders</h3>
              <i className="fa fa-eye" aria-hidden="true" onClick={() => handleIconClick(driver.details.recievedFaciltyAt_11am_to_3pm_order_ids)} style={{ fontSize: '12px' }}></i>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '20px'}}>{driver.details.recievedFaciltyAt_3pm_to_7pm} Orders</h3>
              <i className="fa fa-eye" aria-hidden="true" onClick={() => handleIconClick(driver.details.recievedFaciltyAt_3pm_to_7pm_order_ids)} style={{ fontSize: '12px' }}></i>
            </div>
      </div>

      <div className="pharmacyReport-obj">
        <h3 className="pharmacyReport-head">Direct Dispatch
(Urgent  only)</h3>
      </div>
      <div className="pharmacyReport-obj2">
            <div className="pharmacyReport-obj3" >
              <h3 className="pharmacyReport-text" style={{marginRight: '30px', fontWeight: 'bold' }}>Urgent Orders</h3>
            </div>
            {/* <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>1pm To 3PM</h3>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>4PM To  6PM</h3>
            </div> */}
      </div>
      <div className="pharmacyReport-obj2">
            <div className="pharmacyReport-obj3" >
              <h3 className="pharmacyReport-text" style={{marginRight: '20px'}}>{driver.details.Urgent} Orders</h3>
              <i className="fa fa-eye" aria-hidden="true" onClick={() => handleIconClick(driver.details.Urgent_order_ids)} style={{ fontSize: '12px' }}></i>
            </div>
            {/* <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>0 Orders</h3>
            </div>
            <div className="pharmacyReport-obj3" style={{minWidth: '104px'}}>
              <h3 className="pharmacyReport-text" style={{marginRight: '30px'}}>0 Orders</h3>
            </div> */}
      </div>
    </div>
    </>
    </div>
    </div>
    {/* <div className="order-obj">
        {orders?.length > 0 &&
          orders.map((order) => (
            <OrderDetailsModal open={modalOpen} onClose={() => setModalOpen(false)} orderDetails={orders} order={order}/>
          ))}
      </div> */}
    {/* <OrderDetailsModal open={modalOpen} onClose={() => setModalOpen(false)} orderDetails={orders} /> */}
    <OrderDetailsModal
        open={modalOpen} // Pass modalOpen state as open prop
        onClose={() => setModalOpen(false)}
        orderDetails={selectedOrderDetails} // Pass selectedOrderDetails as orderDetails prop
      />
    </>
  );
}

export default DriverScanObject;

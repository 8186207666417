import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Paper,
  InputBase,
  IconButton,
  Divider,
} from "@material-ui/core";
import { AttachFile, CloseRounded, Send } from "@mui/icons-material";
import TicketCard from "./TicketCard/TicketCard";
import { closeIconRed } from "../../../assests";
import { BiCheckDouble } from "react-icons/bi";
import "./CustomerSupport.css";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  chatContainer: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    background: "#E2E2E2",
    display: "flex",
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
}));

const CustomerSupport = () => {
  const classes = useStyles();
  const [messages, setMessages] = useState([
    { text: "Hello!", sender: "receiver" },
    { text: "Hi there!", sender: "user" },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [value, setValue] = useState(4);

  const [closeChat, setcloseChat] = useState(false);

  const handleSend = () => {
    if (newMessage.trim() !== "") {
      setMessages([...messages, { text: newMessage, sender: "user" }]);
      setNewMessage("");
    }
  };

  const handleFileAttach = () => {
    // Handle file attachment logic (e.g., open file picker)
    console.log("Attach File clicked");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };
  return (
    <div className="customer_support max-h-screen grid grid-cols-12">
      <div className="ticket col-span-4 max-h-screen min-h-[80vh] height-auto overflow-auto">
        <div className="flex flex-col gap-sm">
          <TicketCard />
          <TicketCard />
          <TicketCard />
          <TicketCard />
          <TicketCard />
          <TicketCard />
          <TicketCard />
          <TicketCard />
          <TicketCard />
          <TicketCard />
        </div>
      </div>

      <div className={`classes.root col-span-8`}>
        <div className="flex flex-col gap-md items-end w-full p-md">
          <div>
            <div
              onClick={() => setcloseChat(!closeChat)}
              className="flex items-center gap-sm"
            >
              {closeChat ? (
                <p className="text-[16px] font-[700] text-center">
                  Close Ticket
                </p>
              ) : (
                <p className="text-[16px] font-[700] text-center">
                  Issue Resolved <br /> Close Ticket
                </p>
              )}
              <CloseRounded className="cursor-pointer" />
              {/* <img width="20px" height="auto" src={closeIconRed} alt="phone" /> */}
            </div>
          </div>

          <div className="w-full flex flex-col justify-between max-h-screen min-h-[80vh] height-auto">
            <Paper elevation={0} className={classes.chatContainer}>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={
                    message.sender === "user"
                      ? `classes.message flex gap-sm mb-sm`
                      : `classes.message flex gap-sm mb-sm flex-row-reverse`
                  }
                >
                  <Avatar className={classes.avatar}>
                    {message.sender === "user" ? "U" : "R"}
                  </Avatar>

                  <div className={"flex flex-col w-full"}>
                    <div
                      className={
                        message.sender === "user"
                          ? "flex items-center justify-between"
                          : "flex items-center justify-between flex-row-reverse"
                      }
                    >
                      <p
                        className={
                          message.sender === "user"
                            ? "text-blue text-[20px] font-[700] text"
                            : "text-text text-[20px] font-[700] text-end"
                        }
                      >
                        Serrano, maria
                      </p>
                      <p
                        className={
                          message.sender === "user"
                            ? "flex gap-sm text-blue text"
                            : "flex gap-sm flex-row-reverse text-text text-end"
                        }
                      >
                        Read <BiCheckDouble color="#2757FF" /> 10-24-2023
                        12:20AM
                      </p>
                    </div>
                    <div
                      className={
                        message.sender === "user"
                          ? "bg-blue text-white p-sm rounded-sm h-[50px] flex items-center"
                          : "bg-gray text-text p-sm rounded-sm flex items-center h-[50px]"
                      }
                    >
                      {message.text}
                    </div>
                  </div>
                </div>
              ))}
            </Paper>
            {/* <Divider /> */}

            {closeChat ? (
              <div className="my-[10px]">
                <p className="text-center text-[20px] font-[700]">
                  Issue Resolved Chat closed
                </p>
                <p className="text-center text-[16px]">
                  BY : Carlos Capital Drugs
                </p>
                <p className="text-center text-[16px]">10-12-2023 11:45 pm</p>

                <Box className="flex gap-xs items-center justify-center mx-auto">
                  <p className="text-[20px] font-[700]">How it went?</p>
                  <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                </Box>
              </div>
            ) : null}

            <div className="relative rounded-md mx-auto w-full max-w-[800px] flex justify-center bg-[#E2E2E2]">
              {closeChat ? (
                <div className="absolute z-10 justify-center">
                  <p className="text-center text-[20px] font-[700] mt-[10px]">
                    Thank you for your feed back.
                  </p>

                  <p className="text-center text-[16px] w-[420px] mx-auto">
                    once pharmacy employee clicks on rating star this line will
                    appear instead of how it went and only facility side will be
                    able to see this rating in chat menu.
                  </p>
                </div>
              ) : null}

              <Paper
                elevation={0}
                className={`classes.inputContainer background bg-[#E2E2E2] items-start flex justify-between p-sm w-full`}
              >
                <InputBase
                  autoComplete="off"
                  placeholder="Type a message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className={"classes.input bg-[#E2E2E2] w-[80%]"}
                  onKeyPress={handleKeyPress}
                />

                <div className="bg-[#E2E2E2]">
                  <IconButton color="primary" onClick={handleFileAttach}>
                    {/* You can replace the below icon with an appropriate file attachment icon */}
                    <AttachFile />
                  </IconButton>
                  <IconButton color="primary" onClick={handleSend}>
                    <Send />
                  </IconButton>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupport;

import React from "react";
import "./Privacy.css";

const PrivacyPolicy = () => (
  <div className="privacy">
    <header>
      <h1>Privacy Policy</h1>
      <p>Last Updated: 04/15/2024</p>
    </header>

    <section>
      <h2>Introduction</h2>
      <p>Pill Drop values your privacy and is committed to protecting your personal information. This Privacy Policy ("Policy") explains how we collect, use, and safeguard your information when you use our services, which facilitate the delivery of medications from pharmacies to patients' addresses. By accessing or using our website (<a href="https://pilldrop.ai">https://pilldrop.ai</a>) and services, you consent to the data practices described in this Policy. This Policy is designed to comply with U.S. laws and regulations.</p>
    </section>

    <section>
      <h2>Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> Information such as your name, address, email address, phone number, billing details, and prescription information, provided voluntarily by you or your pharmacy.</li>
        <li><strong>Automatically Collected Information:</strong> Information collected through cookies, third-party tracking technologies, and server logs, including your IP address, browser type, and operating system.</li>
        <li><strong>Non-Personal Information:</strong> Anonymous demographic information, such as age, gender, and preferences, to enhance our services.</li>
      </ul>
    </section>

    <section>
      <h2>Why We Collect Information</h2>
      <ul>
        <li>Fulfill your medication delivery requests.</li>
        <li>Communicate with you about your orders, our services, and promotional offers (with your consent).</li>
        <li>Improve our services and website functionality.</li>
        <li>Conduct market research and analyze trends to better understand user needs.</li>
      </ul>
    </section>

    <section>
      <h2>Use of Information</h2>
      <p>Pill Drop does not sell, rent, or lease customer information to third parties. We use your personal information to:</p>
      <ul>
        <li>Process and deliver your medication orders.</li>
        <li>Communicate with you regarding your deliveries and account.</li>
        <li>Inform you about other products and services from Pill Drop that may interest you (with your consent).</li>
        <li>Conduct surveys and market research to improve our services.</li>
      </ul>
    </section>

    <section>
      <h2>Data Retention</h2>
      <p>We retain your personal information only as long as necessary for the purposes outlined in this Policy, taking into account legal and business requirements.</p>
    </section>

    <section>
      <h2>Disclosure of Information</h2>
      <p>We may disclose your information under the following circumstances:</p>
      <ul>
        <li>To pharmacies and delivery partners to fulfill your medication orders.</li>
        <li>In aggregate form, where your identity cannot be reasonably determined.</li>
        <li>As required by law, or in response to legal requests.</li>
        <li>To auditors or service providers who maintain confidentiality.</li>
        <li>To enforce our Terms of Service or protect our rights and property.</li>
      </ul>
    </section>

    <section>
      <h2>Security</h2>
      <p>We implement robust security measures to protect your information. Sensitive information, such as prescription and payment data, is encrypted during transmission. Access to personal information is restricted to authorized personnel only.</p>
    </section>

    <section>
      <h2>Children's Privacy</h2>
      <p>Our services are not directed at children under 14. We do not knowingly collect personal information from children. If we discover we have inadvertently collected such information, we will promptly delete it or obtain parental consent.</p>
    </section>

    <section>
      <h2>Your Rights</h2>
      <ul>
        <li>Access and update your personal information.</li>
        <li>Opt-out of receiving marketing communications.</li>
        <li>Request deletion of your personal information, subject to legal requirements.</li>
      </ul>
    </section>

    <section>
      <h2>Changes to This Policy</h2>
      <p>We reserve the right to update this Policy at any time. We will notify you of significant changes by posting a notice on our website. Continued use of our website and services indicates your acceptance of the updated Policy.</p>
    </section>

    <section>
      <h2>Contact Us</h2>
      <p>If you have questions or concerns about this Privacy Policy, please contact us:</p>
      <p>
        Pill Drop<br />
        1535 Stillwell Ave, Bronx, NY 10461-2211, United States<br />
        Phone: (845) 314-0341<br />
        Email: <a href="mailto:info@pilldrop.ai">info@pilldrop.ai</a>
      </p>
    </section>

    <section>
      <h2>GDPR Compliance</h2>
      <p>Pill Drop complies with the General Data Protection Regulation (GDPR) for users in the European Union. If you provide information to us, it will be transferred to and processed in the United States. By providing personal information, you consent to its storage and processing as described in this Policy.</p>
    </section>

    <section>
      <h2>COPPA Compliance</h2>
      <p>Pill Drop complies with the Children’s Online Privacy Protection Act (COPPA). We do not collect personal information from children under 13 without verifiable parental consent. If we become aware of any collection of such information, we will take steps to delete it or obtain consent.</p>
    </section>

    <section>
      <h2>External Links</h2>
      <p>Our website may contain links to other websites. We are not responsible for the privacy practices of such websites. We encourage you to review the privacy policies of any website you visit.</p>
    </section>

    <section>
      <h2>Acceptance of Terms</h2>
      <p>By using our website, you accept the terms of this Privacy Policy. If you do not agree, please refrain from using our website and services. Continued use following any updates signifies your acceptance of those changes.</p>
    </section>
  </div>
);

export default PrivacyPolicy;
import React from "react";
import "./VerbalInteraction.css";

const VerbalInteraction = () => {
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', margin: '20px' }}>
            <h1 style={{ color: '#2c3e50' }}>Consent Protocol for Medication Delivery Updates</h1>
            <div className="step" style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#2c3e50' }}>1. Introduction and Identification:</h2>
                <p>The dispatcher introduces themselves and identifies the patient by confirming their name and order details.</p>
            </div>
            <div className="step" style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#2c3e50' }}>2. Explanation of Service:</h2>
                <p>The dispatcher explains the purpose of the call, which includes confirming the details of the medication delivery and ensuring that the patient receives timely updates and instructions.</p>
            </div>
            <div className="step" style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#2c3e50' }}>3. Request for Consent:</h2>
                <p>The dispatcher clearly asks for the patient's consent to receive calls and text messages. The request is made in a straightforward and respectful manner to ensure the patient understands and agrees voluntarily.</p>
                <p><strong>Example Script:</strong> "To ensure you receive timely updates and important instructions about your medication delivery, may we have your permission to contact you via calls and text messages at the phone number you provided?"</p>
            </div>
            <div className="step" style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#2c3e50' }}>4. Confirmation of Consent:</h2>
                <p>The dispatcher waits for the patient's response. If the patient agrees, the dispatcher confirms the consent verbally and notes it in the system.</p>
                <p><strong>Example Script:</strong> "Thank you for your consent. We will use your phone number to provide updates and instructions regarding your medication delivery. If you have any questions or wish to opt-out at any time, please contact our customer service."</p>
            </div>
            <div className="step" style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#2c3e50' }}>5. Documentation:</h2>
                <p>The dispatcher documents the patient's consent in the order management system, ensuring there is a record of the consent given during the interaction.</p>
            </div>
            <div className="step" style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#2c3e50' }}>6. Follow-Up Information:</h2>
                <p>The dispatcher provides the patient with information on how they can opt-out of receiving these communications if they change their mind in the future.</p>
                <p><strong>Example Script:</strong> "If at any time you decide you no longer wish to receive these updates, you can contact our customer service, and we will respect your preference immediately."</p>
            </div>
            <p>By following this protocol, we ensure that all patients are fully informed and give their explicit consent to receive necessary communications about their medication delivery. This process helps maintain transparency and compliance with privacy regulations.</p>
        </div>
    );
};

export default VerbalInteraction;

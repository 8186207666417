import React, {useEffect, useState} from "react";
import moment from "moment";
import { useNavigate } from 'react-router';

import {
  landline,
  mobile,
  pharmacyCall,
  pharmacyCallBlack,
  pharmacyChat,
  text,
  pharmacyAddress
} from '../../../assests';

import "./pharmacyObject.css";
import { 
  PHARMACY_URL,
  PHARAMCY_USER
 } from "../../../utils/apiUrls";
import {
  getApiWithAuth,
  deleteApiWithAuth
} from "../../../utils/api";


function PharmacyObject({pharmacy,onDelete}) {
  const [doneCount, setDoneCount] = useState(13);
  const [failCount, setFailCount] = useState(3);
  const [pendingCount, setPendingCount] = useState(4);

  useEffect(() => {
    setDoneCount(pharmacy.done_orders);
    setFailCount(pharmacy.fail_orders);
    setPendingCount(pharmacy.pending_orders);
  }, [pharmacy])
  const navigate = useNavigate();
  const deletePharmacy = async (id) => {
    // console.log("data----",id)
    // setLoading(true);
    const response = await deleteApiWithAuth(`${PHARMACY_URL}${id}/`);
    // http://127.0.0.1:8000/accounts/users-pharmacy/61/
    console.log(response.data)
    // debugger
    onDelete && onDelete(); 
    // if (response.data.status === 200) {
    //   onDelete && onDelete(); // Call the callback function after successful deletion
    // }
    
  }
  
  const totalOrders = doneCount + failCount + pendingCount;

  const donePercentage = (doneCount / totalOrders) * 100;
  const failPercentage = (failCount / totalOrders) * 100;
  const pendingPercentage = (pendingCount / totalOrders) * 100;

  return (
    <div className="pharmacy-div">
      <div className="pharmacy-first">
        <h3 className="pharmacy-date">{pharmacy.name}</h3>
        <div className="detail-div-pharmacy">
          <div className="d-flex-div">
          <img className="detail-in-icon-ph" src={pharmacyCall} alt="Logo" />
          <h3 className="detail-in-text-pharmacy">{pharmacy.contact_no}</h3>
          </div>
          <div  className="d-flex-div" >
          <img className="detail-in-icon-ph" src={text} alt="Logo" />
          <h3 className="detail-in-text-pharmacy">{pharmacy.email}</h3>
        </div>
        </div>
        
        <div className="detail-div-pharmacy">
          <img className="detail-in-icon-ph" src={pharmacyAddress} alt="Logo" />
          <h3 className="detail-in-text-pharmacy">{pharmacy.location}</h3>
        </div>
      </div>
      <div className="pharmacy-second">
        <div>
        <div className="detail-div-ph">
        <h3 className="pharmacy-detail">Pharmacy Calls</h3>
          <img className="detail-in-icon-ph1" src={pharmacyCallBlack} alt="Logo" />
          
        </div>
        <div className="detail-div-ph">
        <h2 className="pharmacy-detail">Pharmacy Chats</h2>
          <img className="detail-in-icon-ph1" src={pharmacyChat} alt="Logo" />
        
        </div>
        </div>
       
      </div>
      <div className="pharmacy-third">
        <div>
        <h3 className="pharmacy-detail">Total Order</h3>
        <h2 className="pharmacy-detail">{pharmacy.total_orders}</h2>
        </div>
      </div>
      <div className="pharmacy-forth">
        <div className="pharmacy-comp-status">
          <p className="pharmacy-status-text">Done <span className="pharmacy-done">{pharmacy.done_orders}</span></p>
          <p className="pharmacy-status-text">Fail <span className="pharmacy-fail">{pharmacy.fail_orders}</span></p>
          <p className="pharmacy-status-text">Pending <span className="pharmacy-pending">{pharmacy.pending_orders}</span></p>
        </div>
        <div className="pharmacy-progress-bar">
          <div className="done" style={{ width: `${donePercentage}%` }}>{donePercentage.toFixed(2)} %</div>
          <div className="fail" style={{ width: `${failPercentage}%` }}>{failPercentage.toFixed(2)} %</div>
          <div className="pending" style={{ width: `${pendingPercentage}%` }}>{pendingPercentage.toFixed(2)} %</div>
        </div>
      </div>
      <div className="pharmacy-fifth">
        <h3 className="pharmacy-detail">Status </h3>
        <p className={`pharmacy-status ${pharmacy.isActive ? "actived" : "deactivated"}`}>{pharmacy.isActive ? "Active" : "Deactivated"}</p>
        {/* <p className={`pharmacy-status actived`}>{pharmacy.isActive ? "Active" : "DeActive"}</p> */}
      </div>
      <div>
        {/* <button className="pharmacy-action-delete" onClick={() => deletePharmacy(pharmacy.id)}>
          delete
        </button> */}
      </div>
      <div className="pharmacy-six">
        <button className="pharmacy-action p-1 m-3" onClick={() => navigate(`/edit/pharmacy/${pharmacy.id}`)}>
          Account Setting
        </button>
        {/* <h3 className="pharmacy-detail">{moment(pharmacy.createdAt).format('MM-DD-YYYY')}</h3> */}
      </div>
    </div>
  );
}

export default PharmacyObject;
